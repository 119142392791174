.compare-page .cta-1 {
    background-image: url(/public/global/compare/images/bg-whyhashpro.png);
    background-color: var(--hashpro);
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    padding: 40px 15px;
    color: #fff;
    text-align: center;
    /* margin-bottom      : 30px; */
    width: 100%;
    display: none;
}

.compare-page .cta-1 .grid-container {
    margin-top: 20px;
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    -moz-column-gap: 50px;
    column-gap: 50px;
    flex-wrap: wrap;
}

.compare-page .cta-1 .grid-container .timer-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.compare-page .cta-1 .grid-container .timer-container .colon {
    transform: translateY(-5px);
}

.compare-page .cta-1 .grid-container .timer-container span {
    color: black;
    font-weight: 600;
    font-size: 14px;
}

.compare-page .cta-1 .grid-container .timer-container .timer {
    color: white;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    background-color: black;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 1px 1px 1px white;
}

.compare-page .cta-1 p {
    color: #000;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10px;
    font-size: 26px !important;
    text-transform: uppercase;
}

.compare-page .cta-1 button {
    padding: 0.675rem 4.625rem;
    border-radius: 7px;
    background-color: #000;
    color: white;
    font-weight: 500;
    box-shadow: none;
    text-align: center;
    font-size: 16px;
    border: none;
    margin-top: 10px;
    transition: 0.2s;
    /* letter-spacing: 1px; */
    /* white-space   : pre-wrap; */
}

.compare-page .cta-1 button:hover {
    cursor: pointer;
    background-color: white;
    color: black;
}

.compare-page .cta-1 .undertext {
    margin-top: 5px;
    font-size: 0.8rem !important;
    color: rgba(0, 0, 0, 0.787) !important;
}

.mouse-shadow {
    position: fixed;
    top: 50%;
    z-index: 2;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: #fff;
}

p.light {
    color: rgb(187, 187, 187) !important;
    line-height: 22px;
}

p.light-1 {
    color: rgb(220, 220, 220);
}

:root {
    --swiper-theme-color: var(--hashpro) !important;
    --swiper-pagination-bullet-inactive-color: black !important;
}

.compare-page .crafters .swiper-horizontal>.swiper-pagination-bullets,
.compare-page .crafters .swiper-pagination-bullets.swiper-pagination-horizontal,
.compare-page .crafters .swiper-pagination-custom {
    bottom: -20px !important;
    padding-bottom: 20px;
}

.compare-page .crafters .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, 0.377) !important;
}

.compare-page .crafters .swiper-wrapper {
    padding-bottom: 40px;
}

.compare-page .crafters {
    position: relative;
}

.compare-page .crafters .splide {
    margin-top: 30px;
}

.members-container .swiper-button-container {
    position: relative;
    padding: 30px;
    padding-top: 20px;
}

.compare-page .crafters .swiper-pagination {
    z-index: 1000 !important;
}

.next-btn {
    position: absolute;
    top: 50%;
    right: -3%;
}

.prev-btn {
    position: absolute;
    top: 50%;
    left: -3%;
}

.next-btn:hover {
    cursor: pointer;
}

.prev-btn:hover {
    cursor: pointer;
}

@media (min-width: 0px) and (max-width: 580px) {
    .next-btn {
        right: -10%;
    }

    .prev-btn {
        left: -10%;
    }
}

.form {
    padding: 20px 0;
    margin: 50px 5%;
    margin-top: 35px;
    margin-bottom: 20px;
}

.form .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1000px) {
    .form .grid-container {
        display: grid;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 500px) {
    .form {
        padding: 20px 0;
        padding-top: 0;
    }
}

.form .left {
    margin: auto;
    text-align: center;
}

.form .left:hover img {
    scale: 1.05;
}

.form .left:hover .heading {
    scale: 1.05;
}

.form .left img {
    width: 70%;
    margin: auto;
    transition: ease-in-out 0.2s;
}

@media screen and (max-width: 600px) {
    .form {
        margin-left: 0;
        margin-right: 0;
    }
}

.form .heading {
    text-align: center;
    color: #fff;
    margin: auto;
    padding: 30px;
    padding-top: 0;
    transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1000px) {
    .form {
        grid-template-columns: 1fr;
    }

    .form .left img {
        display: none;
    }
}

.form .inputs-container {
    margin: auto;
    width: 80%;
    padding: 15px 20px;
    padding-bottom: 0;
    border-radius: 10px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.form .input-flex div {
    margin: 20px 10px;
}

.form div p {
    color: white;
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
}

.form input {
    width: 100%;
    border-radius: 20px;
    font-size: 14px;
    outline: 0;
    border: none;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.178);
    font-weight: 700;
}

.form input,
.form select,
.form label {
    color: white;
}

.form select * {
    background: transparent;
    color: black;
    padding: 5px;
}

.form input,
.form select {
    padding: 8px 10px;
    background-color: transparent;
}

.form select {
    font-size: 14px;
    border-radius: 6px;
    font-weight: 700;
    border: 1px solid rgba(170, 170, 170, 0.368627451);
}

.form select:focus-visible {
    border: 1px solid #aaaaaa;
    outline: none;
}

.form .input-container {
    margin: 0 !important;
    margin: auto;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.form label {
    margin: auto;
    font-size: 14px;
    font-weight: 500;
}

.form input[type=radio] {
    margin: auto;
    margin: 6px 4px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.form .urgency {
    margin-top: 20px;
}

.compare-page .form button {
    z-index: 100;
    border-radius: 6.6px;
    font-size: 16px;
    padding: 0.875rem 5.625rem;
    background-color: var(--hashpro);
    color: #000;
    width: 90%;
    border: none;
    text-transform: uppercase;
    transition: 0.3s;
    border: 1px solid transparent;
}

.form button:hover {
    cursor: pointer;
    background: black;
    color: white;
    border: 1px solid var(--hashpro);
}

#form-error.hide {
    opacity: 0;
}

#form-error {
    padding: 10px 5px;
    max-width: 80%;
    opacity: 1;
    margin: auto;
    margin-top: 15px;
    font-weight: 500;
    text-align: center;
    transition: 0.5s;
    font-size: 14px;
    line-height: 23px;
    background: black;
    color: white;
    border-radius: 5px;
}

@-webkit-keyframes blinking {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes blinking {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.form-error-anim {
    -webkit-animation: blinking infinite 1s;
    animation: blinking infinite 1s;
}

@media screen and (max-width: 600px) {
    .form .inputs-container {
        padding: 0px 0;
        padding-top: 20px;
        width: 90%;
    }

    .compare-page .form button {
        width: 80%;
    }
}

.acc {
    overflow: hidden;
    padding: 0;
}

.acc li {
    list-style-type: none;
    padding: 0;
}

.acc_ctrl {
    background: #000;
    border: none;
    border-bottom: solid 1px #f2f2f2;
    cursor: pointer;
    display: block;
    outline: 0;
    padding: 2em;
    padding-right: 3em;
    position: relative;
    text-align: center;
    width: 100%;
}

.acc_ctrl:before {
    background: #44596b;
    content: "";
    height: 2px;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: rotate(90deg);
    transition: all 0.2s ease-in-out;
    width: 14px;
}

.acc_ctrl:after {
    background: #44596b;
    content: "";
    height: 2px;
    position: absolute;
    right: 5%;
    top: 50%;
    width: 14px;
}

.acc_ctrl:focus h2 {
    position: relative;
}

.acc_panel {
    background: #f2f2f2;
    display: none;
    overflow: hidden;
}

.acc_panel p {
    font-size: 0.95em !important;
    line-height: 20px;
}

section {
    display: block;
}

.bold {
    font-weight: 800;
}

.yellow-text {
    color: var(--hashpro) !important;
}

.compare-page .instructor-heading {
    display: none;
    margin-top: 50px !important;
}

@media screen and (min-width: 600px) {
    .compare-page .instructor-heading {
        margin-bottom: 30px !important;
    }
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    text-decoration: none;
    display: inline-block;
}

img {
    max-width: 100%;
    font-size: 1rem;
    display: inline-block;
    position: static;
}

.text-weight-bold {
    font-weight: 700;
}

.text-size-large {
    font-size: 1.5rem;
    line-height: 150%;
}

.margin-xxsmall {
    margin: 0.25rem;
}

.margin-xsmall {
    margin: 0.5rem;
}

.margin-medium {
    margin: 2rem;
}

.padding-small {
    padding: 1rem;
}

.margin-bottom {
    height: auto;
    margin: 0 0 1rem;
}

.margin-bottom.margin-regular {
    margin-bottom: 1rem;
}

.padding-vertical {
    padding-left: 0;
    padding-right: 0;
}

.img-cover {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 8px;
    max-width: 500px;
    width: 95%;
}

@media screen and (max-width: 550px) {
    .img-cover {
        -o-object-position: top;
        object-position: top;
        max-height: 35vh;
    }
}

.section-wrapper {
    width: 100%;
    position: relative;
}

.compare-page .hero-mentor-info-highlight {
    z-index: 2;
    width: 50%;
    text-align: center;
    border: 1px solid #000;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    padding: 0.5rem 1rem;
    position: absolute;
    top: -10%;
    bottom: auto;
    left: 5%;
    right: auto;
    background-color: #fff !important;
    color: #000 !important;
    font-weight: 600;
}

.compare-page .hero-mentor-info-highlight.pm2 {
    top: -8%;
    bottom: auto;
    left: 0;
    right: 0;
}

.text-size-regular-2 {
    font-size: 16px;
    color: #878787;
}

.text-size-regular-2.text-align-center {
    /* padding-top: 20px */
    color: #878787 !important;
}

.text-block-223 {
    color: rgba(255, 255, 255, 0.7);
    text-align: left;
    font-size: 0.85rem;
}

.text-block-223.text-align-center {
    text-align: center;
}

.phone-heading {
    color: white;
    display: none;
    margin: 0 3%;
    margin-top: 110px;
}

.phone-heading p {
    text-align: center;
    margin: auto;
    margin-top: 13px;
    width: 96%;
}

.home-section {
    max-width: 95%;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: stretch;
    align-items: center;
    padding: 20px;
    padding-bottom: 30px;
    margin: 0 auto;
    margin-top: 5rem !important;
    display: grid;
    position: relative;
}

.image-150 {
    z-index: -1;
    width: 60rem;
    max-width: none;
    opacity: 0.73;
    position: absolute;
    top: -23%;
    bottom: auto;
    left: 0;
    right: 0;
}

.image-150.b {
    width: 70rem;
    max-width: none;
    opacity: 0.51;
    top: -8%;
    bottom: 0;
    left: 9%;
    right: 0;
}

.compare-page .hero-mentor-details {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    display: grid;
}

.compare-page .hero-mentor-details.margin-medium {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    align-items: center;
    padding: 2rem 2rem 1rem;
    padding-bottom: 1rem !important;
}

.compare-page .hero-mentor-details.margin-medium.pm2 {
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    border-radius: 8px 8px 0 0;
    grid-template-columns: auto;
    margin: 0;
    padding-top: 2.4rem;
    padding-bottom: 1.6rem;
    padding-left: 2.8rem;
}

.heading-64 {
    font-size: 2.8rem !important;
    font-weight: 800;
    margin: 0 auto;
    text-align: left;
}

@media (min-width: 0px) and (max-width: 600px) {
    .heading-64 {
        font-size: 1.8rem !important;
    }

    .home-content_left .font-paragraph {
        text-align: center;
    }
}

@-webkit-keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

.compare-page .hero-mentor-logo_component {
    text-align: center;
    background-color: #e6efef;
    border-top: 1px solid #000;
}

.compare-page .hero-mentor-logo_component.padding-vertical.padding-small {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.18);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    display: grid;
    overflow: hidden;
}

.compare-page .hero-mentor-logo_component.padding-vertical.padding-small {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.18);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    padding: 10px 2rem;
    display: grid;
    overflow: hidden;
}

.compare-page .hero-mentor_component {
    width: 100%;
    border: 0.5px solid rgba(255, 255, 255, 0.6);
    border-radius: 8px;
    margin-top: 1rem;
    position: relative;
    overflow: visible;
}

.angel-investing-timer_container {
    text-align: center;
}

.div-block-252 {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    margin-bottom: 24px;
    overflow: hidden;
    text-align: center;
}

.logo-top {
    width: auto;
    height: 3rem;
    min-height: 0;
    margin-bottom: 20px;
    position: static;
    top: 10%;
    bottom: auto;
    left: 9%;
    right: auto;
}

.cta-angel-investing:hover {
    transform: scale(1.01);
}

.splide__arrow {
    display: none !important;
}

.compare-page .splide__pagination {
    display: none !important;
}

.cta-angel-investing.pm2-gradient {
    background-color: var(--hashpro);
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    max-width: 24rem;
    border-radius: 6.6px !important;
    box-shadow: none;
    transition: 0.3s;
}

.cta-angel-investing.pm2-gradient.full {
    margin-top: 20px;
    max-width: none;
}

@media only screen and (max-width: 500px) {
    .cta-angel-investing.pm2-gradient.full {
        display: none;
    }
}

.body-text {
    margin-bottom: 26px;
    font-size: 2rem;
    line-height: 150%;
}

.div-block-427 {
    display: flex;
}

.div-block-427.pm2 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border: 1px solid rgba(247, 196, 10, 0.7254901961);
    border-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 24px;
    display: grid;
}

.lightbox-link-16 {
    width: 100%;
    max-height: 16rem;
}

.text-align-center {
    text-align: center;
}

@media screen and (min-width: 1280px) {
    .home-section.pm2 {
        height: auto;
    }
}

@media screen and (max-width: 991px) {
    .margin-medium {
        margin: 1.5rem;
    }

    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .padding-vertical {
        padding-left: 0;
        padding-right: 0;
    }

    .cta-angel-investing {
        padding: 1rem 3rem;
    }
}

@media screen and (max-width: 767px) {
    h1 {
        font-size: 2.3rem;
    }

    .text-size-large {
        font-size: 1.25rem;
    }

    .margin-medium {
        margin: 1.25rem;
    }

    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .margin-bottom.margin-regular {
        margin-bottom: 0.5rem;
    }

    .margin-bottom.margin-medium.no-margin {
        margin-bottom: 0;
    }

    .padding-vertical {
        padding-left: 0;
        padding-right: 0;
    }

    .home-section {
        width: 100%;
        height: auto;
        grid-row-gap: 2rem;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        align-content: stretch;
        align-items: stretch;
        margin-top: 3rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        display: block;
    }

    .heading-small-2 {
        font-size: 1.2rem;
    }
}

.heading-small-2 {
    font-size: 1.4rem;
}

@media screen and (max-width: 479px) {
    .margin-bottom {
        margin: 0;
    }

    .padding-vertical {
        padding-left: 0;
        padding-right: 0;
    }

    .section-wrapper {
        margin-left: auto;
        margin-right: auto;
    }

    .compare-page .hero-mentor-info-highlight {
        top: -12%;
    }

    .compare-page .hero-mentor-info-highlight.pm2 {
        width: 50%;
        top: -3%;
    }

    .home-content_left {
        text-align: center;
    }

    .home-content_left.left-on-mobile {
        text-align: left;
        margin-top: 10px;
    }

    .home-content_left.left-on-mobile {
        text-align: left;
    }

    .home-section {
        align-content: stretch;
        align-items: stretch;
        margin-top: 2rem;
        display: block;
        overflow: hidden;
    }

    .home-section.pm2 {
        grid-template-rows: auto;
        overflow: visible;
    }

    .heading-small-2 {
        margin-bottom: 8px;
    }

    .image-150 {
        max-width: none;
        top: 7%;
        bottom: auto;
        left: -172px;
        right: auto;
    }

    .image-150.b {
        left: -50%;
    }

    .compare-page .hero-mentor-details.margin-medium.pm2 {
        padding-left: 2rem;
    }

    .compare-page .hero-mentor-logo_component.padding-vertical.padding-small {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
    }

    .compare-page .hero-mentor-logo_component.padding-vertical.padding-small.pm2 {
        grid-template-rows: auto;
        display: block;
    }

    .compare-page .hero-mentor-logo_component.padding-vertical.padding-small {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        padding-bottom: 17px;
    }

    .logo-top {
        height: 2.4rem;
    }

    .cta-angel-investing {
        font-size: 16px;
    }

    .div-block-427.pm2 {
        margin-bottom: 0;
        padding: 16px;
    }

    .lightbox-link-16 {
        max-height: 13rem;
    }
}

#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac212-6d667f4f,
#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac213-6d667f4f,
#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac22a-6d667f4f {
    grid-area: span 1/span 1/span 1/span 1;
    color: #fff;
    background-color: #000 !important;
}

#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac23a-6d667f4f {
    grid-area: span 1/span 2/span 1/span 2;
    line-height: 22px;
    padding: 10px 0;
}

p {
    margin: 0;
}

.compare-page .hashpro-way-container[data-v-17ab2e64] {
    background: #0a0a0a;
    color: #fff;
    overflow: hidden;
    position: relative;
    padding: 5%;
    display: none;
}

@media only screen and (max-width: 500px) {
    .compare-page .hashpro-way-container[data-v-17ab2e64] {
        margin-top: 30px;
    }
}

.compare-page .hashpro-way-container .restrict-width[data-v-17ab2e64] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 7%;
}

.compare-page .hashpro-way-container .left-container[data-v-17ab2e64] {
    text-align: center;
    margin-right: 4.18%;
    width: 100%;
}

.compare-page .hashpro-way-container h1 {
    margin: 0;
    margin-bottom: 10px;
}

.compare-page .hashpro-way-container .left-container .heading[data-v-17ab2e64] {
    font-style: normal;
    font-weight: 800;
    font-size: 2.875rem;
    margin-bottom: 2rem;
}

.compare-page .hashpro-way-container .left-container .desc[data-v-17ab2e64] {
    font-size: 1.125rem;
    line-height: 200%;
    margin: auto;
    max-width: 85%;
}

.compare-page .hashpro-way-container .right-container[data-v-17ab2e64] {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    justify-content: center;
}

.compare-page .hashpro-way-container .right-container .item[data-v-17ab2e64] {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    padding: 1.25rem 1.5rem;
    width: 300px;
    margin: auto;
    transition: 0.5s;
}

.compare-page .hashpro-way-container .right-container .item[data-v-17ab2e64]:hover {
    scale: 1.1;
}

.compare-page .hashpro-way-container .right-container .item[data-v-17ab2e64] .text {
    color: rgb(230, 230, 230);
    font-weight: 600;
    font-size: 0.88rem;
    /* letter-spacing: 1px; */
    line-height: 18px;
}

.compare-page .hashpro-way-container .right-container .emoji {
    font-size: 55px;
    text-align: center;
    line-height: 80px;
}

.compare-page .hashpro-way-container .right-container .item img[data-v-17ab2e64] {
    height: 4rem;
    width: 4rem;
    max-width: inherit;
    margin-bottom: 1.5rem;
}

@media only screen and (max-width: 1000px) {
    .compare-page .hashpro-way-container .restrict-width[data-v-17ab2e64] {
        flex-direction: column;
    }

    .compare-page .hashpro-way-container .left-container[data-v-17ab2e64] {
        margin-right: 0;
        text-align: center;
        width: 100%;
    }

    .compare-page .hashpro-way-container .right-container[data-v-17ab2e64] {
        width: 100%;
        justify-content: space-between;
    }

    .compare-page .hashpro-way-container .right-container .item[data-v-17ab2e64] {
        align-items: center;
        text-align: center;
    }
}

@media only screen and (max-width: 512px) {
    .compare-page .hashpro-way-container .restrict-width[data-v-17ab2e64] {
        padding: 0 5%;
    }

    .compare-page .hashpro-way-container .left-container .heading[data-v-17ab2e64] {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .compare-page .hashpro-way-container .left-container .desc[data-v-17ab2e64] {
        font-size: 0.875rem;
    }

    .compare-page .hashpro-way-container .left-container .desc p[data-v-17ab2e64] {
        margin-bottom: 0.2rem;
    }

    .compare-page .hashpro-way-container .right-container .item[data-v-17ab2e64] {
        font-size: 0.75rem;
        padding: 1.5rem 0.5rem;
        margin-bottom: 0.5rem;
        max-width: 9.6rem;
    }

    .compare-page .hashpro-way-container .right-container .item img[data-v-17ab2e64] {
        height: 2.5rem;
        width: 2.5rem;
        max-width: inherit;
        margin-bottom: 0.875rem;
    }
}

svg:not(:root) {
    overflow: hidden;
}

* {
    box-sizing: border-box;
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px;
}

.w-embed:after,
.w-embed:before {
    content: " ";
    grid-area: 1/1/2/2;
    display: table;
}

.w-embed:after {
    clear: both;
}

.margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.margin-bottom .margin-medium {
    margin-bottom: 2rem;
}

.text-align-center {
    text-align: center;
}

.text-size-regular {
    font-size: 16px;
}

.text-size-regular.li {
    margin-bottom: 8px;
    font-weight: 400;
}

.heading-who {
    margin-top: 0px;
}

.forwho-card {
    /* background-color   : var(--hashpro); */
    /* background-color: rgb(238, 238, 238); */
    background-color: black;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 1rem;
    border-radius: 8px;
    color: rgb(255, 255, 255);
    margin: 15px 0;
    transition: 0.1s;
}

.forwho-card:hover {
    scale: 1.015;
}

.forwho-card-header {
    display: flex;
    align-items: center;
}

.forwho-card-header .emoji {
    font-size: 25px;
    margin: auto 0;
}

.forwho-card-header h3 {
    background-color: rgba(0, 0, 0, 0.85);
    /* text-shadow   : 1px 1px black; */
    padding: 1px 5px;
    letter-spacing: 0.03rem;
    margin: auto 10px;
    margin-left: 4px;
    font-weight: 600;
    font-size: 1.1rem !important;
    color: #f7c20a;
    text-transform: uppercase;
}

.forwho-card p {
    padding-left: 1%;
    margin-top: 8px;
    font-size: 0.96rem;
    font-weight: 500;
    color: white;
}

@media screen and (max-width: 600px) {
    .forwho-card p {
        padding-left: 0.9%;
        font-weight: 400;
    }
}

.tick-wrapper {
    color: #000 !important;
}

.tick-wrapper svg {
    margin: auto;
    margin-bottom: 1px;
    width: 12px;
}

.div-block-257 {
    grid-column-gap: 12px;
    grid-row-gap: 0;
    background-color: var(--hashpro);
    color: #000 !important;
    border: 1px solid rgba(255, 255, 255, 0.41);
    border-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: 0.7rem;
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    display: grid;
    transition: 0.2s;
}

.div-block-257:hover {
    background-color: var(--hashpro-hover);
}

.div-block-257 h3 {
    margin: 0;
    font-size: 1.1rem;
}

.div-block-257 li {
    font-weight: 500 !important;
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.88);
}

.tick-wrapper {
    width: 1.5em;
    height: 1.5rem;
    background-color: #f7c20a;
    /* border          : 1px solid white; */
    border-radius: 12rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden;
}

.div-block-259 {
    padding-top: 20px;
    color: white !important;
    max-width: 1440px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex;
}

.compare-page .who-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 50px;
    column-gap: 50px;
    row-gap: 30px;
}

.compare-page .who-card svg {
    width: 20px;
    margin: 20px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.compare-page .who-card {
    margin: 0 auto;
    max-width: 90%;
    padding: 20px;
    padding-left: 0;
    border-radius: 8px;
    color: #000;
    display: flex;
    background-color: var(--hashpro);
}

.compare-page .who-card li {
    padding: 6px 0;
}

.div-block-310 {
    max-width: 64rem;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-bottom: 30px;
}

.div-block-311 {
    flex-direction: column;
    flex: 1;
    display: flex;
    margin-right: 30px;
    margin-left: 30px;
}

.list-7 {
    padding-left: 20px;
}

@media (min-width: 0px) and (max-width: 600px) {
    .compare-page .who-card-container {
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 991px) {
    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 767px) {
    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 479px) {
    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .heading-large {
        line-height: 120%;
    }

    .tick-wrapper {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 700px) {
    .div-block-310 {
        flex-direction: column;
    }

    .div-block-311 {
        margin-right: 20px;
        margin-left: 20px;
    }
}

.compare-page .curriculum {
    display: none;
    /* border-radius   : 10px; */
    background-color: #131313;
    color: white;
    margin: 0;
    padding: 20px 10%;
    padding-bottom: 40px;
}

.compare-page .curriculum .heading {
    margin-bottom: 10px;
}

@media screen and (max-width: 767px) {

    .compare-page .curriculum .subheading,
    .heading {
        text-align: center;
    }
}

.compare-page .curriculum-container {
    margin-top: 30px;
}

.compare-page .curriculum-container .right {
    height: 400px;
    overflow: auto;
}

.compare-page .curriculum .compare-page .curriculum-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 50px;
    column-gap: 50px;
}

.compare-page .curriculum-container .course-card {
    color: white;
    position: relative;
    border-radius: 10px;
    background-color: black;
    font-weight: 500;
    padding: 15px;
    margin: 20px 0;
    border: 1px solid var(--hashpro);
    opacity: 0.3;
    transition: 0.3s;
    box-shadow: 1px 1px var(--hashpro);
    font-size: 14px;
}

.compare-page .curriculum-container .course-card:hover {
    cursor: pointer;
    box-shadow: 3px 3px var(--hashpro);
}

.compare-page .curriculum-container .course-card .course {
    transform: translateY(-10px);
    opacity: 0;
    position: absolute;
    top: -15px;
    background-color: white;
    padding: 5px 10px;
    font-size: 0.9rem;
    border-radius: 5px;
    transition: 0.3s;
    color: black;
}

.compare-page .curriculum-container .course-card.active .course {
    transform: translateY(0);
    opacity: 1;
}

.compare-page .curriculum-container .course-card.active {
    opacity: 1;
    box-shadow: 3px 3px var(--hashpro);
    scale: 1.03;
}

.compare-page .curriculum .points {
    display: flex;
    margin: 20px 0;
}

.compare-page .curriculum .points p {
    margin: auto 7px;
    font-size: 14px;
}

.compare-page .curriculum .points img {
    filter: invert(100%);
    height: 18px;
    width: 18px;
}

.compare-page .curriculum #course-2 {
    display: none;
}

.compare-page .curriculum #course-3 {
    display: none;
}

.compare-page .curriculum #course-4 {
    display: none;
}

.compare-page .curriculum #course-5 {
    display: none;
}

.compare-page .curriculum #course-6 {
    display: none;
}

.compare-page .curriculum-container-mobile {
    display: none;
}

@media screen and (max-width: 800px) {
    .compare-page .curriculum-container-mobile {
        display: block;
    }

    .compare-page .curriculum-container .left {
        display: none;
    }

    .compare-page .curriculum .compare-page .curriculum-container .right {
        margin: 0 3%;
        margin-right: 1%;
    }

    .compare-page .curriculum .compare-page .curriculum-container .right h3 {
        margin-top: 0;
    }

    .compare-page .curriculum .compare-page .curriculum-container {
        grid-template-columns: 1fr;
    }
}

.compare-page .curriculum-container-mobile .course-selector {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap;
}

.compare-page .curriculum-container-mobile .course-selector .course-card-mobile {
    background-color: var(--hashpro);
    color: black;
    padding: 5px 10px;
    border-radius: 5px;
    font-size: 15px;
    font-weight: 500;
    opacity: 0.5;
    transition: 0.3s;
    margin: 8px 5px;
}

.compare-page .curriculum-container-mobile .course-selector p:hover {
    cursor: pointer;
}

.compare-page .curriculum-container-mobile .course-selector p.active {
    opacity: 1;
    border-radius: 5px;
    scale: 1.08;
}

#w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1740-14fe2356,
#w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1742-14fe2356 {
    grid-area: span 1/span 1/span 1/span 1;
}

#w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1744-14fe2356 {
    grid-area: 2/2/3/3;
}

#w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a842-14fe2356,
#w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a844-14fe2356 {
    grid-area: span 1/span 1/span 1/span 1;
}

#w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a846-14fe2356 {
    grid-area: 2/2/3/3;
}

.compare-page .instructors-and-members-container {
    /* padding   : 30px 5%; */
    background: #000;
    color: #fff;
    position: relative;
    overflow: hidden;
    display: none;
}

.compare-page .instructors-and-members-container .restrict-width {
    max-width: 90rem;
    margin: 0 auto;
}

.compare-page .instructors-and-members-container h1 {
    margin-bottom: 30px;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container {
    display: flex;
    justify-content: center;
    margin-bottom: 3.5rem;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container {
    margin-right: 5.3vw;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    padding-left: 3.375rem;
    padding-bottom: 2.75rem;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    justify-content: flex-end;
}

.compare-page .instructor-heading {
    color: #fff;
    margin: 0 50px;
    text-align: center;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .dots {
    height: 12rem;
    width: 12rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image {
    width: 22.9vw;
    max-width: 20.6rem;
    height: 23.33vw;
    max-height: 21rem;
    border-radius: 0.875rem;
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin: 0 auto;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image img {
    height: auto;
    width: 24.64vw;
    max-width: 22.17rem;
    position: absolute;
    top: 0;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio {
    width: 40.5rem;
    padding-bottom: 2.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title {
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0 !important;
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title span {
    font-weight: 800;
    font-size: 1.4rem;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title .designation span {
    font-size: 1rem !important;
    font-weight: 300;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .desc {
    font-size: 1.25rem;
    font-weight: 500;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container {
    display: flex;
    margin-top: 1.5rem;
}

.compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container img {
    height: 2rem;
    width: 2rem;
    max-width: inherit;
    margin-right: 1.25rem;
}

.members-container {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #080808;
    /* border-radius: 10px; */
    color: white;
}

.compare-page .instructors-and-members-container .members-container .guests-container {
    margin-right: 10%;
    margin-left: 10%;
}

.compare-page .instructors-and-members-container .members-container .section-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    margin-bottom: 5px;
    text-align: center;
}

.compare-page .instructors-and-members-container .members-container .section-subheading {
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 145%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 0;
    color: rgb(183, 183, 183);
}

@media only screen and (max-width: 1000px) {
    .compare-page .instructors-and-members-container .instructor-container {
        padding: 0;
    }

    .compare-page .instructors-and-members-container .instructor-container .heading {
        text-align: left;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container {
        flex-direction: column;
        margin-bottom: 0.5rem;
        padding: 40px;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container {
        margin-right: 0;
        padding-left: 0;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image {
        width: 84vw;
        height: 50.25vw;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image img {
        height: auto;
        width: 100%;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio {
        width: 100%;
    }
}

@media only screen and (max-width: 512px) {
    .compare-page .instructors-and-members-container .instructor-container {
        padding: 0;
    }

    .compare-page .instructors-and-members-container .instructor-container .heading {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: left;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container {
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin-bottom: 1.5rem;
        justify-content: left;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .dots {
        display: none;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image {
        width: 100%;
        height: 15.75rem;
        border-radius: 0.5rem;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image img {
        height: auto;
        width: 100%;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio {
        width: 100%;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title {
        font-size: 1.25rem;
        margin-bottom: 1rem;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title span {
        font-weight: 800;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .desc {
        font-size: 0.875rem;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container {
        margin-top: 1.25rem;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container img {
        height: 1.25rem;
        width: 1.25rem;
        max-width: inherit;
        margin-right: 1rem;
    }

    .compare-page .instructors-and-members-container .members-container .guests-container {
        margin-bottom: 1rem;
    }

    .compare-page .instructors-and-members-container .members-container .section-heading {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }

    .compare-page .instructors-and-members-container .members-container .section-subheading {
        font-size: 0.875rem;
        width: 100%;
        margin-bottom: 0;
    }
}

.show-more-show-less {
    display: inline;
    line-height: 22px;
}

.member-card-container {
    padding: 4.625rem 0 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
}

@media screen and (min-width: 800px) {
    .member-card-container {
        scale: 0.85;
    }

    .swiper-button-container {
        padding-bottom: 0;
    }
}

.member-card-container .member-image-container {
    position: absolute;
    top: 0;
    z-index: 2;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: 9.25rem;
    max-width: inherit;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}

.member-card-container .member-image-container img {
    height: 8.5rem;
    width: 8.5rem;
    border-radius: 50%;
    padding: 5px;
    background-color: #3d3d3d;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    /* filter       : brightness(40%); */
}

.member-card-container .member-details-container {
    border: 1px solid hsla(0deg, 0%, 17%, 0.58);
    border-radius: 1rem;
    padding: 5.625rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    max-width: 300px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    background-size: cover;
    background-color: #131313;
    color: white;
    /* background-image: linear-gradient(#b8b8b88f, #b8b8b88f), url(./design/guest-bg-1.svg); */
}

/* .member-card-container .member-details-container:nth-child(1) {

    }

    .member-card-container .member-details-container:nth-child(2) {
        background-image: url(./design/guest-bg-2.svg)
    }

    .member-card-container .member-details-container:nth-child(3) {
        background-image: url(./design/guest-bg-3.svg);

    } */
.member-card-container .member-details-container .name {
    border-radius: 5px;
    /* background-color: rgba(0, 0, 0, 0.87); */
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 8px;
    margin: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 1.2rem !important;
    text-align: center;
    margin-bottom: 0.625rem;
    color: white;
}

.member-card-container .member-details-container .role {
    color: white;
    font-size: 0.95rem;
    margin-bottom: 1.5rem;
    line-height: 145%;
    display: -webkit-box;
    font-weight: 500;
}

.socials svg {
    color: #000;
}

.member-card-container .member-details-container .socials img {
    filter: brightness(50%);
    height: 1.5rem;
    width: 1.5rem;
    max-width: inherit;
    margin-right: 1rem;
    cursor: pointer;
    transition: 0.2s;
}

.member-card-container .member-details-container .socials img:hover {
    scale: 1.2;
}

.member-card-container:hover .member-details-container .socials {
    display: flex;
}

@media only screen and (max-width: 512px) {
    .member-card-container .member-image-container {
        height: 8.25rem;
        width: 8.25rem;
    }

    .member-card-container .member-image-container img {
        height: 7.125rem;
        width: 7.125rem;
        padding: 0.375rem;
        background: #393939;
    }

    .member-card-container .member-details-container {
        border-radius: 0.875rem;
        padding: 5rem 0.875rem 1.75rem;
        width: 20.0625rem;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
    }

    .member-card-container .member-details-container .name {
        font-size: 1.25rem;
        margin-bottom: 0.625rem;
    }

    .member-card-container .member-details-container .role {
        font-size: 0.875rem;
        margin-bottom: 1.5rem;
    }

    .member-card-container .member-details-container .socials {
        display: flex;
    }

    .member-card-container .member-details-container .socials img {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: 0.625rem;
    }
}

a,
div,
img,
p,
span {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.output {
    overflow-wrap: anywhere;
}

.margin-medium {
    margin: 2rem;
}

.margin-bottom {
    height: auto;
    margin: 0 0 1rem;
}

.div-block-366 {
    text-transform: uppercase;
    color: #000;
    width: 80%;
    background-color: var(--hashpro);
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-size: 15px !important;
    font-weight: 700;
    position: absolute;
    top: -9%;
    bottom: auto;
    left: 0;
    right: 0;
}

.heading-large-2 {
    font-size: 32px;
    line-height: 140%;
}

.card.pm2 {
    background-color: #000 !important;
    border-radius: 8px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #fff;
}

.section-22 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid;
}

.text-block-215 {
    margin: 0;
    font-size: 17px;
    font-weight: 500;
    color: var(--hashpro);
    margin-bottom: 50px;
}

.text-block-215.text-align-center {
    line-height: 1.5;
}

.compare-page .container-large-2 {
    display: none;
    padding: 50px 0;
    margin: 0 auto;
    padding-left: 7%;
    padding-right: 7%;
    background-color: #0e0e0e;
    color: #fff;
}

@media screen and (max-width: 500px) {
    .compare-page .container-large-2 {
        padding-top: 30px;
    }
}

.compare-page .container-large-2 .heading {
    margin: 0;
}

.text-align-center {
    text-align: center;
}

@media screen and (max-width: 991px) {
    .margin-medium {
        margin: 1.5rem;
    }

    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 479px) {
    .margin-bottom {
        margin: 0;
    }

    .div-block-366 {
        width: 90%;
        font-size: 14px;
    }

    .heading-large-2 {
        line-height: 120%;
    }

    .heading-large-2.text-align-center.pm2 {
        margin-bottom: 0;
        font-size: 24px;
    }

    .section-22 {
        grid-template-columns: 1fr;
    }

    .section-22.margin-bottom.margin-medium {
        grid-column-gap: 16px;
        grid-row-gap: 40px;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 479px) {
    #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac4ea-6d667f4f {
        grid-area: span 1/span 1/span 1/span 1;
    }
}

.w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block;
}

.margin-medium {
    margin: 2rem;
}

.margin-bottom {
    height: auto;
    margin: 0 0 1rem;
}

.margin-bottom.margin-regular {
    margin-bottom: 10px;
}

.margin-bottom.margin-custom {
    margin-bottom: 1.5rem;
}

.html-embed-45 {
    justify-content: center;
    align-items: center;
    display: flex;
}

.d2c-offer-container {
    width: 90%;
    grid-column-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: stretch;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
}

.d2c-offer-container.app {
    grid-column-gap: 3rem;
}

.text-block-221 {
    color: #d8d8d8;
    font-size: 1.05rem;
    line-height: 30px;
}

.pricing-component_right {
    text-align: left;
    border: 1px dashed #491eb8;
    border-radius: 1rem;
    padding: 2.5rem;
    padding-top: 1.2rem;
    padding-bottom: 2rem;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}

.pricing-component_right.pm2 {
    border: 1px solid var(--hashpro);
}

.text-weight-bold-2 {
    font-style: normal;
    font-weight: 700;
}

.text-weight-bold-2.text-align-center {
    font-style: normal;
}

.app-chechbox {
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #00b7fe;
    border-radius: 8px;
    padding: 0.5rem;
}

.app-chechbox.pm2 {
    background-color: var(--hashpro);
    border-style: none;
    border-radius: 80rem;
    justify-content: center;
    align-items: center;
    padding: 0.3rem;
    display: flex;
    margin: auto;
}

.app-chechbox.pm2 svg {
    color: #000 !important;
}

.text-span-84 {
    color: rgb(190, 190, 190);
}

.heading-large-2 {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 140%;
}

.heading-large-2.white {
    color: #fff;
    font-weight: 600;
}

.offer-card {
    text-align: left;
    background-color: #fff;
    background-image: url(https://assets.website-files.com/625416528a3419ca302b6dd8/625c7b4bc8a26a7557c46205_Group/3193.svg), none, none;
    background-position: 0 0, 0 100%, 100% 0;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 17%, 11%, 11%;
    border-radius: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 2.5%;
    padding-bottom: 54px;
    padding-left: 40px;
    display: flex;
    box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.05);
}

.offer-card.app {
    background-color: transparent;
    background-image: none;
    padding: 0 0 2.5rem 0;
}

.angel-investing_component {
    max-width: 72rem;
    text-align: center;
    /* margin-top: 45px; */
    margin-left: auto;
    margin-right: auto;
}

.compare-page .who-program {
    display: none;
    padding-top: 10px;
    background-color: rgb(6, 6, 6) !important;
    color: #fff !important;
    padding: 3%;
    margin-top: 0;
    position: relative;
    overflow: hidden;
}

@media screen and (max-width: 500px) {
    .compare-page .who-program {
        padding-top: 0;
        padding-bottom: 20px;
    }
}

.div-block-259 .heading {
    margin-bottom: 5px;
    color: white;
}

.cta-angel-investing {
    width: 100%;
    text-align: center;
    border-radius: 0.5rem;
    padding: 0.875rem 5.625rem;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0px 0px 6px 0px #f7c20a;
}

.cta-angel-investing:hover {
    background-color: black;
}

.cta-angel-investing.pm2-gradient.offer {
    max-width: none;
}

.what-you-get {
    grid-column-gap: 5px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    margin-top: 16px;
    line-height: 1.5;
    display: grid;
}

.what-you-get span {
    margin: auto 5px;
    font-weight: 500;
}

.what-you-get.pm2 {
    color: rgba(255, 255, 255, 0.8);
    margin-top: 17px;
    font-size: 16px;
}

.text-align-center {
    text-align: center;
}

.oval-button {
    background-color: var(--hashpro) !important;
    background-image: none !important;
    border-radius: 50px !important;
    color: #000;
    font-weight: 600 !important;
    transition: 0.2s;
    border: 1px solid transparent;
}

.oval-button:hover {
    background-color: black !important;
    color: var(--hashpro);
    border: 1px solid rgba(247, 196, 10, 0.2392156863);
}

@media screen and (max-width: 991px) {
    .margin-medium {
        margin: 1.5rem;
    }

    .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0;
    }

    .cta-angel-investing {
        padding: 1rem 3rem;
    }
}

.apply-btn[data-v-2fccc02f] {
    padding: 1rem 2.5rem;
    font-weight: 600;
    font-size: 1.125rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none;
    color: #f6f6ff;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .apply-btn[data-v-2fccc02f] {
        margin: 0 auto 4rem;
    }
}

@media only screen and (max-width: 512px) {
    .apply-btn[data-v-2fccc02f] {
        padding: 0.75rem 2.5rem;
        font-size: 0.75rem;
        border-radius: 0.375rem;
        margin-bottom: 2rem;
    }
}

.enrollment-process-container {
    padding: 6.25rem 7vw;
    background: #fafafa;
    color: #171421;
    position: relative;
    z-index: 2;
    overflow: hidden;
}

.enrollment-process-container .main-heading {
    font-style: normal;
    font-size: 2.875rem;
    text-align: center;
    margin-bottom: 5rem;
}

.enrollment-process-container .limited-seats {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 130%;
    color: #000;
    margin: -4rem auto 5rem;
    text-align: center;
}

.enrollment-process-container .limited-seats span {
    color: #6334c8;
}

.enrollment-process-container .banner-container {
    background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
    box-shadow: 0 0 4rem rgba(104, 63, 190, 0.27);
    border-radius: 1.25rem;
    z-index: 2;
    margin: 0 4.625rem;
    overflow: hidden;
}

.enrollment-process-container .banner-container .background-image-container {
    padding: 2.5rem 4.5rem;
    background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner.d495bac.png);
    background-size: cover;
    background-position: 50%;
}

.enrollment-process-container .banner-container .background-image-container .text {
    width: 47.5vw;
}

.enrollment-process-container .banner-container .background-image-container .text .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    color: #fdfdfd;
    margin-bottom: 1rem;
}

.enrollment-process-container .banner-container .background-image-container .text .content {
    font-size: 1.125rem;
    line-height: 150%;
    color: #fff;
    margin-bottom: 1.75rem;
}

.enrollment-process-container .banner-container .background-image-container .text .apply-btn {
    background: #e8ddff;
    border-radius: 0.375rem;
    padding: 0.75rem 1.5rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #171421;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

@media only screen and (max-width: 880px) {
    .enrollment-process-container .main-heading {
        text-align: left;
    }

    .enrollment-process-container .banner-container {
        box-shadow: 0 0 1.5rem rgba(104, 63, 190, 0.27);
        border-radius: 0.5rem;
        margin: 0.5rem 0 0;
    }

    .enrollment-process-container .banner-container .background-image-container {
        padding: 2.5rem 4.5rem;
        background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner.d495bac.png);
        background-size: cover;
        background-position: 50%;
    }

    .enrollment-process-container .banner-container .background-image-container .text {
        width: 47.5vw;
    }

    .enrollment-process-container .banner-container .background-image-container .text .heading {
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
        color: #fdfdfd;
        margin-bottom: 1rem;
    }

    .enrollment-process-container .banner-container .background-image-container .text .content {
        font-size: 1.125rem;
        line-height: 150%;
        color: #fff;
        margin-bottom: 1.75rem;
    }

    .enrollment-process-container .banner-container .background-image-container .text .apply-btn {
        background: #e8ddff;
        border-radius: 0.375rem;
        padding: 0.75rem 1.5rem;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: #171421;
        display: flex;
        align-items: center;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }
}

@media only screen and (max-width: 512px) {
    .enrollment-process-container {
        padding: 2.5rem 7vw;
    }

    .enrollment-process-container .main-heading {
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 1.5rem;
    }

    .enrollment-process-container .limited-seats {
        font-size: 0.875rem;
        margin: -0.625rem auto 1.5rem;
        text-align: left;
    }

    .enrollment-process-container .banner-container {
        background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
        box-shadow: 0 0 1.5rem rgba(104, 63, 190, 0.27);
        border-radius: 0.5rem;
        margin: 0;
    }

    .enrollment-process-container .banner-container .background-image-container {
        padding: 1.25rem;
        background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner-mobile.ecf70b2.png);
    }

    .enrollment-process-container .banner-container .background-image-container .text {
        width: 100%;
        text-align: center;
    }

    .enrollment-process-container .banner-container .background-image-container .text .heading {
        font-size: 1.125rem;
        margin-bottom: 0.5rem;
    }

    .enrollment-process-container .banner-container .background-image-container .text .content {
        font-size: 0.75rem;
        margin-bottom: 0.75rem;
    }

    .enrollment-process-container .banner-container .background-image-container .text .apply-btn {
        border-radius: 0.375rem;
        padding: 0.5rem 3.75rem;
        font-size: 0.625rem;
        margin-bottom: 0;
    }
}

.apply-btn[data-v-2fccc02f] {
    padding: 1rem 2.5rem;
    font-weight: 600;
    font-size: 1.125rem;
    background: #683fbe;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none;
    color: #f6f6ff;
    cursor: pointer;
}

@media only screen and (max-width: 1000px) {
    .apply-btn[data-v-2fccc02f] {
        margin: 0 auto 4rem;
    }
}

@media only screen and (max-width: 512px) {
    .apply-btn[data-v-2fccc02f] {
        padding: 0.75rem 2.5rem;
        font-size: 0.75rem;
        border-radius: 0.375rem;
        margin-bottom: 2rem;
    }
}

.compare-page .admission-process {
    display: none;
    background-color: #0a0a0a;
    color: #fff;
    text-align: center;
    padding: 0 9%;
}

.compare-page .admission-process h1 {
    font-size: 2rem !important;
    margin: 0;
    margin-bottom: 5px;
}

.compare-page .admission-process p {
    font-size: 15px !important;
}

.admission-card-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px;
}

.admission-card {
    text-align: center;
    max-width: 300px;
    margin: 20px 30px;
}

.admission-card h1 {
    font-size: 50px !important;
    margin: 0;
}

.admission-card h3 {
    font-size: 18px;
    color: var(--hashpro);
    margin: 0;
}

.admission-card p {
    margin-top: 10px;
    font-size: 14px;
}

.compare-page .faq-section-container {
    display: none;
    background: #000;
    padding: 1rem 2vw;
    padding-bottom: 2.7rem;
}

.compare-page .doubt-banner {
    transition: 0.4s;
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    /* box-shadow      : rgba(255, 255, 255, .192) 0 6px 24px 0, rgba(255, 255, 255, .106) 0 0 0 1px; */
    border: 1px solid rgba(187, 187, 187, 0.253);
    padding: 15px 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 140%;
    z-index: 9999;
    background-color: #131313 !important;
    color: white;
}

.compare-page .doubt-banner a {
    color: white !important;
    font-size: 16px;
    text-align: center;
}

.compare-page .doubt-banner .phone-icon {
    /* transform: translateY(5px); */
}

.compare-page .doubt-banner .phone-icon img {
    height: 30px;
    width: 30px;
}

.compare-page .doubt-banner .contact-us {
    font-weight: 600;
    line-height: 147%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    border-radius: 0.375rem;
    /* margin-left    : 1rem; */
    color: #000;
    text-decoration: none;
}

.compare-page .doubt-banner .button {
    font-size: 16px;
    white-space: nowrap;
    background-color: #f7c20a;
    border-radius: 5px;
    text-align: center;
    color: black !important;
    padding: 5px 15px;
}

.compare-page .doubt-banner .contact-us img {
    margin-right: 0.375rem;
    height: 2rem;
    max-width: inherit;
}

.compare-page .faq-section-container .course-details-banner {
    padding: 3rem 4.5rem;
    padding-right: 1rem;
    margin: 0 5%;
    margin-top: 90px;
    background-color: #000;
    box-shadow: 0 0 10px var(--hashpro);
    border-radius: 1.25rem;
    position: relative;
    overflow: hidden;
    z-index: 2;
    display: flex;
    align-items: center;
}

.compare-page .faq-section-container .course-details-banner .heading-banner {
    color: #fff;
    font-size: 1.3rem;
}

.compare-page .faq-section-container .course-details-banner .ripple-effect {
    position: absolute;
    height: 35.375rem;
    width: 35.375rem;
    max-width: inherit;
    z-index: -1;
    right: -1.5rem;
}

.compare-page .faq-section-container .course-details-banner .left-container {
    margin-right: 20vw;
    width: 61vw;
}

.compare-page .faq-section-container .course-details-banner .left-container h2 {
    margin-bottom: 50px;
}

.compare-page .faq-section-container .course-details-banner .left-container .heading {
    font-style: normal;
    font-weight: 800;
    font-size: 2.25rem;
    line-height: 2.75rem;
    color: var(--hashpro);
    margin-bottom: 1.5rem;
    text-transform: uppercase;
}

.compare-page .faq-section-container .course-details-banner .left-container .mobile-container {
    display: none;
}

.compare-page .faq-section-container .course-details-banner .left-container .apply-btn {
    padding: 0.875rem 5.625rem;
    display: flex;
    align-items: center;
    background: var(--hashpro);
    border-radius: 6.61765px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #171421;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none;
    box-shadow: 0px 0px 6px 0px #f7c20a;
    text-transform: uppercase;
    transition: 0.3s;
}

.compare-page .faq-section-container .course-details-banner .left-container .apply-btn:hover {
    scale: 1.1;
}

.compare-page .faq-section-container .course-details-banner .right-container {
    display: flex;
    flex-wrap: wrap;
}

.compare-page .faq-section-container .course-details-banner .right-container .content {
    padding: 1.25rem 0;
}

.compare-page .faq-section-container .course-details-banner .right-container .content .heading {
    transform: translateX(-8px);
    font-size: 1rem;
    color: #cccad2;
    margin-bottom: 0.75rem;
}

.compare-page .faq-section-container .course-details-banner .right-container .content .value {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 130%;
    color: #f6f6ff;
}

@media only screen and (max-width: 1210px) {
    .compare-page .faq-section-container .faq {
        width: 50rem;
    }

    .compare-page .faq-section-container .course-details-banner .left-container {
        margin-right: 5vw;
    }

    .compare-page .faq-section-container .course-details-banner .right-container .content {
        width: 90%;
        text-align: left;
    }
}

@media only screen and (max-width: 1000px) {
    .compare-page .faq-section-container .faq {
        width: 100%;
    }

    .compare-page .doubt-banner {
        width: 80%;
    }

    .compare-page .faq-section-container .course-details-banner {
        padding: 2rem 0;
        border-radius: 0.5rem;
    }

    .compare-page .faq-section-container .course-details-banner .ripple-effect {
        position: absolute;
        height: 15.625rem;
        width: 15.625rem;
        max-width: inherit;
        z-index: -1;
        right: -7.5rem;
    }

    .compare-page .faq-section-container .course-details-banner .left-container {
        margin-right: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        display: flex;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .heading {
        text-align: center;
        margin: 0 7%;
        margin-bottom: 20px;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-bottom: 2rem;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container .content {
        padding: 1.25rem 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container .content .heading {
        font-size: 1rem;
        line-height: 175%;
        color: #cccad2;
        margin-bottom: 0.75rem;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container .content .value {
        font-style: normal;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 130%;
        color: #f6f6ff;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .apply-btn {
        background-color: var(--hashpro);
        padding: 0.875rem 5.625rem;
        display: flex;
        align-items: center;
        border-radius: 6.61765px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #171421;
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
    }

    .compare-page .faq-section-container .course-details-banner .right-container {
        display: none;
    }
}

@media only screen and (max-width: 512px) {
    .compare-page .faq-section-container {
        padding: 0.8rem;
        padding-bottom: 2.7rem;
    }

    .compare-page .doubt-banner {
        padding: 12px 12px;
        width: 90%;
        font-size: 0.875rem;
        line-height: 140%;
    }

    .compare-page .doubt-banner .contact-us {
        line-height: 116%;
        font-size: 0.75rem;
        /* padding      : .5rem .625rem; */
        border-radius: 0.375rem;
        /* margin-left  : 1rem */
    }

    .compare-page .doubt-banner .contact-us img {
        margin-right: 0.25rem;
        height: 0.875rem;
    }

    .compare-page .faq-section-container .course-details-banner {
        padding: 1rem 1.3rem;
        padding-top: 2.5rem;
        margin: 1.5rem 5%;
        border-radius: 0.5rem;
        margin-bottom: 0;
    }

    .compare-page .faq-section-container .course-details-banner .ripple-effect {
        height: 15.625rem;
        width: 15.625rem;
        right: -7.8125rem;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .heading {
        margin: auto;
        font-size: 1.25rem;
        margin-bottom: 1.15rem;
        /* text-align   : left */
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container .content {
        padding: 0.75rem 0;
        width: 100%;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container .content .heading {
        font-size: 0.75rem;
        line-height: 2.2rem;
        margin-bottom: 0;
        font-weight: 400;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .mobile-container .content .value {
        font-size: 1rem;
        line-height: 130%;
    }

    .compare-page .faq-section-container .course-details-banner .left-container .apply-btn {
        padding: 0.75rem;
        font-size: 0.75rem;
        line-height: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.faq-container {
    margin-top: 80px;
    width: 100%;
}

.faq-container .main-heading {
    margin-top: 0 !important;
    font-style: normal;
    font-size: 2.75rem;
    color: #fff;
    margin-bottom: 1rem;
    text-align: center;
}

.faq-container .main-heading.mobile {
    display: none;
}

.faq-container .faq {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    width: 55.2vw;
    color: #221c16;
}

@media only screen and (max-width: 1210px) {
    .faq-container .faq {
        width: 50rem;
    }
}

@media only screen and (max-width: 1000px) {
    .faq-container .faq {
        width: 100%;
    }
}

@media only screen and (max-width: 512px) {
    .faq-container .main-heading.mobile {
        display: block;
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
    }

    .faq-container .faq {
        margin-bottom: 0.75rem;
    }
}

.compare-page .get-in-touch {
    display: none;
    text-align: center;
    color: #fff;
    margin: 0 5%;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 30px !important;
}

.extra-details {
    max-width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.extra-details-card {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: white;
}

@media only screen and (min-width: 600px) {
    .extra-details-card {
        margin-top: 15px;
    }
}

.last {
    padding: 0 10% !important;
    margin: auto;
    text-align: center;
}

.last a {
    margin-left: 3px !important;
}

@media (min-width: 0px) and (max-width: 600px) {
    .extra-details {
        flex-direction: column;
    }

    .extra-details-card {
        margin-bottom: 15px;
    }
}

.extra-details-card a {
    color: #fff;
    transition: 0.5s;
    font-weight: 700;
}

.extra-details-card a:hover {
    color: var(--hashpro);
}

.extra-details-card a {
    margin-left: 10px;
}

@-webkit-keyframes marquee-anim {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-180px);
    }
}

@keyframes marquee-anim {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-180px);
    }
}


.marquee {
    display: flex;
    -webkit-animation: marquee-anim linear infinite 3s;
    animation: marquee-anim linear infinite 3s;
    transition: 0.2s;
}

.marquee img {
    border-top: 1px solid var(--hashpro);
    border-bottom: 1px solid var(--hashpro);
    width: 180px;
    padding: 2px 20px;
    transition: 0.5s;
}

.marquee-text {
    margin: 0 50px;
    font-size: 20px;
}

.extra-details {
    max-width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.extra-details-card {
    font-size: 0.8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}

@media (min-width: 0px) and (max-width: 600px) {
    .extra-details {
        flex-direction: column;
    }

    .extra-details-card {
        margin-bottom: 15px;
    }
}

.extra-details-card a {
    color: white;
    transition: 0.5s;
    font-weight: bold;
}

.extra-details-card a:hover {
    color: var(--hashpro);
}

.extra-details-card a {
    margin-left: 10px;
}

@media screen and (min-width: 600px) {
    .compare-page .hashpro-way-container[data-v-17ab2e64] {
        padding-top: 60px;
        padding-bottom: 90px;
    }

    .compare-page .curriculum .heading {
        margin-top: 50px;
    }

    .compare-page .admission-process {
        padding-top: 60px;
        padding-bottom: 40px;
    }
}

@media screen and (max-width: 600px) {
    #form {
        transform: translateY(-35px);
    }
}

@media screen and (max-width: 500px) {
    .desktop-heading {
        /* display: none !important */
    }

    .phone-heading {
        display: block !important;
    }

    .div-block-427.pm2 {
        margin-top: 0px;
    }

    .compare-page .cta-1 {
        padding-top: 40px;
    }

    .compare-page .instructors-and-members-container .instructor-container .instructor-bio-container {
        padding-bottom: 10px;
    }

    .home-section {
        margin-top: 0rem !important;
    }

    .heading-who {
        margin-top: 0;
    }

    .compare-page .admission-process {
        padding-top: 60px;
        padding-bottom: 20px;
    }
}

.splide__slide {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto;
}

.carousel-container img {
    border-radius: 5px;
    margin: auto;
}

.carousel-container {
    text-align: center;
}

.compare-page .cta-1.two {
    /* background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.9)), url(/public/global/compare/images/cta-2-bg.svg); */
    background-color: #404040;
    background-size: cover;
}

.compare-page .cta-1.two .subheading {
    text-transform: uppercase;
    color: white;
}

.compare-page .cta-1.two button {
    background-color: #1a1a1a;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.compare-page .cta-1.two button:hover {
    background-color: rgb(53, 53, 53);
    color: white;
}

.compare-page .cta-1.two .undertext {
    color: rgb(136, 136, 136) !important;
    margin-top: 12px;
}

.compare-page li {
    margin: auto !important;
}

.home-content_left {
    grid-area: hl;
}

.home-content_left {
    color: white;
    padding-left: 10%;
}

.vsl-container {
    /* padding-right: 10%; */
    grid-area: ss;
}

@media only screen and (max-width: 500px) {
    .home-content_left {
        max-width: 90%;
        margin: auto;
    }
}

@media only screen and (max-width: 1000px) {
    .home-content_left {
        padding-left: 0;
        max-width: 90%;
        margin: auto;
    }
}

.compare-page .cta-1.one {
    grid-area: cta;
}

.compare-page .hashpro-way-container {
    grid-area: way;
}

.form {
    grid-area: form;
}

.top-height {
    margin-top: 150px;
}

@media only screen and (max-width: 500px) {
    .top-height {
        margin-top: 100px;
    }
}

.phone-form-switcher {
    -moz-column-gap: 5%;
    column-gap: 5%;
    display: grid;
    grid-template-areas: "hl ss" "cta cta" "way way " "form form";
    grid-template-rows: -webkit-max-content -webkit-max-content -webkit-max-content;
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1000px) {
    .phone-form-switcher {
        grid-template-areas: "hl hl" "ss ss" "cta cta " "way way " "form form";
    }
}

@media only screen and (max-width: 500px) {
    .phone-form-switcher {
        grid-template-rows: -webkit-max-content;
        grid-template-rows: max-content;
        grid-template-areas: "hl " "form" "ss" "way" "cta ";
        grid-template-columns: 1fr;
    }
}

@media only screen and (min-width: 500px) {
    .form-mobile {
        display: none;
    }
}

.form-mobile p,
.form-mobile label {
    color: black;
}

#form {
    transform: translateY(-100px);
}

.vsl-container {
    width: 90%;
    /* height       : 100%; */
    /* height       : 56%; */
    margin: auto;
    display: block;
    text-align: center;
}

.vsl-container iframe {
    /* position: absolute; */
    /* width         : 100%; */
    /* margin-left: auto; */
    margin: 0 auto;
    aspect-ratio: 16/9;
    display: block;
}

.compare-page .hero {
    margin-top: 35px;
    padding-top: 5rem;
    padding-bottom: 5rem;
    color: white;
}

@media only screen and (max-width: 600px) {
    .compare-page .hero {
        padding: 0;
        padding-top: 10%;
    }
}

.compare-page .hero .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

@media only screen and (max-width: 600px) {
    .compare-page .hero .grid {
        grid-template-columns: 1fr;
    }
}

.compare-page .hero .grid .info {
    background: #131313;
    overflow: hidden;
    position: relative;
    border-radius: 15px;
    padding: 10%;
}

@media only screen and (max-width: 600px) {
    .compare-page .hero .grid .info {
        padding: 5%;
        margin: 0 2%;
    }
}

.compare-page .hero .grid .info h1 {
    font-size: 3rem !important;
    margin-bottom: 10px;
}

.compare-page .hero .grid .info .btn-container {
    margin-top: 30px;
    display: flex;
    row-gap: 15px;
    -moz-column-gap: 20px;
    column-gap: 20px;
    flex-wrap: wrap;
}

.compare-page .hero .grid .info .btn-container .apply {
    box-shadow: 0px 0px 11px 1px var(--hashpro);
    color: white;
    background-color: black;
    border: 1px solid var(--hashpro);
}

.compare-page .hero .grid .info .btn-container .right {
    color: black;
}

.compare-page .hero .grid .info.dmx {
    background-color: var(--hashpro);
    color: black;
}

@-webkit-keyframes move-launch {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(120%);
    }
}

@keyframes move-launch {
    from {
        transform: translateX(-50%);
    }

    to {
        transform: translateX(120%);
    }
}

.compare-page .hero .grid .info.dmx .newly-launched {
    background-color: white;
    font-size: 0.8rem;
    position: absolute;
    top: -20px;
    right: -133px;
    rotate: 45deg;
    padding: 5px 15px;
    -webkit-animation: move-launch 5s linear infinite;
    animation: move-launch 5s linear infinite;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    white-space: nowrap;
    border-radius: 20px;
}

.compare-page .hero .grid .info.dmx .newly-launched span {
    white-space: nowrap;
    line-height: 1;
    font-weight: 600;
}

.compare-page .hero .compare {
    margin: 0 auto;
    margin-top: 40px;
    font-size: 1.2rem;
}

.compare-page .usp-container {
    background-image: linear-gradient(to top, #0a0802, transparent 100%);
    color: white;
    padding-top: 50px;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    -moz-column-gap: 30px;
    column-gap: 30px;
    row-gap: 30px;
    grid-template-areas: none;
}

@media only screen and (max-width: 1000px) {
    .compare-page .usp-container {
        grid-template-columns: 1fr 1fr;
    }
}

.compare-page .usp-container .divider {
    display: none;
}

@media only screen and (max-width: 600px) {
    .compare-page .usp-container .divider {
        display: block;
        width: 50%;
        margin: auto;
        background-color: rgb(63, 63, 63);
        height: 2px;
    }
}

@media only screen and (max-width: 600px) {
    .compare-page .usp-container {
        grid-template-columns: 1fr;
    }
}

.compare-page .usp-container .usp {
    text-align: center;
}

.compare-page .usp-container .usp h3 {
    text-transform: uppercase;
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 2px;
}

.compare-page .usp-container .usp h2 {
    margin: 10px 0;
    font-size: 1.5rem;
}

.compare-page .usp-container .usp h4 {
    font-size: 0.8rem;
    font-weight: 400;
    margin: auto;
}

@-webkit-keyframes btn-glow-anim {
    from {
        box-shadow: 0px 0px 5px var(--hashpro);
    }

    to {
        box-shadow: 0px 0px 15px var(--hashpro);
    }
}

@keyframes btn-glow-anim {
    from {
        box-shadow: 0px 0px 5px var(--hashpro);
    }

    to {
        box-shadow: 0px 0px 15px var(--hashpro);
    }
}

.glow-anim {
    -webkit-animation: 1.5s ease-in-out alternate infinite btn-glow-anim;
    animation: 1.5s ease-in-out alternate infinite btn-glow-anim;
}

@-webkit-keyframes btn-glow-anim-white {
    from {
        box-shadow: 0px 0px 5px white;
    }

    to {
        box-shadow: 0px 0px 15px white;
    }
}

@keyframes btn-glow-anim-white {
    from {
        box-shadow: 0px 0px 5px white;
    }

    to {
        box-shadow: 0px 0px 15px white;
    }
}

.glow-anim-white {
    -webkit-animation: 1.5s ease-in-out alternate infinite btn-glow-anim-white;
    animation: 1.5s ease-in-out alternate infinite btn-glow-anim-white;
}

.gradient-border {
    --borderWidth: 2px;
    background: #1D1F20;
    position: relative;
    border-radius: var(--borderWidth);
}

.gradient-border:after {
    content: "";
    position: absolute;
    top: calc(-1 * var(--borderWidth));
    left: calc(-1 * var(--borderWidth));
    height: calc(100% + var(--borderWidth) * 2);
    width: calc(100% + var(--borderWidth) * 2);
    background: linear-gradient(60deg, var(--hashpro), white, black);
    border-radius: 20px;
    z-index: -1;
    -webkit-animation: animatedgradient 3s linear infinite;
    animation: animatedgradient 3s linear infinite;
    background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

@keyframes animatedgradient {
    0% {
        background-position: 0% 50%;
    }

    50% {
        background-position: 100% 50%;
    }

    100% {
        background-position: 0% 50%;
    }
}

.compare-page .growth {
    overflow: hidden;
    padding-top: 70px;
    padding-bottom: 70px;
    color: white;
    background-image: linear-gradient(to bottom, #0a0802, #000000 80%);
    position: relative;
}

.compare-page .growth .ribbon {
    position: absolute;
    top: 31px;
    left: -67px;
    rotate: 350deg;
    padding: 2px 0;
    opacity: 0.5;
    z-index: -1;
    background: black;
    display: none;
}

.compare-page .growth .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px) {
    .compare-page .growth .grid {
        grid-template-columns: 1fr;
    }
}

.compare-page .growth .grid .info h2 {
    font-size: 2rem;
    text-align: left;
}

.compare-page .growth .grid .info p {
    margin-top: 20px;
    font-weight: 300;
}

.compare-page .growth .grid .info a {
    text-decoration: underline;
    color: #3b3b3b;
}

.compare-page .growth .grid .info a:hover {
    color: #cfcfcf;
}

@-webkit-keyframes text-gradient-anim {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

@keyframes text-gradient-anim {
    0% {
        background-position: 0% 50%;
    }

    100% {
        background-position: 100% 50%;
    }
}

.yellow-gradient {
    background: linear-gradient(to right, #c2c2c2 20%, var(--hashpro) 30%, #c2c2c2 70%, var(--hashpro) 80%);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent;
    background-size: 500% auto;
    -webkit-animation: text-gradient-anim 5s ease-in-out infinite alternate;
    animation: text-gradient-anim 5s ease-in-out infinite alternate;
}

.black-gradient {
    color: white;
    text-shadow: -1px 1px 0 hsla(0deg, 0%, 0%, 0.4), 1px 1px 0 hsla(0deg, 0%, 0%, 0.4), 1px -1px 0 hsla(0deg, 0%, 0%, 0.4), -1px -1px 0 hsla(0deg, 0%, 0%, 0.4);
}

.compare-page .learn {
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: var(--hashpro);
    color: black;
}

.compare-page .learn h2 {
    font-size: 2rem;
    color: black;
}

.compare-page .learn p {
    font-size: 1rem;
}

.compare-page .learn .cards {
    color: white;
    margin-top: 45px;
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 600px) {
    .compare-page .learn .cards {
        grid-template-columns: 1fr;
    }
}

.compare-page .learn .cards .card {
    padding: 30px;
    border: 1px solid rgb(31, 31, 31);
    border-radius: 10px;
    background: #050505;
    box-shadow: 2px 2px rgba(232, 189, 31, 0.1215686275);
    border: 1px solid rgba(255, 255, 255, 0.0901960784);
}

.compare-page .learn .cards .card h3 {
    font-weight: 500;
    color: white;
}

.compare-page .learn .cards .card .points {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 15px;
}

.compare-page .learn .cards .card .points .point {
    display: flex;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

.compare-page .learn .cards .card .points .point p {
    font-size: 0.85rem;
    line-height: 1.5;
    font-weight: 300;
    color: #e6e6e6;
}

.compare-page .learn .cards .card .points .point img {
    height: 22px;
    width: 16px;
    filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%) hue-rotate(348deg) brightness(98%) contrast(86%);
    transform: translateY(-4px);
}

.compare-page .learn .cards .card.dmx {
    border: 3px solid white;
    -webkit-animation: card-glow-anim linear infinite 2s alternate;
    animation: card-glow-anim linear infinite 2s alternate;
}

@-webkit-keyframes card-glow-anim {
    from {
        box-shadow: inset 0px 0px 6px var(--hashpro);
    }

    to {
        box-shadow: inset 0px 0px 15px var(--hashpro);
    }
}

@keyframes card-glow-anim {
    from {
        box-shadow: inset 0px 0px 6px var(--hashpro);
    }

    to {
        box-shadow: inset 0px 0px 15px var(--hashpro);
    }
}

.compare-page .who {
    background-color: #131313;
    color: white;
    padding-top: 50px;
    padding-bottom: 70px;
    background-image: linear-gradient(to bottom, #070707, rgb(12, 10, 2) 100%);
}

.compare-page .who h2 {
    font-size: 2rem;
    color: white;
}

.compare-page .who .grid {
    margin-top: 45px;
    display: grid;
    color: white;
    grid-template-columns: 60% 40%;
    gap: 15px;
}

@media only screen and (max-width: 600px) {
    .compare-page .who .grid {
        grid-template-columns: 100%;
        margin-top: 30px;
    }
}

.compare-page .who .grid .info .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 15px;
}

@media only screen and (max-width: 600px) {
    .compare-page .who .grid .info .cards {
        grid-template-columns: 100%;
        row-gap: 20px;
    }
}

.compare-page .who .grid .info .cards .card {
    padding: 10px;
    display: flex;
    flex-direction: column;
    row-gap: 5px;
}

.compare-page .who .grid .info .cards .card h3 {
    font-weight: 500;
    color: white;
    border-bottom: 2px solid var(--hashpro);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.compare-page .who .grid .info .cards .card p {
    font-size: 0.85rem;
    color: #cccccc;
    line-height: 1.5;
}

.compare-page .who .grid .banner {
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.compare-page .process.copy {
    background-color: #f8d00d !important;
}

.compare-page .process.copy .card span {
    background-color: var(--hashpro) !important;
}

.compare-page .process {
    padding-top: 50px;
    padding-bottom: 70px;
    color: black;
    background-color: var(--hashpro);
}

.compare-page .process h2 {
    color: black;
    font-size: 2rem;
}

.compare-page .process .steps {
    background-color: transparent !important;
    margin-top: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    position: relative;
    gap: 20px;
    padding: 0 !important;
}

@media only screen and (max-width: 767px) {
    .compare-page .process .steps {
        grid-template-columns: 1fr;
    }
}

.compare-page .process .steps .line {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    width: 100%;
    height: 3px;
    background-color: #050505;
}

@media only screen and (max-width: 767px) {
    .compare-page .process .steps .line {
        transform: translateX(-50%);
        top: 0;
        bottom: 0;
        left: 50%;
        height: 100%;
        width: 4px;
    }
}

.compare-page .process .steps .card {
    border: 1px solid rgba(255, 255, 255, 0.2196078431);
    border-radius: 15px;
    padding: 20px;
    z-index: 2;
    background-color: #050505;
    display: flex;
    flex-direction: column;
    gap: 15px;
    color: white;
}

.compare-page .process .steps .card span {
    background: var(--hashpro);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    color: black;
    font-size: 0.7rem;
    padding: 4px 12px;
    font-weight: 600;
    border-radius: 20px;
}

.compare-page .process .steps .card h3 {
    font-size: 1.17rem;
}

.compare-page .process .steps .card p {
    font-size: 0.9rem;
    color: #cccccc;
    line-height: 1.5;
}

.compare-page .process .grid {
    margin-top: 20px;
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 450px) {
    .compare-page .process .grid {
        grid-template-columns: 1fr;
    }
}

.compare-page .process .grid p {
    margin-top: 12px;
    font-size: 0.9rem;
    color: #cccccc;
    line-height: 1.5;
}

.compare-page .process .grid .placeholder {
    background-color: black;
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.compare-page .process .grid .second {
    background-color: #050505;
    border: 1px solid rgba(232, 189, 31, 0.2117647059);
    border-radius: 15px;
    padding: 20px;
}

.compare-page .process .grid .second h3 {
    color: white;
}

.compare-page .process .grid .second .points {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    -moz-column-gap: 10px;
    column-gap: 10px;
    row-gap: 15px;
}

.compare-page .process .grid .second .points .point {
    display: flex;
    -moz-column-gap: 10px;
    column-gap: 10px;
}

.compare-page .process .grid .second .points .point p {
    font-size: 0.85rem;
    line-height: 1.5;
    font-weight: 300;
    color: #ffffff;
}

.compare-page .process .grid .second .points .point img {
    height: 12px;
    width: 12px;
    margin-top: 2px;
    filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%) hue-rotate(348deg) brightness(98%) contrast(86%);
}

.compare-page .crafters {
    position: relative;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #070707;
    overflow: hidden;
    color: white;
}

.compare-page .crafters .slide-btn-container {
    display: none;
    -moz-column-gap: 15px;
    column-gap: 15px;
    position: absolute;
    top: 50px;
    right: 50px;
}

.compare-page .crafters .slide-btn-container .btn-container {
    width: 60px;
    height: 60px;
    background-color: rgb(139, 139, 139);
    border-radius: 50%;
    position: relative;
    transition: 0.1s;
}

.compare-page .crafters .slide-btn-container .btn-container:hover {
    cursor: pointer;
    filter: invert(100%);
}

.compare-page .crafters .slide-btn-container img.left {
    transform: translate(-50%, -50%) rotate(180deg);
}

.compare-page .crafters .slide-btn-container img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50px;
    height: 50px;
}

.heading-img {
    width: 20px !important;
    scale: 2;
}

.compare-page .crafters .heading img {
    margin-left: 8px;
}

.compare-page .crafters .heading {
    font-size: 2rem !important;
    text-align: left !important;
    color: white;
}

.compare-page .crafters .subheading {
    margin-top: 6px;
    text-align: left !important;
}

.compare-page .crafters .subheading {
    margin-bottom: 50px;
    text-align: left;
    font-size: 1rem !important;
}

.compare-page .crafters .splide__slide {
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important;
    margin: 0px 10px !important;
    border: none !important;
}

@media only screen and (max-width: 800px) {
    .compare-page .crafters .splide__arrows {
        top: 100%;
        right: 50%;
        transform: translateX(50%) translateY(-60%);
    }

    .compare-page .crafters .splide__arrow {
        width: 30px !important;
        height: 30px !important;
    }
}

.splide__arrow {
    background-color: white !important;
    opacity: 1 !important;
}

.compare-page .crafters .splide__arrow {
    position: static !important;
    background-color: white !important;
    opacity: 1 !important;
    width: 45px !important;
    height: 45px !important;
}

.compare-page .crafters .splide {
    position: static !important;
}

.compare-page .crafters .splide__pagination {
    display: none;
}

.compare-page .crafters .splide__track {
    overflow: visible !important;
    position: static !important;
}

.compare-page .faculty .faculty-img {
    border-radius: 6px;
    background-color: var(--hashpro);
    aspect-ratio: 1/1;
    width: 250px;
    height: 250px;
    box-shadow: 2px 2px var(--hashpro);
}

.faculty .text {
    margin-top: 5px;
    position: relative;
    margin-left: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.faculty .text p {
    line-height: 28px;
}

.faculty .text .name {
    font-weight: 500;
    font-size: 0.95rem;
    color: white;
    filter: invert(0);
}

.faculty .text .desc {
    font-weight: 500;
    font-size: 0.85rem;
    line-height: 20px;
    color: #707070b6;
}

.text .social-media {
    display: flex;
    gap: 4px;
    position: absolute;
    bottom: 0px;
    right: 0;
}

.faculty .text .social-media img {
    width: 20px;
    height: 20px;

    filter: brightness(80%);
}

.name {
    font-size: 0.9rem;
}

.compare-page .crafters a {
    height: 100%;
    display: inline-block;
    transition: 0.2s ease-in-out;
}

.compare-page .crafters a:hover {
    cursor: pointer;
    transform: translateY(-5px);
    z-index: 999;
}

.compare-page .splide__pagination__page.is-active {
    background: #fff;
    transform: scale(1.4);
    z-index: 1;
}

.compare-page .splide__pagination__page:hover {
    cursor: pointer;
    opacity: 0.9;
}

.splide__slide {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.splide__slide:focus {
    outline: 0;
}

.compare-page .crafters .splide__arrows {
    position: absolute;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: flex;
    -moz-column-gap: 20px;
    column-gap: 20px;
    right: 10vw;
    top: 60px;
}

@media only screen and (max-width: 800px) {
    .compare-page .crafters .splide__arrows {
        /* padding-top: 10px; */
        top: 100%;
        right: 50%;
        transform: translateX(50%) translateY(-60%);
    }

    .compare-page .crafters .splide__arrow {
        width: 30px !important;
        height: 30px !important;
    }
}

.splide__arrow {
    background-color: white !important;
    opacity: 1 !important;
}

.compare-page .crafters .splide__arrow {
    position: static !important;
    background-color: white !important;
    opacity: 1 !important;
    width: 45px !important;
    height: 45px !important;
}

.splide__track--nav>.splide__list>.splide__slide.is-active {
    border-color: transparent !important;
}

@-webkit-keyframes box-glow-anim {
    from {
        box-shadow: 0px 0px 15px white;
    }

    to {
        box-shadow: 0px 0px 6px white;
    }
}

@keyframes box-glow-anim {
    from {
        box-shadow: 0px 0px 15px white;
    }

    to {
        box-shadow: 0px 0px 6px white;
    }
}

.compare-page .difference {
    padding-top: 50px;
    padding-bottom: 70px;
    /* border-bottom-left-radius: 60px;  */
    /* border-bottom-right-radius: 60px; */
    color: white;
    background-color: #161616;
}

@media only screen and (max-width: 600px) {
    .compare-page .difference {
        padding-left: 0;
        padding-right: 0;
    }
}

.compare-page .difference .container {
    background: #101010;
    padding: 50px 2%;
    border-radius: 20px;
}

@media only screen and (max-width: 600px) {
    .compare-page .difference .container {
        padding: 50px 0;
    }
}

.compare-page .difference .heading {
    color: var(--hashpro);
    margin-bottom: 40px;
}

@media only screen and (max-width: 600px) {
    .compare-page .difference .desktop .chip {
        display: none !important;
    }
}

.compare-page .difference .desktop .grid {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.compare-page .difference .desktop .row.grid {
    -moz-column-gap: 10px;
    column-gap: 10px;
}

.compare-page .difference .desktop .side-heading {
    margin: 10px 0;
}

.compare-page .difference .desktop .head {
    margin-bottom: 20px;
}

.compare-page .difference .desktop .head .chip {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #202020;
    padding: 5px 10px;
    border-radius: 20px;
    margin: auto;
}

.compare-page .difference .desktop .head .chip p {
    text-transform: uppercase;
    font-size: 0.7rem;
}

.compare-page .difference .desktop .head .price {
    font-size: 2.5rem;
    font-weight: 600;
}

.compare-page .difference .desktop .head .duration {
    text-transform: uppercase;
    font-size: 0.9rem;
}

.compare-page .difference .desktop .head h2 {
    text-align: center;
    font-size: 1.5rem;
}

@media only screen and (max-width: 600px) {
    .compare-page .difference .desktop .head h2 {
        font-size: 1.2rem;
        text-align: left;
    }
}

.compare-page .difference .desktop .head div {
    padding: 0 20px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    row-gap: 15px;
}

@media only screen and (min-width: 600px) {
    .compare-page .difference .desktop .head div {
        padding: 20px;
    }
}

.compare-page .difference .desktop .head .dmx {
    /* scale: 1.05; */
    /* color: black; */
    /* background-color: var(--hashpro); */
    /* box-shadow: 0px 0px 10px var(--hashpro); */
    color: var(--hashpro);
}

@media only screen and (min-width: 600px) {
    .compare-page .difference .desktop .head .dmx {
        background-color: var(--hashpro);
        color: black;
    }
}

.compare-page .difference .desktop .head .dmx p {
    color: white;
}

.compare-page .difference .desktop .head .dmx .duration {
    color: black;
}

.compare-page .difference .desktop .head .dmx .price {
    color: black;
}

.compare-page .difference .desktop .head .dmx .chip {
    background-color: black !important;
}

.compare-page .difference .desktop .info {
    display: flex;
    flex-direction: column;
}

.compare-page .difference .desktop .info .row {
    padding: 25px 20px;
    border-radius: 15px;
}

@media only screen and (max-width: 600px) {
    .compare-page .difference .desktop .info .row {
        padding: 15px 20px;
    }
}

.compare-page .difference .desktop .info .row img {
    width: 25px;
    height: 25px;
    margin: auto;
}

.compare-page .difference .desktop .info .row h5,
.compare-page .difference .desktop .info .row p {
    font-size: 1.05rem;
    font-weight: 400;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto 0;
}

@media only screen and (max-width: 600px) {

    .compare-page .difference .desktop .info .row h5,
    .compare-page .difference .desktop .info .row p {
        font-size: 0.9rem;
    }
}

.compare-page .difference .desktop .info .row p {
    text-align: center;
}

.compare-page .difference .desktop .info .row:nth-child(odd) {
    background: #0a0a0a;
}

.compare-page .difference .mobile {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media only screen and (min-width: 600px) {
    .compare-page .difference .mobile {
        display: none;
    }
}

.compare-page .difference .mobile>div {
    padding: 40px 20px;
    border-radius: 25px;
}

.compare-page .difference .mobile .dm {
    background: #0e0e0e;
}

.compare-page .difference .mobile .dmx {
    background: #0e0e0e;
    border: 2px solid var(--hashpro);
    box-shadow: 0px 0px 15px var(--hashpro);
}

.compare-page .difference .mobile .dmx .rows p {
    font-size: 0.9rem;
    margin: auto 0;
    font-weight: 500;
}

.compare-page .difference .mobile .head {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
}

.compare-page .difference .mobile .head h2 {
    font-size: 2rem;
}

.compare-page .difference .mobile .head .chip {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background: #474747;
    padding: 5px 10px;
    border-radius: 20px;
}

.compare-page .difference .mobile .head .chip p {
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: 600;
}

.compare-page .difference .mobile .head .price {
    font-size: 2.5rem;
    font-weight: 600;
}

.compare-page .difference .mobile .divider {
    width: 100%;
    height: 2px;
    background: #808080;
    margin: 10px 0;
}

.compare-page .difference .mobile .rows {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.compare-page .difference .mobile .rows .row {
    display: flex;
    -moz-column-gap: 10px;
    column-gap: 10px;
    border-radius: 15px;
}

.compare-page .difference .mobile .rows .row img {
    width: 28px;
    height: 28px;
    margin: auto 0;
}

.compare-page .difference .mobile .rows .row p {
    font-size: 0.9rem;
    margin: auto 0;
    font-weight: 400;
}

.compare-page .difference button {
    width: 100%;
    padding: 10px 20px;
    border: none;
    border-radius: 20px;
    margin-top: 20px;
    font-size: 1.1rem;
    background: #474747;
    color: white;
}

.compare-page .difference .dmx button {
    background: black;
    color: white;
    box-shadow: 0px 0px 5px var(--hashpro);
}

.compare-page .difference .confused {
    text-align: center;
    margin-top: 50px;
}

.compare-page .difference .confused button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    margin-top: 15px;
}

.force-yellow {
    filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%) hue-rotate(348deg) brightness(98%) contrast(86%) !important;
}

#compare-programs {
    transform: translateY(-40px);
}

/*# sourceMappingURL=dme.css.map */