
.our-work {
  margin-top: 10%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-image: url(https://www.hashproacademy.com/static/media/white.3835c0a89846b3c06a1d.jpg);
  overflow: hidden;
  width: 100%;
  height: 100vh;
  position: relative;
  border-radius: 20px;
}
.our-work h1 {
  background-color: #f8d00d;
  border-radius: 10px;
  padding: 1rem;
  border: 5px;
}
.carosel-heading {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 10%;
  min-height: 100%;
  margin: auto 0;
  top: 0;
  font-size: 3rem;

  top: 50%;
  z-index: 1234567890;

  color: black !important;
  height: auto;
}

.our-work::before {
  width: 50vw;
  height: 50vw;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 0;

  border-radius: 760px;
  transform: translate(-50%, -50%);
  backface-visibility: hidden;
  opacity: 0.4;
  filter: blur(270px);
  content: "";
  pointer-events: none;
  will-change: transform;
}

#coroselslider {
  position: relative;

  margin: 0;
  padding: 0;
  z-index: 1; /* Ensure slider is on top of overlay */
}

.carousel__slider {
  position: relative;
  width: 100%;
  min-height: 400px;
}

.carousel__item {
  color: black;
  width: 500px;

  padding: 48px;
  min-height: 400px;

  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  position: absolute;
  top: 0%;
  opacity: 0;
  top: 0%;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-shadow: 10px 10px #424b58, -10px -10px #424b58;
  align-items: left;
}

.carousel__nav {
  display: flex;
  gap: 16px;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
}

.carousel__nav__item {
  background: #424b58;
  box-shadow: none;
  display: block;
  width: 10px;
  height: 10px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  cursor: pointer;
}

.carousel__nav__item--active {
  background: red;
}

.carousel__nav {
  position: absolute;
  top: 40%;
  left: 550px;
}
.carousel__nav__item {
  height: 20px !important;
  display: block;
  display: none;
  width: 20px !important;
}

@media screen and (max-width: 600px) {
  .our-work {
    flex-direction: column !important;
    margin-top: 10%;
  }

  #parent-carosel,
  #coroselslider {
    display: flex;
    flex-direction: column;
  }
  .carousel__item {
    position: absolute;
    left: -140px;
    transform: translate(-50%, -50%);
    width: 280px;
    padding: 30px;
    min-height: 300px;
    top: 25%;
  }

  .carosel-heading {
    margin: 0;
    padding: 0;
    position: absolute;
    left: 10%;
    min-height: 100%;
    margin: auto 0;
    top: 15%;
    font-size: 2.5rem;
    width: 100%;
    margin: auto;
    
  }
  .our-work h1{
    border: 5px solid black;
  }
}
