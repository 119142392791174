.navbar {
    position: relative;
    z-index: 9998;
}

.nav .logo-img-container {
    height: fit-content;
    display: block;
    margin: auto 0;
}

.nav .logo-img {
    display: flex;
    align-items: center;
    background-color: transparent;
    display: flex;
    text-align: center;
    margin: auto;
    width: 150px !important;
    margin-left: 20px;
}

.nav-container {
    height: fit-content;
    background-color: #181818;
    position: fixed;
    top: 0px;
    left: 0;
    width: 100%;
    z-index: 9998;
}

.nav-container .courses-desktop .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 30px;
}

.nav-container .courses-desktop {
    z-index: -1;
    padding: 5% 8%;
    width: 100%;
    position: fixed;
    color: white;
    top: -100%;
    background-color: #101010;

    /* background-color: var(--hashpro); */
    transition: 0.5s;
    left: 0;
    box-shadow: 0px 0px 12px #484848;
    right: 0;
}

@media only screen and (max-width: 900px) {
    .nav-container .courses-desktop {
        display: none;
        box-shadow: none;
    }

    .nav-container .courses-desktop.mobile {
        position: relative;
        display: block;
        z-index: 9998;
        top: auto;
    }

    .nav-container .courses-desktop.mobile .grid {
        grid-template-columns: 1fr;
        gap: 50px;
    }
}

.nav-container .courses-desktop .coursess {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.nav-container .courses-desktop .course {
    display: flex;
    color: black;
    gap: 10px;
}

.nav-container .courses-desktop .course a {
    font-weight: 500;
    /* color: black; */
}

.nav-container .courses-desktop .contact .container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.nav-container .courses-desktop .contact .container .card {
    /* filter: invert(1); */
}

.nav-container .courses-desktop .contact .container .card a {
    display: flex;
    gap: 5px;
    font-weight: 300;
}

.nav-container .courses-desktop .contact .container .card img {
    width: 15px;
    height: 15px;
    display: block;
    margin: auto 0;
}

.nav-container .courses-desktop .pages {
    /* color: black; */
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.nav-container .courses-desktop .pages .page a {
    /* color: black; */
    font-weight: 500;
}

.nav {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9998;
    display: flex;
    justify-content: space-between;
    width: 100%;
    /* background-color: #181818;*/
    border-bottom: 1px solid #aaaaaa;
    box-shadow: 0px 0px 15px black;

}

.nav .final {
    display: flex;
}

.nav .contact-now {
    display: flex;
    align-items: center;
}

.nav .contact-now a {
    color: black;
    font-size: 14px;
    transition: 0.2s;
    border-radius: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    background-color: #f8d00d;
}

.nav .contact-now a:hover img {
    animation: phoneRinging 0.2s infinite;
}

.nav .contact-now a img {
    transition: 0.5s;
    transform: rotateY(180deg);
    width: 20px;
    height: 20px;
    margin-right: 3px;
    filter: invert(100%);
}

.nav .contact-now a:hover {
    background-color: #f8d00d71;
}

.nav .explore-courses {
    display: flex;
    align-items: center;
}

.nav .explore-courses {
    color: black;
    font-size: 14px;
    transition: 0.2s;
    border-radius: 30px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    margin-right: 10px;
    background-color: #f8d00d;
    font-weight: 600;
}

.nav .explore-courses img {
    transition: 0.5s;
    width: 11px;
    filter: drop-shadow(79px 4px 50px black);
    margin-left: 5px;
}

.nav .explore-courses:hover {
    cursor: pointer;
    background-color: #f8d00d71;
}

.nav .refs {
    display: flex;
    align-items: center;
}

.nav .refs a {
    border-radius: 5px;
    padding-top: 50px;
    padding: 2px 20px;
    font-size: 15px;
    color: white;
    transition: 0.2s;
}

.nav .refs a:hover {
    background-color: #f8d00d71;
}

.nav>.nav-btn {
    display: none;
}

.nav>.nav-links {
    z-index: 0;
    display: none;
    float: right;
    font-size: 18px;
}

.nav>#nav-check {
    display: none;
}

@media (max-width:900px) {
    .nav .final {
        display: none;
    }

    .nav>.refs {
        display: none;
    }

    .nav>.nav-btn {
        display: block;
        position: absolute;
        right: 0px;
        top: 9px;
    }

    .nav>.nav-btn>label {
        margin-top: 8px;
        margin-right: 10px;
        display: inline-block;
        width: 50px;
        height: 50px;
        padding: 13px;
        border-radius: 2px;
    }

    .nav>.nav-btn>label:hover,
    #nav-check:checked~.nav-btn>label {
        cursor: pointer;
        background-color: rgba(0, 0, 0, 0.1);
    }

    .nav>.nav-btn>label>span {
        display: block;
        width: 25px;
        height: 10px;
        border-top: 2px solid white;
    }

    .nav>.nav-links {
        position: absolute;
        display: block;
        width: 100%;
        background-color: #101010;
        height: 0px;
        transition: all 0.3s ease-in;
        overflow-y: hidden;
        top: 70px;
        left: 0px;
    }

    .nav-links-container {
        margin-bottom: 50px;
    }

    .nav-links-container>.navbar-mobile-links {
        display: flex;
        padding: 5px 0;
        color: black;
    }

    .nav-links-container>.navbar-mobile-links.first {
        margin-top: 15px;
        font-weight: 500;
    }

    .nav-links-container>.navbar-mobile-links img {
        display: block;
        margin: auto 0;
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }

    .nav-links-container>.navbar-mobile-links span {
        display: inline-block;
        margin: auto 0;
        margin-left: 5px;
    }

    #nav-check:not(:checked)~.nav-links {
        height: 0px;
    }

    #nav-check:checked~.nav-links {
        height: 100vh;
        overflow-y: auto;
    }
}

@media only screen and (max-width: 474px) {
    .nav>.nav-btn {
        top: 20px;
    }
}

.navbar-marquee {
    display: flex;
    padding: 5px 0;
    z-index: 9998;
    position: fixed;
    top: 70px;
    left: 0;
    width: 100%;
    background-color: #f8d00d;
    color: black;
}

@media only screen and (max-width: 900px) {
    .navbar-marquee {
        top: 55px;
    }
}

.navbar-marquee p {
    white-space: pre;
    font-size: 13px;
    white-space: nowrap;
    display: inline-block;
    font-weight: 500;
    animation: move-navbar-marquee 10s linear infinite;
}

@media screen and (max-width:600px) {
    .navbar-marquee p {
        font-size: 0.6rem;
        animation: move-navbar-marquee 9s linear infinite;
    }
}

@media screen and (max-width:474px) {
    .nav .logo-img {
        width: 120px !important;
        /* margin-top: 5px; */
    }

    .navbar-marquee {
        top: 50px;
    }



    .nav>.nav-btn>label {
        margin-top: -2px;
    }
}

@keyframes phoneRinging {
    0% {
        transform: rotateZ(-5deg) rotateY(180deg) translateY(2px) translateX(1px);
    }

    50% {
        transform: rotateZ(5deg) rotateY(180deg) translateY(2px) translateX(1px);
    }

    100% {
        transform: rotateZ(-5deg) rotateY(180deg) translateY(2px) translateX(1px);
    }
}

@keyframes move-navbar-marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-528px);
    }
}

@keyframes move-navbar-marquee {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-390px);
    }
}



.navbar .courses-desktop a {
    font-size: 0.87rem;
    width: fit-content;
}

.navbar .courses-desktop a:hover {
    text-decoration: underline;
}

.navbar h3 {
    font-weight: 700;
    color: #f8d00d;
}


.dmx-masterclass-book-desktop {
    /* display: block; */
    display: none;
}

.dmx-masterclass-book-mobile {
    display: none;

}

@media only screen and (max-width: 1200px) {

    .dmx-masterclass-book-desktop {
        display: none;

    }

    .dmx-masterclass-book-mobile {
        /* display: block; */
        display: none;
    }

}

