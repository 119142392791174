@font-face {
  font-family: Valerius;
  src: url(/public/global/aoi/fonts/valerius/Valerius.ttf);
}

@font-face {
  font-family: ValeriusBold;
  src: url(/public/global/aoi/fonts/valerius/Valerius.otf);
}

@font-face {
  font-family: Slalom;
  src: url(/public/global/aoi/fonts/slalom/CASlalomExtended-Medium.otf);
}

@font-face {
  font-family: SlalomLight;
  src: url(/public/global/aoi/fonts/slalom/CASlalomExtended-Light.otf);
}

.aoi-page section {
  padding: 5rem 10vw;
}

@media only screen and (max-width: 600px) {
  .aoi-page section {
    padding: 4rem 7%;
  }
}

.aoi-page .extra-bold {
  font-weight: 900;
}

.aoi-page h1 {
  font-family: Valerius, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 474px) {
  .aoi-page h1 {
    font-size: 70px;
  }
}

.aoi-page span {
  font-family: inherit !important;
}

.aoi-page h2 {
  color: white;
  text-align: center;
  font-size: 40px;
  font-weight: 400;
}

@media screen and (max-width: 474px) {
  .aoi-page h2 {
    font-size: 30px;
  }
}

.aoi-page h3 {
  font-family: Slalom, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.aoi-page h4 {
  font-family: Slalom, Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.aoi-page h6 {
  font-family: Slalom, Arial, Helvetica, sans-serif;
  font-size: 20px;
  font-weight: 400;
}

.aoi-page p,
.aoi-page h2 {
  font-family: Slalom, Arial, Helvetica, sans-serif;
}

.aoi-page h1,
.aoi-page h2,
.aoi-page h3,
.aoi-page h4,
.aoi-page h5,
.aoi-page h6 {
  line-height: 1;
}

.aoi-page button {
  font-family: Slalom, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: white;
  font-size: 20px;
  height: fit-content;
}

.aoi-page button:hover {
  cursor: pointer;
  font-family: Slalom, Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: white;
  font-size: 20px;
}

.aoi-page .bold {
  font-weight: 500;
  font-family: Slalom, Arial, Helvetica, sans-serif;
}

.aoi-page .bold-italic {
  font-weight: 600;
  font-style: italic;
}

.aoi-page .yellow {
  color: #e9c01d;
}

.aoi-page .black {
  color: black;
}

.aoi-hero {
  overflow: hidden;
  /* padding:0 vw ; */

  padding-top: 9rem !important;
  background-color: #181818;
  background-image: radial-gradient(
      circle,
      #202020,
      #181818,
      rgba(24, 24, 24, 0.8)
    ),
    url(/public/global/aoi/design/grid-white.png);
  background-size: cover;
  text-align: center;
  position: relative;
}

.aoi-hero .text {
  color: white;
  /* padding-top: 50px; */
  z-index: 2;
}

.aoi-hero .text .heading {
  font-size: 100px;
  text-align: center;
  line-height: 1;
}

@media only screen and (min-width: 1400px) {
  .aoi-hero .text .heading {
    font-size: 120px;
  }
}

@media only screen and (max-width: 1200px) {
  .aoi-hero .text .heading {
    font-size: 80px;
  }
}

@media only screen and (max-width: 1000px) {
  .aoi-hero .text .heading {
    font-size: 60px;
  }
}

@media only screen and (max-width: 850px) {
  .aoi-hero .text .heading {
    font-size: 60px;
  }
}

@media only screen and (max-width: 570px) {
  .aoi-hero .text .heading {
    font-size: 45px;
  }
}

.aoi-hero .text .subheading {
  text-align: center;
  color: white;
}

.aoi-hero .text img.heading-img {
  width: 50vw;
  max-width: 700px;
}

.aoi-hero .text a {
  margin: auto;
}

.aoi-hero .text button {
  margin: auto;
  background-color: #cb8d02;
  border: 2px solid #ffcc00;
  /* border       : 2px solid white; */
  /* box-shadow   : 1px 1px 10px white; */
  padding: 2px 20px;
  border-radius: 30px;
  font-size: 25px;
  color: white;

  z-index: 1;
}

@media only screen and (max-width: 1000px) {
  .aoi-hero .text button {
    font-size: 20px;
  }
}

.aoi-hero .text .face-container img {
  grid-area: img;
}

.aoi-hero .text .face-container .vertical-flex {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  grid-area: txt;
}

.aoi-hero .text .face-container .vertical-flex a {
  display: block;
  margin-top: 30px;
}

.aoi-hero .text .face-container .vertical-flex .center {
  margin: auto;
  height: fit-content;
  width: fit-content;
}

.aoi-hero .text .face-container {
  /* margin-top: 40px; */
  display: grid;
  grid-template-areas: "txt img";
  /* grid-template-columns: 1fr 1fr; */

  text-align: center;
  /* margin-top        : 20px; */
  position: relative;
  transform: translateY(20px);
  opacity: 0;
}

@media only screen and (max-width: 850px) {
  .aoi-hero .text .face-container {
    grid-template-areas:
      "txt"
      "img";
    row-gap: 30px;
  }
}

@media only screen and (max-width: 500px) {
  .aoi-hero .text .face-container {
    /* transform: translateY(-50px); */
  }
}

.aoi-hero .text .face-container img {
  width: 100%;
  margin: 0 auto;
  /* transform: translateY(50px); */
}

.aoi-hero .text .face-container .ellipse {
  z-index: -1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  max-width: 800px;
}

.world {
  display: none;
  overflow: hidden;
  margin-top: 0;
  z-index: 1;
  padding-top: 150px;
  padding-bottom: 50px;
  background-color: transparent;
  background-image: radial-gradient(circle, #e9c002, #977f16);
}

.world .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 1000px) {
  .world .grid-container {
    grid-template-columns: 1fr;
  }
}

.world .grid-container div {
  margin: 0 auto;
}

.world .grid-container .text {
  margin: auto;
  color: black;
  align-items: center;
  position: relative;
  /* margin-left: 5%; */
}

.world .grid-container .text .heading {
  font-size: 65px;
  font-weight: 600;
  font-family: ValeriusBold, Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 474px) {
  .world .grid-container .text .heading {
    font-size: 50px;
  }
}

.world .grid-container .text h6 {
  margin-top: 5%;
  line-height: 25px;
  font-size: 22px;
}

.world .grid-container .text img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 600px;
}

.world .grid-container .text button {
  margin: 40px 0px;
  background-color: #cb8d02;
  border: 2px solid #ffcc00;
  padding: 10px 20px;
  border-radius: 30px;
  z-index: 1;
  font-size: 18px;
}

.world button {
  margin: auto;
}

@media screen and (max-width: 1000px) {
  .world .grid-container .marvel {
    text-align: center;
  }
}

.world .grid-container .marvel img {
  width: 90%;
}

@media screen and (min-width: 1000px) {
  .world .grid-container .marvel img {
    float: right;
  }
}

.in-the-dark {
  position: relative;
  background-image: linear-gradient(to bottom, #141414 0%, black 100%),
    url(/public/global/aoi/design/noise.svg);
  /* height          : 150vh; */
  width: 100%;
  overflow: hidden;
}

.in-the-dark .text-placeholder {
  text-align: center;
  margin: 150px 0;
  visibility: hidden;
}

.in-the-dark .text {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  /* display      : flex; */
  /* transform    : translate(-50%, -50%); */
  /* left         : 50%; */
  margin: 150px 0;

  /* max-width : 50%; */
}

.in-the-dark .text.new {
  z-index: 100;
}

.in-the-dark .text.new h2 {
  z-index: 200;

  color: black;
  mix-blend-mode: difference;
}

.in-the-dark .text .corner-in-dark {
  text-align: center;
  color: white;
}

.in-the-dark .in-the-light {
  position: absolute;
  right: 2%;
  top: 2%;
  height: 150px;
  width: 150px;
  scale: 0.1;
  background-color: #cfab08;
  border-radius: 50%;
}

.slalom-font {
  font-family: Slalom, Arial, Helvetica, sans-serif;
}

.aoi-video {
  padding-top: 80px;
  padding-bottom: 50px;
  background-image: linear-gradient(
    0deg,
    rgba(19, 19, 19, 1) 0%,
    rgba(16, 16, 16, 1) 70%
  );
}

.aoi-video .heading {
  text-align: center;
  font-size: 40px;
  color: white;
}

.aoi-video .grid-container .aoi-video-container {
  margin: 0 auto;
  margin-top: 20px;
  width: 80%;
  max-width: 600px;
}

.aoi-video .grid-container .aoi-video-container iframe {
  width: 100%;
  height: 100%;
  aspect-ratio: 16/9;
}

.aoi-video .grid-container .points-container {
  padding: 30px 10px;
  color: white;
}

.aoi-video .grid-container .points-container button {
  display: flex;
  margin: auto;
  font-family: Slalom, Arial, Helvetica, sans-serif;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #cb8d02;
  border: 2px solid #ffcc00;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 20px;
  z-index: 1;
}

.aoi-video .grid-container .points-container button:hover {
  cursor: auto;
}

.aoi-video .grid-container .points-container h3 {
  font-size: 32px;
  text-align: center;
}

.aoi-video .grid-container .points-container .points {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

@media screen and (max-width: 900px) {
  .aoi-video .grid-container .points-container .points {
    grid-template-columns: 1fr;
  }
}

.aoi-video .grid-container .points-container .points .point {
  display: flex;
  margin: 10px;
}

.aoi-video .grid-container .points-container .points .point img {
  background-color: #0b2cd1;
  padding: 5px;
  border-radius: 5px;
  height: 50px;
  width: 50px;
  display: flex;
  margin: auto 0;
  margin-right: 10px;
  filter: invert(100%);
}

.aoi-video .grid-container .points-container .points .point p {
  margin: auto 0;
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  line-height: 20px;
}

.aoi-form {
  padding-top: 80px;
  padding-bottom: 80px;
  background: linear-gradient(
    180deg,
    rgb(109, 90, 14) 15%,
    rgba(16, 16, 16, 1) 100%
  );
}

.aoi-form .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 30px;
}

@media screen and (max-width: 1000px) {
  .aoi-form .grid-container {
    grid-template-columns: 1fr;
    row-gap: 30px;
  }
}

.aoi-form .grid-container .aoi-form-text {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: white;
}

.aoi-form .grid-container .aoi-form-text h2 {
  text-align: left;
}

.aoi-form .grid-container .aoi-form-text .subheading {
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  font-size: 24px;
  font-weight: 400;
}

.aoi-form .grid-container .aoi-form-text .para-container {
  margin-top: 30px;
}

.aoi-form .grid-container .aoi-form-text .para-container .para {
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: 1px;
  line-height: 25px;
}

.aoi-form .grid-container .aoi-form-container {
  background-color: white;
  margin: 0 auto;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: 2px 3px var(--hashpro);
}

@media screen and (max-width: 600px) {
  .aoi-form .grid-container .aoi-form-container {
    background-color: white;
    margin: 0 auto;
    width: 100%;
    padding: 0px;
    border-radius: 10px;
    border: 2px solid black;
    box-shadow: 2px 3px var(--hashpro);
  }
}

.aoi-form .grid-container .aoi-form-container #form-tag {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.aoi-form .grid-container .aoi-form-container h1 {
  letter-spacing: 1px;
  font-size: 28px;
}

@media screen and (max-width: 600px) {
  .aoi-form .grid-container .aoi-form-container h1 {
    text-align: center;
  }
}

.aoi-form .grid-container .aoi-form-container .input-container {
  margin: 10px 0;
  width: 100%;
}

.aoi-form .grid-container .aoi-form-container .input-container input {
  border: 2px solid #ffcc00;
  border-radius: 2rem;
  background-color: #f5f5f5;
  font-family: Slalom, Arial, Helvetica, sans-serif;
  padding: 5px 10px;
  display: block;
  width: 100%;
  transition: 0.2s;
  font-size: 1rem;
}

::placeholder {
  color: #aaa;
  opacity: 1;
}

::-ms-input-placeholder {
  color: #aaa;
}

@media screen and (max-width: 600px) {
  .aoi-form .grid-container .aoi-form-container .input-container input {
    width: 100%;
  }
}

.aoi-form
  .grid-container
  .aoi-form-container
  .input-container
  input:focus-visible {
  outline: none;
}

.aoi-form .grid-container .aoi-form-container .input-container input:focus {
  font-size: 16px;
}

.aoi-form .grid-container .aoi-form-container button {
  background-color: #cb8d02;
  border: 2px solid #ffcc00;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 16px;
  z-index: 1;
  width: 100%;
  margin-top: 6px;
}

.scroll-marquee {
  padding: 0 !important;
  margin: 0;
  background-color: #e9c01d;
  background: #d0ab19;
  background: -moz-linear-gradient(
    90deg,
    #d0ab19 0%,
    #90760e 23%,
    #d0ab19 50%,
    #90760e 70%,
    #d0ab19 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    #d0ab19 0%,
    #90760e 23%,
    #d0ab19 50%,
    #90760e 70%,
    #d0ab19 100%
  );
  background: linear-gradient(
    90deg,
    #d0ab19 0%,
    #90760e 23%,
    #d0ab19 50%,
    #90760e 70%,
    #d0ab19 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d0ab19", endColorstr="#d0ab19", GradientType=1);
  overflow-x: hidden;
}

.scroll-marquee .words-container {
  display: flex;
  padding-left: 0px;
  margin-left: 0px;
}

.scroll-marquee .words-container h1 {
  font-family: SlalomLight, "Franklin Gothic Medium", "Arial Narrow", Arial,
    sans-serif;
  white-space: nowrap;
  font-size: 18px;
  padding: 5px 20px;
  letter-spacing: 1px;
  font-weight: 600;
}

@media screen and (max-width: 600px) {
  .scroll-marquee .words-container h1 {
    font-size: 16px;
  }
}

.aoi-page .who {
  background: #131313;
  padding-top: 50px;
  padding-bottom: 50px;
}

.who h2 {
  text-align: center;
  margin-bottom: 50px;
}

.who .line-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background-color: rgba(255, 255, 255, 0.404);
  height: 100%;
  z-index: 2;
}

@media screen and (max-width: 650px) {
  .who .line-container {
    left: 0;
    transform: translateX(-15px);
  }
}

.who .ball-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: white;
  border-radius: 50%;
  will-change: top;
  box-shadow: 1px 1px 8px 4px white;
}

@media screen and (max-width: 650px) {
  .who .ball-container {
    left: 0;
    transform: translateX(-20px);
  }
}

.who .blocks-container {
  margin-top: 20px;
  position: relative;
}

.who .blocks-container .block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10%;
  border-radius: 10px;
  padding: 40px 20px;
  color: white;
}

@media screen and (max-width: 650px) {
  .who .blocks-container .block {
    padding: 0;
    margin-bottom: 30px;
  }
}

.who .blocks-container .block img {
  width: 70%;
  scale: 1.05;
  margin: 0 auto;
}

.who .blocks-container .block .text {
  margin: auto;
}

.who .blocks-container .block .text h4,
.who .blocks-container .block .text p {
  line-height: 20px;
  text-align: left;
}

.who .blocks-container .block .text h4 {
  line-height: 20px;
  font-size: 20px;
  color: var(--hashpro);
  font-weight: 300;
}

@media screen and (max-width: 650px) {
  .who .blocks-container .block .text h4 {
    font-size: 16px;
    text-align: left;
  }
}

.who .blocks-container .block .text p {
  font-size: 14px;
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  letter-spacing: 1px;
  margin-top: 5px;
  color: #ffffff;
}

@media screen and (max-width: 650px) {
  .who .blocks-container .block .text p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 30px;
    text-align: left;
  }
}

@media screen and (max-width: 650px) {
  .who .blocks-container .block {
    transform: translateX(20px);
    grid-template-columns: none;
    grid-template-areas: "img" "p";
  }

  .who .blocks-container .block img {
    grid-area: img;
  }

  .who .blocks-container .block .text {
    margin-top: 10px;
    text-align: center;
    grid-area: p;
  }
}

.trainers {
  position: relative;
  padding-top: 70px;
  padding-bottom: 100px !important;
  /* padding            : 70px 10%; */
  transform: translateY(-1px);
  background-image: linear-gradient(
    180deg,
    #131313 0%,
    rgba(48, 40, 7, 1) 100%
  );
}

@media screen and (max-width: 850px) {
  .trainers h3.heading {
    text-align: left;
  }
}

.trainers .trainer {
  margin: 0 auto;
  width: fit-content;
  margin-left: auto;
  display: flex;
  column-gap: 30px;
  margin-top: 30px;
}

@media screen and (max-width: 850px) {
  .trainers .trainer {
    display: block;
  }
}

.trainers .trainer .pic-container {
  height: fit-content;
  margin: auto;
  padding-bottom: 5px;
}

.trainers .trainer .pic-container img {
  border-radius: 10px;
  width: 250px;
  display: flex;
  justify-content: flex-end;
  box-shadow: 2px 2px white;
}

.trainers .trainer .about {
  margin-top: 5%;
  float: left;
  max-width: 500px;
  width: 100%;
  color: white;
}

.trainers .trainer .about .name {
  font-size: 1.2rem;
}

.trainers .trainer .about .role {
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  margin-top: 5px;
  color: white;
}

.trainers .trainer .about .text {
  margin-top: 20px;
  line-height: 25px;
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  font-weight: 700;
}

@media only screen and (max-width: 1200px) {
  .trainers .trainer * {
    text-align: center;
    margin: 0 auto;
  }
}

.trainers .btn-container {
  position: absolute;
  top: 30px;
  right: 0%;
}

@media only screen and (max-width: 1200px) {
  .trainers .btn-container {
    top: calc(100% + 10px);
    right: 50%;
    transform: translateX(50%);
  }
}

.trainers .splide-btn {
  width: 35px;
  height: 35px;
  filter: invert(100%);
  border-radius: 50%;
  z-index: 100;
}

.trainers .splide-btn:hover {
  cursor: pointer;
  filter: invert(0%);
  background-color: white;
}

.trainers #prev-btn {
  rotate: 180deg;
  margin-right: 5px;
}

.aoi-cta {
  transform: translateY(-1px);
  background-color: #2f2807;
  padding-top: 100px;
  padding-bottom: 80px;
  color: white;
  text-align: center;
}

.aoi-cta h2 {
  margin-bottom: 20px;
}

.aoi-cta a {
  width: fit-content;
  display: block;
  margin: 0 auto;
}

.aoi-cta p {
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 5px;
}

.aoi-cta button {
  margin: 20px 0px;
  margin-top: 40px;
  background-color: #cb8d02;
  border: 2px solid #ffcc00;
  padding: 10px 20px;
  border-radius: 30px;
  z-index: 1;
  font-size: 18px;
  transition: 0.2s;
}

.aoi-cta button:hover {
  scale: 1.2;
}

.timeline {
  /* padding-top:60px ; */
  margin-top: -1px;
  /* background-image: linear-gradient(to bottom , #2f2807, #111111 ,#2f2807 ); */
  background-color: #2f2807;

  overflow: hidden;
  padding: 60px 5%;
  /* background-color: #131313; */
}

.timeline .grid-container {
  position: relative;
  height: 150px;
}

@media only screen and (max-width: 1120px) {
  .timeline .grid-container {
    height: 300px;
    width: 300px;
    margin: auto;
  }
}

.timeline .grid-container .bar {
  position: absolute;
  bottom: -150%;
  width: 2px;
  margin: 0 auto;
  /* background-color   : #555555; */
  /* background-color   : #E9C01D; */
  background-color: white;
  box-shadow: 1px 1px 5px white;
}

.timeline.mobile .grid-container .bar {
  left: 0;
  top: 0;
  height: 2px;
  opacity: 0;
}

.timeline h2 {
  margin-bottom: 70px;
}

.timeline .grid-container .text {
  position: relative;
}

.timeline .grid-container .text p {
  text-align: center;
  width: max-content;
  /* color     : #e9c01d; */
  color: white;
  position: absolute;
  top: -10px;
  transform: translateY(-0%) translateX(-50%);
  /* -100% */
  left: 0;
  opacity: 0;
}

.timeline.mobile .grid-container .text p {
  text-align: left;
  white-space: nowrap;
  width: max-content;
  /* color     : #e9c01d; */
  color: white;
  position: absolute;
  top: 0px;
  transform: translateX(-100%);
  /* -100% */
  right: 0;
  opacity: 0;
}

.timeline .grid-container .bar:nth-child(1) {
  height: 50px;
  left: 20%;

  opacity: 0;
}

.timeline .grid-container .bar:nth-child(2) {
  left: 40%;
  opacity: 0;

  height: 80px;
}

.timeline .grid-container .bar:nth-child(3) {
  left: 60%;
  opacity: 0;

  height: 120px;
}

.timeline .grid-container .bar:nth-child(4) {
  left: 80%;
  bottom: -180%;
  opacity: 0;

  height: 150px;
}

/* @media only screen and (max-width: 1100px) {
    .timeline .grid-container .bar:nth-child(1) {
      height: 70px;
      left  : 20%;
  
      opacity: 0;
  
    }
  
    .timeline .grid-container .bar:nth-child(2) {
      left   : 40%;
      opacity: 0;
  
      height: 160px;
    }
  
    .timeline .grid-container .bar:nth-child(3) {
      left   : 60%;
      opacity: 0;
  
      height: 230px;
    }
  
    .timeline .grid-container .bar:nth-child(4) {
      left   : 80%;
      bottom : -180%;
      opacity: 0;
  
      height: 300px;
    }
  } */

.timeline.mobile .grid-container .bar:nth-child(1) {
  width: 40px;
  top: 20%;
  height: 2px;
  opacity: 0;
  left: 0;
}

.timeline.mobile .grid-container .bar:nth-child(2) {
  width: 60px;
  top: 40%;
  height: 2px;
  left: 0;

  opacity: 0;
}

.timeline.mobile .grid-container .bar:nth-child(3) {
  width: 90px;
  top: 60%;
  height: 2px;
  left: 0;

  opacity: 0;
}

.timeline.mobile .grid-container .bar:nth-child(4) {
  width: 120px;
  top: 80%;
  height: 2px;
  left: 0;

  opacity: 0;
}

.steps {
  margin-top: -1px;
  background-color: #2f2807;
  padding-top: 30px;
  padding-bottom: 50px;
}

.steps .heading {
  margin-bottom: 30px;
}

.steps .text-container {
  border: 1px solid rgba(255, 255, 255, 0.356);
  display: flex;
  justify-content: space-between;
  padding: 0;
  /* background-color: #977f16; */
  margin: 20px 0;
  box-shadow: 1px 1px 50px rgba(255, 255, 255, 0.15);
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}

.steps .text-container p {
  font-size: 18px;
}

.steps .text-container .emoji {
  /* right: 100px; */
  left: 10px;
  top: 0;
  position: absolute;
  scale: 5;
  opacity: 0;
}

.steps .text-container:nth-child(2) {
  background-color: #eac01ddb;
}

.steps .text-container:nth-child(3) {
  background-color: #eac01dcf;
}

.steps .text-container:nth-child(4) {
  background-color: #eac01dde;
}

.steps .text-container:nth-child(5) {
  background-color: #eac01d;
}

.steps .text-container p {
  font-weight: 500;
  margin: auto 0;
  margin-left: 5%;
  padding: 10px 0;
}

.steps .text-container .number {
  /* width           : max-content; */
  background-color: #524300;
  width: 70px;
}

.steps .text-container h2 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: unset;
  font-size: 35px;
  color: white;
  padding: 10px 20px;
  /* margin       : auto ; */
  /* height       : 100%; */
  background-color: #524300;
  font-weight: 900;
}

.steps .text-container h2.one {
  font-size: 43px;
  font-weight: 500;
}

.white {
  /* color: white; */
}

.faq {
  transform: translateY(-1px);
  padding-top: 50px;
  padding-bottom: 50px;
  color: white;
  background: #2f2807;
  background: -moz-linear-gradient(180deg, #2f2807 0%, #090909 100%);
  background: -webkit-linear-gradient(180deg, #2f2807 0%, #090909 100%);
  background: linear-gradient(180deg, #2f2807 0%, #090909 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#2f2807", endColorstr="#090909", GradientType=1);
}

.faq .questionss {
  margin-top: 20px;
}

.faq .questionss .question-containerr {
  transition: 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.39);
}

.faq .questionss .question-containerr .question {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}

.faq .questionss .question-containerr .question img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 20px;
  height: 20px;
  filter: invert(100%);
  transition: 0.2s;
}

.faq .questionss .question-containerr .answer {
  color: #c7c7c7;
  padding-left: 20px;
  padding-bottom: 20px;
  overflow: hidden;
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
  /* letter-spacing: 1px; */
  font-size: 14px;
  line-height: 20px;
}

.faq .questionss .question-containerr:hover {
  cursor: pointer;
  background-color: rgba(129, 114, 20, 0.129);
}

.faq .questionss .question-containerr.collapsed .answer {
  display: none;
}

.faq .questionss .question-containerr.show {
  background-color: rgba(129, 114, 20, 0.129);
}

.faq .questionss .question-containerr.show .answer {
  display: block;
}

.splide__track {
  position: relative;
  /* overflow  : visible !important; */
  /* overflow-x: hidden !important ; */
  /* overflow-y: visible !important ; */
}

.splide__arrow {
  height: 20px !important;
  width: 20px !important;
  /* display: none !important; */
}

.splide__arrow svg {
  height: 15px !important;
  width: 15px !important;
}

.aoi-page .splide__pagination {
  bottom: -30px !important;
}

.aoi-page .splide__pagination__page {
  width: 5px !important;
  height: 5px !important;
}

.aoi-page a {
  font-family: SlalomLight, Arial, Helvetica, sans-serif;
}

.aoi-page a {
  text-decoration: none;
  color: white;
  font-weight: 500;
  letter-spacing: 1px;
}

a {
  margin: 0;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: white;
  font-weight: 600;
  padding: 2px 0;
}

@media screen and (max-width: 600px) {
  .subheading {
    font-size: 0.96rem;
  }
}

.extra-details {
  max-width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
  text-align: left;
  justify-content: left;
  align-items: center;
}

.extra-details-card {
  font-size: 0.8rem;
  display: flex;
  justify-content: left;
  align-items: center;
  flex-direction: row;
}

@media (min-width: 0px) and (max-width: 600px) {
  .extra-details {
    flex-direction: column;
  }

  .extra-details-card {
    margin-bottom: 3px;
  }
}

footer .extra-details-card img {
  opacity: 0.8;
}

.extra-details-card a {
  margin: 5px 0;
  margin-left: 6px;
  color: #b4b4b4;
  font-weight: 400;
}

@media screen and (max-width: 600px) {
  .extra-details-card a {
    margin: 1px 0;
    margin-left: 6px;
  }
}

.extra-details-card a:hover {
  color: white;
}

/*! CSS Used fontfaces */
#art-of-influence {
  transform: translateY(-140px);
}

#what {
  transform: translateY(-140px);
}

#admissions {
  transform: translateY(-140px);
}

#form-a {
  transform: translateY(-50px);
}

.submit-loader {
  width: 20px;
}

.submit-text {
  font-size: 12px;

  color: black;
  text-align: left;
  margin-left: 5px;
  margin-top: 5px;
  /* margin-right: 0 !important; */
}

.aoi-page .splide__arrow {
  align-items: center;
  background: #ccc;
  border: 0;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 2em;
  justify-content: center;
  opacity: 0.7;
  padding: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 2em;
  z-index: 1;
}

.aoi-page .splide__arrow svg {
  fill: #000;
  height: 1.2em;
  width: 1.2em;
}

.aoi-page .splide__arrow:hover:not(:disabled) {
  opacity: 0.9;
}

.aoi-page .splide__arrow:disabled {
  opacity: 0.3;
}

.aoi-page .splide__arrow--prev {
  left: 1em;
}

.aoi-page .splide__arrow--prev svg {
  transform: scaleX(-1);
}

.aoi-page .splide__arrow--next {
  right: 1em;
}

/*! CSS Used from: Embedded */
.aoi-page .splide__arrows {
  position: absolute;
  height: -moz-fit-content;
  height: fit-content;
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  column-gap: 20px;
  right: 10vw;
  top: 60px;
}

@media only screen and (max-width: 800px) {
}

.aoi-page .splide__arrow {
  background-color: white !important;
  opacity: 1 !important;
  position: static !important;
  background-color: white !important;
  background-color: #fff !important;
  opacity: 1 !important;
  height: 45px !important;
  width: 45px !important;
  /* padding: 20px; */
}

.aoi-page .splide__arrow {
  position: static !important;
  background-color: white !important;
  opacity: 1 !important;
  width: 45px !important;
  height: 45px !important;
}

.aoi-page .splide__arrow {
  background-color: #fff !important;
  opacity: 1 !important;
}

.aoi-page .splide__arrows {
  top: auto !important;
  bottom: -6rem !important;
  right: 50% !important;
  transform: translateX(50%) translateY(0%) !important;
}

.aoi-page .splide__arrow {
  width: 40px !important;
  height: 40px !important;
}
