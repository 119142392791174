.about-page {

    .hero {
        padding-top: 10rem;
        text-align: center;
        display: flex;
        flex-direction: column;

        // gap: 30px;
        .subheading {
            margin-top: 15px;
        }

        button {
            display: block;
            margin: auto;
            width: fit-content;
            margin-top: 30px;
        }
    }



    .hashpro-way {

        margin-top: 7rem;

        @media only screen and (max-width: 600px) {
            margin-top: 3rem;
        }

        .head {

            text-align: center;
            margin-bottom: 50px;
        }

        h1 {}

        p {}

        .banner {
            img {
                width: 100%;
                border-radius: 1rem;
                border: 1px solid #515151;
            }
        }

        .text {
            width: fit-content;
            height: fit-content;
            margin: auto;

            h3 {
                font-size: 1.5rem;
                line-height: 1.5;

            }

            p {
                margin-top: 10px;
                font-size: 0.9rem;
                line-height: 1.7;
                font-weight: 300;

            }
        }

        .grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 18px 60px;
            margin-bottom: 5rem;

            @media only screen and (max-width: 600px) {
                grid-template-columns: 100%;

                &.inv {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }
    }

    $cta-color: #f8d00d;
    $cta-color-hover: darken($cta-color, 10%);

    .cta-container {
        position: relative;
        background-color: transparent;
        padding-top: 69px;
        padding-bottom: 69px;
        border-bottom-left-radius: 6rem;
        border-bottom-right-radius: 6rem;
        transition: 0.3s;

        .cta {
            padding: 30px 35px;
            border-radius: 8px;
            margin: 0 auto;
            color: black;
            display: grid;
            grid-template-columns: 80% 20%;
            width: 80%;
            background-color: $cta-color;
            background-size: 150%;
            transition: 0.3s;
            border: 1px solid rgba($cta-color, 0.42);
            row-gap: 20px;

            @media screen and (max-width: 500px) {
                width: 90%;
            }

            .left {
                display: flex;
                align-items: center;

                .heading-cta {
                    text-transform: uppercase;
                    font-size: 1.4rem;
                    font-weight: 700;
                    color: black;
                    line-height: 1.5;

                    @media only screen and (max-width: 767px) {
                        text-align: center;
                    }

                    span {
                        font-size: 1.4rem;
                        font-style: italic;
                    }
                }
            }

            .right {
                margin: auto;

                button {
                    width: 100%;
                }
            }

            @media screen and (max-width: 769px) {
                grid-template-columns: 1fr;
            }
        }
    }

    @keyframes move-bg-anim {
        from {
            background-size: 150%;
        }

        to {
            background-size: 120%;

        }
    }

    .hiring {


        // background-color: white;
        // background-image: url(/public/global/images/grid-pattern-white.webp);
        color: black;
        padding-top: 5rem;
        padding-bottom: 5rem;
        background-position: center;
        background-size: cover;
        // animation: move-bg-anim 8s ease-in-out alternate infinite;


        .heading {
            text-align: left;
            color: black;
        }

        .subheading {
            text-align: left;
            color: #202020;
        }

        .jobs {
            margin-top: 40px;

            .card {
                border: 1px solid black;
                display: flex;
                justify-content: space-between;
                background-color: black;
                color: white;
                padding: 30px 30px;
                border-radius: 1rem;
                box-shadow: 3px 3px var(--hashpro);
                gap: 20px;

                @media only screen and (max-width: 850px) {
                    flex-direction: column;
                }

                .job-title {
                    font-size: 1.2rem;
                }

                .time {
                    font-size: 0.9rem;
                    margin-top: 10px;
                }
            }
        }
    }

}