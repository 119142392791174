.class-hero {
  padding-top: 10rem;
}
@media only screen and (max-width: 600px) {
  .class-hero {
    padding-top: 8rem;
  }
}
.class-hero .grid .heading {
  text-align: left;
  text-transform: uppercase;
}

.class {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "class-first class-fourth" "class-third class-third ";
  gap: 20px;
}
.class .first {
  grid-area: class-first;
}
.class .second {
  grid-area: class-second;
}
.class .third {
  grid-area: class-third;
}
.class .fourth {
  grid-area: class-fourth;
}
@media only screen and (max-width: 1200px) {
  .class {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: "class-first class-first class-first class-first" "class-third class-third class-fourth class-fourth";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .class {
    grid-template-columns: 1fr;
    grid-template-areas: "class-first" "class-fourth" "class-third";
    gap: 20px;
  }
}
.class img {
  width: 100%;
  border-radius: 0.7rem;
}
.class div {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}
.class .card {
  padding: 40px;
  border-radius: 1rem;
  background-color: #131313;
}
.class .card .chip {
  border-radius: 2rem;
  padding: 3px 8px;
  font-size: 0.7rem;
  background-color: var(--hashpro);
  color: #000;
  font-weight: 600;
  width: -moz-fit-content;
  width: fit-content;
}
.class .card h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 500;
}
.class .card .para {
  margin-top: 15px;
  line-height: 1.5;
  font-size: 0.9rem;
}
.class .class {
  display: grid;
  grid-template-columns: 30% 70%;
}

.guest-hero {
  margin-top: 7rem;
}
.guest-hero .grid {
  display: grid;
  grid-template-columns: 30% 70%;
}
.guest-hero .grid .heading {
  text-align: left;
}

.guest {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "guest-first guest-text" "guest-third guest-text";
  gap: 20px;
}
.guest .first {
  grid-area: guest-first;
}
.guest .second {
  grid-area: guest-second;
}
.guest .third {
  grid-area: guest-third;
}
.guest .fourth {
  grid-area: guest-fourth;
}
.guest .text {
  grid-area: guest-text;
}
@media only screen and (max-width: 1200px) {
  .guest {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "guest-empty guest-first" "guest-second guest-third " "guest-fourth guest-fourth";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .guest {
    grid-template-columns: 100%;
    grid-template-areas: "guest-first" "guest-second" "guest-third" "guest-fourth";
    gap: 20px;
  }
}
.guest img {
  width: 100%;
}
.guest div {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}
.guest .card {
  padding: 40px;
  border-radius: 1rem;
  background-color: #131313;
}
.guest .card .chip {
  border-radius: 2rem;
  padding: 10px 15px;
  font-size: 0.7rem;
  background-color: white;
  color: black;
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
}
.guest .card h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 500;
}
.guest .card .para {
  margin-top: 15px;
  line-height: 1.5;
}
.guest .class {
  display: grid;
  grid-template-columns: 30% 70%;
}

.experiential-hero {
  margin-top: 7rem;
}
.experiential-hero .grid {
  display: grid;
  grid-template-columns: 80% 20%;
}
.experiential-hero .grid .heading {
  text-align: left;
}

.experiential {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "guest-fifth guest-first guest-second " "guest-third guest-fourth  guest-empty";
  gap: 20px;
}
.experiential .first {
  grid-area: guest-first;
}
.experiential .second {
  grid-area: guest-second;
}
.experiential .third {
  grid-area: guest-third;
}
.experiential .fourth {
  grid-area: guest-fourth;
}
.experiential .empty {
  grid-area: guest-empty;
}
@media only screen and (max-width: 1200px) {
  .experiential {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "guest-empty guest-first" "guest-second guest-third " "guest-fourth guest-fourth";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .experiential {
    grid-template-columns: 100%;
    grid-template-areas: "guest-first" "guest-second" "guest-third" "guest-fourth";
    gap: 20px;
  }
}
.experiential img {
  width: 100%;
  border-radius: 0.7rem;
  aspect-ratio: 15/9;
  -o-object-fit: cover;
     object-fit: cover;
  -o-object-position: center;
     object-position: center;
}
.experiential div {
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}
.experiential .card {
  padding: 40px;
  border-radius: 1rem;
  background-color: #131313;
}
.experiential .card .chip {
  border-radius: 2rem;
  padding: 10px 15px;
  font-size: 0.7rem;
  background-color: white;
  color: black;
  font-weight: 700;
  width: -moz-fit-content;
  width: fit-content;
}
.experiential .card h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  font-weight: 500;
}
.experiential .card .para {
  margin-top: 15px;
  line-height: 1.5;
}
.experiential .class {
  display: grid;
  grid-template-columns: 30% 70%;
}

.gl {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "gl-empty gl-empty ." ". gl-fourth gl-second" "gl-first gl-third gl-second";
  gap: 20px;
}
.gl .first {
  grid-area: gl-first;
}
.gl .first img {
  aspect-ratio: 12/16;
}
.gl .second {
  grid-area: gl-second;
  margin: 0;
}
.gl .second img {
  aspect-ratio: 12/16;
}
.gl .third {
  grid-area: gl-third;
  margin: 0;
}
.gl .fourth {
  grid-area: gl-fourth;
}
.gl .empty {
  grid-area: gl-empty;
}
.gl .empty .heading {
  text-align: left;
}
@media only screen and (max-width: 1200px) {
  .gl {
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "gl-empty gl-first" "gl-second gl-third " "gl-fourth gl-fourth";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .gl {
    grid-template-columns: 100%;
    grid-template-areas: "gl-empty" "gl-first" "gl-second" "gl-third" "gl-fourth";
    gap: 20px;
  }
}

.cl {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: "cl-first cl-first cl-second" "cl-third cl-fourth cl-empty";
  gap: 20px;
}
.cl .first {
  grid-area: cl-first;
}
.cl .first img {
  aspect-ratio: 16/9 !important;
}
.cl .second {
  grid-area: cl-second;
}
.cl .third {
  grid-area: cl-third;
}
.cl .fourth {
  grid-area: cl-fourth;
}
.cl .empty {
  grid-area: cl-empty;
}
.cl img {
  aspect-ratio: 14/16;
}
@media only screen and (max-width: 1200px) {
  .cl {
    grid-template-columns: 50% 50%;
    grid-template-areas: "cl-first cl-second" "cl-fourth cl-third";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .cl {
    grid-template-columns: 100%;
    grid-template-areas: "cl-empty" "cl-first" "cl-second" "cl-third" "cl-fourth";
    gap: 20px;
  }
}

.iv {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". iv-text iv-text" "iv-second iv-fifth iv-fifth" "iv-third iv-fifth iv-fifth ";
  gap: 20px;
}
.iv .heading {
  text-align: right;
}
.iv .first {
  grid-area: iv-first;
  margin: 0;
}
.iv .first img {
  aspect-ratio: 12/16;
}
.iv .second {
  grid-area: iv-second;
}
.iv .third {
  grid-area: iv-third;
}
.iv .fourth {
  grid-area: iv-fourth;
}
.iv .fourth img {
  aspect-ratio: 12/16;
}
.iv .fifth {
  margin: 0;
  grid-area: iv-fifth;
}
.iv .fifth img {
  aspect-ratio: 27/16;
  margin: 0;
}
.iv .text {
  grid-area: iv-text;
}
@media only screen and (max-width: 1200px) {
  .iv {
    grid-template-columns: 50% 50%;
    grid-template-areas: "iv-text iv-third" "iv-fifth iv-second";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .iv {
    grid-template-columns: 100%;
    grid-template-areas: "iv-text" "iv-second" "iv-third" "iv-fifth";
    gap: 20px;
  }
}

.ec {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: "ec-text ec-text ec-second ec-second " "ec-third ec-third ec-fourth ec-fourth";
  gap: 20px;
}
.ec .first {
  grid-area: ec-first;
}
.ec .second {
  grid-area: ec-second;
}
.ec .third {
  grid-area: ec-third;
}
.ec .fourth {
  grid-area: ec-fourth;
}
.ec .fifth {
  grid-area: ec-fifth;
}
.ec .text {
  grid-area: ec-text;
}
@media only screen and (max-width: 1200px) {
  .ec {
    grid-template-columns: 50% 50%;
    grid-template-areas: "ec-first ec-text" "ec-fourth ec-third" "ec-fifth ec-second";
    gap: 20px;
  }
}
@media only screen and (max-width: 900px) {
  .ec {
    grid-template-columns: 100%;
    grid-template-areas: "ec-first" "ec-text" "ec-second" "ec-third" "ec-fourth";
    gap: 20px;
  }
}/*# sourceMappingURL=students-life.css.map */