footer>* {
    z-index: 0;
    -webkit-transform: translateZ(0) translate3d(0px, 0px, 0px);
    transform: translateZ(0) translate3d(0px, 0px, 0px);
}

footer {
    text-align: center;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 30px;
    border-top: 1px solid #303030;
}

@media only screen and (max-width: 1000px) {
    footer {
        text-align: left;
    }
}

.footer-social-media a:hover img {
    transition: .2s;
    filter: invert(67%) sepia(97%) saturate(476%) hue-rotate(358deg) brightness(102%) contrast(94%);
    scale: 1.2;
}

footer {
    background-color: #090909;
    text-align: center;
}

footer .divider {
    height: 2px;
    width: 100%;
    background-color: #767676;
}

footer .bottom-container {
    padding: 0 7%;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 30% 40% 30%;
}

@media only screen and (max-width: 1000px) {
    footer .bottom-container {
        display: block;
    }
}

footer .logo {
    width: 90%;
    margin: 50px auto;
    display: block;
}

@media screen and (max-width: 650px) {
    footer {
        padding-bottom: 50px;
    }
}


.footer-social-media img {
    opacity: 0.7;
    width: 25px !important;
    height: 25px !important;
}

.footer-social-media a:hover img {
    opacity: 1;
    filter: brightness(150%);
}


.policy-container {
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.policy {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
}

.policy:hover {
    color: white;
}

.footer-courses .course-name {
    text-align: center;
    color: #9b9b9b;
    font-size: 14px;
    font-weight: 400;
    margin: 2px 0;
    width: fit-content;
}

@media only screen and (max-width: 1000px) {
    .footer-courses {
        display: block;
    }

   
}

.footer-courses .course-name:hover {
    color: white;
}

footer>* {
    z-index: 0;
    transform: translateZ(0) translate3d(0px, 0px, 0px);
}



/*! CSS Used from: https://hashproacademy.com/webpage-structure.css */
.company-name h3 {
    font-weight: 300 !important;
    color: #9b9b9b;
    font-size: 14px;
}



.policy-container {
    padding: 20px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
}

.policy {
    flex-shrink: 0;
    font-size: 14px;
    font-weight: 400;
    color: #aaaaaa;
}

.policy:hover {
    color: white;
}