#backup {
  padding: 0rem 0px;
}

#backup button {
  transition: ease 1s;
}
#backup button:hover {
  scale: 1.02;
}

#paymentnavigate-header {
  width: 100%;
  background-color: #333;
  padding: 1rem 0rem;
  margin-top: 91px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
#paymentnavigate-header button,
.userhead-button {
  all: unset;
  background-color: white;
  color: black;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  transition: 0.5s ease;
  border-radius: 10px 2px 10px 2px;
}

.dashboard {
  margin-top: 10px;
  padding: 2rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  overflow: hidden;
}

.dashboard .verticle-column1 {
  width: 20%;
  background-color: #333;
  text-align: center;
  color: black;
  border-radius: 5px;
  overflow: hidden;
}
.dashboard .verticle-column1 img {
  height: 150px;
  position: relative;
  top: -140px;
}

.verticle-column1 p,
.mail-user-dash {
  position: relative;
  top: -130px;
  color: #fff;
  text-align: center;
  margin: 0 0.5rem;
}

.dashboard .verticle-column1 h3 {
  position: relative;
  top: -130px;
  color: #fff;
  text-align: left;
  margin-top: 1rem;
  padding: 0.3rem 0.5rem 0.3rem 1rem;
  width: fit-content;
  background-color: #050505;
  border-radius: 0px 5px 5px 0px;
  color: black;
  border-width: 2px 2px 2px 0px;
  border-style: solid;
  text-transform: capitalize;
  color: white;
}

#course-name {
  max-width: 95%;
  text-align: center;
  margin-top: 1.5rem;
}
.dashboard .verticle-column2 {
  width: 80%;
  background-color: #333;
  margin-left: 2rem;
  padding: 2rem 1rem;
  border-radius: 5px;
}

.verticle-column2-1 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 90%;
  height: 130px;
  background-color: #f7d10c;
  border-radius: 10px;
  margin: 0 auto;
}
.verticle-column2-1 img {
  height: 90%;
}

.verticle-column2-1 h2 {
  font-size: 25px;
  text-transform: capitalize;
  color: black;
}

.verticle-column2-1 p {
  color: #333;
  text-transform: capitalize;
}
.clippath {
  clip-path: circle(49.8% at 0 0);
  background-color: #f7d10c;
  height: 200px;
  width: 200px;
}

.verticle-column2-2 {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.verticle-column2-2-payment-history {
  width: 90%;
  border-radius: 5px;
  margin: 0 auto;
}
.verticle-column2-2-payment-history h3 {
  color: white;
}
.verticle-column2-2-payment-history table th,
td {
  font-size: 0.6rem;
  border: 1px solid #ddd;
  padding: 8px;
  margin: 2rem;
}
.verticle-column2-2-payment-history table {
  border-collapse: collapse;
  width: 100%;
}

.greeting-message {
  max-width: 90%;
  margin: 0 auto;
  margin-top: 20px;
}
.greeting-message span {
  color: #f7d10c;
}
.course-details-mobile {
  display: none;
}

.heading-payment-history {
  display: flex;
  max-width: 90%;
  justify-content: space-around;
  padding: 1rem 0;
  align-items: center;
}

.heading-payment-history button {
  all: unset;
  background-color: white;
  color: black;
  padding: 0.3rem 1rem;
  border-radius: 5px;
  text-transform: capitalize;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 5px;
  transition: 0.5s ease;
  border-radius: 10px 2px 10px 2px;
}

.heading-payment-history button:hover,
#paymentnavigate-header button:hover {
  background-color: black;
  color: white;
}
.payment-hostory-mobile {
  display: none;
}

#course-details-lap,
#course-name {
  text-align: left !important;
}

.heading-payment-history {
  justify-content: left !important;
}
.heading-payment-history h3 {
  font-size: 25px;
}
@media screen and (max-width: 768px) {
  #paymentnavigate-header {
    height: 50px;
    width: 100%;
    margin-top: 81px;
    padding: 2rem 0rem;
  }
  .dashboard {
    flex-direction: column;
    padding: 1rem;
    overflow: visible;
  }
  .dashboard .verticle-column1 {
    width: 100%;
    display: flex;
    align-items: center;
    overflow: hidden;
    justify-content: space-between;
    padding: 2rem 0rem;
  }
  .dashboard .verticle-column1 img {
    height: 120px;
    position: relative;
    top: 0px;
  }
  .clippath {
    position: absolute;
    top: 1rem;
    height: 150px;
    width: 150px;
  }
  .verticle-column1 p,
  .mail-user-dash {
    position: relative;
    top: 0px;
    color: #fff;
    text-align: left;
    margin: 0 0.5rem;
  }
  .course-details-lap {
    display: none;
  }
  .course-details-mobile {
    display: block;
  }
  .mobile-course-details {
    width: 100%;
    background-color: #333;
    position: relative;
    top: -1px;
    padding-bottom: 1rem;
  }

  .mobile-course-details h3 {
    margin-top: 1rem;
    position: relative;
    top: 0px;
    color: #fff;
    text-align: left;
    margin-top: 1rem;
    padding: 0.2rem 0.5rem 0.2rem 1rem;
    width: fit-content;
    background-color: #050505;
    border-radius: 0px 5px 5px 0px;
    color: black;
    border-width: 2px 2px 2px 0px;
    border-style: solid;
    text-transform: capitalize;
    color: white;
  }
  #course-name {
    max-width: 95%;
    text-align: center;
    margin-top: 1rem;
  }
  .dashboard .verticle-column2 {
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    justify-content: space-around;
    top: -1px;
    padding-top: 2rem;
    border-radius: 0px;
  }
  .verticle-column2-1 {
    max-width: 100%;
    border-radius: 1px;
  }

  .verticle-column2-1 div {
    padding-left: 0.3rem;
  }
  .verticle-column2-1 h2 {
    font-size: 1.3rem;
  }
  .verticle-column2-2-payment-history {
    overflow-x: auto;
  }

  .verticle-column2-2-payment-history::-webkit-scrollbar {
    display: none;
  }
  .greeting-message {
    padding-bottom: 3rem;
    text-align: center;
    max-width: 98%;
  }
  .heading-payment-history button {
    all: unset;
    background-color: white;
    color: black;
    padding: 0.3rem 0.2rem;
    display: flex;
    gap: 5px;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
  }
  .payment-history-lap {
    display: none;
  }
  .payment-hostory-mobile {
    display: flex;
    justify-content: space-around;
    align-items: center;
    max-width: 100%;
    align-items: center !important;
    margin-top: 3rem;
  }
  #course-details-lap,
  #course-name {
    text-align: center !important;
  }
  #course-details-lap {
    display: none;
  }

  .heading-payment-history {
    justify-content: left !important;
  }
  .heading-payment-history h3 {
    font-size: 25px;
    margin-left: 5px;
  }
  #paymentnavigate-header {
    height: 50px;
    width: 100%;
    margin-top: 81px;
    padding: 2rem 0rem;
    overflow: hidden !important;
  }
}

#welcome {
  position: relative;
  top: 1rem;
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
  padding: 0 !important;
}

#h-p {
  margin: 0 !important;
  text-transform: uppercase;
  color: #f7d10c;
  font-weight: 800;
  margin: 0 !important;
  padding: 0 !important;
  margin-top: 0.5rem !important;
}

#h-p + p {
  position: relative;
  bottom: 3px;
}

.checkboxinput {
  display: flex;
  gap: 2px;
}

#inputfeildloaderhii {
  width: 30px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #f7d10f;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
}

.paymentstatusfeildloader {
  width: 50px;
  padding: 3px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: #f7d10f;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: l3 1s infinite linear;
  margin: 0 auto;
  margin-bottom: 30px;
}
@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}

.paymentreciptcenter {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid #ddd;
  width: fit-content;
  margin: 0 auto;
  margin: 20px auto;
  padding: 20px;
  border-radius: 4px;
}

.paymentreciptcenter button {
  text-align: center;
}

@media screen and (max-width: 600px) {
  .paymentreciptcenter {
    margin: 50px auto;
  }
}
