.react-calendar {
    border: none !important;
}

.react-calendar__month-view__days {
    gap: 2px 0;
    padding: 3px;
}

.react-calendar__tile {
    aspect-ratio: 1/1;
    color: black !important;
}

.react-calendar__tile {
    color: white !important;
    border: 1px solid white !important;
    /* background-color: black !important; */
    border-radius: 6px !important;
    position: relative !important;
}

.react-calendar__tile--active {
    background-color: inherit !important;
    color: black !important;
    border: 2px solid black !important;
    font-weight: 600 !important;
}

.react-calendar__tile:disabled {
    color: black !important;
    border: 1px solid transparent !important;
    background-color: transparent !important;
    position: relative !important;
    font-weight: 800;
}

.react-calendar__tile:disabled::after {
    display: inline-block;
    content: "x";
    position: absolute;
    top: 13px;
    left: 0px;
    right: 0;
    font-size: 1.5rem;
    bottom: 0;
    color: red;
    font-weight: 200;
}

.react-calendar__month-view__weekdays__weekday {
    color: black !important;
}

.react-calendar__month-view__weekdays__weekday abbr {
    text-decoration: none !important;
}

.react-calendar__tile:not([disabled]) abbr {
    display: none !important;
}

.react-calendar__tile:not([disabled]) .saturday-masterclass {

    position: absolute !important;
    z-index: 9999 !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    bottom: 0 !important;
    display: flex;
    align-items: center;
}



.react-calendar__tile:not([disabled]) .saturday-masterclass p {
    display: block !important;
    width: fit-content !important;
    height: fit-content !important;
    margin: auto !important;
    font-weight: 600;
}



.react-calendar__tile:not([disabled]) .fast-selling {
    background-color: #facc15;
}

.react-calendar__tile:not([disabled]) .available-class {
    background-color: green !important;
}

.react-calendar__tile:not([disabled]) .filled-class {
    background-color: red !important;
}

.react-calendar__tile:not([disabled]) .saturday-masterclass p {
    color: black !important;
}

.react-calendar__tile:not([disabled]) .available-class p {
    color: white !important;
}

.react-calendar__tile:not([disabled]) .filled-class p {
    color: white !important;

}

.react-calendar__tile .fast-selling p {
    display: none !important;
}

.react-calendar__navigation__label {
    pointer-events: none !important;

}

/* for current date  */
.react-calendar__tile--now {
    display: block !important;
    background-color: white !important;
}

.react-calendar__tile--now:not([disabled]) abbr {
    display: block !important;
    color: black !important;
    font-weight: 700 !important;
}

.react-calendar__tile--now:not([disabled])::after {
    display: inline-block;
    content: "x";
    position: absolute;
    top: 13px;
    left: 0px;
    right: 0;
    font-size: 1.5rem;
    bottom: 0;
    color: red;
    font-weight: 200;
}