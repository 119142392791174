hr {
  color: #4c4c4c !important;
}

.center {
  text-align: center;
}

.mouse-shadow {
  position: fixed;
  top: 50%;
  z-index: 2;
  left: 50%;
  width: 50px;
  height: 50px;
  background-color: #fff;
}

p.light {
  color: #bbb !important;
  line-height: 22px;
}

p.light-1 {
  color: #dcdcdc;
}

:root {
  --swiper-theme-color: var(--hashpro) !important;
  --swiper-pagination-bullet-inactive-color: black !important;
}

.crafters .swiper-horizontal > .swiper-pagination-bullets,
.crafters .swiper-pagination-bullets.swiper-pagination-horizontal,
.crafters .swiper-pagination-custom {
  bottom: -20px !important;
  padding-bottom: 20px;
}

.crafters .swiper-pagination-bullet {
  background-color: rgba(255, 255, 255, 0.377) !important;
}

.crafters .swiper-wrapper {
  padding-bottom: 40px;
}

.crafters {
  position: relative;
}

.members-container .swiper-button-container {
  position: relative;
  padding: 30px;
  padding-top: 20px;
}

.crafters .swiper-pagination {
  z-index: 1000 !important;
}

.next-btn {
  position: absolute;
  top: 50%;
  right: -3%;
}

.prev-btn {
  position: absolute;
  top: 50%;
  left: -3%;
}

.next-btn:hover {
  cursor: pointer;
}

.prev-btn:hover {
  cursor: pointer;
}

@media (min-width: 0px) and (max-width: 580px) {
  .next-btn {
    right: -10%;
  }

  .prev-btn {
    left: -10%;
  }
}

.floating-navbar {
  margin: auto;
  position: fixed;
  width: 100vw;
  top: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  z-index: 100;
  background-color: #000;
  transition: 0.3s;
  border-bottom: 1px solid rgba(255, 255, 255, 0.117);
  display: flex;
  justify-content: space-between;
  padding-right: 10px;
}

.floating-navbar .contact-us {
  display: flex;
}

.floating-navbar .contact-us p {
  display: block;
  margin: auto;
  margin-right: 5px;
  font-weight: 800;
}

@media screen and (max-width: 600px) {
  .floating-navbar .contact-us p {
    display: none;
  }
}

.floating-navbar img {
  margin-left: 6%;
  padding: 20px;
}

.floating-navbar .social {
  display: flex;
}

.floating-navbar .social a {
  margin: auto 5px;
  color: #008d97;
  font-weight: 600;
  font-size: 18px;
}

.floating-navbar .social .call-a {
  border-radius: 10px;
}

.floating-navbar .social .call-a img {
  scale: 1.1;
  transform: rotateY(180deg);
  filter: invert(97%) sepia(60%) saturate(1318%) hue-rotate(167deg)
    brightness(95%) contrast(89%);
}

.floating-navbar .social img {
  padding: 0;
  margin: auto 5px;
}

.dmx-form .grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 1000px) {
  .dmx-form .grid-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .dmx-form {
    padding: 20px 0;
    padding-top: 0;
  }
}

.dmx-form .left {
  margin: auto;
  text-align: center;
}

.dmx-form .left:hover img {
  scale: 1.05;
}

.dmx-form .left:hover .heading {
  scale: 1.05;
}

.dmx-form .left img {
  width: 70%;
  margin: auto;
  transition: ease-in-out 0.2s;
}

@media screen and (max-width: 600px) {
  .dmx-form {
    margin-left: 0;
    margin-right: 0;
  }
}

.dmx-form .heading {
  text-align: center;
  color: #fff;
  margin: auto;
  padding: 30px;
  padding-top: 0;
  transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1000px) {
  .dmx-form {
    grid-template-columns: 1fr;
  }

  .dmx-form .left img {
    display: none;
  }
}

.dmx-form .inputs-container {
  margin: auto;
  background-color: var(--hashpro);
  border: 1px solid var(--hashpro);
  width: 80%;
  padding: 15px 20px;
  padding-bottom: 0;
  border-radius: 10px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.dmx-form div p {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}

.dmx-form input {
  width: 100%;
  font-size: 14px;
  outline: 0;
  border: none;
  color: #000;
  font-weight: 400;
}

.dmx-form input,
.dmx-form select {
  padding: 13px 10px;
}

.dmx-form select {
  font-size: 14px;
  font-weight: 400;
  color: #636363;
  background-color: rgba(0, 0, 0, 0);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2784313725);
}

.dmx-form select {
  outline: none !important;
}

option {
  color: #000;
}

.dmx-form .input-container {
  margin: 0 !important;
  margin: auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dmx-form label {
  margin: auto;
  font-size: 14px;
  font-weight: 500;
}

.dmx-form input[type="radio"] {
  margin: auto;
  margin: 6px 4px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.dmx-form .urgency {
  margin-top: 20px;
}

.dmx-form button {
  z-index: 100;
  width: 100%;
  text-transform: uppercase;
}

.dmx-form button:hover {
  cursor: pointer;
}

#form-error.hide {
  display: none;
}

#form-error {
  max-width: 80%;
  opacity: 1;
  margin: auto;
  font-weight: 200;
  text-align: center;
  transition: 0.5s;
  font-size: 12px;
  line-height: 23px;
  color: #fff;
  border-radius: 5px;
}

@-webkit-keyframes blinking {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes blinking {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.dmx-form-error-anim {
  -webkit-animation: blinking infinite 1s;
  animation: blinking infinite 1s;
}

@media screen and (max-width: 600px) {
  .dmx-form .inputs-container {
    padding: 0px 0;
    padding-top: 20px;
    width: 90%;
  }
}

.acc {
  overflow: hidden;
  padding: 0;
}

.acc li {
  list-style-type: none;
  padding: 0;
}

.acc_ctrl {
  background: #000;
  border: none;
  border-bottom: solid 1px #f2f2f2;
  cursor: pointer;
  display: block;
  outline: 0;
  padding: 2em;
  padding-right: 3em;
  position: relative;
  text-align: center;
  width: 100%;
}

.acc_ctrl:before {
  background: #44596b;
  content: "";
  height: 2px;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: rotate(90deg);
  transition: all 0.2s ease-in-out;
  width: 14px;
}

.acc_ctrl:after {
  background: #44596b;
  content: "";
  height: 2px;
  position: absolute;
  right: 5%;
  top: 50%;
  width: 14px;
}

.acc_ctrl:focus h2 {
  position: relative;
}

.acc_panel {
  background: #f2f2f2;
  display: none;
  overflow: hidden;
}

.acc_panel p {
  font-size: 0.95em !important;
  line-height: 20px;
}

section {
  display: block;
}

a {
  background-color: rgba(0, 0, 0, 0);
}

.bold {
  font-weight: 800;
}

.yellow-text {
  color: var(--hashpro) !important;
}

.instructor-heading {
  display: none;
  margin-top: 50px !important;
}

@media screen and (min-width: 600px) {
  .instructor-heading {
    margin-bottom: 30px !important;
  }
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  text-decoration: none;
  display: inline-block;
}

img {
  max-width: 100%;
  font-size: 1rem;
  display: inline-block;
  position: static;
}

.text-weight-bold {
  font-weight: 700;
}

.text-size-large {
  font-size: 1.5rem;
  line-height: 150%;
}

.margin-xxsmall {
  margin: 0.25rem;
}

.margin-xsmall {
  margin: 0.5rem;
}

.margin-medium {
  margin: 2rem;
}

.padding-small {
  padding: 1rem;
}

.margin-bottom {
  height: auto;
  margin: 0 0 1rem;
}

.margin-bottom.margin-regular {
  margin-bottom: 1rem;
}

.padding-vertical {
  padding-left: 0;
  padding-right: 0;
}

.img-cover {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 8px;
  max-width: 500px;
  width: 95%;
}

@media screen and (max-width: 550px) {
  .img-cover {
    -o-object-position: top;
    object-position: top;
    max-height: 35vh;
  }
}

.section-wrapper {
  width: 100%;
  position: relative;
}

.hero-mentor-info-highlight {
  z-index: 2;
  width: 50%;
  text-align: center;
  border: 1px solid #000;
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem 1rem;
  position: absolute;
  top: -10%;
  bottom: auto;
  left: 5%;
  right: auto;
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 600;
}

.hero-mentor-info-highlight.pm2 {
  top: -8%;
  bottom: auto;
  left: 0;
  right: 0;
}

.text-size-regular-2 {
  font-size: 16px;
  color: #878787;
}

.text-size-regular-2.text-align-center {
  color: #878787 !important;
}

.text-block-223 {
  color: rgba(255, 255, 255, 0.7);
  text-align: left;
  font-size: 0.85rem;
}

.text-block-223.text-align-center {
  text-align: center;
}

.phone-heading {
  color: #fff;
  display: none;
  margin: 0 3%;
  margin-top: 110px;
}

.phone-heading p {
  text-align: center;
  margin: auto;
  margin-top: 13px;
  width: 96%;
}

.home-section {
  max-width: 95%;
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  align-items: center;
  padding: 20px;
  padding-bottom: 30px;
  margin: 0 auto;
  margin-top: 5rem !important;
  display: grid;
  position: relative;
}

.image-150 {
  z-index: -1;
  width: 60rem;
  max-width: none;
  opacity: 0.73;
  position: absolute;
  top: -23%;
  bottom: auto;
  left: 0;
  right: 0;
}

.image-150.b {
  width: 70rem;
  max-width: none;
  opacity: 0.51;
  top: -8%;
  bottom: 0;
  left: 9%;
  right: 0;
}

.hero-mentor-details {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  justify-content: center;
  display: grid;
}

.hero-mentor-details.margin-medium {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  border-radius: 8px;
  align-items: center;
  padding: 2rem 2rem 1rem;
  padding-bottom: 1rem !important;
}

.hero-mentor-details.margin-medium.pm2 {
  text-align: center;
  border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 8px 8px 0 0;
  grid-template-columns: auto;
  margin: 0;
  padding-top: 2.4rem;
  padding-bottom: 1.6rem;
  padding-left: 2.8rem;
}

.heading-64 {
  font-size: 2.8rem !important;
  font-weight: 800;
  margin: 0 auto;
  text-align: left;
}

@media (min-width: 0px) and (max-width: 600px) {
  .heading-64 {
    font-size: 1.8rem !important;
  }

  .home-content_left .font-paragraph {
    text-align: center;
  }
}

@-webkit-keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

.hero-mentor-logo_component {
  text-align: center;
  background-color: #e6efef;
  border-top: 1px solid #000;
}

.hero-mentor-logo_component.padding-vertical.padding-small {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0);
  border-top-color: rgba(255, 255, 255, 0.18);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding-left: 2rem;
  padding-right: 2rem;
  display: grid;
  overflow: hidden;
}

.hero-mentor-logo_component.padding-vertical.padding-small {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0);
  border-top-color: rgba(255, 255, 255, 0.18);
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  padding: 10px 2rem;
  display: grid;
  overflow: hidden;
}

.hero-mentor_component {
  width: 100%;
  border: 0.5px solid rgba(255, 255, 255, 0.6);
  border-radius: 8px;
  margin-top: 1rem;
  position: relative;
  overflow: visible;
}

.angel-investing-timer_container {
  text-align: center;
}

.div-block-252 {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 8px;
  margin-bottom: 24px;
  overflow: hidden;
  text-align: center;
}

.logo-top {
  width: auto;
  height: 3rem;
  min-height: 0;
  margin-bottom: 20px;
  position: static;
  top: 10%;
  bottom: auto;
  left: 9%;
  right: auto;
}

.cta-angel-investing:hover {
  transform: scale(1.01);
}

.dmx-page .splide__arrow {
  display: none !important;
}

.dmx-page .splide__pagination {
  display: none !important;
}

.cta-angel-investing.pm2-gradient {
  background-color: var(--hashpro);
  font-weight: bold;
  color: #000;
  text-transform: uppercase;
  max-width: 24rem;
  border-radius: 6.6px !important;
  box-shadow: none;
  transition: 0.3s;
}

.cta-angel-investing.pm2-gradient.full {
  margin-top: 20px;
  max-width: none;
}

@media only screen and (max-width: 500px) {
  .cta-angel-investing.pm2-gradient.full {
    display: none;
  }
}

.body-text {
  margin-bottom: 26px;
  font-size: 2rem;
  line-height: 150%;
}

.div-block-427 {
  display: flex;
}

.div-block-427.pm2 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  border: 1px solid rgba(247, 196, 10, 0.7254901961);
  border-radius: 8px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  margin-top: 20px;
  margin-bottom: 40px;
  padding: 24px;
  display: grid;
}

.lightbox-link-16 {
  width: 100%;
  max-height: 16rem;
}

.text-align-center {
  text-align: center;
}

@media screen and (min-width: 1280px) {
  .home-section.pm2 {
    height: auto;
  }
}

@media screen and (max-width: 991px) {
  .margin-medium {
    margin: 1.5rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .cta-angel-investing {
    padding: 1rem 3rem;
  }
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 2.3rem;
  }

  .text-size-large {
    font-size: 1.25rem;
  }

  .margin-medium {
    margin: 1.25rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .margin-bottom.margin-regular {
    margin-bottom: 0.5rem;
  }

  .margin-bottom.margin-medium.no-margin {
    margin-bottom: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .home-section {
    width: 100%;
    height: auto;
    grid-row-gap: 2rem;
    grid-template-rows: auto auto;
    grid-template-columns: 1fr;
    align-content: stretch;
    align-items: stretch;
    margin-top: 3rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    display: block;
  }

  .heading-small-2 {
    font-size: 1.2rem;
  }
}

.heading-small-2 {
  font-size: 1.4rem;
}

@media screen and (max-width: 479px) {
  .margin-bottom {
    margin: 0;
  }

  .padding-vertical {
    padding-left: 0;
    padding-right: 0;
  }

  .section-wrapper {
    margin-left: auto;
    margin-right: auto;
  }

  .hero-mentor-info-highlight {
    top: -12%;
  }

  .hero-mentor-info-highlight.pm2 {
    width: 50%;
    top: -3%;
  }

  .home-content_left {
    text-align: center;
  }

  .home-content_left.left-on-mobile {
    text-align: left;
    margin-top: 10px;
  }

  .home-content_left.left-on-mobile {
    text-align: left;
  }

  .home-section {
    align-content: stretch;
    align-items: stretch;
    margin-top: 2rem;
    display: block;
    overflow: hidden;
  }

  .home-section.pm2 {
    grid-template-rows: auto;
    overflow: visible;
  }

  .heading-small-2 {
    margin-bottom: 8px;
  }

  .image-150 {
    max-width: none;
    top: 7%;
    bottom: auto;
    left: -172px;
    right: auto;
  }

  .image-150.b {
    left: -50%;
  }

  .hero-mentor-details.margin-medium.pm2 {
    padding-left: 2rem;
  }

  .hero-mentor-logo_component.padding-vertical.padding-small {
    grid-template-rows: auto auto;
    grid-template-columns: auto;
  }

  .hero-mentor-logo_component.padding-vertical.padding-small.pm2 {
    grid-template-rows: auto;
    display: block;
  }

  .hero-mentor-logo_component.padding-vertical.padding-small {
    grid-template-rows: auto auto;
    grid-template-columns: auto;
    padding-bottom: 17px;
  }

  .logo-top {
    height: 2.4rem;
  }

  .cta-angel-investing {
    font-size: 16px;
  }

  .div-block-427.pm2 {
    margin-bottom: 0;
    padding: 16px;
  }

  .lightbox-link-16 {
    max-height: 13rem;
  }
}

#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac212-6d667f4f,
#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac213-6d667f4f,
#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac22a-6d667f4f {
  grid-area: span 1 / span 1 / span 1 / span 1;
  color: #fff;
  background-color: #000 !important;
}

#w-node-d57d53ab-fae1-8635-5e73-ffdec08ac23a-6d667f4f {
  grid-area: span 1 / span 2 / span 1 / span 2;
  line-height: 22px;
  padding: 10px 0;
}

p {
  margin: 0;
}

.hashpro-way-container[data-v-17ab2e64] {
  background: #0a0a0a;
  color: #fff;
  overflow: hidden;
  position: relative;
  padding: 5%;
  display: none;
}

@media only screen and (max-width: 500px) {
  .hashpro-way-container[data-v-17ab2e64] {
    margin-top: 30px;
  }
}

.hashpro-way-container .restrict-width[data-v-17ab2e64] {
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;
  max-width: 100rem;
  margin: 0 auto;
  padding: 0 7%;
}

.hashpro-way-container .left-container[data-v-17ab2e64] {
  text-align: center;
  margin-right: 4.18%;
  width: 100%;
}

.hashpro-way-container h1 {
  margin: 0;
  margin-bottom: 10px;
}

.hashpro-way-container .left-container .heading[data-v-17ab2e64] {
  font-style: normal;
  font-weight: 800;
  font-size: 2.875rem;
  margin-bottom: 2rem;
}

.hashpro-way-container .left-container .desc[data-v-17ab2e64] {
  font-size: 1.125rem;
  line-height: 200%;
  margin: auto;
  max-width: 85%;
}

.hashpro-way-container .right-container[data-v-17ab2e64] {
  width: 70%;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  justify-content: center;
}

.hashpro-way-container .right-container .item[data-v-17ab2e64] {
  display: flex;
  flex-direction: column;
  font-style: normal;
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 140%;
  padding: 1.25rem 1.5rem;
  width: 300px;
  margin: auto;
  transition: 0.5s;
}

.hashpro-way-container .right-container .item[data-v-17ab2e64]:hover {
  scale: 1.1;
}

.hashpro-way-container .right-container .item[data-v-17ab2e64] .text {
  color: #e6e6e6;
  font-weight: 600;
  font-size: 0.88rem;
  line-height: 18px;
}

.hashpro-way-container .right-container .emoji {
  font-size: 55px;
  text-align: center;
  line-height: 80px;
}

.hashpro-way-container .right-container .item img[data-v-17ab2e64] {
  height: 4rem;
  width: 4rem;
  max-width: inherit;
  margin-bottom: 1.5rem;
}

@media only screen and (max-width: 1000px) {
  .hashpro-way-container .restrict-width[data-v-17ab2e64] {
    flex-direction: column;
  }

  .hashpro-way-container .left-container[data-v-17ab2e64] {
    margin-right: 0;
    text-align: center;
    width: 100%;
  }

  .hashpro-way-container .right-container[data-v-17ab2e64] {
    width: 100%;
    justify-content: space-between;
  }

  .hashpro-way-container .right-container .item[data-v-17ab2e64] {
    align-items: center;
    text-align: center;
  }
}

@media only screen and (max-width: 512px) {
  .hashpro-way-container .restrict-width[data-v-17ab2e64] {
    padding: 0 5%;
  }

  .hashpro-way-container .left-container .heading[data-v-17ab2e64] {
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .hashpro-way-container .left-container .desc[data-v-17ab2e64] {
    font-size: 0.875rem;
  }

  .hashpro-way-container .left-container .desc p[data-v-17ab2e64] {
    margin-bottom: 0.2rem;
  }

  .hashpro-way-container .right-container .item[data-v-17ab2e64] {
    font-size: 0.75rem;
    padding: 1.5rem 0.5rem;
    margin-bottom: 0.5rem;
    max-width: 9.6rem;
  }

  .hashpro-way-container .right-container .item img[data-v-17ab2e64] {
    height: 2.5rem;
    width: 2.5rem;
    max-width: inherit;
    margin-bottom: 0.875rem;
  }
}

svg:not(:root) {
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

ul {
  margin-top: 0;
  margin-bottom: 10px;
  padding-left: 40px;
}

.w-embed:after,
.w-embed:before {
  content: " ";
  grid-area: 1/1/2/2;
  display: table;
}

.w-embed:after {
  clear: both;
}

.margin-bottom {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
}

.margin-bottom .margin-medium {
  margin-bottom: 2rem;
}

.text-align-center {
  text-align: center;
}

.text-size-regular {
  font-size: 16px;
}

.text-size-regular.li {
  margin-bottom: 8px;
  font-weight: 400;
}

.heading-who {
  margin-top: 0px;
}

.forwho-card {
  background-color: #000;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 8px;
  color: #fff;
  margin: 15px 0;
  transition: 0.1s;
}

.forwho-card:hover {
  scale: 1.015;
}

.forwho-card-header {
  display: flex;
  align-items: center;
}

.forwho-card-header .emoji {
  font-size: 25px;
  margin: auto 0;
}

.forwho-card-header h3 {
  background-color: rgba(0, 0, 0, 0.85);
  padding: 1px 5px;
  letter-spacing: 0.03rem;
  margin: auto 10px;
  margin-left: 4px;
  font-weight: 600;
  font-size: 1.1rem !important;
  color: #f7c20a;
  text-transform: uppercase;
}

.forwho-card p {
  padding-left: 1%;
  margin-top: 8px;
  font-size: 0.96rem;
  font-weight: 500;
  color: #fff;
}

@media screen and (max-width: 600px) {
  .forwho-card p {
    padding-left: 0.9%;
    font-weight: 400;
  }
}

.tick-wrapper {
  color: #000 !important;
}

.tick-wrapper svg {
  margin: auto;
  margin-bottom: 1px;
  width: 12px;
}

.div-block-257 {
  grid-column-gap: 12px;
  grid-row-gap: 0;
  background-color: var(--hashpro);
  color: #000 !important;
  border: 1px solid rgba(255, 255, 255, 0.41);
  border-radius: 8px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: center;
  margin-bottom: 1rem;
  margin-right: 0.7rem;
  padding: 1rem;
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
  display: grid;
  transition: 0.2s;
}

.div-block-257:hover {
  background-color: var(--hashpro-hover);
}

.div-block-257 h3 {
  margin: 0;
  font-size: 1.1rem;
}

.div-block-257 li {
  font-weight: 500 !important;
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.88);
}

.tick-wrapper {
  width: 1.5em;
  height: 1.5rem;
  background-color: #f7c20a;
  border-radius: 12rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.div-block-259 {
  padding-top: 20px;
  color: #fff !important;
  max-width: 1440px;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

.who-card-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 50px;
  column-gap: 50px;
  row-gap: 30px;
}

.who-card svg {
  width: 20px;
  margin: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.who-card {
  margin: 0 auto;
  max-width: 90%;
  padding: 20px;
  padding-left: 0;
  border-radius: 8px;
  color: #000;
  display: flex;
  background-color: var(--hashpro);
}

.who-card li {
  padding: 6px 0;
}

.div-block-310 {
  max-width: 64rem;
  justify-content: space-around;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  padding-bottom: 30px;
}

.div-block-311 {
  flex-direction: column;
  flex: 1;
  display: flex;
  margin-right: 30px;
  margin-left: 30px;
}

.list-7 {
  padding-left: 20px;
}

@media (min-width: 0px) and (max-width: 600px) {
  .who-card-container {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 991px) {
  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 767px) {
  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 479px) {
  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .heading-large {
    line-height: 120%;
  }

  .tick-wrapper {
    padding: 0.5rem;
  }
}

@media screen and (max-width: 700px) {
  .div-block-310 {
    flex-direction: column;
  }

  .div-block-311 {
    margin-right: 20px;
    margin-left: 20px;
  }
}

#w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1740-14fe2356,
#w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1742-14fe2356 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1744-14fe2356 {
  grid-area: 2/2/3/3;
}

#w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a842-14fe2356,
#w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a844-14fe2356 {
  grid-area: span 1 / span 1 / span 1 / span 1;
}

#w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a846-14fe2356 {
  grid-area: 2/2/3/3;
}

.show-more-show-less {
  display: inline;
  line-height: 22px;
}

.member-card-container {
  padding: 4.625rem 0 0;
  display: flex !important;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (min-width: 800px) {
  .member-card-container {
    scale: 0.85;
  }

  .swiper-button-container {
    padding-bottom: 0;
  }
}

.member-card-container .member-image-container {
  position: absolute;
  top: 0;
  z-index: 2;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 9.25rem;
  max-width: inherit;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.member-card-container .member-image-container img {
  height: 8.5rem;
  width: 8.5rem;
  border-radius: 50%;
  padding: 5px;
  background-color: #3d3d3d;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
}

a,
div,
img,
p,
span {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

.output {
  overflow-wrap: anywhere;
}

.margin-medium {
  margin: 2rem;
}

.margin-bottom {
  height: auto;
  margin: 0 0 1rem;
}

.div-block-366 {
  text-transform: uppercase;
  color: #000;
  width: 80%;
  background-color: var(--hashpro);
  border-radius: 8px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font-size: 15px !important;
  font-weight: 700;
  position: absolute;
  top: -9%;
  bottom: auto;
  left: 0;
  right: 0;
}

.heading-large-2 {
  font-size: 32px;
  line-height: 140%;
}

.card.pm2 {
  background-color: #000 !important;
  border-radius: 8px;
  padding-top: 3rem;
  padding-bottom: 3rem;
  color: #fff;
}

.section-22 {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.text-block-215 {
  margin: 0;
  font-size: 17px;
  font-weight: 500;
  color: var(--hashpro);
  margin-bottom: 50px;
}

.text-block-215.text-align-center {
  line-height: 1.5;
}

.text-align-center {
  text-align: center;
}

@media screen and (max-width: 991px) {
  .margin-medium {
    margin: 1.5rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }
}

@media screen and (max-width: 479px) {
  .margin-bottom {
    margin: 0;
  }

  .div-block-366 {
    width: 90%;
    font-size: 14px;
  }

  .heading-large-2 {
    line-height: 120%;
  }

  .heading-large-2.text-align-center.pm2 {
    margin-bottom: 0;
    font-size: 24px;
  }

  .section-22 {
    grid-template-columns: 1fr;
  }

  .section-22.margin-bottom.margin-medium {
    grid-column-gap: 16px;
    grid-row-gap: 40px;
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 479px) {
  #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac4ea-6d667f4f {
    grid-area: span 1 / span 1 / span 1 / span 1;
  }
}

.w-button {
  color: #fff;
  line-height: inherit;
  cursor: pointer;
  background-color: #3898ec;
  border: 0;
  border-radius: 0;
  padding: 9px 15px;
  text-decoration: none;
  display: inline-block;
}

.margin-medium {
  margin: 2rem;
}

.margin-bottom {
  height: auto;
  margin: 0 0 1rem;
}

.margin-bottom.margin-regular {
  margin-bottom: 10px;
}

.margin-bottom.margin-custom {
  margin-bottom: 1.5rem;
}

.html-embed-45 {
  justify-content: center;
  align-items: center;
  display: flex;
}

.d2c-offer-container {
  width: 90%;
  grid-column-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  align-content: stretch;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  display: grid;
}

.d2c-offer-container.app {
  grid-column-gap: 3rem;
}

.text-block-221 {
  color: #d8d8d8;
  font-size: 1.05rem;
  line-height: 30px;
}

.pricing-component_right {
  text-align: left;
  border: 1px dashed #491eb8;
  border-radius: 1rem;
  padding: 2.5rem;
  padding-top: 1.2rem;
  padding-bottom: 2rem;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

.pricing-component_right.pm2 {
  border: 1px solid var(--hashpro);
}

.text-weight-bold-2 {
  font-style: normal;
  font-weight: 700;
}

.text-weight-bold-2.text-align-center {
  font-style: normal;
}

.app-chechbox {
  width: 1.2rem;
  height: 1.2rem;
  border: 2px solid #00b7fe;
  border-radius: 8px;
  padding: 0.5rem;
}

.app-chechbox.pm2 {
  background-color: var(--hashpro);
  border-style: none;
  border-radius: 80rem;
  justify-content: center;
  align-items: center;
  padding: 0.3rem;
  display: flex;
  margin: auto;
}

.app-chechbox.pm2 svg {
  color: #000 !important;
}

.text-span-84 {
  color: #bebebe;
}

.heading-large-2 {
  margin-bottom: 0;
  font-size: 25px;
  line-height: 140%;
}

.heading-large-2.white {
  color: #fff;
  font-weight: 600;
}

.offer-card {
  text-align: left;
  background-color: #fff;
  background-image: url(https://assets.website-files.com/625416528a3419ca302b6dd8/625c7b4bc8a26a7557c46205_Group/3193.svg),
    none, none;
  background-position: 0 0, 0 100%, 100% 0;
  background-repeat: no-repeat, no-repeat, no-repeat;
  background-size: 17%, 11%, 11%;
  border-radius: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin: 0 2.5%;
  padding-bottom: 54px;
  padding-left: 40px;
  display: flex;
  box-shadow: 10px 10px 24px rgba(0, 0, 0, 0.05);
}

.offer-card.app {
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  padding: 0 0 2.5rem 0;
}

.div-block-259 .heading {
  margin-bottom: 5px;
  color: #fff;
}

.cta-angel-investing {
  width: 100%;
  text-align: center;
  border-radius: 0.5rem;
  padding: 0.875rem 5.625rem;
  font-size: 16px;
  font-weight: 700;
  box-shadow: 0px 0px 6px 0px #f7c20a;
}

.cta-angel-investing:hover {
  background-color: #000;
}

.cta-angel-investing.pm2-gradient.offer {
  max-width: none;
}

.what-you-get {
  grid-column-gap: 5px;
  grid-row-gap: 16px;
  grid-template-rows: auto;
  grid-template-columns: auto 1fr;
  grid-auto-columns: 1fr;
  align-items: start;
  margin-top: 16px;
  line-height: 1.5;
  display: grid;
}

.what-you-get span {
  margin: auto 5px;
  font-weight: 500;
}

.what-you-get.pm2 {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 17px;
  font-size: 16px;
}

.text-align-center {
  text-align: center;
}

.oval-button {
  background-color: var(--hashpro) !important;
  background-image: none !important;
  border-radius: 50px !important;
  color: #000;
  font-weight: 600 !important;
  transition: 0.2s;
  border: 1px solid rgba(0, 0, 0, 0);
}

.oval-button:hover {
  background-color: #000 !important;
  color: var(--hashpro);
  border: 1px solid rgba(247, 196, 10, 0.2392156863);
}

@media screen and (max-width: 991px) {
  .margin-medium {
    margin: 1.5rem;
  }

  .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .cta-angel-investing {
    padding: 1rem 3rem;
  }
}

.apply-btn[data-v-2fccc02f] {
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-decoration: none;
  color: #f6f6ff;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .apply-btn[data-v-2fccc02f] {
    margin: 0 auto 4rem;
  }
}

@media only screen and (max-width: 512px) {
  .apply-btn[data-v-2fccc02f] {
    padding: 0.75rem 2.5rem;
    font-size: 0.75rem;
    border-radius: 0.375rem;
    margin-bottom: 2rem;
  }
}

.enrollment-process-container {
  padding: 6.25rem 7vw;
  background: #fafafa;
  color: #171421;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.enrollment-process-container .main-heading {
  font-style: normal;
  font-size: 2.875rem;
  text-align: center;
  margin-bottom: 5rem;
}

.enrollment-process-container .limited-seats {
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 130%;
  color: #000;
  margin: -4rem auto 5rem;
  text-align: center;
}

.enrollment-process-container .limited-seats span {
  color: #6334c8;
}

.enrollment-process-container .banner-container {
  background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
  box-shadow: 0 0 4rem rgba(104, 63, 190, 0.27);
  border-radius: 1.25rem;
  z-index: 2;
  margin: 0 4.625rem;
  overflow: hidden;
}

.enrollment-process-container .banner-container .background-image-container {
  padding: 2.5rem 4.5rem;
  background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner.d495bac.png);
  background-size: cover;
  background-position: 50%;
}

.enrollment-process-container
  .banner-container
  .background-image-container
  .text {
  width: 47.5vw;
}

.enrollment-process-container
  .banner-container
  .background-image-container
  .text
  .heading {
  font-style: normal;
  font-weight: 700;
  font-size: 2.25rem;
  color: #fdfdfd;
  margin-bottom: 1rem;
}

.enrollment-process-container
  .banner-container
  .background-image-container
  .text
  .content {
  font-size: 1.125rem;
  line-height: 150%;
  color: #fff;
  margin-bottom: 1.75rem;
}

.enrollment-process-container
  .banner-container
  .background-image-container
  .text
  .apply-btn {
  background: #e8ddff;
  border-radius: 0.375rem;
  padding: 0.75rem 1.5rem;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  color: #171421;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

@media only screen and (max-width: 880px) {
  .enrollment-process-container .main-heading {
    text-align: left;
  }

  .enrollment-process-container .banner-container {
    box-shadow: 0 0 1.5rem rgba(104, 63, 190, 0.27);
    border-radius: 0.5rem;
    margin: 0.5rem 0 0;
  }

  .enrollment-process-container .banner-container .background-image-container {
    padding: 2.5rem 4.5rem;
    background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner.d495bac.png);
    background-size: cover;
    background-position: 50%;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text {
    width: 47.5vw;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text
    .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    color: #fdfdfd;
    margin-bottom: 1rem;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text
    .content {
    font-size: 1.125rem;
    line-height: 150%;
    color: #fff;
    margin-bottom: 1.75rem;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text
    .apply-btn {
    background: #e8ddff;
    border-radius: 0.375rem;
    padding: 0.75rem 1.5rem;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #171421;
    display: flex;
    align-items: center;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
  }
}

@media only screen and (max-width: 512px) {
  .enrollment-process-container {
    padding: 2.5rem 7vw;
  }

  .enrollment-process-container .main-heading {
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 1.5rem;
  }

  .enrollment-process-container .limited-seats {
    font-size: 0.875rem;
    margin: -0.625rem auto 1.5rem;
    text-align: left;
  }

  .enrollment-process-container .banner-container {
    background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
    box-shadow: 0 0 1.5rem rgba(104, 63, 190, 0.27);
    border-radius: 0.5rem;
    margin: 0;
  }

  .enrollment-process-container .banner-container .background-image-container {
    padding: 1.25rem;
    background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner-mobile.ecf70b2.png);
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text {
    width: 100%;
    text-align: center;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text
    .heading {
    font-size: 1.125rem;
    margin-bottom: 0.5rem;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text
    .content {
    font-size: 0.75rem;
    margin-bottom: 0.75rem;
  }

  .enrollment-process-container
    .banner-container
    .background-image-container
    .text
    .apply-btn {
    border-radius: 0.375rem;
    padding: 0.5rem 3.75rem;
    font-size: 0.625rem;
    margin-bottom: 0;
  }
}

.apply-btn[data-v-2fccc02f] {
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-size: 1.125rem;
  background: #683fbe;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  text-decoration: none;
  color: #f6f6ff;
  cursor: pointer;
}

@media only screen and (max-width: 1000px) {
  .apply-btn[data-v-2fccc02f] {
    margin: 0 auto 4rem;
  }
}

@media only screen and (max-width: 512px) {
  .apply-btn[data-v-2fccc02f] {
    padding: 0.75rem 2.5rem;
    font-size: 0.75rem;
    border-radius: 0.375rem;
    margin-bottom: 2rem;
  }
}

.dmx-page .admission-process {
  display: none;
  background-color: #0a0a0a;
  color: #fff;
  text-align: center;
  padding: 0 9%;
}

.admission-process h1 {
  font-size: 2rem !important;
  margin: 0;
  margin-bottom: 5px;
}

.admission-process p {
  font-size: 15px !important;
}

.doubt-banner {
  transition: 0.4s;
  border-radius: 81px;
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  border: 1px solid #000;
  box-shadow: 2px 2px #f8d00d;
  display: flex;
  justify-content: center;
  align-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  font-style: normal;
  font-weight: 700;
  font-size: 1.5rem;
  z-index: 9999;
  background-color: #fff;
  color: #000;
  gap: 10px;
  border-radius: 0;
  width: 100%;
  bottom: 0;
  padding: 10px 1rem;
  padding-bottom: 0;
}

.doubt-banner h3 {
  font-size: 1rem;
  color: #000;
}

.doubt-banner a {
  color: #000 !important;
  font-size: 16px;
  text-align: center;
}

.doubt-banner .phone-icon img {
  height: 30px;
  width: 30px;
}

.doubt-banner .contact-us {
  font-weight: 600;
  line-height: 147%;
  font-size: 1rem;
  display: flex;
  align-items: center;
  border-radius: 0.375rem;
  color: #000;
  text-decoration: none;
}

.doubt-banner .button {
  font-size: 16px;
  white-space: nowrap;
  background-color: #f7c20a;
  text-align: center;
  color: #000 !important;
  padding: 5px 15px;
  border: 2px solid #000;
}

.doubt-banner .contact-us img {
  height: 60px;
  width: 60px;
  max-width: inherit;
  transform: translate(23px, -10px);
  transform-origin: center;
  scale: 0.8;
}

.get-in-touch {
  display: none;
  text-align: center;
  color: #fff;
  margin: 0 5%;
  margin-top: 30px;
  margin-bottom: 80px;
  font-size: 30px !important;
}

.extra-details {
  max-width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extra-details-card {
  max-width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 0.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  color: #fff;
}

@media only screen and (min-width: 600px) {
  .extra-details-card {
    margin-top: 15px;
  }
}

.last {
  padding: 0 10% !important;
  margin: auto;
  text-align: center;
}

.last a {
  margin-left: 3px !important;
}

@media (min-width: 0px) and (max-width: 600px) {
  .extra-details {
    flex-direction: column;
  }

  .extra-details-card {
    margin-bottom: 15px;
  }
}

.extra-details-card a {
  color: #fff;
  transition: 0.5s;
  font-weight: 700;
}

.extra-details-card a:hover {
  color: var(--hashpro);
}

.extra-details-card a {
  margin-left: 10px;
}

@-webkit-keyframes marquee-anim {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-180px);
  }
}

@keyframes marquee-anim {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-180px);
  }
}

.marquee {
  display: flex;
  -webkit-animation: marquee-anim linear infinite 3s;
  animation: marquee-anim linear infinite 3s;
  transition: 0.2s;
}

.marquee img {
  border-top: 1px solid var(--hashpro);
  border-bottom: 1px solid var(--hashpro);
  width: 180px;
  padding: 2px 20px;
  transition: 0.5s;
}

.marquee-text {
  margin: 0 50px;
  font-size: 20px;
}

.extra-details {
  max-width: 90%;
  margin-top: 20px;
  margin-left: 5%;
  margin-right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.extra-details-card {
  font-size: 0.9rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

@media (min-width: 0px) and (max-width: 600px) {
  .extra-details {
    flex-direction: column;
  }

  .extra-details-card {
    margin-bottom: 15px;
  }
}

.extra-details-card a {
  color: #fff;
  transition: 0.5s;
  font-weight: bold;
}

.extra-details-card a:hover {
  color: var(--hashpro);
}

.extra-details-card a {
  margin-left: 10px;
}

@media screen and (min-width: 600px) {
  .hashpro-way-container[data-v-17ab2e64] {
    padding-top: 60px;
    padding-bottom: 90px;
  }

  .admission-process {
    padding-top: 60px;
    padding-bottom: 40px;
  }
}

@media screen and (max-width: 600px) {
  #form {
    transform: translateY(-35px);
  }
}

@media screen and (max-width: 500px) {
  .phone-heading {
    display: block !important;
  }

  .div-block-427.pm2 {
    margin-top: 0px;
  }

  .cta-1 {
    padding-top: 40px;
  }

  .instructors-and-members-container
    .instructor-container
    .instructor-bio-container {
    padding-bottom: 10px;
  }

  .home-section {
    margin-top: 0rem !important;
  }

  .heading-who {
    margin-top: 0;
  }

  .admission-process {
    padding-top: 60px;
    padding-bottom: 20px;
  }
}

.splide__slide {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

.carousel-container img {
  border-radius: 5px;
  margin: auto;
}

.carousel-container {
  text-align: center;
}

.cta-1.two {
  background-color: #404040;
  background-size: cover;
}

.cta-1.two .subheading {
  text-transform: uppercase;
  color: #fff;
}

.cta-1.two button {
  background-color: #1a1a1a;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px,
    rgba(0, 0, 0, 0.22) 0px 15px 12px;
}

.cta-1.two button:hover {
  background-color: #353535;
  color: #fff;
}

.cta-1.two .undertext {
  color: #888 !important;
  margin-top: 12px;
}

.dmx-page li {
  margin: auto !important;
}

.home-content_left {
  grid-area: hl;
}

.top-height {
  margin-top: 150px;
}

@media only screen and (max-width: 500px) {
  .top-height {
    margin-top: 100px;
  }
}

#form {
  transform: translateY(-100px);
}

.vsl-container {
  width: 90%;
  margin: auto;
  display: block;
  text-align: center;
}

.vsl-container iframe {
  margin: 0 auto;
  aspect-ratio: 16/9;
  display: block;
}

.leads {
  position: fixed;
  z-index: 9999;
  height: 100vh;
  width: 100dvw;
  top: 500%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0);
  transition: 0.5s cubic-bezier(0.88, -0.18, 0, 1.2);
}

.leads .fixed-container {
  position: relative;
  width: 100%;
  height: 100%;
}

.lead-form {
  padding: 20px;
  border-radius: 15px;
  border: 2px solid #000;
  box-shadow: 3px 3px var(--hashpro);
  background-color: #fff;
  width: 40%;
}

@media only screen and (max-width: 500px) {
  .lead-form {
    padding: 10px !important;
  }
}

.dmx-page .lead-form {
  margin: 4rem auto;

  top: 50%;
  /* position: absolute; */
  left: 50%;
  /* transform: translate(-50%, -50%); */
  width: 80%;
}

.lead-form select {
  border: 1px solid black;
  border-radius: 2rem;
  padding: 8px 15px;
  color: black;
  font-size: 1rem;
}

@media only screen and (max-width: 600px) {
  .dmx-page .lead-form {
    width: 100%;
  }
}

.lead-form .container {
  position: relative;
  width: 100%;
  height: 100%;
}

.lead-form .container .info {
  margin-top: 10px;
  color: #000;
  position: relative;
  text-align: center;
}

.lead-form .container .info h5 {
  margin-top: 8px;
  font-weight: 400;
}

.lead-form .container img {
  width: 25px;
  float: right;
  display: block;
  margin-left: auto;
  margin-right: 0px;
  position: absolute;
  top: 10%;
  transform: translateY(-50%);
  right: 0;
}

.lead-form .dmx-form {
  padding: 20px 10px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
}

.lead-form .dmx-form input {
  padding: 8px 15px;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #000;
  border-radius: 2rem;
}

.lead-form .dmx-form select {
  padding: 8px 15px;
  border: none;
  border: 1px solid #000;
  border-radius: 2rem;
  width: 100%;
}

.lead-form .dmx-form .dmx-form-grid {
  margin: 0;
  grid-template-columns: 1fr 1fr;
}

.hero {
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8) 70%,
      #050505
    ),
    url(/public/global/dmx/images/DMX_grid.webp);
  padding-bottom: 50px;
  color: #fff;
  background-size: cover;
  position: relative;
}

.hero .info-container {
  position: relative;
  padding-bottom: 60px;
}

.hero .info-container .info {
  padding-top: 10%;
  top: 50%;
  left: 50%;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .hero .info-container .info {
    top: 40%;
    padding-top: 20%;
  }
}

.hero .chip-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-shrink: 0;
  margin-top: 40px;
}

@media only screen and (max-width: 800px) {
  .hero .chip-container {
    flex-direction: column;
  }
}

.hero .chip-container .chip {
  border-radius: 2rem;
  padding: 10px 20px;
  background-color: var(--hashpro);
  display: flex;
  flex-shrink: 0;
  gap: 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  transition: 0.2s;
}

.hero .chip-container .chip:hover {
  border: 1px solid rgba(0, 0, 0, 0);
  scale: 1;
  transform: translateY(-2px);
}

@media only screen and (max-width: 800px) {
  .hero .chip-container .chip {
    margin: auto;
  }
}

.hero .chip-container .chip img {
  width: 20px;
  filter: invert(1);
}

.hero .chip-container .chip p {
  color: #000;
  font-size: 1rem;
  font-weight: 700;
}

.hero .heading {
  font-size: 2.5rem;
  padding-bottom: 1%;
  color: #fff;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 10px;
}

@media only screen and (max-width: 700px) {
  .hero .heading {
    font-size: 1.6rem;
  }
}

@media only screen and (min-width: 900px) {
  .hero .heading {
    font-size: 2.6rem;
  }
}

.hero .heading span {
  font-size: 3rem;
  font-weight: 700;
}

@media only screen and (max-width: 700px) {
  .hero .heading span {
    font-size: 2.3rem;
  }
}

@media only screen and (min-width: 900px) {
  .hero .heading span {
    font-size: 4.2rem;
  }
}

.hero .logo {
  width: 20%;
  margin: auto;
  display: block;
  margin-bottom: 20px;
}

@media only screen and (max-width: 600px) {
  .hero .logo {
    width: 60%;
    margin-top: 20px;
  }
}

.hero .sublogo {
  text-align: center;
  margin-bottom: 10px;
  line-height: 1.5;
  color: #fafafaab;
}

@media only screen and (min-width: 1000px) {
  .hero .sublogo {
    max-width: 60%;
    margin: auto;
  }
}

.hero .tl {
  background-size: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

.hero .grid {
  grid-template-columns: 1fr 1fr;
  gap: 30px;
}

@media only screen and (max-width: 800px) {
  .hero .grid {
    grid-template-columns: 1fr;
  }
}

.hero .grid .video {
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

.hero .grid .video iframe {
  border-radius: 15px;
  width: 100%;
  aspect-ratio: 16/9;
  display: block;
}

.insights {
  padding-top: 70px;
  padding-bottom: 70px;
  color: #fff;
  background-color: #1a1a1a;
}

@media only screen and (max-width: 600px) {
  .insights {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.insights .heading {
  text-align: left;
}

.insights .card {
  background-color: #222;
  padding: 30px;
  border-radius: 15px;
}

.insights .card h3 {
  font-size: 1.17rem;
  font-weight: 500;
  margin-bottom: 30px;
}

.insights .card:nth-child(2) img {
  scale: 1.1;
}

.insights .card img {
  display: block;
  margin: auto;
}

.insights .cards-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  margin-top: 30px;
}

@media only screen and (max-width: 850px) {
  .insights .cards-container {
    grid-template-columns: 1fr;
  }
}

.insights .cards-container .card.pa .points {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 25px 20px;
}

.insights .cards-container .card.pa .points .point {
  display: flex;
  flex-shrink: 1 0 auto;
  gap: 10px;
}

.insights .cards-container .card.pa .points .point img {
  margin: 0;
  transform: translateY(2px);
  width: 12px;
  height: 12px;
  opacity: 0.7;
  filter: invert(1);
}

.insights .cards-container .card.pa .points .point p {
  font-size: 0.9rem;
}

.insights .grid-2 {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 25px 20px;
}

@media only screen and (max-width: 800px) {
  .insights .grid-2 {
    grid-template-columns: 1fr;
  }
}

.insights .grid-2 img {
  width: 60%;
}

@media only screen and (max-width: 800px) {
  .insights .grid-2 img {
    width: 90%;
  }
}

.statistics {
  color: #fff;
  padding-top: 70px;
  padding-bottom: 70px;
}

.statistics .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
}

@media only screen and (max-width: 600px) {
  .statistics .grid {
    gap: 50px;
    grid-template-columns: 1fr;
  }
}

.statistics .grid .banner img {
  width: 100%;
}

.statistics .grid .stats-container {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto 0;
}

.statistics .grid .stats-container .stats {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  margin: auto;
}

@media only screen and (max-width: 600px) {
  .statistics .grid .stats-container .stats {
    grid-template-columns: 1fr 1fr;
  }
}

.statistics .grid .stats-container .stats .stat {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.statistics .grid .stats-container .stats .stat p {
  letter-spacing: 1px;
  opacity: 0.4;
  margin-bottom: 10px;
  font-weight: 300;
}

.statistics .grid .stats-container .stats .stat h3 {
  font-weight: 500;
  font-size: 1.3rem;
}

.statistics .grid .stats-container button {
  margin: auto;
  margin-top: 50px;
  padding-left: 30px;
  padding-right: 30px;
}

.yellow-white-text {
  background-image: linear-gradient(to bottom, var(--hashpro), white);
  -webkit-background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
}

.highlights {
  padding-top: 40px;
  padding-bottom: 70px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .highlights {
    padding: 0;
    padding-top: 30px;
    padding-bottom: 2px;
  }
}

.highlights .grid {
  background-color: #131313;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

@media only screen and (max-width: 600px) {
  .highlights .grid {
    grid-template-columns: 50% 50%;
  }
}

.highlights .grid .point {
  padding: 50px 30px;
  text-align: center;
  box-shadow: inset 0px 0px 5px rgba(248, 208, 13, 0.7803921569);
}

.highlights .grid .point p {
  font-size: 2rem;
  font-weight: 800;
}

@media only screen and (max-width: 767px) {
  .highlights .grid .point p {
    font-size: 1.3rem;
  }
}

.highlights .grid .point h3 {
  margin-top: 10px !important;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #a6a6a6;
}

@media only screen and (min-width: 1000px) {
  .highlights .grid .point h3 {
    max-width: 80%;
    margin: auto;
  }
}

.highlights .grid .point h4 {
  margin-top: 5px;
  font-weight: 500;
}

.tools {
  padding-top: 70px;
  padding-bottom: 70px;
  background-color: #080808;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .tools {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

.tools .heading {
  margin-bottom: 30px;
}

.tools .grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 50px;
}

@media only screen and (max-width: 1000px) {
  .tools .grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 500px) {
  .tools .grid {
    grid-template-columns: 33.3% 33.3% 33.3%;
    gap: 20px 0px;
  }
}

.tools .grid .tool {
  width: 80%;
  margin: 0 auto;
}

.tools .grid .tool:nth-child(12) img {
  scale: 0.6;
  background: #fff;
  border-radius: 50%;
}

.tools .grid .tool .center-img {
  border-radius: 20px 10px;
  box-shadow: 2px 2px var(--hashpro);
  border: 1px solid rgba(248, 208, 13, 0.3019607843);
}

@media only screen and (max-width: 500px) {
  .tools .grid .tool .center-img {
    width: 80%;
    margin: auto;
  }
}

.tools .grid .tool img {
  width: 80%;
  height: 80%;
  margin: auto;
  display: block;
  padding: 15px;
}

.tools .grid .tool p {
  color: #a9a9a9;
  margin-top: 10px;
  font-size: 0.8rem;
  text-align: center;
}

.usp-container {
  background-image: linear-gradient(
    to top,
    transparent,
    transparent,
    transparent
  );
  color: #fff;
  padding-top: 20px;
  padding-bottom: 0px;

  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr 0.1fr 1fr 0.1fr;
  -moz-column-gap: 30px;
  column-gap: 30px;
  row-gap: 30px;
}

@media only screen and (max-width: 600px) {
  .usp-container {
    padding-top: 0px;
    padding-bottom: 0px;
  }
}

@media only screen and (max-width: 1000px) {
  .usp-container {
    grid-template-columns: 1fr 1fr;
    gap: 50px;
  }
}

.usp-container .divider {
  width: 2px;
  background-color: #343434;
  height: 60%;
  margin: auto;
}

@media (min-width: 601px) and (max-width: 999px) {
  .usp-container .divider {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .usp-container .divider {
    display: block;
    width: 50%;
    margin: auto;
    height: 2px;
    background-color: rgba(0, 0, 0, 0);
  }
}

@media only screen and (max-width: 600px) {
  .usp-container {
    grid-template-columns: 1fr;
    gap: 30px;
  }
}

.usp-container .usp {
  text-align: center;
}

.usp-container .usp h3 {
  text-transform: uppercase;
  font-weight: 400;
  font-size: 1rem;
  letter-spacing: 2px;
  color: #cacaca;
}

.usp-container .usp h2 {
  margin: 15px 0;
  font-size: 1.5rem;
  font-weight: 500;
  color: #fff;
  text-shadow: 0px 0px 6px rgba(248, 208, 13, 0.3607843137);
}

.usp-container .usp h4 {
  font-size: 0.8rem;
  font-weight: 400;
  margin: auto;
  line-height: 1.4;
  color: #a5a5a5;
}

@-webkit-keyframes btn-glow-anim {
  from {
    box-shadow: 0px 0px 5px #f8d00d;
  }

  to {
    box-shadow: 0px 0px 15px #f8d00d;
  }
}

@keyframes btn-glow-anim {
  from {
    box-shadow: 0px 0px 5px #f8d00d;
  }

  to {
    box-shadow: 0px 0px 15px #f8d00d;
  }
}

.glow-anim {
  -webkit-animation: 1.5s ease-in-out alternate infinite btn-glow-anim;
  animation: 1.5s ease-in-out alternate infinite btn-glow-anim;
}

.gradient-border {
  --borderWidth: 2px;
  background: #1d1f20;
  position: relative;
  border-radius: var(--borderWidth);
}

.gradient-border:after {
  content: "";
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height: calc(100% + var(--borderWidth) * 2);
  width: calc(100% + var(--borderWidth) * 2);
  background: linear-gradient(60deg, #f8d00d, white, black);
  border-radius: 20px;
  z-index: -1;
  -webkit-animation: animatedgradient 3s linear infinite;
  animation: animatedgradient 3s linear infinite;
  background-size: 300% 300%;
}

@-webkit-keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes animatedgradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.growth {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  color: #fff;
  background-image: linear-gradient(to bottom, #0f0d02, transparent 80%);
  position: relative;
}

@media only screen and (max-width: 600px) {
  .growth {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

.growth .ribbon {
  position: absolute;
  top: 31px;
  left: -67px;
  rotate: 350deg;
  padding: 2px 0;
  opacity: 0.5;
  z-index: -1;
  background: #000;
  display: none;
}

.growth .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media only screen and (max-width: 600px) {
  .growth .grid {
    grid-template-columns: 1fr;
  }
}

.growth .grid .info h2 {
  font-size: 2rem;
  text-align: left;
}

.growth .grid .info p {
  margin-top: 20px;
  font-weight: 300;
}

@-webkit-keyframes text-gradient-anim {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

@keyframes text-gradient-anim {
  0% {
    background-position: 0% 50%;
  }

  100% {
    background-position: 100% 50%;
  }
}

.yellow-gradient {
  background: linear-gradient(
    to right,
    #c2c2c2 20%,
    #f8d00d 30%,
    #c2c2c2 70%,
    #f8d00d 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
  background-size: 500% auto;
  -webkit-animation: text-gradient-anim 5s ease-in-out infinite alternate;
  animation: text-gradient-anim 5s ease-in-out infinite alternate;
}

.black-gradient {
  background: linear-gradient(
    to right,
    black 20%,
    white 30%,
    black 70%,
    white 80%
  );
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: rgba(0, 0, 0, 0);
  text-fill-color: rgba(0, 0, 0, 0);
  background-size: 500% auto;
  -webkit-animation: text-gradient-anim 5s ease-in-out infinite alternate;
  animation: text-gradient-anim 5s ease-in-out infinite alternate;
}

.learn {
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f8d00d;
  color: #000;
}

.learn h2 {
  font-size: 2rem;
}

.learn p {
  font-size: 1rem;
}

.learn .cards {
  color: #fff;
  margin-top: 20px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr 1fr 1fr;
}

@media only screen and (max-width: 600px) {
  .learn .cards {
    grid-template-columns: 1fr;
  }
}

.learn .cards .card {
  padding: 30px;
  border: 1px solid #1f1f1f;
  border-radius: 10px;
  background: #131313;
  box-shadow: 2px 2px #fff;
}

.learn .cards .card h3 {
  font-weight: 500;
}

.learn .cards .card .points {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 15px;
}

.learn .cards .card .points .point {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.learn .cards .card .points .point p {
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 300;
  color: #ccc;
}

.learn .cards .card .points .point img {
  height: 12px;
  width: 12px;
  margin-top: 2px;
  filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%)
    hue-rotate(348deg) brightness(98%) contrast(86%);
}

.dmx-page .who {
  /* background-color: #e8e8e8 !important; */
  background-color: #e8e8e8 !important;

  color: #000;
  padding-top: 70px;
  padding-bottom: 90px;
}

@media only screen and (max-width: 600px) {
  .who {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

.who h2 {
  font-size: 2rem;
  color: #000;
}

.who .grid {
  margin-top: 45px;
  display: grid;
  grid-template-columns: 60% 40%;
  gap: 15px;
}

@media only screen and (max-width: 900px) {
  .who .grid {
    grid-template-columns: 100%;
    margin-top: 30px;
  }
}

.who .grid .info .cards {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

@media only screen and (max-width: 600px) {
  .who h2 {
    margin-bottom: 1rem !important;
  }

  .who .grid .info .cards {
    grid-template-columns: 100%;
    row-gap: 10px;
    margin-bottom: 2rem;
  }
}

.who .grid .info .cards .card {
  opacity: 0;
  padding: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 5px;
}

.who .grid .info .cards .card h3 {
  font-weight: 600;
  /* border-bottom: 2px solid #f8d00d; */
  width: -moz-fit-content;
  width: fit-content;
  color: #000;
  background-color: var(--hashpro);
  font-size: 1.3rem;
  padding: 3px 10px;
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: 2px 2px black;
}

.who .grid .info .cards .card p {
  font-size: 0.9rem;
  color: #1b1b1b;
  line-height: 1.5;
  font-weight: 500;
  display: none;
}

.who .grid .banner {
  background-color: #fff;
  width: 100%;
  border-radius: 15px;
}

.who .grid .banner img {
  width: 100%;
  margin: auto;
  -o-object-fit: cover;
  object-fit: cover;
  display: block;
  height: 100%;
  border-radius: 10px;
  aspect-ratio: 16/9;
}

.process {
  padding-top: 70px;
  padding-bottom: 90px;
  color: #000;
  background-color: #fff;
}

@media only screen and (max-width: 600px) {
  .process {
    padding-top: 40px;
    padding-bottom: 60px;
  }
}

.process h2 {
  color: #000;
}

.dmx-page .process .steps {
  margin-top: 45px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: relative;
  gap: 20px;
  background-color: transparent;
  padding: 0;
}

@media only screen and (max-width: 767px) {
  .dmx-page .process .steps {
    grid-template-columns: 1fr;
  }
}

.process .steps .line {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  width: 100%;
  height: 3px;
  background-color: #050505;
}

@media only screen and (max-width: 767px) {
  .process .steps .line {
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    left: 50%;
    height: 100%;
    width: 4px;
  }
}

.process .steps .card {
  border: 1px solid rgba(255, 255, 255, 0.2196078431);
  border-radius: 15px;
  padding: 20px;
  z-index: 2;
  background-color: #050505;
  display: flex;
  flex-direction: column;
  gap: 15px;
  color: #fff;
}

.process .steps .card span {
  background: #f8d00d;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #000;
  font-size: 0.7rem;
  padding: 4px 12px;
  font-weight: 600;
  border-radius: 20px;
}

.process .steps .card h3 {
  font-size: 1.17rem;
  font-weight: 700;
}

.process .steps .card p {
  font-size: 0.9rem;
  color: #ccc;
  line-height: 1.5;
}

.process .grid {
  /* margin-top: 20px; */
  gap: 15px;
  /* grid-template-columns: 1fr 1fr */
}

@media only screen and (max-width: 450px) {
  .process .grid {
    grid-template-columns: 1fr;
  }
}

.process .grid p {
  margin-top: 12px;
  font-size: 0.9rem;
  color: #ccc;
  line-height: 1.5;
}

.process .grid .placeholder {
  background-color: #000;
  width: 100%;
  height: 100%;
  border-radius: 15px;
}

.process .grid .second {
  background-color: #050505;
  border: 1px solid rgba(232, 189, 31, 0.2117647059);
  border-radius: 15px;
  padding: 20px;
  margin-top: 1rem;
}

@media only screen and (min-width: 600px) {
  .process .grid .second {
    padding: 30px;
  }
}

.process .grid .second h3 {
  color: var(--hashpro);
  font-weight: 700;
  font-size: 1.5rem;
}

.process .grid .second .points {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  -moz-column-gap: 10px;
  column-gap: 10px;
  row-gap: 15px;
}

.process .grid .second .points .point {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.process .grid .second .points .point p {
  font-size: 0.9rem;
  line-height: 1.5;
  font-weight: 300;
  color: #fff;
}

.process .grid .second .points .point img {
  height: 12px;
  width: 12px;
  margin-top: 2px;
  filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%)
    hue-rotate(348deg) brightness(98%) contrast(86%);
}

.crafters {
  position: relative;
  padding-top: 50px;
  padding-bottom: 70px;
  background-color: #fff;
  overflow: hidden;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .crafters {
    padding-top: 40px;
    padding-bottom: 70px;
  }
}

.crafters .tr {
  background-size: 41px;
  width: 35%;
  height: 50%;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

.crafters .splide {
  margin-top: 50px;
}

.crafters .heading {
  color: #000;
  margin-bottom: 30px;
}

.crafters .slide-btn-container {
  display: none;
  -moz-column-gap: 15px;
  column-gap: 15px;
  position: absolute;
  top: 50px;
  right: 50px;
}

.crafters .slide-btn-container .btn-container {
  width: 60px;
  height: 60px;
  background-color: #8b8b8b;
  border-radius: 50%;
  position: relative;
  transition: 0.1s;
}

.crafters .slide-btn-container .btn-container:hover {
  cursor: pointer;
  filter: invert(100%);
}

.crafters .slide-btn-container img.left {
  transform: translate(-50%, -50%) rotate(180deg);
}

.crafters .slide-btn-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
}

.heading-img {
  width: 20px !important;
  scale: 2;
}

.crafters .heading img {
  margin-left: 8px;
}

.crafters .heading {
  text-align: left !important;
}

.crafters .subheading {
  margin-top: 6px;
  text-align: left !important;
}

.crafters .subheading {
  margin-bottom: 50px;
  text-align: left;
  font-size: 1rem !important;
}

.crafters .splide__slide {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  margin: 0px 20px !important;
  border: none !important;
}

.splide__arrow {
  background-color: #fff !important;
  opacity: 1 !important;
}

.crafters .splide {
  position: static !important;
}

.crafters .splide__pagination {
  display: none;
}

.crafters .splide__track {
  overflow: visible !important;
  position: static !important;
}

.faculty .faculty-img {
  border-radius: 6px;
  aspect-ratio: 1/1;
  width: 250px;
  height: 250px;
  box-shadow: 2px 2px #000;
  border: 1px solid #000;
}

.faculty .text {
  margin-top: 5px;
  position: relative;
  margin-left: 5px;
  display: block;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}

.faculty .text p {
  line-height: 28px;
}

.dmx-page .faculty .text .name {
  font-weight: 500;
  font-size: 1rem;
  margin-top: 10px;
  color: black;
  /* filter: invert(1) */
}

.dmx-page .faculty .text .desc {
  font-weight: 400;
  font-size: 0.9rem;
  line-height: 20px;
  color: rgb(0 0 0 / 71%);
}

.text .social-media {
  position: absolute;
  top: 105%;
  left: 0;
}

.dmx-page .faculty .text .social-media img {
  width: 18px;
  height: 18px;
  filter: invert(1);
  opacity: 0.7;
}

.name {
  font-size: 0.9rem;
}

.crafters a {
  height: 100%;
  display: inline-block;
  transition: 0.2s ease-in-out;
}

.crafters a:hover {
  cursor: pointer;
  transform: translateY(-5px);
  z-index: 999;
}

@-webkit-keyframes phoneRinging {
  0% {
    transform: rotateZ(-5deg) rotateY(180deg) translateY(2px) translateX(1px);
  }

  50% {
    transform: rotateZ(5deg) rotateY(180deg) translateY(2px) translateX(1px);
  }

  100% {
    transform: rotateZ(-5deg) rotateY(180deg) translateY(2px) translateX(1px);
  }
}

@keyframes phoneRinging {
  0% {
    transform: rotateZ(-5deg) rotateY(180deg) translateY(2px) translateX(1px);
  }

  50% {
    transform: rotateZ(5deg) rotateY(180deg) translateY(2px) translateX(1px);
  }

  100% {
    transform: rotateZ(-5deg) rotateY(180deg) translateY(2px) translateX(1px);
  }
}

@-webkit-keyframes move-navbar-marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-528px);
  }
}

@keyframes move-navbar-marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-528px);
  }
}

@keyframes move-navbar-marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-390px);
  }
}

.program-highlights {
  color: #000;
  padding-top: 60px;
  padding-bottom: 90px;
  background-color: #1a1a1a;
}

@media only screen and (max-width: 600px) {
  .program-highlights {
    padding-top: 30px;
    padding-bottom: 40px;
  }
}

.program-highlights .heading {
  top: 72px;
  z-index: 3;
  padding: 20px 0;
  margin-bottom: 40px;
  width: calc(102% + 1px);
}

.program-highlights .gridd {
  grid-template-columns: 35% 61%;
  -moz-column-gap: 40px;
  column-gap: 40px;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .program-highlights .gridd {
    grid-template-columns: 1fr;
    row-gap: 20px;
  }
}

.program-highlights .gridd .line {
  position: absolute;
  height: 100%;
  width: 2px;
  left: -20px;
  background-color: #000;
}

@media only screen and (max-width: 600px) {
  .program-highlights .gridd .line {
    transform: translate(0%);
    left: -20px;
  }
}

.program-highlights .gridd .info {
  top: 200px;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: #fff;
  padding: 25px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  border: 1px solid #000;
}

@media only screen and (min-width: 600px) {
  .program-highlights .gridd .info {
    position: -webkit-sticky;
    position: sticky;
  }
}

.program-highlights .gridd .info h6 {
  font-size: 1rem;
  font-weight: 600;
}

.program-highlights .gridd .info .divider {
  width: 100%;
  height: 2px;
  background-image: linear-gradient(
    to right,
    transparent,
    #f8d00d,
    transparent
  );
}

.program-highlights .gridd .info .points {
  display: flex;
  flex-direction: column;
  row-gap: 15px;
}

.program-highlights .gridd .info .points .point {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.program-highlights .gridd .info .points .point p {
  font-size: 0.9rem;
  font-weight: 500;
}

.program-highlights .gridd .info .points .point img {
  width: 13px;
  height: 17px;
  transform: translateY(-2px);
}

.program-highlights .gridd .slider {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px 30px;
}

@media only screen and (max-width: 600px) {
  .program-highlights .gridd .slider {
    grid-template-columns: 1fr;
  }
}

.program-highlights .gridd .slider .section {
  margin-bottom: 20px;
}

.program-highlights .gridd .slider .section .question-container {
  border: none;
  position: relative;
}

.program-highlights .gridd .slider .section .questions {
  background-color: #fff !important;
  color: #000 !important;
}

.program-highlights .gridd .slider .section .questions .faq-icon {
  filter: invert(0) !important;
}

.program-highlights .gridd .slider .section .questions .question {
  font-weight: 600 !important;
  font-size: 1.05rem !important;
}

.program-highlights .gridd .slider .section .questions .name {
  padding: 8px 15px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  background-color: var(--hashpro);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: absolute;
  top: 0;
  color: #000;
  transform: translateY(-100%) translateX(0px);
  font-size: 0.75rem;
}

.program-highlights .gridd .slider .section .questions .answer p {
  color: #000;
  font-weight: 500;
}

.program-highlights .gridd .slider .section .questions .answer img {
  filter: invert(0) !important;
}

.questions {
  background-color: #121212;
  color: #fff;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  border: 1px solid #7a7a7a;
}

.questions .question-container {
  transition: 0.5s;
  border: 1px solid rgba(255, 255, 255, 0.39);
}

.questions .question-container .question {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  font-weight: 400;
  font-size: 1rem;
}

.questions .question-container .question img {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: 0.2s;
  filter: invert(1);
  width: 15px;
  height: 15px;
}

.questions .question-container .answer {
  padding: 20px;
  padding-top: 0;
  overflow: hidden;
  transition: 0.2s cubic-bezier(0.91, 0.03, 0.23, 1.01);
  transform-origin: top;
  will-change: max-height;
  display: grid !important;
  gap: 20px;
  grid-template-columns: 1fr;
  font-size: 0.9rem;
  color: #000;
}

@media only screen and (max-width: 900px) {
  .questions .question-container .answer {
    grid-template-columns: 1fr;
  }
}

.questions .question-container .answer .point {
  display: flex;
  -moz-column-gap: 10px;
  column-gap: 10px;
}

.questions .question-container .answer .point img {
  margin-top: 2px;
  width: 13px;
  height: 13px;
  filter: invert(1);
  opacity: 0.8;
}

.questions .question-container .answer .point p {
  font-size: 0.9rem;
  font-weight: 200;
  color: #fff;
}

.questions .question-container:hover {
  cursor: pointer;
}

.questions .question-container.collapsed .answer {
  max-height: 0px;
  padding-bottom: 0;
}

.questions .question-container.show .answer {
  max-height: 500vh;
}

@-webkit-keyframes moveBox {
  0%,
  100% {
    top: 0;
    left: 0;
  }

  25% {
    top: 100%;
    left: 0;
  }

  50% {
    top: 100%;
    left: 100%;
  }

  75% {
    top: 0;
    left: 100%;
  }
}

@keyframes moveBox {
  0%,
  100% {
    top: 0;
    left: 0;
  }

  25% {
    top: 100%;
    left: 0;
  }

  50% {
    top: 100%;
    left: 100%;
  }

  75% {
    top: 0;
    left: 100%;
  }
}

body {
  position: relative;
}

.x-glow-container {
  background: rgba(0, 0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100dvw;
}

.x-glow-container .container {
  width: 100%;
  height: 100%;
  position: relative;
}

.x-glow {
  position: absolute;
  box-shadow: inset 0px 0px 15px #f8d00d;
  -webkit-animation: moveBox 2s linear infinite;
  animation: moveBox 2s linear infinite;
  z-index: 9999;
}

lite-youtube {
  margin: auto;
  border-radius: 20px;
}

lite-youtube button::before {
  background-image: none !important;
}

@-webkit-keyframes stroke-anim {
}

@keyframes stroke-anim {
}

@-webkit-keyframes elementor-headline-dash {
  0% {
    stroke-dasharray: 0 1500;
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

@keyframes elementor-headline-dash {
  0% {
    stroke-dasharray: 0 1500;
    opacity: 1;
  }

  to {
    opacity: 1;
  }
}

.features {
  background-color: #131313;
  color: #fff;
  margin: 0;
  padding-top: 50px;
  padding-bottom: 70px;
}

.features .heading {
  margin-bottom: 30px;
}

@media screen and (max-width: 767px) {
  .heading {
    text-align: center;
  }
}

.features-container {
  margin-top: 30px;
}

.features-container .right {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  overflow: auto;
}

.features .features-container {
  display: grid;
  grid-template-columns: 30% 70%;
  -moz-column-gap: 50px;
  column-gap: 50px;
}

.features-container .course-card {
  color: #fff;
  position: relative;
  font-weight: 500;
  padding: 25px 0;
  transition: 0.3s;
  font-size: 0.9rem;
}

.features-container .course-card p {
  opacity: 0.3;
  transition: 0.3s;
}

.features-container .course-card:hover {
  cursor: pointer;
}

.features-container .course-card.active p {
  opacity: 1;
  scale: 1.03;
}

.features .points-container {
  margin-top: 20px;
}

.features .points {
  display: flex;
  margin: 10px 0;
}

.features .points p {
  margin: auto 7px;
  font-size: 0.9rem;
  opacity: 0.9;
  line-height: 1.3;
  font-weight: 200;
}

.features .points img {
  filter: invert(1);
  height: 18px;
  width: 12px;
  transform: translateY(-1px);
}

.features #course-2 {
  display: none;
}

.features #course-3 {
  display: none;
}

.features #course-4 {
  display: none;
}

.features #course-5 {
  display: none;
}

.features #course-6 {
  display: none;
}

.features-container-mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .features-container-mobile {
    display: block;
  }

  .features-container .left {
    display: none;
  }

  .features .features-container .right {
    margin: 0 3%;
    margin-right: 1%;
  }

  .features .features-container .right h3 {
    margin-top: 0;
    font-weight: 500;
  }

  .features .features-container {
    grid-template-columns: 1fr;
  }
}

.features-container-mobile .course-selector {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  flex-wrap: wrap;
}

.features-container-mobile .course-selector p {
  border-radius: 15px;
}

.features-container-mobile .course-selector .course-card-mobile {
  background-color: var(--hashpro);
  color: #000;
  padding: 5px 10px;
  border-radius: 15px;
  font-size: 15px;
  font-weight: 500;
  opacity: 0.5;
  transition: 0.3s;
  margin: 8px 5px;
}

.features-container-mobile .course-selector p:hover {
  cursor: pointer;
}

.features-container-mobile .course-selector p.active {
  opacity: 1;
  scale: 1.08;
}

.features-container .course-card {
  border-top: 1px solid rgba(255, 255, 255, 0.168627451);
}

.features-container .course-card:nth-child(1) {
  border: none;
}

.features .course-card-answer {
  grid-template-columns: 1fr 1fr;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  gap: 20px;
}

@media only screen and (max-width: 1000px) {
  .features .course-card-answer {
    grid-template-columns: 1fr;
  }
}

.features .course-card-answer .subtext {
  margin-top: 10px;
  font-size: 0.9rem;
  opacity: 0.9;
  line-height: 1.6;
  font-weight: 200;
}

.features .course-card-answer h3 {
  font-weight: 500;
}

.features .banner {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

.features .banner img {
  border-radius: 10px;
  display: block;
  margin: auto;
}

.outcomes {
  padding-top: 70px;
  padding-bottom: 100px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .outcomes {
    padding-top: 50px;
    padding-bottom: 70px;
  }
}

.outcomes .subheading {
  text-align: center;
}

.outcomes .grid {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 42px 20px;
}

@media only screen and (max-width: 1000px) {
  .outcomes .grid {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 600px) {
  .outcomes .grid {
    grid-template-columns: 1fr;
    gap: 30px 20px;
  }
}

.outcomes .grid .card {
  font-weight: 600;

  color: #000;
  background-color: var(--hashpro);
  font-size: 1.3rem;
  padding: 5px 10px;
  border-radius: 10px;
  border: 2px solid black;
  box-shadow: 3px 3px white;
  margin: auto;
  text-align: center;

  width: 100%;
  height: 100%;
}

.outcomes .grid .card .divider {
  margin: auto;
  height: 1px;
  margin: 12px 0;
  background-color: #4e4e4e;
  width: 100%;
  display: none;
}

.outcomes .grid .card h3 {
  font-size: 1.17rem;
  font-weight: 700;
}

.outcomes .grid .card p {
  font-size: 0.9rem;
  font-weight: 300;
  line-height: 1.5;
  color: #ababab;
  display: none;
}

.outcomes .grid .card p.hashtags {
  color: var(--hashpro);
}

.learn-quickly {
  padding-top: 70px;
  padding-bottom: 70px;
  color: #fff;
}

@media only screen and (max-width: 600px) {
  .learn-quickly {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}

@media only screen and (min-width: 650px) {
  .learn-quickly {
    padding: 70px 15%;
  }
}

.learn-quickly h4 {
  text-align: left;
}

.learn-quickly h3 {
  text-align: center;
  font-size: 32px;
  margin-bottom: 50px;
}

.learn-quickly .line-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 2px;
  background-color: rgba(255, 255, 255, 0.404);
  height: 100%;
  z-index: 2;
}

@media screen and (max-width: 650px) {
  .learn-quickly .line-container {
    left: 0;
    transform: translateX(-15px);
  }
}

.learn-quickly .ball-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 10px;
  height: 10px;
  background-color: var(--hashpro);
  border-radius: 50%;
  will-change: top;
  box-shadow: 1px 1px 10px 8px var(--hashpro);
}

@media screen and (max-width: 650px) {
  .learn-quickly .ball-container {
    left: 0;
    transform: translateX(-20px);
  }
}

.learn-quickly .blocks-container {
  margin-top: 60px;
  position: relative;
}

.learn-quickly .blocks-container .block {
  display: grid;
  grid-template-columns: 1fr 1fr;
  -moz-column-gap: 10%;
  column-gap: 10%;
  border-radius: 10px;
  padding: 20px;
  color: #fff;
}

@media screen and (max-width: 650px) {
  .learn-quickly .blocks-container .block {
    padding: 0;
    margin-bottom: 30px;
  }
}

.learn-quickly .blocks-container .block img {
  width: 50%;
  scale: 1.05;
  margin: 0 auto;
}

.learn-quickly .blocks-container .block .text {
  margin: auto;
  padding: 1rem;
  /* background-color: var(--hashpro); */
  /* color: black; */
  border-radius: 1rem;
}

.learn-quickly .blocks-container .block .text h4,
.learn-quickly .blocks-container .block .text p {
  line-height: 20px;
}

.learn-quickly .blocks-container .block .text h4 {
  line-height: 1.2;
  font-size: 1.1rem;
  color: var(--hashpro);
  font-weight: 900;
  text-transform: uppercase;
  color: black;
  padding: 3px 7px;
  background-color: white;
  box-shadow: 3px 3px var(--hashpro);
  border-radius: 0.3rem;
  width: fit-content;
}

@media screen and (max-width: 650px) {
  .learn-quickly .blocks-container .block .text h4 {
    font-size: 16px;
    text-align: left;
  }
}

.learn-quickly .blocks-container .block .text p {
  margin-top: 10px;
  /* color: black; */
  font-size: 0.9rem;
  /* box-shadow: 2px 2px var(--hashpro); */
  line-height: 1.6;
  font-weight: 500;
  margin-top: 1rem;
}

@media screen and (max-width: 650px) {
  .learn-quickly .blocks-container .block .text p {
    font-size: 14px;
    line-height: 1.8;
    margin-bottom: 30px;
    text-align: left;
  }
}

@media screen and (max-width: 650px) {
  .learn-quickly .blocks-container .block {
    transform: translateX(20px);
    grid-template-columns: none;
    grid-template-areas: "img" "p";
  }

  .learn-quickly .blocks-container .block img {
    grid-area: img;
  }

  .learn-quickly .blocks-container .block .text {
    margin-top: 10px;
    text-align: center;
    grid-area: p;
    padding: 0;
  }
}

.certificate {
  padding-top: 70px;
  padding-bottom: 70px;
  background-image: linear-gradient(to bottom, #1a1a1a, black);
  border-bottom: 2px solid #f8d00d;
}

@media only screen and (max-width: 600px) {
  .certificate {
    padding-top: 30px;
    padding-bottom: 50px;
  }
}

.certificate .grid {
  margin-top: 40px;
  display: grid;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-template-columns: 1fr 1fr;
  gap: 30px 80px;
}

@media only screen and (max-width: 900px) {
  .certificate .grid {
    grid-template-columns: 1fr;
  }
}

.certificate .grid img.certi {
  display: block;
  width: 100%;
  border-radius: 1rem;
}

@media only screen and (min-width: 900px) {
  .certificate .grid img.certi {
    width: 70%;
    margin-left: auto;
  }
}

.certificate .grid .additional {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
}

@media only screen and (min-width: 900px) {
  .certificate .grid .additional {
    margin-left: 0;
  }
}

.certificate .grid .additional div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

.certificate .grid .additional h3 {
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 16px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 15px;
  font-style: italic;
  text-align: center;
  font-size: 1.2rem;
  font-weight: 400;
  margin-bottom: 16px;
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 15px;
  font-style: italic;
}

.pricing {
  overflow-x: hidden;
  padding-top: 70px;
  padding-bottom: 70px;
  margin: 0 auto;
  background-image: linear-gradient(to bottom, #1a1a1a, #040404);
}

@media only screen and (max-width: 1200px) {
  .pricing {
    max-width: 100%;
  }
}

@media only screen and (max-width: 600px) {
  .pricing {
    padding-top: 40px;
    padding-bottom: 40px;
    padding: 40px 0%;
  }
}

.pricing .container {
  max-width: 60% !important;
  margin: 0 auto;
  border-radius: 2rem;
  padding: 3rem;
  color: #fff;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-image: linear-gradient(
    198deg,
    rgba(248, 208, 13, 0.0588235294),
    #0a0a0a 80%
  );
  border: 3px solid rgba(248, 208, 13, 0.3019607843);
  box-shadow: 2px 2px rgba(255, 209, 48, 0);
  box-shadow: 0px 0px 15px rgba(248, 208, 13, 0.3019607843);
}

@media only screen and (max-width: 1000px) {
  .pricing .container {
    max-width: 100% !important;
    padding: 2.5rem;
    width: 95%;
  }
}

.pricing .container .info {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

@media only screen and (min-width: 800px) {
  .pricing .container .info {
    padding: 2rem 5rem;
    scale: 1.1;
  }
}

.pricing .container .info .chip {
  font-size: 0.9rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 2rem;
  color: #fff;
  font-weight: 300;
}

.pricing .container .info h3 {
  margin-top: 20px;
  font-size: 1.9rem;
}

.pricing .container .info h4 {
  margin-top: 12px;
  font-size: 1.1rem;
  font-weight: 400;
  margin-bottom: 8px;
  white-space: nowrap;
}

.pricing .container .info h4 span.old {
  text-decoration: line-through;
  font-size: 0.9rem;
  color: #bcbcbc;
}

.pricing .container .info h4 span.new {
  color: var(--hashpro);
  text-shadow: 0px 0px 3px var(--hashpro);
  font-size: 1.2rem;
}

.pricing .container .info h5 {
  font-weight: 200;
  margin: 7px 0;
  font-size: 0.76rem;
  color: #a0a0a0;
}

.pricing .container .info .points {
  margin-top: 35px;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.pricing .container .info .points .point {
  display: flex;
  gap: 10px;
  flex-shrink: 1 0 auto;
}

.pricing .container .info .points .point img {
  width: 15px;
  height: 15px;
  transform: translateY(2px);
}

.pricing .container .info .points .point p {
  font-size: 0.9rem;
  font-weight: 300;
}

.pricing .container .info .price {
  margin-top: 35px;
  font-size: 0.75rem;
}

.pricing .container .info .price span {
  font-size: 1.45rem;
  font-weight: 700;
}

.pricing .container .info .star-info {
  margin-top: 29px;
  color: #a1a1a1;
  font-size: 0.75rem;
  line-height: 1.5;
}

.faqs {
  padding-top: 40px;
  padding-bottom: 70px;
  border-top: 1px solid #a6a6a6;
}

.faqs .heading {
  margin-bottom: 30px;
}

.faqs .question-container .question {
  font-weight: 400;
}

.faqs .answer p {
  color: #fff;
  font-weight: 200;
  font-size: 0.9rem;
  line-height: 1.5;
}

.faq-icon {
  filter: invert(1);
  width: 1.5rem;
  height: 1.5rem;
  color: rgba(152, 162, 179, 0);
  border: 2px solid rgba(152, 162, 179, 0);
  border-radius: 50%;
  justify-content: center;
  align-self: flex-start;
  align-items: center;
  display: flex;
  position: relative;
}

.faq-hor {
  width: 1rem;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  position: absolute;
}

.faq-ver {
  width: 2px;
  height: 1rem;
  background-color: #000;
  border-radius: 1px;
  position: absolute;
  transition: 0.2s;
}

#clockdiv {
  font-family: sans-serif;
  color: #fff;
  display: flex;
  font-weight: 100;
  text-align: center;
  font-size: 30px;
  gap: 10px;
}

#clockdiv > div {
  border-radius: 3px;
  display: inline-block;
}

#clockdiv div > span {
  padding: 11px;
  border: 1px solid #000;
  border-radius: 3px;
  background: var(--hashpro);
  display: inline-block;
  font-weight: 700;
  font-size: 1.17rem;
  color: #000;
  width: 3rem;
  aspect-ratio: 1/1;
}

@media only screen and (max-width: 400px) {
  #clockdiv div > span {
    font-size: 0.9rem;
  }
}

.smalltext {
  padding-top: 5px;
  font-size: 0.8rem;
  font-weight: 600;
  color: #000;
}

.dmx-page .cta {
  background-color: #151515;
  margin: 2% 10%;
  padding: 3rem 2rem;
  text-align: center;
  border-radius: 3rem;
  color: #fff;
  box-shadow: inset 0px 0px 15px rgba(255, 255, 255, 0.449);
}

@media only screen and (max-width: 600px) {
  .dmx-page .cta {
    padding: 2.5rem 1rem;
  }
}

.dmx-page .cta button {
  margin: 0 auto;
  margin-top: 20px;
}

#otherInputContainer {
  display: none;
}

/* #floating-doubt-banner{
    display: none;
} */
/*# sourceMappingURL=dmx.min.css.map */

#bootcampoview {
}

.highlights .grid {
  background-color: #131313;
  margin-top: 30px;
  display: grid;
  grid-template-columns: 50% 50% !important;
}

.removedtiming {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
}

.removedtiming button,
#grapyourseatbtn {
  background-color: #151515;
  color: #fff;
  border: none;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
}

.removedtiming button:hover {
  background-color: #f8d00d;
  color: #000;
}

.program-highlights .heading {
  top: 72px;
  z-index: 3;
  padding: 0px 0;
  margin-bottom: 10px;
  width: calc(102% + 1px);
}
.program-highlights .subheading {
  text-align: center;
  margin: 10px 0;
  color: #f8d00d;
}

.program-highlights .gridd {
  margin-top: 72px;
}

#grapyourseatbtn {
  background-color: #f8d00d;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  margin: 0 auto;
  margin-top: 20px;
}

#dmxherotimeline {
  all: unset;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  margin-bottom: 0px !important;
}

#dmxherotimeline p {
  font-size: 1.1rem;
  font-weight: 700;
}

.mrightspan {
  height: 20px;
  display: block;
  width: 3px;
  background-color: #f8d00d;
}
.tools .grid .tool img {
  width: 100%;
  height: 100%;
  margin: auto;
  display: block;
  padding: 15px;
}
.tools .grid .tool {
  border-radius: 0px !important;
}

.tools .grid .tool .center-img {
  width: 100%;
  margin: auto;
}

.tools .grid .tool .center-img {
  border-radius: 10px 10px;
  box-shadow: 2px 2px var(--hashpro);
  border: 1px solid rgba(248, 208, 13, 0.3019607843);
}

.bgofwhychoose {
  background-color: #1a1a1a;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 60px;
}

.bgofwhychoose img {
  border-radius: 5px !important;
  box-shadow: 2px 2px 1px #151515, -2px -2px 1px #151515;
}

#whychooseheading {
  /* color: #000; */
}

.twocontainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-top: 20px;
}
.twocontainer > div {
  background-color: #000 !important;
  box-shadow: 2px 2px 2px #333, -2px -2px 2px #333;
  margin: 0px;
  padding: 0px;
  border-radius: 10px;
  padding: 20px 20px;
}
.twocontainer h3 span,
.whychooseSlider h3 span,
.placemenTass h3 span {
  font-size: 40px;
  font-weight: 900;
  color: #f8d00d;
}
.twocontainer h3,
.whychooseSlider h3,
.placemenTass h3 {
  display: flex;
  position: relative;
  gap: 10px;
  align-items: center;
  color: #f6f6ffb7;
  font-size: 1.1rem;
  font-weight: 500;
}

.twocontainer img {
  object-fit: cover;
  width: 100%;
}

.whychooseSlider {
  background-color: #000;
  border-radius: 5px;
  margin-top: 40px;
  color: #fff;
  padding: 10px 20px;
  box-shadow: 2px 2px 2px #333, -2px -2px 2px #333;
}
.whychooseSlider h3 {
  position: relative;
  left: 10px;
  color: #f6f6ff9b;
  font-weight: 400 !important;
}
.placemenTass {
  margin-top: 40px;
  background-color: #000;
  padding: 10px 20px;
  padding-top: 10px;
  border-radius: 5px;
  box-shadow: 2px 2px 2px #333, -2px -2px 2px #333;
  overflow: hidden;
}

.placemenTass img {
  width: 100%;
  height: 50vh;
  object-fit: cover;
  aspect-ratio: 4/2 !important;
}
@media screen and (max-width: 767px) {
  .twocontainer h3,
  .whychooseSlider h3,
  .placemenTass h3 {
    display: flex;
    position: relative;
    gap: 10px;
    align-items: center;
    color: #f6f6ff;
    font-size: 1rem;
    font-weight: 500;
  }
  .placemenTass img {
    width: 100%;
    height: 50vh;
    object-fit: cover;
  }
  .twocontainer {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }
  .twocontainer > div {
    background-color: #000;
    margin: 0px;
    padding: 0px;
    border-radius: 10px;
    padding: 10px 20px;
  }

  .whychooseSlider {
    background-color: #000;
    border-radius: 5px;
    margin-top: 40px;
    color: #fff;
    padding: 10px 10px;
  }
}

@media screen and (max-width: 767px) {
  .hideheaderbtn {
    display: none !important;
  }
}
.chip {
  padding: 1rem !important;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #636363;
  /* box-shadow: 2px 2px 0px #f6f6ff, -2px -2px 0px #f6f6ff; */
}

#nextbootcamp {
  font-size: 1.3rem;
  text-align: center;
  margin-top: 1rem;
  font-weight: 400;
  /* opacity: 0.8; */
  color: #fafafaab;
}
#nextbootcamp span {
  color: #f8d00d !important;
  font-weight: 900;
  opacity: 1;
}

.hero {
  padding-bottom: 0px !important;
}
.bootcampp {
  font-size: 25px !important;
  text-align: center;
}
.bootcamph4 {
  font-size: 1.2rem !important;
  font-weight: 600 !important;
  text-transform: capitalize;
}
.bootcamph3 {
  font-size: 0.9rem !important;
}
@media screen and (max-width: 767px) {
  #nextbootcamp {
    font-size: 1rem;
    text-align: center;
    margin-top: 1rem;
    font-weight: 400;
    /* opacity: 0.8; */
    color: #fafafaab;
  }
  #nextbootcamp span {
    color: #f8d00d !important;
    font-weight: 900;
    opacity: 1;
    font-size: 1.2rem;
  }
  .highlights .grid .point {
    padding: 50px 10px !important;
  }
  .subheadingdmx {
    font-weight: 800 !important;
  }
  .bootcampheading {
    font-size: 1.8rem;
  }
  .futurereadyheading {
    font-size: 2.5rem;
  }
}

.overallbutton {
  border-radius: 30px !important;
  padding: 0.8rem 1.5rem !important;
  border: 2px solid #000 !important;
  text-transform: uppercase !important;
}

.learn-quickly .blocks-container .block {
  padding: 0;
  margin-bottom: 0px;
}
.learn-quickly .blocks-container .block .text p {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  text-align: left;
  color: #f6f6ffbe !important;
}

.floatingh3 {
  font-weight: 400;
  color: #333 !important;
}

.floatingh3 span {
  font-weight: 800;
  color: #000;
}
.floatingbuttondmx {
  padding: 0.2rem 2rem !important;
  color: #000 !important;
  border: 2px solid #000 !important;
  background-color: #fff !important;
  text-transform: uppercase;
}

.whiteborder {
  border: 2px solid #fdfdfd !important;
}

@media only screen and (max-width: 500px) {
  .tools .grid {
    grid-template-columns: repeat(3, 1fr) !important;
    gap: 20px 0px;
  }
}

.toolsscroll {
  padding: 0 !important;
}

.tools {
  margin: 0 !important;
  padding-top: 50px;
  padding-bottom: 50px;
  padding: 0 !important;
  padding-top: 3rem !important;
  padding-bottom: 50px !important;
}

.tools .heading {
  margin-bottom: 10px;
}
.highlights {
  padding: 0;
  padding-top: 0px !important;
  padding-bottom: 2px;
}

.headingdmxh1 {
  font-size: 42px !important;
}

.learn-quickly .blocks-container {
  margin-top: 30px !important;
  position: relative;
}
.dmxleadform {
  font-size: 30px;
}

.submitbtntextdmx {
  text-transform: uppercase;
}
.removedtiming {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}

.certificate .grid .additional > div {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 10px;
}

.certificate .grid .additional > div > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column !important;
  background-color: #fff;
  padding: 10px 10px;
  color: #000;
  border-radius: 10px;
}
.certificate .grid .additional > div > div p {
  font-weight: 600;
}

.alluminai {
  background-size: 12rem;
  background-color: gainsboro;
  padding-top: 2rem;

  padding: 2rem 1rem;

  padding-bottom: 4rem;
}

.alluminai h2 {
  color: #131313;
}
.alluminaigrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  position: relative;
  margin-top: 4rem;
  row-gap: 0rem;

  column-gap: 3rem;
}

.alluminaigrid > div {
  /* background-color: #008d97; */
  padding-bottom: 0rem;
  padding-top: 0rem;
  /* background-color: #ffff; */
  backdrop-filter: blur(30px);
  position: inherit;
  border-radius: 10px;
  max-width: 450px;
  box-shadow: 1px 1px 1px #040404, -1px -1px 1px #040404;
  /* border: 1px solid #131313; */
  max-height: 200px;
}

.alluminaiimg {
  height: 100px;
  width: 100px;
  object-fit: cover;
  border-top: 5px solid #131313;
  border-right: 5px solid #f8d00c;
  border-bottom: 5px solid #f8d00c;
  border-left: 5px solid #131313;
  border-radius: 100px !important;
  position: relative;
  margin: 0 auto;
  top: -50px;
  display: block;
  overflow: hidden;
}

.alluminaiimg img {
  object-fit: cover;
  width: 130% !important;
  height: 130% !important;
}

#rajeevimg {
  width: 170% !important;
  height: 170% !important;
  scale: 1.35;
}

.alluminaiimgcontainer {
}

.studentalluminaiinfo {
  position: relative;
  margin: 0 auto;
  top: -30px;
  color: #000;
  background-color: #ffffff98 !important;
  max-width: 90%;
  padding: 0.5rem 0;
  border-radius: 10px;
}

.studentalluminaiinfo h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
}
.studentalluminaiinfo h5 {
  font-size: 16px;
  text-transform: capitalize;
  color: #000000d2;
  font-weight: 500;
}
.studentalluminaiinfo p {
  font-size: 16px;
  text-transform: capitalize;
  color: #000000c4;
  font-weight: 400;
}

.pricealuminaigrid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 3rem;
}
.pricedmx {
  margin-top: 4rem;
  border: 2px solid #131313;
  padding-bottom: 2rem;
  border-radius: 10px;
  overflow: hidden;
  background-color: #131313;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 2rem;

  /* min-height: 100px; */
}
.pricedmx ul {
  margin-left: 0;
  padding-left: 0px;
  list-style-type: none;
}
.pricedmx ul li {
  display: flex;
  gap: 10px;
  text-align: start;
  font-size: 16px !important;
  color: #f2f2f2;
  margin-left: 0;
  padding-left: 0px;
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}
.checkcircle {
  background-color: #000 !important;
  fill: #f8d00c !important;
  font-size: 18px;
  border-radius: 50%;
  display: inline;
}
.pricedmximg {
  /* max-width: 95%; */

  border-radius: 10px;
  display: block;
}
.pricedmxcontent {
}

.pricedmxcontent h3 {
  font-size: 1.8rem;
  margin-top: 1rem;
}
.pricedmxcontent h2 {
  color: #f6f6ff;
}
.pricedmxcontent h2 span {
  font-size: 2.2rem;
  margin-top: 1rem;
  color: #f8d00c;
}

#dmxpricebtn {
  width: 100%;
  margin-top: 2rem;
  background-color: #f8d00c;
  font-size: 1.3rem;
}
#dmxpricebtn:hover {
  color: #000;
}
.hurryuptextalluminai {
  text-align: center;
  margin-top: 1rem;
  color: #131313;
}

.fortemp {
  position: relative;
}
@media screen and (max-width: 768px) {
  .fortemp {
    position: relative;
  }
  .pricedmx {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }
  .pricedmxcontent {
    /* padding: 1rem ; */
  }
  .pricealuminaigrid {
    display: grid;
    grid-template-columns: 1fr;
  }
  .alluminaigrid {
    display: grid;
    margin-top: 4rem;
    grid-template-columns: 1fr;
    row-gap: 5rem;
  }
}

.faculty .name {
  font-weight: 900 !important;
}

#floating-doubt-banner {
  background-color: #f8d10d !important;
}

.bootcampheading {
  font-weight: 900 !important;
}

.overallbutton {
  box-shadow: 2px 2px 0px #000;
}
