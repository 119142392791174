.whychoosesledediv {
  width: 100%;
  margin: 0 auto;
  position: relative;
  background-color: #333 !important;
}

.slide-content {
  border-radius: 5px;
  border: 1px solid #f6f6f6 !important;
  scale: 0.8;
  overflow: hidden;
}

.swiper-button-next,
.swiper-button-prev {
  top: 50%;
  transform: translateY(-50%);
}

.swiper-button-prev {
  left: 0px;
}

.swiper-button-next {
  right: 0px;
}

.custom-swiper-button-prev,
.custom-swiper-button-next {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  font-size: 24px;
  cursor: pointer;
  z-index: 10;
}

.custom-swiper-button-prev {
  left: 0;
}

.custom-swiper-button-next {
  right: 0;
}

.thumbnailwhychoose > a {
  height: 100% !important;
  width: 100% !important;
  position: relative;
  padding: 0 !important;
  margin: 0 !important;

  border: 2px solid red !important;
}

.thumbnailwhychoose {
  padding: 0 !important;
  margin: 0 !important;
  object-fit: cover !important;
  height: 100%;
  width: 100%;
}

.whychooseinstabtn {
  position: absolute;
  right: 0;
  bottom: 10%;

  z-index: 300;
  color: #f8ce0d;
  font-size: 70px;
  filter: drop-shadow(2px 2px 0px #131313);
}

.whychooseinstabtnbtn {
  box-shadow: 0px 0px 0px #151515, -0px -0px 0px #151515 !important;
}

.swiper-wrapper {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}
