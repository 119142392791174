.blogscrad-container {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;
  padding: 2rem;
  align-items: stretch;
  background-color: black;
  background-color: #333;
}
.allblogs {
  background-color: #333;
  position: relative;
}

.Blog-card {
  position: relative;
  border: 2px solid black;
  background: linear-gradient(71deg, #080509, #1a171c, #080509);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-radius: 10px;
  border: 1px solid #1a171c;
  overflow: hidden;
  overflow-y: hidden;
}

.bloContnet {
  padding: 1rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.bloContnet p {
  font-size: 0.8rem;
}

.bloContnet button {
  margin-top: 10px;
  margin-left: auto;
  border-radius: 10px;
}

.Blog-card img {
  height: 300px;
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  transition: 0.5s ease;
}

.Blog-card:hover img {
  scale: 1.04;
}

.blogout {
  max-width: 90%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 4rem;
}
.blogout img {
  width: 100%;
  object-fit: cover;
  border: 2px solid #080509;
  border-radius: 20px;
}

.loadingScreen{
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
@media screen and (min-width: 768px) {
  .blogscrad-container {
    grid-template-columns: repeat(3, 1fr);
  }
  .blogout {
    max-width: 60% !important;
  }
}
