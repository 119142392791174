      .class-hero {
          padding-top: 10rem;

          @media only screen and (max-width: 600px) {
              padding-top: 8rem;

          }

          .grid {

              // display: grid;  
              // grid-template-columns: 30% 70%;

              .heading {
                  text-align: left;
                  text-transform: uppercase;
              }
          }
      }


      .class {
          margin-top: 50px;

          .first {
              grid-area: class-first;
          }

          .second {
              grid-area: class-second;
          }

          .third {
              grid-area: class-third;
          }

          .fourth {
              grid-area: class-fourth;
          }

          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: 'class-first class-fourth'
          'class-third class-third ';
          gap: 20px;

          @media only screen and (max-width: 1200px) {
              grid-template-columns: 1fr 1fr 1fr 1fr;
              grid-template-areas: 'class-first class-first class-first class-first'
                  'class-third class-third class-fourth class-fourth';
              gap: 20px;
          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 1fr;
              grid-template-areas: 'class-first'
                  'class-fourth' 'class-third';
              gap: 20px;
          }


          img {
              width: 100%;
              border-radius: 0.7rem;
          }

          div {

              width: fit-content;
              height: fit-content;
              margin: auto;

          }

          .card {

              padding: 40px;
              border-radius: 1rem;
              background-color: #131313;



              .chip {
                  border-radius: 2rem;
                  padding: 3px 8px;
                  font-size: .7rem;
                  background-color: var(--hashpro);
                  color: #000;
                  font-weight: 600;
                  width: fit-content;

              }

              h3 {
                  margin-top: 20px;
                  font-size: 1.5rem;
                  font-weight: 500;
              }

              .para {
                  margin-top: 15px;
                  line-height: 1.5;
                  font-size: 0.9rem;
              }
          }

          .class {
              display: grid;
              grid-template-columns: 30% 70%;

          }
      }


      .guest-hero {
          margin-top: 7rem;

          .grid {

              display: grid;
              grid-template-columns: 30% 70%;

              .heading {
                  text-align: left;
              }
          }
      }

      .guest {
          margin-top: 50px;

          .first {
              grid-area: guest-first;
          }

          .second {
              grid-area: guest-second;
          }

          .third {
              grid-area: guest-third;
          }

          .fourth {
              grid-area: guest-fourth;
          }

          .text {
              grid-area: guest-text;
          }

          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-areas: 'guest-first guest-text'
          'guest-third guest-text';
          gap: 20px;

          @media only screen and (max-width: 1200px) {
              grid-template-columns: 1fr 1fr;
              grid-template-areas: 'guest-empty guest-first'
                  'guest-second guest-third '
                  'guest-fourth guest-fourth';
              gap: 20px;

          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 100%;
              grid-template-areas: 'guest-first'
                  'guest-second' 'guest-third'
                  'guest-fourth';
              gap: 20px;
          }

          img {
              width: 100%;
          }

          div {

              width: fit-content;
              height: fit-content;
              margin: auto;

          }

          .card {

              padding: 40px;
              border-radius: 1rem;
              background-color: #131313;



              .chip {
                  border-radius: 2rem;
                  padding: 10px 15px;
                  font-size: 0.7rem;
                  background-color: white;
                  color: black;
                  font-weight: 700;
                  width: fit-content;

              }

              h3 {
                  margin-top: 20px;
                  font-size: 1.5rem;
                  font-weight: 500;
              }

              .para {
                  margin-top: 15px;
                  line-height: 1.5;
              }
          }

          .class {
              display: grid;
              grid-template-columns: 30% 70%;

          }
      }


      .experiential-hero {
          margin-top: 7rem;

          .grid {

              display: grid;
              grid-template-columns: 80% 20%;

              .heading {
                  text-align: left;
              }
          }
      }

      .experiential {
          margin-top: 50px;

          .first {
              grid-area: guest-first;
          }

          .second {
              grid-area: guest-second;
          }

          .third {
              grid-area: guest-third;
          }

          .fourth {
              grid-area: guest-fourth;
          }

          .empty {
              grid-area: guest-empty;

          }

          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: 'guest-fifth guest-first guest-second '
          'guest-third guest-fourth  guest-empty';
          gap: 20px;

          @media only screen and (max-width: 1200px) {
              grid-template-columns: 1fr 1fr;
              grid-template-areas: 'guest-empty guest-first'
                  'guest-second guest-third '
                  'guest-fourth guest-fourth';
              gap: 20px;

          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 100%;
              grid-template-areas: 'guest-first'
                  'guest-second' 'guest-third'
                  'guest-fourth';
              gap: 20px;
          }

          img {
              width: 100%;
              border-radius: 0.7rem;
              aspect-ratio: 15/9;
              object-fit: cover;
              object-position: center;
          }

          div {

              width: fit-content;
              height: fit-content;
              margin: auto;

          }

          .card {

              padding: 40px;
              border-radius: 1rem;
              background-color: #131313;



              .chip {
                  border-radius: 2rem;
                  padding: 10px 15px;
                  font-size: 0.7rem;
                  background-color: white;
                  color: black;
                  font-weight: 700;
                  width: fit-content;

              }

              h3 {
                  margin-top: 20px;
                  font-size: 1.5rem;
                  font-weight: 500;
              }

              .para {
                  margin-top: 15px;
                  line-height: 1.5;
              }
          }

          .class {
              display: grid;
              grid-template-columns: 30% 70%;

          }
      }

      .gl {

          .first {
              grid-area: gl-first;

              img {
                  aspect-ratio: 12/16;
              }
          }

          .second {
              grid-area: gl-second;
              margin: 0;

              img {
                  aspect-ratio: 12/16;
              }
          }

          .third {
              grid-area: gl-third;
              margin: 0;
          }

          .fourth {
              grid-area: gl-fourth;
          }

          .empty {
              grid-area: gl-empty;

              .heading {
                  text-align: left;
              }
          }

          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: 'gl-empty gl-empty .'
          '. gl-fourth gl-second'
          'gl-first gl-third gl-second';
          gap: 20px;

          @media only screen and (max-width: 1200px) {
              grid-template-columns: 1fr 1fr;
              grid-template-areas: 'gl-empty gl-first'
                  'gl-second gl-third '
                  'gl-fourth gl-fourth';
              gap: 20px;

          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 100%;
              grid-template-areas:
                  'gl-empty'
                  'gl-first'
                  'gl-second'
                  'gl-third'
                  'gl-fourth'
              ;
              gap: 20px;
          }
      }


      .cl {

          .first {
              grid-area: cl-first;

              img {

                  aspect-ratio: 16/9 !important;
              }
          }

          .second {
              grid-area: cl-second;
          }

          .third {
              grid-area: cl-third;
          }

          .fourth {
              grid-area: cl-fourth;
          }

          .empty {
              grid-area: cl-empty;
          }

          img {
              aspect-ratio: 14/16;
          }

          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: 'cl-first cl-first cl-second'
          'cl-third cl-fourth cl-empty';
          gap: 20px;

          @media only screen and (max-width: 1200px) {
              grid-template-columns: 50% 50%;
              grid-template-areas: 'cl-first cl-second'
                  'cl-fourth cl-third';
              gap: 20px;
          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 100%;
              grid-template-areas:
                  'cl-empty'
                  'cl-first'
                  'cl-second'
                  'cl-third'
                  'cl-fourth'
              ;
              gap: 20px;
          }
      }

      .iv {
          .heading {
              text-align: right;
          }

          .first {
              grid-area: iv-first;
              margin: 0;

              img {
                  aspect-ratio: 12/16;
              }
          }

          .second {
              grid-area: iv-second;
          }

          .third {
              grid-area: iv-third;
          }

          .fourth {
              grid-area: iv-fourth;

              img {
                  aspect-ratio: 12/16;
              }
          }

          .fifth {
              margin: 0;

              grid-area: iv-fifth;

              img {
                aspect-ratio: 27/16;
                  margin: 0;
              }
          }

          .text {
              grid-area: iv-text;
          }

          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-areas: '. iv-text iv-text'
          'iv-second iv-fifth iv-fifth' 
          'iv-third iv-fifth iv-fifth ';
          gap: 20px;
 
          @media only screen and (max-width: 1200px) {
              grid-template-columns: 50% 50%;
              grid-template-areas:
                  'iv-text iv-third'
                  'iv-fifth iv-second';
              gap: 20px;

          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 100%;
              grid-template-areas:
                  'iv-text'

                  'iv-second'
                  'iv-third'
                  'iv-fifth'
              ;
              gap: 20px;
          }
      }


      .ec {

          .first {
              grid-area: ec-first;
          }

          .second {
              grid-area: ec-second;
          }

          .third {
              grid-area: ec-third;
          }

          .fourth {
              grid-area: ec-fourth;
          }

          .fifth {
              grid-area: ec-fifth;
          }

          .text {
              grid-area: ec-text;
          }

          display: grid;
          grid-template-columns:1fr 1fr 1fr 1fr;
          grid-template-areas: 'ec-text ec-text ec-second ec-second '
          'ec-third ec-third ec-fourth ec-fourth';
          gap: 20px;

          @media only screen and (max-width: 1200px) {
              grid-template-columns: 50% 50%;
              grid-template-areas: 'ec-first ec-text'
                  'ec-fourth ec-third'
                  'ec-fifth ec-second';
              gap: 20px;

          }

          @media only screen and (max-width: 900px) {
              grid-template-columns: 100%;
              grid-template-areas:
                  'ec-first'
                  'ec-text'
                  'ec-second'
                  'ec-third'
                  'ec-fourth'
              ;
              gap: 20px;
          }
      }