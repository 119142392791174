.HireFromUs {
  padding: 6rem 3vw;
  padding-top: 7rem;
}

.HireFromUs-herosection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 3rem;
}

.HireFromUs-herosection1 h1 {
  font-size: 2.5rem;
  color: #f8d00d;
}
.HireFromUs-herosection1 button {
  margin-top: 1rem;
}
.HireFromUs-herosection1 h1 span {
  background-color: #ffff;
  color: black;
  padding: 0 5px;
}
.HireFromUs-herosection2 {
  overflow: hidden;
  height: 80vh;
  width: 60%;
  border-radius: 54px;
}
.HireFromUs-herosection2 img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.HireFromUs-herosection1 p {
  margin-top: 1rem;
}

#HireFromUs-companySections {
  padding: 2rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#HireFromUs-companySections h1 {
  text-align: center;
  max-width: 60%;
  font-size: 2rem;
}

.HireFromUs-faqs {
  width: 100%;
  padding: 0;
}
.HireFromUs-faqs h1 {
  font-size: 2rem;
}

/* // accordian */

.accordion {
  width: 100%;
  box-shadow: 0 8px 17px rgba(0, 0, 0, 0.2);
}

.accordion-menu {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: #f8d00d;
  color: black;

  padding: 10px;
  cursor: pointer;
  user-select: none;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.accordion-content {
  max-height: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
  transition: max-height 0.5s ease-out;
  background-color: #e0e0e0;
  color: black;
  margin-top: 10px;
}

.accordion-content p {
  margin: 10px;
}

.accordion-content.expanded {
  max-height: 1000px; /* Adjust to a value that ensures full content display */
}

.accordion-plus,
.accordion-minus {
  color: black;
  font-size: 30px;
  cursor: pointer;
}

.hidden {
  display: none;
}

#HireFromUs-form {
  padding: 0;
  padding-top: 100px;
}

#HireFromUs-form h1 {
  text-align: center;
  text-transform: capitalize;
  font-size: 2rem;
  padding-bottom: 20px;
}
#HireFromUs-form #lead-form {
  width: 50%;
}

/* why hashpro */

@media screen and (max-width: 600px) {
  .HireFromUs {
    padding: 7rem 5vw;
  }
  .HireFromUs-herosection {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f8d00d;
  }
  .HireFromUs-herosection1 h1 {
    font-size: 1.8rem;
    max-width: 100%;
  }
  .HireFromUs-herosection2 {
    margin-top: 2rem;
    height: 50vh;
    width: 100%;
    border-radius: 30px;
    background: #e0e0e0;
  }
  #HireFromUs-companySections h1 {
    max-width: 90%;
    font-size: 1.5rem;
    text-align: left;
  }
  #hiring-process-heading {
    max-width: 100%;
  }
  #HireFromUs-form #lead-form {
    width: 100%;
  }
  #HireFromUs-form h1 {
    font-size: 1.8rem;
  }
}

/* slider */

.HireFromUs-hiringPartners {
  padding: 0;
}
.slide-container {
  width: 100%;
  display: flex;
  position: relative;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  margin: 0;
}
.recruter-card {
  position: relative;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.5);
  display: flex;
  flex-direction: row;
  border-radius: 30px;
  position: relative;
margin-top: 1rem;

  scroll-snap-align: start;
  width: 300px;
  display: flex;

  margin-left: 30px;
  flex: 0 0 auto;
}
.recruter-card h2 {
  margin: 0;
  padding: 0 1rem;
}
.recruter-card .title {
 
  text-align: right;
  color: #f8d00d;
  font-weight: bold;
  font-size: 12px;
}
.recruter-card .desc {
  font-size: 12px;
  color: black;
}


.img-avatar {
  width: 80px;
  height: 80px;
  position: absolute;
  border-radius: 50%;
  border: 6px solid white;
  top: 15px;
  left: 85px;
}

.img-avatar img {
  height: 100%;
  width: 100%;
}

.recruitercard-text {
  display: grid;
  grid-template-columns: 1fr 2fr;
}

.title-total {
  padding: 2.5em 1.5em 1.5em 1.5em;
}



.img-portada {
  width: 100%;
}

.portada {
  width: 100%;
  height: 100%;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-image: url("https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg");
  background-position: bottom center;
  background-size: cover;
}

.slide-container::-webkit-scrollbar {
  display: none;
}

.title-total .desc {
  padding: 0;
}

#iconcloud{
  display: flex;
  flex-direction: column;
}


#HireFromUs-companySections{
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 600px) {
  .title-total .desc {
    padding: 0;
  }
  
  .hirefrom-us-slides-control {
    position: relative;
    margin-top: 2rem;
    transform: translateY(-50%);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    display: none;
   
  }
  .nav-button.left {
    left: 10;
    z-index: 1234567890;
    display: none;
  }
  
  .nav-button.right {
    right: -10px;
     z-index: 1234567890;
     display: none;
  }
  .recruter-card {
    width: 280px;
    margin-right: -5px;
  }
}

.hirefrom-us-slides-control {
  position: relative;
  margin-top: 2rem;
  transform: translateY(-50%);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
 
}

.nav-button.left {
  left: 10;
  z-index: 1234567890;
}

.nav-button.right {
  right: -10px;
   z-index: 1234567890;
}
