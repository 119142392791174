.customAlertHat {
    position: fixed;
    top: 200%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    border: 1px solid #ccc;
    padding: 30px;
    border: 1px solid black;
    border-radius: 8px;
    box-shadow: 5px 5px var(--hashpro);
    transition: top 0.5s ease;
    z-index: 9999;
}

.customAlertHat.show {
    top: 50%;
}