.containertoolsnew {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  overflow: hidden;
  margin: 0;
  margin: 0 !important;

  padding: 0 !important;
  padding-top: 2rem !important;
}

.slidertooldmx,
.slider2 {
  overflow: hidden;
  white-space: nowrap;
  position: relative;
  width: 100%;
}

.slide-track,
.slide-track2 {
  display: flex;
  width: calc(150px * 12);
  animation: scroll-left 20s linear infinite;
}

.slide-track2 {
  animation: scroll-right 20s linear infinite;
}

.slide,
.slide2tool {
  flex: 0 0 150px;
  margin-right: 1rem;
  text-align: center;
  color: white;
}

@keyframes scroll-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%);
  }
}

@keyframes scroll-right {
  0% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0);
  }
}

.tool {
  padding: 1rem;
  background: #333;
  border-radius: 0.5rem;
}

.center-img img {
  max-width: 60px;
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 767px) {
  .slide-track,
  .slide-track2 {
    display: flex;
    width: calc(150px * 12);
    animation: scroll-left 10s linear infinite;
  }

  .slide-track2 {
    animation: scroll-right 10s linear infinite;
  }
}
