.dme-page {
    @import"https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap"
}

.dme-page :root {
    --blue: #1e90ff;
    --white: #fff;
    --hashpro: #f7c20a;
    --hashpro-hover: #ffdb00;
    --hashprolight: #f7c40a60;
    --font-paragraph: 1.03rem;
    --font-subheading: 1rem;
    --font-heading: 2rem
}

.dme-page html {
    overflow: -moz-scrollbars-vertical;
    overflow-y: scroll;
    scroll-behavior: smooth
}

.dme-page ::-webkit-scrollbar {
    width: 6px
}

.dme-page ::-webkit-scrollbar-track {
    background: #2c2c2c
}

.dme-page ::-webkit-scrollbar-thumb {
    height: 10px;
    background: var(--hashpro)
}

.dme-page ::-webkit-scrollbar-thumb:hover {
    background: var(--hashpro-hover)
}

.dme-page hr {
    color: #4c4c4c !important
}

.dme-page .center {
    text-align: center
}

.dme-page .cta-1 {
    background-color: var(--hashpro);
    background-size: cover;
    background-position: center;
    margin: 0 auto;
    padding: 40px 10%;
    color: #fff;
    text-align: center;
    width: 100%;

}

.dme-page .cta-1 .grid-container {
    margin-top: 20px;
    display: flex;
    grid-template-columns: 1fr 1fr;
    justify-content: center;
    -moz-column-gap: 50px;
    column-gap: 50px;
    flex-wrap: wrap
}

.dme-page .cta-1 .grid-container .timer-container {
    display: flex;
    justify-content: center;
    align-items: center
}

.dme-page .cta-1 .grid-container .timer-container .colon {
    transform: translateY(-5px)
}

.dme-page .cta-1 .grid-container .timer-container span {
    color: #000;
    font-weight: 600;
    font-size: 14px
}

.dme-page .cta-1 .grid-container .timer-container .timer {
    color: #fff;
    padding: 10px;
    border-radius: 5px;
    margin: 5px;
    background-color: #000;
    width: -moz-fit-content;
    width: fit-content;
    height: -moz-fit-content;
    height: fit-content;
    box-shadow: 1px 1px 1px #fff
}



.dme-page .cta-1 button:hover {
    cursor: pointer;
    background-color: #fff;
    color: #000
}

.dme-page .cta-1 .undertext {
    margin-top: 5px;
    font-size: .8rem !important;
    color: rgba(0, 0, 0, .787) !important
}

.dme-page .mouse-shadow {
    position: fixed;
    top: 50%;
    z-index: 2;
    left: 50%;
    width: 50px;
    height: 50px;
    background-color: #fff
}


.dme-page .font-paragraph {
    font-size: var(--font-paragraph) !important;
    line-height: 20px
}

.dme-page .subheading {
    font-size: var(--font-subheading) !important
}

.dme-page p.light {
    color: #bbb !important;
    line-height: 22px
}

.dme-page p.light-1 {
    color: #dcdcdc
}

@media screen and (max-width: 600px) {
    .dme-page .font-paragraph {
        font-size: .95rem !important
    }

    .dme-page .heading {
        font-size: 1.6rem !important
    }

    .dme-page .subheading {
        font-size: 1.05rem !important
    }
}

.dme-page :root {
    --swiper-theme-color: var(--hashpro) !important;
    --swiper-pagination-bullet-inactive-color: black !important
}

.dme-page .crafters .swiper-horizontal>.swiper-pagination-bullets,
.dme-page .crafters .swiper-pagination-bullets.swiper-pagination-horizontal,
.dme-page .crafters .swiper-pagination-custom {
    bottom: -20px !important;
    padding-bottom: 20px
}

.dme-page .crafters .swiper-pagination-bullet {
    background-color: rgba(255, 255, 255, .377) !important
}

.dme-page .crafters .swiper-wrapper {
    padding-bottom: 40px
}

.dme-page .crafters {
    position: relative;
    margin: 0 10%
}

.dme-page .members-container .swiper-button-container {
    position: relative;
    padding: 30px;
    padding-top: 20px
}

.dme-page .crafters .swiper-pagination {
    z-index: 1000 !important
}

.dme-page .next-btn {
    position: absolute;
    top: 50%;
    right: -3%
}

.dme-page .prev-btn {
    position: absolute;
    top: 50%;
    left: -3%
}

.dme-page .next-btn:hover {
    cursor: pointer
}

.dme-page .prev-btn:hover {
    cursor: pointer
}

@media(min-width: 0)and (max-width: 580px) {
    .dme-page .next-btn {
        right: -10%
    }

    .dme-page .prev-btn {
        left: -10%
    }
}

.dme-page .container {
    height: -moz-fit-content;
    height: fit-content;
    width: 90%;
    margin: 0 auto;
    border: 1px solid var(--hashpro)
}

.dme-page .container h2 {
    text-align: left;
    font-size: 1.3rem;
    color: #fff
}

.dme-page .container p {
    padding: 20px;
    padding-right: 7%;
    font-weight: 600;
    font-size: .95rem;
    line-height: 25px;
    background-color: rgba(247, 194, 10, .9411764706);
    color: #000
}

.dme-page .form {
    padding: 20px 0;
    margin: 50px 5%;
    margin-top: 35px;
    margin-bottom: 20px
}

.dme-page .form .grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr
}

@media only screen and (max-width: 1000px) {
    .dme-page .form .grid-container {
        display: grid;
        grid-template-columns: 1fr
    }
}

@media screen and (max-width: 500px) {
    .dme-page .form {
        padding: 20px 0;
        padding-top: 0
    }
}

.dme-page .form .left {
    margin: auto;
    text-align: center
}

.dme-page .form .left:hover img {
    scale: 1.05
}

.dme-page .form .left:hover .heading {
    scale: 1.05
}

.dme-page .form .left img {
    width: 70%;
    margin: auto;
    transition: ease-in-out .2s
}

@media screen and (max-width: 600px) {
    .dme-page .form {
        margin-left: 0;
        margin-right: 0
    }
}

.dme-page .form .heading {
    text-align: center;
    color: #fff;
    font-size: 3.5rem !important;
    margin: auto;
    padding: 30px;
    padding-top: 0;
    transition: .2s ease-in-out
}

@media screen and (max-width: 1000px) {
    .dme-page .form {
        grid-template-columns: 1fr
    }

    .dme-page .form .left img {
        display: none
    }

    .dme-page .form .heading {
        font-size: 1.6rem !important
    }
}

.dme-page .form .inputs-container {
    margin: auto;
    width: 100% !important;
    padding: 15px 0;
    padding-bottom: 0;
    border-radius: 10px;
    height: -moz-fit-content;
    height: fit-content;
    color: #000
}

.dme-page .form .input-flex div {
    margin: 20px 30px
}

.dme-page .form div p {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 0;
    color: rgb(35, 35, 35);
}

.dme-page .form select {
    font-size: 14px;
    border-radius: 6px;
    border: 0;
    font-weight: 700;
    color: #131313;
    border: 1px solid black;
    border-radius: 2rem;
}

.dme-page .form .input-container {
    margin: 0 !important;
    margin: auto;
    height: -moz-fit-content;
    height: fit-content;
    width: -moz-fit-content;
    width: fit-content
}

.dme-page .form label {
    margin: auto;
    font-size: 14px;
    font-weight: 500;
    color: black;

}

.dme-page .form input[type=radio] {
    margin: auto;
    margin: 6px 4px;
    width: -moz-fit-content;
    width: fit-content
}

.dme-page .form .urgency {
    margin-top: 20px
}



.dme-page .form button:hover {
    cursor: pointer
}

.dme-page #form-error.hide {
    opacity: 0
}

.dme-page #form-error {
    padding: 10px 5px;
    max-width: 80%;
    opacity: 1;
    margin: auto;
    margin-top: 15px;
    font-weight: 500;
    text-align: center;
    transition: .5s;
    font-size: 14px;
    line-height: 23px;
    background: #000;
    color: #fff;
    border-radius: 5px
}

@keyframes blinking {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

.dme-page .form-error-anim {
    animation: blinking infinite 1s
}

@media screen and (max-width: 600px) {
    .dme-page .form .inputs-container {
        padding: 0;
        padding-top: 20px;
        width: 90%
    }


    .dme-page .form .heading {
        font-size: 1.6rem !important
    }
}

.dme-page .acc {
    overflow: hidden;
    padding: 0
}

.dme-page .acc li {
    list-style-type: none;
    padding: 0
}

.dme-page .acc_ctrl {
    background: #000;
    border: 0;
    border-bottom: solid 1px #f2f2f2;
    cursor: pointer;
    display: block;
    outline: 0;
    padding: 2em;
    padding-right: 3em;
    position: relative;
    text-align: center;
    width: 100%;
    border-radius: 0
}

.dme-page .acc_ctrl:hover {
    scale: 1;
    border: none;
    border-bottom: solid 1px #f2f2f2
}

.dme-page .acc_ctrl:before {
    background: #44596b;
    content: "";
    height: 2px;
    position: absolute;
    right: 5%;
    top: 50%;
    transform: rotate(90deg);
    transition: all .2s ease-in-out;
    width: 14px
}

.dme-page .acc_ctrl:after {
    background: #44596b;
    content: "";
    height: 2px;
    position: absolute;
    right: 5%;
    top: 50%;
    width: 14px
}

.dme-page .acc_ctrl:focus h2 {
    position: relative
}

.dme-page .acc_panel {
    background: #f2f2f2;
    display: none;
    overflow: hidden
}

.dme-page .acc_panel p {
    font-size: .95em !important;
    line-height: 20px;
    color: #000
}

.dme-page body {
    max-width: 1600px;
    background-color: #000;
    margin: auto
}

.dme-page section {
    display: block
}

.dme-page a {
    background-color: rgba(0, 0, 0, 0)
}

.dme-page .bold {
    font-weight: 800
}

.dme-page .yellow-text {
    color: var(--hashpro) !important
}

.dme-page .instructor-heading {
    margin-top: 50px !important
}

@media screen and (min-width: 600px) {
    .dme-page .instructor-heading {
        margin-bottom: 30px !important
    }
}

.dme-page .w-inline-block {
    max-width: 100%;
    display: inline-block
}

.dme-page .w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    text-decoration: none;
    display: inline-block
}

.dme-page img {
    max-width: 100%;
    font-size: 1rem;
    display: inline-block;
    position: static
}

.dme-page .text-weight-bold {
    font-weight: 700
}

.dme-page .text-size-large {
    font-size: 1.5rem;
    line-height: 150%
}

.dme-page .margin-xxsmall {
    margin: .25rem
}

.dme-page .margin-xsmall {
    margin: .5rem
}

.dme-page .margin-medium {
    margin: 2rem
}

.dme-page .padding-small {
    padding: 1rem
}

.dme-page .margin-bottom {
    height: auto;
    margin: 0 0 1rem
}

.dme-page .margin-bottom.margin-regular {
    margin-bottom: 1rem
}

.dme-page .padding-vertical {
    padding-left: 0;
    padding-right: 0
}

.dme-page .img-cover {
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
    border-radius: 8px;
    max-width: 500px;
    width: 95%
}

@media screen and (max-width: 550px) {
    .dme-page .img-cover {
        -o-object-position: top;
        object-position: top;
        max-height: 35vh
    }
}

.dme-page .section-wrapper {
    width: 100%;
    position: relative
}

.dme-page .hero-mentor-info-highlight {
    z-index: 2;
    width: 50%;
    text-align: center;
    border: 1px solid #000;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    padding: .5rem 1rem;
    position: absolute;
    top: -10%;
    bottom: auto;
    left: 5%;
    right: auto;
    background-color: #fff !important;
    color: #000 !important;
    font-weight: 600
}

.dme-page .hero-mentor-info-highlight.pm2 {
    top: -8%;
    bottom: auto;
    left: 0;
    right: 0
}

.dme-page .vsl-container {
    -webkit-backdrop-filter: none;
    backdrop-filter: none;
    border-radius: 12rem;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    display: flex
}

.dme-page .text-size-regular-2 {
    font-size: 16px;
    color: #878787
}

.dme-page .text-size-regular-2.text-align-center {
    color: #878787 !important
}

.dme-page .text-block-223 {
    color: rgba(255, 255, 255, .7);
    text-align: left;
    font-size: .85rem
}

.dme-page .text-block-223.text-align-center {
    text-align: center
}

.dme-page .phone-heading {
    color: #fff;
    display: none;
    margin: 0 3%;
    margin-top: 110px
}

.dme-page .phone-heading p {
    text-align: center;
    margin: auto;
    margin-top: 13px;
    width: 96%
}

.dme-page .home-section {
    max-width: 95%;
    grid-column-gap: 2rem;
    grid-row-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: stretch;
    align-items: center;
    padding: 20px;
    padding-bottom: 30px;
    margin: 0 auto;
    margin-top: 5rem !important;
    display: grid;
    position: relative
}

.dme-page .image-150 {
    z-index: -1;
    width: 60rem;
    max-width: none;
    opacity: .73;
    position: absolute;
    top: -23%;
    bottom: auto;
    left: 0;
    right: 0
}

.dme-page .image-150.b {
    width: 70rem;
    max-width: none;
    opacity: .51;
    top: -8%;
    bottom: 0;
    left: 9%;
    right: 0
}

.dme-page .hero-mentor-details {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    justify-content: center;
    display: grid
}

.dme-page .hero-mentor-details.margin-medium {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    border-radius: 8px;
    align-items: center;
    padding: 2rem 2rem 1rem;
    padding-bottom: 1rem !important
}

.dme-page .hero-mentor-details.margin-medium.pm2 {
    text-align: center;
    border-bottom: 1px solid rgba(255, 255, 255, .5);
    border-radius: 8px 8px 0 0;
    grid-template-columns: auto;
    margin: 0;
    padding-top: 2.4rem;
    padding-bottom: 1.6rem;
    padding-left: 2.8rem
}

.dme-page .heading-64 {
    font-size: 2.8rem !important;
    font-weight: 800;
    margin: 0 auto;
    text-align: left
}

@media(min-width: 0)and (max-width: 600px) {
    .dme-page .heading-64 {
        font-size: 1.8rem !important
    }

    .dme-page .home-content_left .font-paragraph {
        text-align: center
    }
}

@keyframes typing {
    from {
        width: 0
    }

    to {
        width: 100%
    }
}

.dme-page .hero-mentor-logo_component {
    text-align: center;
    background-color: #e6efef;
    border-top: 1px solid #000
}

.dme-page .hero-mentor-logo_component.padding-vertical.padding-small {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0);
    border-top-color: rgba(255, 255, 255, .18);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    display: grid;
    overflow: hidden
}

.dme-page .hero-mentor-logo_component.padding-vertical.padding-small {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgba(0, 0, 0, 0);
    border-top-color: rgba(255, 255, 255, .18);
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    padding: 10px 2rem;
    display: grid;
    overflow: hidden
}

.dme-page .hero-mentor_component {
    width: 100%;
    border: .5px solid rgba(255, 255, 255, .6);
    border-radius: 8px;
    margin-top: 1rem;
    position: relative;
    overflow: visible
}

.dme-page .angel-investing-timer_container {
    text-align: center
}

.dme-page .div-block-252 {
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 8px;
    margin-bottom: 24px;
    overflow: hidden;
    text-align: center
}

.dme-page .logo-top {
    width: auto;
    height: 3rem;
    min-height: 0;
    margin-bottom: 20px;
    position: static;
    top: 10%;
    bottom: auto;
    left: 9%;
    right: auto
}

.dme-page .cta-angel-investing:hover {
    transform: scale(1.01)
}

.dme-page .splide__arrow {
    display: none !important
}

.dme-page .splide__pagination {
    display: none !important
}

.dme-page .cta-angel-investing.pm2-gradient {
    background-color: var(--hashpro);
    font-weight: bold;
    color: #000;
    text-transform: uppercase;
    max-width: 24rem;
    border-radius: 6.6px !important;
    box-shadow: none;
    transition: .3s
}

.dme-page .cta-angel-investing.pm2-gradient.full {
    margin-top: 20px;
    max-width: none
}

@media only screen and (max-width: 500px) {
    .dme-page .cta-angel-investing.pm2-gradient.full {
        display: none
    }
}

.dme-page .body-text {
    margin-bottom: 26px;
    font-size: 2rem;
    line-height: 150%
}

.dme-page .div-block-427 {
    display: flex
}

.dme-page .div-block-427.pm2 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    border: 1px solid rgba(247, 196, 10, .7254901961);
    border-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    margin-top: 20px;
    margin-bottom: 40px;
    padding: 24px;
    display: grid
}

.dme-page .lightbox-link-16 {
    width: 100%;
    max-height: 16rem
}

.dme-page .text-align-center {
    text-align: center
}

@media screen and (min-width: 1280px) {
    .dme-page .home-section.pm2 {
        height: auto
    }
}

@media screen and (max-width: 991px) {
    .dme-page .margin-medium {
        margin: 1.5rem
    }

    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }

    .dme-page .padding-vertical {
        padding-left: 0;
        padding-right: 0
    }

    .dme-page .vsl-container {
        right: 9%
    }

    .dme-page .cta-angel-investing {
        padding: 1rem 3rem
    }
}

@media screen and (max-width: 767px) {
    .dme-page h1 {
        font-size: 2.3rem
    }

    .dme-page .text-size-large {
        font-size: 1.25rem
    }

    .dme-page .margin-medium {
        margin: 1.25rem
    }

    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }

    .dme-page .margin-bottom.margin-regular {
        margin-bottom: .5rem
    }

    .dme-page .margin-bottom.margin-medium.no-margin {
        margin-bottom: 0
    }

    .dme-page .padding-vertical {
        padding-left: 0;
        padding-right: 0
    }

    .dme-page .vsl-container {
        top: auto;
        bottom: -100%;
        left: 43%;
        right: 0
    }

    .dme-page .home-section {
        width: 100%;
        height: auto;
        grid-row-gap: 2rem;
        grid-template-rows: auto auto;
        grid-template-columns: 1fr;
        align-content: stretch;
        align-items: stretch;
        margin-top: 3rem;
        padding-left: .5rem;
        padding-right: .5rem;
        display: block
    }

    .dme-page .heading-small-2 {
        font-size: 1.2rem
    }
}

.dme-page .heading-small-2 {
    font-size: 1.4rem
}

@media screen and (max-width: 479px) {
    .dme-page .margin-bottom {
        margin: 0
    }

    .dme-page .padding-vertical {
        padding-left: 0;
        padding-right: 0
    }

    .dme-page .section-wrapper {
        margin-left: auto;
        margin-right: auto
    }

    .dme-page .hero-mentor-info-highlight {
        top: -12%
    }

    .dme-page .hero-mentor-info-highlight.pm2 {
        width: 50%;
        top: -3%
    }

    .dme-page .vsl-container {
        z-index: 2;
        bottom: -54%;
        left: 33%
    }

    .dme-page .home-content_left {
        text-align: center
    }

    .dme-page .home-content_left.left-on-mobile {
        text-align: left;
        margin-top: 10px
    }

    .dme-page .home-content_left.left-on-mobile {
        text-align: left
    }

    .dme-page .home-section {
        align-content: stretch;
        align-items: stretch;
        margin-top: 2rem;
        display: block;
        overflow: hidden
    }

    .dme-page .home-section.pm2 {
        grid-template-rows: auto;
        overflow: visible
    }

    .dme-page .heading-small-2 {
        margin-bottom: 8px
    }

    .dme-page .image-150 {
        max-width: none;
        top: 7%;
        bottom: auto;
        left: -172px;
        right: auto
    }

    .dme-page .image-150.b {
        left: -50%
    }

    .dme-page .hero-mentor-details.margin-medium.pm2 {
        padding-left: 2rem
    }

    .dme-page .hero-mentor-logo_component.padding-vertical.padding-small {
        grid-template-rows: auto auto;
        grid-template-columns: auto
    }

    .dme-page .hero-mentor-logo_component.padding-vertical.padding-small.pm2 {
        grid-template-rows: auto;
        display: block
    }

    .dme-page .hero-mentor-logo_component.padding-vertical.padding-small {
        grid-template-rows: auto auto;
        grid-template-columns: auto;
        padding-bottom: 17px
    }

    .dme-page .logo-top {
        height: 2.4rem
    }

    .dme-page .cta-angel-investing {
        font-size: 16px
    }

    .dme-page .div-block-427.pm2 {
        margin-bottom: 0;
        padding: 16px
    }

    .dme-page .lightbox-link-16 {
        max-height: 13rem
    }
}

.dme-page #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac212-6d667f4f,
.dme-page #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac213-6d667f4f,
.dme-page #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac22a-6d667f4f {
    grid-area: span 1/span 1/span 1/span 1;
    color: #fff;
    background-color: #000 !important
}

.dme-page #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac23a-6d667f4f {
    grid-area: span 1/span 2/span 1/span 2;
    line-height: 22px;
    padding: 10px 0
}

.dme-page p {
    margin: 0
}

.dme-page .hashpro-way-container[data-v-17ab2e64] {
    background: #0a0a0a;
    color: #fff;
    overflow: hidden;
    position: relative;
    padding: 5%
}

@media only screen and (max-width: 500px) {
    .dme-page .hashpro-way-container[data-v-17ab2e64] {
        margin-top: 30px
    }
}

.dme-page .hashpro-way-container .restrict-width[data-v-17ab2e64] {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    max-width: 100rem;
    margin: 0 auto;
    padding: 0 7%
}

.dme-page .hashpro-way-container .left-container[data-v-17ab2e64] {
    text-align: center;
    margin-right: 4.18%;
    width: 100%
}

.dme-page .hashpro-way-container h1 {
    margin: 0;
    margin-bottom: 10px
}

.dme-page .hashpro-way-container .left-container .heading[data-v-17ab2e64] {
    font-style: normal;
    font-weight: 800;
    font-size: 2.875rem;
    margin-bottom: 2rem
}

.dme-page .hashpro-way-container .left-container .desc[data-v-17ab2e64] {
    font-size: 1.125rem;
    line-height: 200%;
    margin: auto;
    max-width: 85%
}

.dme-page .hashpro-way-container .right-container[data-v-17ab2e64] {
    width: 70%;
    margin: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    flex-wrap: wrap;
    justify-content: center
}

.dme-page .hashpro-way-container .right-container .item[data-v-17ab2e64] {
    display: flex;
    flex-direction: column;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 1.25rem;
    line-height: 140%;
    padding: 1.25rem 1.5rem;
    width: 300px;
    margin: auto;
    transition: .5s
}

.dme-page .hashpro-way-container .right-container .item[data-v-17ab2e64]:hover {
    scale: 1.1
}

.dme-page .hashpro-way-container .right-container .item[data-v-17ab2e64] .text {
    color: #e6e6e6;
    font-weight: 600;
    font-size: .88rem;
    line-height: 18px
}

.dme-page .hashpro-way-container .right-container .emoji {
    font-size: 55px;
    text-align: center;
    line-height: 80px
}

.dme-page .hashpro-way-container .right-container .item img[data-v-17ab2e64] {
    height: 4rem;
    width: 4rem;
    max-width: inherit;
    margin-bottom: 1.5rem
}

@media only screen and (max-width: 1000px) {
    .dme-page .hashpro-way-container .restrict-width[data-v-17ab2e64] {
        flex-direction: column
    }

    .dme-page .hashpro-way-container .left-container[data-v-17ab2e64] {
        margin-right: 0;
        text-align: center;
        width: 100%
    }

    .dme-page .hashpro-way-container .right-container[data-v-17ab2e64] {
        width: 100%;
        justify-content: space-between
    }

    .dme-page .hashpro-way-container .right-container .item[data-v-17ab2e64] {
        align-items: center;
        text-align: center
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .hashpro-way-container .restrict-width[data-v-17ab2e64] {
        padding: 0 5%
    }

    .dme-page .hashpro-way-container .left-container .heading[data-v-17ab2e64] {
        font-size: 1.5rem;
        margin-bottom: 1.5rem
    }

    .dme-page .hashpro-way-container .left-container .desc[data-v-17ab2e64] {
        font-size: .875rem
    }

    .dme-page .hashpro-way-container .left-container .desc p[data-v-17ab2e64] {
        margin-bottom: .2rem
    }

    .dme-page .hashpro-way-container .right-container .item[data-v-17ab2e64] {
        font-size: .75rem;
        padding: 1.5rem .5rem;
        margin-bottom: .5rem;
        max-width: 9.6rem
    }

    .dme-page .hashpro-way-container .right-container .item img[data-v-17ab2e64] {
        height: 2.5rem;
        width: 2.5rem;
        max-width: inherit;
        margin-bottom: .875rem
    }
}

.dme-page svg:not(:root) {
    overflow: hidden
}

.dme-page * {
    box-sizing: border-box
}

.dme-page h2 {
    margin-bottom: 10px;
    font-weight: 700
}

.dme-page h2 {
    margin-top: 20px;
    font-size: 32px;
    line-height: 36px
}

.dme-page ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px
}

.dme-page .w-embed:after,
.dme-page .w-embed:before {
    content: " ";
    grid-area: 1/1/2/2;
    display: table
}

.dme-page .w-embed:after {
    clear: both
}

.dme-page h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    font-weight: 700;
    line-height: 130%
}

.dme-page .margin-bottom {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0
}

.dme-page .margin-bottom .margin-medium {
    margin-bottom: 2rem
}

.dme-page .text-align-center {
    text-align: center
}

.dme-page .text-size-regular {
    font-size: 16px
}

.dme-page .text-size-regular.li {
    margin-bottom: 8px;
    font-weight: 400
}

.dme-page .heading-large {
    font-family: Gilroy, sans-serif;
    font-size: 3.25rem;
    line-height: 140%
}

.dme-page .heading-who {
    margin-top: 0
}

.dme-page .forwho-card {
    background-color: #000;
    border: 1px solid rgba(255, 255, 255, .2);
    padding: 1rem;
    border-radius: 8px;
    color: #fff;
    margin: 15px 0;
    transition: .1s
}

.dme-page .forwho-card:hover {
    scale: 1.015
}

.dme-page .forwho-card-header {
    display: flex;
    align-items: center
}

.dme-page .forwho-card-header .emoji {
    font-size: 25px;
    margin: auto 0
}

.dme-page .forwho-card-header h3 {
    background-color: rgba(0, 0, 0, .85);
    padding: 1px 5px;
    letter-spacing: .03rem;
    margin: auto 10px;
    margin-left: 4px;
    font-weight: 600;
    font-size: 1.1rem !important;
    color: #f7c20a;
    text-transform: uppercase
}

.dme-page .forwho-card p {
    padding-left: 1%;
    margin-top: 8px;
    font-size: .96rem;
    font-weight: 500;
    color: #fff
}

@media screen and (max-width: 600px) {
    .dme-page .forwho-card p {
        padding-left: .9%;
        font-weight: 400
    }
}

.dme-page .tick-wrapper {
    color: #000 !important
}

.dme-page .tick-wrapper svg {
    margin: auto;
    margin-bottom: 1px;
    width: 12px
}

.dme-page .div-block-257 {
    grid-column-gap: 12px;
    grid-row-gap: 0;
    background-color: var(--hashpro);
    color: #000 !important;
    border: 1px solid rgba(255, 255, 255, .41);
    border-radius: 8px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: center;
    margin-bottom: 1rem;
    margin-right: .7rem;
    padding: 1rem;
    font-size: 1.25rem;
    font-weight: 500;
    line-height: 1.5;
    display: grid;
    transition: .2s
}

.dme-page .div-block-257:hover {
    background-color: var(--hashpro-hover)
}

.dme-page .div-block-257 h3 {
    margin: 0;
    font-size: 1.1rem
}

.dme-page .div-block-257 li {
    font-weight: 500 !important;
    font-size: .9rem;
    color: rgba(0, 0, 0, .88)
}

.dme-page .tick-wrapper {
    width: 1.5em;
    height: 1.5rem;
    background-color: #f7c20a;
    border-radius: 12rem;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    overflow: hidden
}

.dme-page .div-block-259 {
    padding-top: 20px;
    color: #fff !important;
    max-width: 1440px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    display: flex
}

.dme-page .who-card-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 50px;
    column-gap: 50px;
    row-gap: 30px
}

.dme-page .who-card svg {
    width: 20px;
    margin: 20px;
    height: -moz-fit-content;
    height: fit-content
}

.dme-page .who-card {
    margin: 0 auto;
    max-width: 90%;
    padding: 20px;
    padding-left: 0;
    border-radius: 8px;
    color: #000;
    display: flex;
    background-color: var(--hashpro)
}

.dme-page .who-card li {
    padding: 6px 0
}

.dme-page .div-block-310 {
    max-width: 64rem;
    justify-content: space-around;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    padding-bottom: 30px
}

.dme-page .div-block-311 {
    flex-direction: column;
    flex: 1;
    display: flex;
    margin-right: 30px;
    margin-left: 30px
}

.dme-page .list-7 {
    padding-left: 20px
}

@media(min-width: 0)and (max-width: 600px) {
    .dme-page .who-card-container {
        grid-template-columns: 1fr
    }
}

@media screen and (max-width: 991px) {
    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }
}

@media screen and (max-width: 767px) {
    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }
}

@media screen and (max-width: 479px) {
    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }

    .dme-page .heading-large {
        line-height: 120%
    }

    .dme-page .tick-wrapper {
        padding: .5rem
    }
}

@media screen and (max-width: 700px) {
    .dme-page .div-block-310 {
        flex-direction: column
    }

    .dme-page .div-block-311 {
        margin-right: 20px;
        margin-left: 20px
    }
}

.dme-page .curriculum {
    background-color: #131313;
    color: #fff;
    margin: 0;
    padding: 20px 10%;
    padding-bottom: 40px;
    padding-top: 3rem;
}

.dme-page .curriculum .heading {
    margin-bottom: 10px
}

@media screen and (max-width: 767px) {

    .dme-page .curriculum .subheading,
    .dme-page .heading {
        text-align: center
    }
}

.dme-page .curriculum-container {
    margin-top: 30px
}

.dme-page .curriculum-container .right {
    height: 400px;
    overflow: auto
}

.dme-page .curriculum .curriculum-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    -moz-column-gap: 50px;
    column-gap: 50px
}

.dme-page .curriculum-container .course-card {
    color: #fff;
    position: relative;
    border-radius: 10px;
    background-color: #000;
    font-weight: 500;
    padding: 15px;
    margin: 20px 0;
    border: 1px solid var(--hashpro);
    opacity: .3;
    transition: .3s;
    box-shadow: 1px 1px var(--hashpro);
    font-size: 14px
}

.dme-page .curriculum-container .course-card:hover {
    cursor: pointer;
    box-shadow: 3px 3px var(--hashpro)
}

.dme-page .curriculum-container .course-card .course {
    transform: translateY(-10px);
    opacity: 0;
    position: absolute;
    top: -15px;
    background-color: #fff;
    padding: 5px 10px;
    font-size: .9rem;
    border-radius: 5px;
    transition: .3s;
    color: #000
}

.dme-page .curriculum-container .course-card.active .course {
    transform: translateY(0);
    opacity: 1
}

.dme-page .curriculum-container .course-card.active {
    opacity: 1;
    box-shadow: 3px 3px var(--hashpro);
    scale: 1.03
}

.dme-page .curriculum .points {
    display: flex;
    margin: 20px 0
}

.dme-page .curriculum .points p {
    margin: auto 7px;
    font-size: 14px
}

.dme-page .curriculum .points img {
    filter: invert(100%);
    height: 18px;
    width: 18px
}

.dme-page .curriculum #course-2 {
    display: none
}

.dme-page .curriculum #course-3 {
    display: none
}

.dme-page .curriculum #course-4 {
    display: none
}

.dme-page .curriculum #course-5 {
    display: none
}

.dme-page .curriculum #course-6 {
    display: none
}

.dme-page .curriculum-container-mobile {
    display: none
}

@media screen and (max-width: 800px) {
    .dme-page .curriculum-container-mobile {
        display: block
    }

    .dme-page .curriculum-container .left {
        display: none
    }

    .dme-page .curriculum .curriculum-container .right {
        margin: 0 3%;
        margin-right: 1%
    }

    .dme-page .curriculum .curriculum-container .right h3 {
        margin-top: 0
    }

    .dme-page .curriculum .curriculum-container {
        grid-template-columns: 1fr
    }
}

.dme-page .curriculum-container-mobile .course-selector {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    flex-wrap: wrap
}

.dme-page .curriculum-container-mobile .course-selector .course-card-mobile {
    background-color: var(--hashpro);
    color: #000;
    padding: 5px 10px;
    border-radius: 1rem;
    font-size: 15px;
    font-weight: 500;
    opacity: .5;
    transition: .3s;
    margin: 8px 5px;
    text-align: center;
}

.dme-page .curriculum-container-mobile .course-selector p:hover {
    cursor: pointer
}

.dme-page .curriculum-container-mobile .course-selector p.active {
    opacity: 1;
    scale: 1.08
}

.dme-page #w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1740-14fe2356,
.dme-page #w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1742-14fe2356 {
    grid-area: span 1/span 1/span 1/span 1
}

.dme-page #w-node-_84d9717c-cb6a-6082-0b2c-24a1d2af1744-14fe2356 {
    grid-area: 2/2/3/3
}

.dme-page #w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a842-14fe2356,
.dme-page #w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a844-14fe2356 {
    grid-area: span 1/span 1/span 1/span 1
}

.dme-page #w-node-bb5871f7-9cdb-ac67-95b2-c2ec0232a846-14fe2356 {
    grid-area: 2/2/3/3
}

.dme-page .instructors-and-members-container {
    padding-top: 6rem;
    background: #000;
    color: #fff;
    position: relative;
    overflow: hidden
}

@media only screen and (max-width: 600px) {
    .dme-page .instructors-and-members-container {
        padding-top: 3rem;
    }

}

.dme-page .instructors-and-members-container .restrict-width {
    max-width: 90rem;
    margin: 0 auto
}

.dme-page .instructors-and-members-container h1 {
    margin-bottom: 30px
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container {
    display: flex;
    justify-content: center;
    margin-bottom: 3.5rem
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container {
    margin-right: 5.3vw;
    width: -moz-max-content;
    width: max-content;
    position: relative;
    padding-left: 3.375rem;
    padding-bottom: 2.75rem;
    height: -moz-max-content;
    height: max-content;
    display: flex;
    justify-content: flex-end
}

.dme-page .instructor-heading {
    color: #fff;
    margin: 0 50px;
    text-align: center
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .dots {
    height: 12rem;
    width: 12rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image {
    width: 22.9vw;
    max-width: 20.6rem;
    height: 23.33vw;
    max-height: 21rem;
    border-radius: .875rem;
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin: 0 auto
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image img {
    height: auto;
    width: 24.64vw;
    max-width: 22.17rem;
    position: absolute;
    top: 0
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio {
    width: 40.5rem;
    padding-bottom: 2.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title {
    font-style: normal;
    font-weight: 500;
    margin-bottom: 0 !important;
    height: -moz-fit-content !important;
    height: fit-content !important
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title span {
    font-weight: 800;
    font-size: 1.4rem
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title .designation span {
    font-size: 1rem !important;
    font-weight: 300
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .desc {
    font-size: 1.25rem;
    font-weight: 500
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container {
    display: flex;
    margin-top: 1.5rem
}

.dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container img {
    height: 2rem;
    width: 2rem;
    max-width: inherit;
    margin-right: 1.25rem
}

.dme-page .members-container {
    padding-top: 50px;
    padding-bottom: 30px;
    background-color: #080808;
    color: #fff
}

.dme-page .instructors-and-members-container .members-container .guests-container {
    margin-right: 10%;
    margin-left: 10%
}

.dme-page .instructors-and-members-container .members-container .section-heading {
    font-style: normal;
    font-weight: 800;
    font-size: 52px;
    margin-bottom: 5px;
    text-align: center
}

.dme-page .instructors-and-members-container .members-container .section-subheading {
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 145%;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 0;
    color: #b7b7b7
}

@media only screen and (max-width: 1000px) {
    .dme-page .instructors-and-members-container .instructor-container {
        padding: 0
    }

    .dme-page .instructors-and-members-container .instructor-container .heading {
        text-align: left
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container {
        flex-direction: column;
        margin-bottom: .5rem;
        padding: 40px
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container {
        margin-right: 0;
        padding-left: 0
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image {
        width: 84vw;
        height: 50.25vw
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image img {
        height: auto;
        width: 100%
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio {
        width: 100%
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .instructors-and-members-container .instructor-container {
        padding: 0
    }

    .dme-page .instructors-and-members-container .instructor-container .heading {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        text-align: left
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container {
        width: 100%;
        padding-left: 0;
        padding-bottom: 0;
        margin-bottom: 1.5rem;
        justify-content: left
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .dots {
        display: none
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image {
        width: 100%;
        height: 15.75rem;
        border-radius: .5rem
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .instructor-image-container .instructor-image img {
        height: auto;
        width: 100%
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio {
        width: 100%
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title {
        font-size: 1.25rem;
        margin-bottom: 1rem
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .title span {
        font-weight: 800
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .desc {
        font-size: .875rem
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container {
        margin-top: 1.25rem
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container .bio .social-icons-container img {
        height: 1.25rem;
        width: 1.25rem;
        max-width: inherit;
        margin-right: 1rem
    }

    .dme-page .instructors-and-members-container .members-container .guests-container {
        margin-bottom: 1rem
    }

    .dme-page .instructors-and-members-container .members-container .section-heading {
        font-size: 1.5rem;
        margin-bottom: 1rem
    }

    .dme-page .instructors-and-members-container .members-container .section-subheading {
        font-size: .875rem;
        width: 100%;
        margin-bottom: 0
    }
}

.dme-page .show-more-show-less {
    display: inline;
    line-height: 22px
}

.dme-page .member-card-container {
    padding: 4.625rem 0 0;
    display: flex !important;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 300px;
}

@media screen and (min-width: 800px) {
    .dme-page .member-card-container {
        scale: .85
    }

    .dme-page .swiper-button-container {
        padding-bottom: 0
    }
}

.dme-page .member-card-container .member-image-container {
    position: absolute;
    top: 0;
    z-index: 2;
    height: -moz-fit-content;
    height: fit-content;
    width: 9.25rem;
    max-width: inherit;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%
}

.dme-page .member-card-container .member-image-container img {
    height: 8.5rem;
    width: 8.5rem;
    border-radius: 50%;
    padding: 5px;
    background-color: #3d3d3d;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center
}

.dme-page .member-card-container .member-details-container {
    border: 2px solid rgba(43, 43, 43, .58);
    border-radius: 1rem;
    padding: 5.625rem 2rem 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    z-index: 1;
    max-width: 300px;
    height: -moz-fit-content;
    height: fit-content;
    text-align: center;
    background-size: cover;
    background-color: #131313;
    color: #fff
}

.dme-page .member-card-container .member-details-container .name {
    border-radius: 5px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 8px;
    margin: auto;
    font-style: normal;
    font-weight: 800;
    font-size: 1.1rem !important;
    text-align: center;
    margin-bottom: .625rem;
    color: white;
}

.dme-page .member-card-container .member-details-container .role {
    color: #fff;
    font-size: .95rem;
    line-height: 145%;
    display: -webkit-box;
    font-weight: 500;
    margin: 0 auto;
    width: -moz-fit-content;
    width: fit-content;
    margin-bottom: 1.5rem
}

.dme-page .socials svg {
    color: #000
}

.dme-page .member-card-container .member-details-container .socials img {
    filter: brightness(50%);
    height: 1.5rem;
    width: 1.5rem;
    max-width: inherit;
    margin-right: 1rem;
    cursor: pointer;
    transition: .2s
}

.dme-page .member-card-container .member-details-container .socials img:hover {
    scale: 1.2
}

.dme-page .member-card-container:hover .member-details-container .socials {
    display: flex
}

@media only screen and (max-width: 512px) {
    .dme-page .member-card-container .member-image-container {
        height: 8.25rem;
        width: 8.25rem
    }

    .dme-page .member-card-container .member-image-container img {
        height: 7.125rem;
        width: 7.125rem;
        padding: .375rem;
        background: #393939
    }

    .dme-page .member-card-container .member-details-container {
        border-radius: .875rem;
        padding: 5rem .875rem 1.75rem;
        width: 20.0625rem;
        height: -moz-fit-content;
        height: fit-content
    }

    .dme-page .member-card-container .member-details-container .name {
        font-size: 1.25rem;
        margin-bottom: .625rem
    }

    .dme-page .member-card-container .member-details-container .role {
        font-size: .875rem;
        margin-bottom: 1.5rem
    }

    .dme-page .member-card-container .member-details-container .socials {
        display: flex
    }

    .dme-page .member-card-container .member-details-container .socials img {
        height: 1.25rem;
        width: 1.25rem;
        margin-right: .625rem
    }
}


.dme-page a:hover {
    text-decoration: none
}

.dme-page .output {
    overflow-wrap: anywhere
}

.dme-page .margin-medium {
    margin: 2rem
}

.dme-page .margin-bottom {
    height: auto;
    margin: 0 0 1rem
}

.dme-page .div-block-366 {
    text-transform: uppercase;
    color: #000;
    width: 80%;
    background-color: var(--hashpro);
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    padding-top: .5rem;
    padding-bottom: .5rem;
    font-size: 15px !important;
    font-weight: 700;
    position: absolute;
    top: -9%;
    bottom: auto;
    left: 0;
    right: 0
}

.dme-page .heading-large-2 {
    font-size: 32px;
    line-height: 140%
}

.dme-page .heading-large-2.text-align-center.pm2 {
    margin-bottom: 20px;
    font-family: IBM Plex Sans Condensed, sans-serif;
    font-size: 42px;
    font-weight: 600
}

.dme-page .card {
    text-align: center;
    border: 1px solid #4d4d4d;
    border-radius: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2.5rem 1.5rem;
    padding-bottom: 2rem !important;
    display: flex;
    position: relative
}

.dme-page .card.pm2 {
    background-color: #000 !important;
    border-radius: 8px;
    padding-top: 3rem;
    padding-bottom: 3rem;
    color: #fff
}

.dme-page .section-22 {
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 1fr;
    display: grid
}

.dme-page .text-block-215 {
    margin: 0;
    font-size: 17px;
    font-weight: 500;
    color: var(--hashpro);
    margin-bottom: 50px
}

.dme-page .text-block-215.text-align-center {
    line-height: 1.5
}

.dme-page .container-large-2 {
    padding: 50px 0;
    margin: 0 auto;
    padding-left: 7%;
    padding-right: 7%;
    background-color: #0e0e0e;
    color: #fff
}

@media screen and (max-width: 500px) {
    .dme-page .container-large-2 {
        padding-top: 3rem
    }
}

.dme-page .container-large-2 .heading {
    margin: 0
}

.dme-page .text-align-center {
    text-align: center
}

@media screen and (max-width: 991px) {
    .dme-page .margin-medium {
        margin: 1.5rem
    }

    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }
}

@media screen and (max-width: 479px) {
    .dme-page .margin-bottom {
        margin: 0
    }

    .dme-page .div-block-366 {
        width: 90%;
        font-size: 14px
    }

    .dme-page .heading-large-2 {
        line-height: 120%
    }

    .dme-page .heading-large-2.text-align-center.pm2 {
        margin-bottom: 0;
        font-size: 24px
    }

    .dme-page .section-22 {
        grid-template-columns: 1fr
    }

    .dme-page .section-22.margin-bottom.margin-medium {
        grid-column-gap: 16px;
        grid-row-gap: 40px;
        grid-template-columns: 1fr
    }
}

@media screen and (max-width: 479px) {
    .dme-page #w-node-d57d53ab-fae1-8635-5e73-ffdec08ac4ea-6d667f4f {
        grid-area: span 1/span 1/span 1/span 1
    }
}

.dme-page .w-button {
    color: #fff;
    line-height: inherit;
    cursor: pointer;
    background-color: #3898ec;
    border: 0;
    border-radius: 0;
    padding: 9px 15px;
    text-decoration: none;
    display: inline-block
}

.dme-page .margin-medium {
    margin: 2rem
}

.dme-page .margin-bottom {
    height: auto;
    margin: 0 0 1rem
}

.dme-page .margin-bottom.margin-regular {
    margin-bottom: 10px
}

.dme-page .margin-bottom.margin-custom {
    margin-bottom: 1.5rem
}

.dme-page .html-embed-45 {
    justify-content: center;
    align-items: center;
    display: flex
}

.dme-page .d2c-offer-container {
    width: 90%;
    grid-column-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 1fr;
    align-content: stretch;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    display: grid
}

.dme-page .d2c-offer-container.app {
    grid-column-gap: 3rem
}

.dme-page .text-block-221 {
    color: #d8d8d8;
    font-size: 1.05rem;
    line-height: 30px
}

.dme-page .pricing-component_right {
    text-align: left;
    border: 1px dashed #491eb8;
    border-radius: 1rem;
    padding: 2.5rem;
    padding-top: 1.2rem;
    padding-bottom: 2rem;
    height: -moz-fit-content;
    height: fit-content;
    margin: auto
}

.dme-page .pricing-component_right.pm2 {
    border: 1px solid var(--hashpro)
}

.dme-page .text-weight-bold-2 {
    font-style: normal;
    font-weight: 700
}

.dme-page .text-weight-bold-2.text-align-center {
    font-style: normal
}

.dme-page .app-chechbox {
    width: 1.2rem;
    height: 1.2rem;
    border: 2px solid #00b7fe;
    border-radius: 8px;
    padding: .5rem
}

.dme-page .app-chechbox.pm2 {
    background-color: var(--hashpro);
    border-style: none;
    border-radius: 80rem;
    justify-content: center;
    align-items: center;
    padding: .3rem;
    display: flex;
    margin: auto
}

.dme-page .app-chechbox.pm2 svg {
    color: #000 !important
}

.dme-page .text-span-84 {
    color: #bebebe
}

.dme-page .heading-large-2 {
    margin-bottom: 0;
    font-size: 25px;
    line-height: 140%
}

.dme-page .heading-large-2.white {
    color: #fff;
    font-weight: 600
}

.dme-page .offer-card {
    text-align: left;
    background-color: #fff;
    background-image: url(https://assets.website-files.com/625416528a3419ca302b6dd8/625c7b4bc8a26a7557c46205_Group/3193.svg), none, none;
    background-position: 0 0, 0 100%, 100% 0;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-size: 17%, 11%, 11%;
    border-radius: 20px;
    flex-direction: column;
    align-items: flex-start;
    margin: 0 2.5%;
    padding-bottom: 54px;
    padding-left: 40px;
    display: flex;
    box-shadow: 10px 10px 24px rgba(0, 0, 0, .05)
}

.dme-page .offer-card.app {
    background-color: rgba(0, 0, 0, 0);
    background-image: none;
    padding: 0 0 2.5rem 0
}

.dme-page .angel-investing_component {
    max-width: 72rem;
    text-align: center;
    /* margin-top: 45px; */
    margin-left: auto;
    margin-right: auto
}

.dme-page .who-program {
    padding-top: 10px;
    background-color: #060606 !important;
    color: #fff !important;
    padding: 3%;
    margin-top: 0;
    position: relative;
    overflow: hidden
}

@media screen and (max-width: 500px) {
    .dme-page .who-program {
        padding-top: 3rem;
        padding-bottom: 20px
    }
}

.dme-page .div-block-259 .heading {
    margin-bottom: 5px;
    color: #fff
}

.dme-page .cta-angel-investing {
    width: 100%;
    text-align: center;
    border-radius: .5rem;
    padding: .875rem 5.625rem;
    font-size: 16px;
    font-weight: 700;
    box-shadow: 0 0 6px 0 #f7c20a
}

.dme-page .cta-angel-investing:hover {
    background-color: #000
}

.dme-page .cta-angel-investing.pm2-gradient.offer {
    max-width: none
}

.dme-page .what-you-get {
    grid-column-gap: 5px;
    grid-row-gap: 16px;
    grid-template-rows: auto;
    grid-template-columns: auto 1fr;
    grid-auto-columns: 1fr;
    align-items: start;
    margin-top: 16px;
    line-height: 1.5;
    display: grid
}

.dme-page .what-you-get span {
    margin: auto 5px;
    font-weight: 500
}

.dme-page .what-you-get.pm2 {
    color: rgba(255, 255, 255, .8);
    margin-top: 17px;
    font-size: 16px
}

.dme-page .text-align-center {
    text-align: center
}

.dme-page .oval-button {
    background-color: var(--hashpro) !important;
    background-image: none !important;
    border-radius: 50px !important;
    color: #000;
    font-weight: 600 !important;
    transition: .2s;
    border: 1px solid rgba(0, 0, 0, 0)
}

.dme-page .oval-button:hover {
    background-color: #000 !important;
    color: var(--hashpro);
    border: 1px solid rgba(247, 196, 10, .2392156863)
}

@media screen and (max-width: 991px) {
    .dme-page .margin-medium {
        margin: 1.5rem
    }

    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }

    .dme-page .cta-angel-investing {
        padding: 1rem 3rem
    }
}

@media screen and (max-width: 767px) {
    .dme-page h2 {
        font-size: 2rem
    }

    .dme-page h3 {
        font-size: 1.5rem
    }

    .dme-page .margin-medium {
        margin: 1.25rem
    }

    .dme-page .margin-bottom {
        margin-top: 0;
        margin-left: 0;
        margin-right: 0
    }

    .dme-page .margin-bottom.margin-regular {
        margin-bottom: .5rem
    }

    .dme-page .d2c-offer-container {
        grid-template-columns: 1fr
    }

    .dme-page .cta-angel-investing.pm2-gradient.offer {
        display: block
    }
}

@media screen and (max-width: 500px) {
    .dme-page .margin-bottom {
        margin: 0
    }

    .dme-page .d2c-offer-container {
        width: 100%;
        grid-template-columns: 1fr
    }

    .dme-page .text-block-221 {
        font-size: 14px;
        line-height: 24px
    }

    .dme-page .pricing-component_right {
        padding-left: 1rem;
        padding-right: 1rem
    }

    .dme-page .heading-large-2 {
        font-size: 21px !important;
        line-height: 120%
    }

    .dme-page .offer-card {
        background-size: 30%, 17%, 21%;
        margin-top: 30px;
        margin-bottom: 0;
        padding: 44px 24px 60px;
        margin: 0 7%;
        margin-top: 10px
    }

    .dme-page .offer-card h3 {
        font-weight: 500
    }

    .dme-page .offer-card.app {
        padding: 0
    }

    .dme-page .cta-angel-investing {
        padding-top: .875rem;
        padding-bottom: .875rem;
        font-size: 16px
    }
}

.dme-page #pricing.w-node-d57d53ab-fae1-8635-5e73-ffdec08ac716-6d667f4f {
    align-self: stretch
}

@media screen and (max-width: 600px) {
    .dme-page #pricing.w-node-d57d53ab-fae1-8635-5e73-ffdec08ac716-6d667f4f {
        margin: 0 5%
    }
}

.dme-page .apply-btn[data-v-2fccc02f] {
    padding: 1rem 2.5rem;
    font-weight: 600;
    font-size: 1.125rem;
    background: #683fbe;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none;
    color: #f6f6ff;
    cursor: pointer
}

@media only screen and (max-width: 1000px) {
    .dme-page .apply-btn[data-v-2fccc02f] {
        margin: 0 auto 4rem
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .apply-btn[data-v-2fccc02f] {
        padding: .75rem 2.5rem;
        font-size: .75rem;
        border-radius: .375rem;
        margin-bottom: 2rem
    }
}

.dme-page .enrollment-process-container {
    padding: 6.25rem 7vw;
    background: #fafafa;
    color: #171421;
    position: relative;
    z-index: 2;
    overflow: hidden
}

.dme-page .enrollment-process-container .main-heading {
    font-style: normal;
    font-size: 2.875rem;
    text-align: center;
    margin-bottom: 5rem
}

.dme-page .enrollment-process-container .limited-seats {
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 130%;
    color: #000;
    margin: -4rem auto 5rem;
    text-align: center
}

.dme-page .enrollment-process-container .limited-seats span {
    color: #6334c8
}

.dme-page .enrollment-process-container .banner-container {
    background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
    box-shadow: 0 0 4rem rgba(104, 63, 190, .27);
    border-radius: 1.25rem;
    z-index: 2;
    margin: 0 4.625rem;
    overflow: hidden
}

.dme-page .enrollment-process-container .banner-container .background-image-container {
    padding: 2.5rem 4.5rem;
    background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner.d495bac.png);
    background-size: cover;
    background-position: 50%
}

.dme-page .enrollment-process-container .banner-container .background-image-container .text {
    width: 47.5vw
}

.dme-page .enrollment-process-container .banner-container .background-image-container .text .heading {
    font-style: normal;
    font-weight: 700;
    font-size: 2.25rem;
    color: #fdfdfd;
    margin-bottom: 1rem
}

.dme-page .enrollment-process-container .banner-container .background-image-container .text .content {
    font-size: 1.125rem;
    line-height: 150%;
    color: #fff;
    margin-bottom: 1.75rem
}

.dme-page .enrollment-process-container .banner-container .background-image-container .text .apply-btn {
    background: #e8ddff;
    border-radius: .375rem;
    padding: .75rem 1.5rem;
    font-family: "Plus Jakarta Sans";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    color: #171421;
    text-decoration: none;
    display: flex;
    align-items: center;
    width: -moz-max-content;
    width: max-content
}

@media only screen and (max-width: 880px) {
    .dme-page .enrollment-process-container .main-heading {
        text-align: left
    }

    .dme-page .enrollment-process-container .banner-container {
        box-shadow: 0 0 1.5rem rgba(104, 63, 190, .27);
        border-radius: .5rem;
        margin: .5rem 0 0
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container {
        padding: 2.5rem 4.5rem;
        background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner.d495bac.png);
        background-size: cover;
        background-position: 50%
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text {
        width: 47.5vw
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text .heading {
        font-style: normal;
        font-weight: 700;
        font-size: 2.25rem;
        color: #fdfdfd;
        margin-bottom: 1rem
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text .content {
        font-size: 1.125rem;
        line-height: 150%;
        color: #fff;
        margin-bottom: 1.75rem
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text .apply-btn {
        background: #e8ddff;
        border-radius: .375rem;
        padding: .75rem 1.5rem;
        font-style: normal;
        font-weight: 700;
        font-size: 1rem;
        color: #171421;
        display: flex;
        align-items: center;
        width: -moz-max-content;
        width: max-content
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .enrollment-process-container {
        padding: 2.5rem 7vw
    }

    .dme-page .enrollment-process-container .main-heading {
        font-size: 1.5rem;
        text-align: left;
        margin-bottom: 1.5rem
    }

    .dme-page .enrollment-process-container .limited-seats {
        font-size: .875rem;
        margin: -0.625rem auto 1.5rem;
        text-align: left
    }

    .dme-page .enrollment-process-container .banner-container {
        background: linear-gradient(145.07deg, #683fbe -2.83%, #493080 89%);
        box-shadow: 0 0 1.5rem rgba(104, 63, 190, .27);
        border-radius: .5rem;
        margin: 0
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container {
        padding: 1.25rem;
        background-image: url(https://www.hashpro.live/_nuxt/img/enrolment-steps-banner-mobile.ecf70b2.png)
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text {
        width: 100%;
        text-align: center
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text .heading {
        font-size: 1.125rem;
        margin-bottom: .5rem
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text .content {
        font-size: .75rem;
        margin-bottom: .75rem
    }

    .dme-page .enrollment-process-container .banner-container .background-image-container .text .apply-btn {
        border-radius: .375rem;
        padding: .5rem 3.75rem;
        font-size: .625rem;
        margin-bottom: 0
    }
}

.dme-page .apply-btn[data-v-2fccc02f] {
    padding: 1rem 2.5rem;
    font-weight: 600;
    font-size: 1.125rem;
    background: #683fbe;
    border-radius: .5rem;
    display: flex;
    align-items: center;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none;
    color: #f6f6ff;
    cursor: pointer
}

@media only screen and (max-width: 1000px) {
    .dme-page .apply-btn[data-v-2fccc02f] {
        margin: 0 auto 4rem
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .apply-btn[data-v-2fccc02f] {
        padding: .75rem 2.5rem;
        font-size: .75rem;
        border-radius: .375rem;
        margin-bottom: 2rem
    }
}

.dme-page .admission-process {
    background-color: #0a0a0a;
    color: #fff;
    text-align: center;
    padding: 0 9%
}

.dme-page .admission-process h1 {
    font-size: 2rem !important;
    margin: 0;
    margin-bottom: 5px
}

.dme-page .admission-process p {
    font-size: 15px !important
}

.dme-page .admission-card-container {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin-top: 10px
}

.dme-page .admission-card {
    text-align: center;
    max-width: 300px;
    margin: 20px 30px
}

.dme-page .admission-card h1 {
    font-size: 50px !important;
    margin: 0
}


.dme-page .admission-card p {
    margin-top: 10px;
    font-size: 14px
}

.dme-page .faq-section-container {
    background: #000;
    padding: 3rem 2vw;
    padding-bottom: 2.7rem
}

.dme-page .doubt-banner {
    transition: .4s;
    border-radius: 5px;
    position: fixed;
    bottom: 10px;
    left: 50%;
    transform: translate(-50%, 0);
    border: 1px solid rgba(187, 187, 187, .253);
    padding: 15px 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    margin: 0 auto;
    font-style: normal;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 140%;
    z-index: 100;
    background-color: #dadada
}

.dme-page .doubt-banner a {
    color: #000 !important;
    font-size: 16px;
    text-align: center
}

.dme-page .doubt-banner .phone-icon img {
    height: 30px;
    width: 30px
}

.dme-page .doubt-banner .contact-us {
    font-weight: 600;
    line-height: 147%;
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0 1.25rem;
    border-radius: .375rem;
    color: #000;
    text-decoration: none
}

.dme-page .doubt-banner .button {
    font-size: 16px;
    white-space: nowrap;
    background-color: #f7c20a;
    border-radius: 5px;
    text-align: center;
    color: #000 !important;
    padding: 5px 15px
}

.dme-page .doubt-banner .contact-us img {
    margin-right: .375rem;
    height: 2rem;
    max-width: inherit
}

.dme-page .faq-section-container .course-details-banner {
    padding: 3rem 4.5rem;
    padding-right: 1rem;
    margin: 0 5%;
    margin-top: 90px;
    background-color: #000;
    box-shadow: 0 0 10px var(--hashpro);
    border-radius: 1.25rem;
    position: relative;
    overflow: hidden;
    z-index: 2;
    display: flex;
    align-items: center
}

.dme-page .faq-section-container .course-details-banner .heading-banner {
    color: #fff;
    font-size: 1.3rem
}

.dme-page .faq-section-container .course-details-banner .ripple-effect {
    position: absolute;
    height: 35.375rem;
    width: 35.375rem;
    max-width: inherit;
    z-index: -1;
    right: -1.5rem
}

.dme-page .faq-section-container .course-details-banner .left-container {
    margin-right: 16vw;
    width: 61vw
}

.dme-page .faq-section-container .course-details-banner .left-container h2 {
    margin-bottom: 50px
}

.dme-page .faq-section-container .course-details-banner .left-container .heading {
    font-style: normal;
    font-weight: 800;
    font-size: 2.25rem;
    line-height: 2.75rem;
    color: var(--hashpro);
    margin-bottom: 1.5rem;
    text-transform: uppercase
}

.dme-page .faq-section-container .course-details-banner .left-container .mobile-container {
    display: none
}

.dme-page .faq-section-container .course-details-banner .left-container .apply-btn {
    padding: .875rem 5.625rem;
    display: flex;
    align-items: center;
    background: var(--hashpro);
    border-radius: 6.61765px;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.25rem;
    color: #171421;
    width: -moz-max-content;
    width: max-content;
    text-decoration: none;
    box-shadow: 0 0 6px 0 #f7c20a;
    text-transform: uppercase;
    transition: .3s;
    width: 100%;
    text-align: center;
    justify-content: center;
}

.dme-page .faq-section-container .course-details-banner .left-container .apply-btn:hover {
    scale: 1.1
}

.dme-page .faq-section-container .course-details-banner .right-container {
    display: flex;
    flex-wrap: wrap
}

.dme-page .faq-section-container .course-details-banner .right-container .content {
    padding: 1.25rem 0
}

.dme-page .faq-section-container .course-details-banner .right-container .content .heading {
    transform: translateX(-8px);
    font-size: 1.8rem;
    color: #cccad2;
    margin-bottom: .75rem
}

.dme-page .faq-section-container .course-details-banner .right-container .content .value {
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 130%;
    color: #f6f6ff
}

@media only screen and (max-width: 1210px) {
    .dme-page .faq-section-container .faq {
        width: 50rem
    }

    .dme-page .faq-section-container .course-details-banner .left-container {
        margin-right: 5vw
    }

    .dme-page .faq-section-container .course-details-banner .right-container .content {
        width: 90%;
        text-align: left
    }
}

@media only screen and (max-width: 1000px) {
    .dme-page .faq-section-container .faq {
        width: 100%
    }

    .dme-page .doubt-banner {
        width: 80%
    }

    .dme-page .faq-section-container .course-details-banner {
        padding: 2rem 0;
        border-radius: .5rem
    }

    .dme-page .faq-section-container .course-details-banner .ripple-effect {
        position: absolute;
        height: 15.625rem;
        width: 15.625rem;
        max-width: inherit;
        z-index: -1;
        right: -7.5rem
    }

    .dme-page .faq-section-container .course-details-banner .left-container {
        margin-right: 0;
        width: 100%;
        flex-direction: column;
        align-items: center;
        display: flex
    }

    .dme-page .faq-section-container .course-details-banner .left-container .heading {
        text-align: center;
        margin: 0 7%;
        margin-bottom: 20px
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        margin-bottom: 2rem
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container .content {
        padding: 1.25rem 1.5rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container .content .heading {
        font-size: 1rem;
        line-height: 175%;
        color: #cccad2;
        margin-bottom: .75rem
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container .content .value {
        font-style: normal;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 130%;
        color: #f6f6ff
    }

    .dme-page .faq-section-container .course-details-banner .left-container .apply-btn {
        background-color: var(--hashpro);
        padding: .875rem 5.625rem;
        display: flex;
        align-items: center;
        border-radius: 6.61765px;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1.25rem;
        color: #171421;
        width: -moz-max-content;
        width: max-content
    }

    .dme-page .faq-section-container .course-details-banner .right-container {
        display: none
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .faq-section-container {
        padding: 3rem 0;
        padding-bottom: 2.7rem
    }

    .dme-page .doubt-banner {
        padding: 12px 12px;
        width: 90%;
        font-size: .875rem;
        line-height: 140%
    }

    .dme-page .doubt-banner .contact-us {
        line-height: 116%;
        font-size: .75rem;
        border-radius: .375rem
    }

    .dme-page .doubt-banner .contact-us img {
        margin-right: .25rem;
        height: .875rem
    }

    .dme-page .faq-section-container .course-details-banner {
        padding: 1rem 1.3rem;
        padding-top: 2.5rem;
        margin: 1.5rem 5%;
        border-radius: .5rem;
        margin-bottom: 0
    }

    .dme-page .faq-section-container .course-details-banner .ripple-effect {
        height: 15.625rem;
        width: 15.625rem;
        right: -7.8125rem
    }

    .dme-page .faq-section-container .course-details-banner .left-container .heading {
        margin: auto;
        font-size: 1.25rem;
        margin-bottom: 1.15rem
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container .content {
        padding: .75rem 0;
        width: 100%
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container .content .heading {
        font-size: .75rem;
        line-height: 2.2rem;
        margin-bottom: 0;
        font-weight: 400
    }

    .dme-page .faq-section-container .course-details-banner .left-container .mobile-container .content .value {
        font-size: 1rem;
        line-height: 130%
    }

    .dme-page .faq-section-container .course-details-banner .left-container .apply-btn {
        padding: .75rem;
        font-size: .75rem;
        line-height: 1rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center
    }
}

.dme-page .faq-container {
    margin-top: 80px;
    width: 100%
}

.dme-page .faq-container .main-heading {
    margin-top: 0 !important;
    font-style: normal;
    font-size: 2.75rem;
    color: #fff;
    margin-bottom: 1rem;
    text-align: center
}

.dme-page .faq-container .main-heading.mobile {
    display: none
}

.dme-page .faq-container .faq {
    background: #fff;
    border: 1px solid #eaeaea;
    border-radius: .5rem;
    margin-bottom: 1rem;
    width: 55.2vw;
    color: #221c16
}

@media only screen and (max-width: 1210px) {
    .dme-page .faq-container .faq {
        width: 50rem
    }
}

@media only screen and (max-width: 1000px) {
    .dme-page .faq-container .faq {
        width: 100%
    }
}

@media only screen and (max-width: 512px) {
    .dme-page .faq-container .main-heading.mobile {
        display: block;
        font-size: 1.5rem;
        margin-bottom: 1.5rem
    }

    .dme-page .faq-container .faq {
        margin-bottom: .75rem
    }
}

.dme-page .get-in-touch {
    text-align: center;
    color: #fff;
    margin: 0 5%;
    margin-top: 30px;
    margin-bottom: 80px;
    font-size: 30px !important
}

.dme-page footer {
    text-align: center;
    margin: 0;
    margin-bottom: 50px
}

@media only screen and (max-width: 600px) {
    .dme-page footer {
        text-align: left
    }
}

.dme-page .footer-social-media {
    width: -moz-fit-content;
    width: fit-content;
    margin: auto;
    margin-top: 30px
}

.dme-page .footer-social-media a {
    padding: 0 10px;
    margin: 0 5px
}

.dme-page .footer-social-media a:hover img {
    transition: .2s;
    filter: invert(67%) sepia(97%) saturate(476%) hue-rotate(358deg) brightness(102%) contrast(94%);
    scale: 1.2
}

.dme-page .extra-details {
    max-width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center
}

.dme-page .extra-details-card {
    max-width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    color: #fff
}

@media only screen and (min-width: 600px) {
    .dme-page .extra-details-card {
        margin-top: 15px
    }
}

.dme-page .last {
    padding: 0 10% !important;
    margin: auto;
    text-align: center
}

.dme-page .last a {
    margin-left: 3px !important
}

@media(min-width: 0)and (max-width: 600px) {
    .dme-page .extra-details {
        flex-direction: column
    }

    .dme-page .extra-details-card {
        margin-bottom: 15px
    }
}

.dme-page .extra-details-card a {
    color: #fff;
    transition: .5s;
    font-weight: 700
}

.dme-page .extra-details-card a:hover {
    color: var(--hashpro)
}

.dme-page .extra-details-card a {
    margin-left: 10px
}

@keyframes marquee-anim {
    from {
        transform: translateX(0)
    }

    to {
        transform: translateX(-180px)
    }
}

.dme-page .overflow-hidden {
    overflow: hidden
}

.dme-page .marquee {
    display: flex;
    animation: marquee-anim linear infinite 3s;
    transition: .2s
}

.dme-page .marquee img {
    border-top: 1px solid var(--hashpro);
    border-bottom: 1px solid var(--hashpro);
    width: 180px;
    padding: 2px 20px;
    transition: .5s
}

.dme-page .marquee-text {
    margin: 0 50px;
    font-size: 20px
}

.dme-page .extra-details {
    max-width: 90%;
    margin-top: 20px;
    margin-left: 5%;
    margin-right: 5%;
    display: flex;
    justify-content: center;
    align-items: center
}

.dme-page .extra-details-card {
    font-size: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row
}

@media(min-width: 0)and (max-width: 600px) {
    .dme-page .extra-details {
        flex-direction: column
    }

    .dme-page .extra-details-card {
        margin-bottom: 15px
    }
}

.dme-page .extra-details-card a {
    color: #fff;
    transition: .5s;
    font-weight: bold
}

.dme-page .extra-details-card a:hover {
    color: var(--hashpro)
}

.dme-page .extra-details-card a {
    margin-left: 10px
}

@media screen and (min-width: 600px) {
    .dme-page .hashpro-way-container[data-v-17ab2e64] {
        padding-top: 60px;
        padding-bottom: 90px
    }

    .dme-page .curriculum .heading {
        margin-top: 50px
    }

    .dme-page .admission-process {
        padding-top: 60px;
        padding-bottom: 40px
    }
}

@media screen and (max-width: 600px) {
    .dme-page #form {
        transform: translateY(-35px)
    }
}

@media screen and (max-width: 500px) {
    .dme-page .phone-heading {
        display: block !important
    }

    .dme-page .div-block-427.pm2 {
        margin-top: 0
    }

    .dme-page .cta-1 {
        padding-top: 40px
    }

    .dme-page .instructors-and-members-container .instructor-container .instructor-bio-container {
        padding-bottom: 10px
    }

    .dme-page .home-section {
        margin-top: 0rem !important
    }

    .dme-page .heading-who {
        margin-top: 0
    }

    .dme-page .admission-process {
        padding-top: 60px;
        padding-bottom: 20px
    }
}

.dme-page .splide__slide {
    height: -moz-fit-content;
    height: fit-content;
    margin: auto
}

.dme-page .carousel-container img {
    border-radius: 5px;
    margin: auto
}

.dme-page .carousel-container {
    text-align: center
}

.dme-page .cta-1.two {
    background-color: #404040;
    background-size: cover
}

.dme-page .cta-1.two .subheading {
    text-transform: uppercase;
    color: #fff
}

.dme-page .cta-1.two button {
    background-color: #1a1a1a;
    box-shadow: rgba(0, 0, 0, .3) 0 19px 38px, rgba(0, 0, 0, .22) 0 15px 12px
}

.dme-page .cta-1.two button:hover {
    background-color: #353535;
    color: #fff
}

.dme-page .cta-1.two .undertext {
    color: #888 !important;
    margin-top: 12px
}

.dme-page li {
    margin: auto !important
}

.dme-page .home-content_left {
    grid-area: hl
}

.dme-page .home-content_left {
    color: #fff;
    padding-left: 10%
}

.dme-page .vsl-container {
    padding-right: 10%;
    grid-area: ss
}

@media only screen and (max-width: 500px) {
    .dme-page .home-content_left {
        max-width: 90%;
        margin: auto
    }

    .dme-page .vsl-container {
        max-width: 90%;
        margin: auto
    }
}

@media only screen and (max-width: 1000px) {
    .dme-page .home-content_left {
        padding-left: 0;
        max-width: 90%;
        margin: auto
    }

    .dme-page .vsl-container {
        max-width: 90%;
        margin: auto;
        padding-right: 0
    }
}

.dme-page .cta-1.one {
    margin-top: 2rem;
    grid-area: cta
}

.dme-page .hashpro-way-container {
    grid-area: way
}

.dme-page .form {
    grid-area: form
}

.dme-page .top-height {
    margin-top: 150px
}

@media only screen and (max-width: 500px) {
    .dme-page .top-height {
        margin-top: 100px
    }
}

.dme-page .phone-form-switcher {
    -moz-column-gap: 5%;
    column-gap: 5%;
    display: grid;
    grid-template-areas: "hl ss" "cta cta" "way way " "form form";
    grid-template-rows: max-content max-content max-content;
    grid-template-columns: 1fr 1fr;
    row-gap: 1rem;
}

@media only screen and (max-width: 1000px) {
    .dme-page .phone-form-switcher {
        grid-template-areas: "hl hl" "ss ss" "cta cta " "way way " "form form"
    }
}

@media only screen and (max-width: 500px) {
    .dme-page .phone-form-switcher {
        grid-template-rows: max-content;
        grid-template-areas: "hl " "form" "ss" "way" "cta ";
        grid-template-columns: 1fr
    }
}

@media only screen and (min-width: 500px) {
    .dme-page .form-mobile {
        display: none
    }
}

.dme-page .form-mobile p,
.dme-page .form-mobile label {
    color: #000
}

.dme-page #form {
    transform: translateY(-100px)
}