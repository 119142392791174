.instaform {
  padding: 8rem 0;
  color: #000;
  width: 50%;
  background-color: #fffefa;
}

.multistepform-container {
  width: 100%;
  overflow: hidden;
  display: flex;
}

.instaRightform {
  position: relative;
}

#aoibg::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-image: url("../../../public/global/aoi/img/instaAOIBg.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

#dmxbg::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url("../../instaimages/dmxbg.png");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}

#financebg::before {
  content: " ";
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  background-image: url("../../instaimages/financebootcamp.jpg");
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: cover;
}

.instaRightform {
  width: 50%;
  background-color: #000;
  color: #fcd00e;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.instaRightform h3 {
  position: relative;
  z-index: 3456;
}
.instaRightform p {
  font-size: 1rem;
  color: #fffefa;
  max-width: 90%;
  margin: 0 auto;
  position: relative;
  z-index: 3456;
}

.stepform {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 0rem;
  overflow: hidden;
}
.form-field {
  max-width: 90%;
  margin: 0.3rem auto;
}

.stepform h3 {
  text-align: center;
  margin-bottom: 2rem;
  color: #000;
  text-decoration: underline 2px solid black;
  font-size: 25px;
}

.stepform .checkfeildinsta {
  width: 10% !important;
}
.stepform .textareafeild {
  height: 150px;
}
.instaform button {
  all: unset;
  padding: 0.3rem 1rem;
  background-color: #fcd00e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  cursor: pointer;
  box-shadow: 2px 2px #000;
}

.stepform .arrow-insta {
  color: #000;
}

.btninstagroup {
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}
.stepform .readiofeild {
  display: flex;
  margin-top: 0.3rem;
}

.stepform .readi-btn {
  position: relative;
  height: 20px;
  width: 20px;
  margin: 0.3rem;
  padding: 0;
  background-color: #fcd00e;
}

.stepform label {
  color: black;
  opacity: 1;
  font-weight: 600;
}
.stepform .field {
  font-size: 13px;
  border: 0.2px solid rgb(61, 61, 61);
  color: #000;
  padding: 0.3rem 0.5rem;
  border-radius: 5px;
  outline: none;
}

.error {
  color: red;
}

.stepform .field option {
  color: black;
}
.stepform .form-field > label {
  margin-top: 0.5rem;
  display: inline-block;
}
.slectinstaocc > label {
  display: flex;
  gap: 0;
  justify-content: start;
  align-items: center;
}

.singlenextinstabtn {
  display: flex;
  justify-content: end;
  max-width: 90%;
  margin: 0 auto;
  margin-top: 1.5rem;
}

.readiofeelabel {
  display: flex;
}
.checkadiofee {
  width: 10%;
  margin: 0.3rem 0rem;
}
.radiodmxmode {
  margin-left: 1rem;
}

.instaRightform h3 {
  font-size: 2rem;
  width: 90%;
  margin: 0 auto;
}

.instathanks {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.instathanks p {
  max-width: 50%;
}

.instathanks button {
  all: unset;
  padding: 0.5rem 1rem;
  background-color: #fcd00e;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: black !important;
  cursor: pointer;
  box-shadow: 2px 2px #000;
  gap: 10px;
  margin-top: 1rem;
}

.instathanks h2 {
  font-size: 2rem;
  color: #fcd00e;
}

.instathanks .right-div {
  height: 140px;
  width: 140px;
  background-color: #fcd00e;
  clip-path: circle(50% at 50% 50%);

  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 768px) {
  .stepform {
    max-width: 100%;
  }
  .stepform {
    overflow: hidden;
  }
  .readiofeilddmx {
    margin-left: 1rem;
  }
  .instathanks p {
    max-width: 90%;
  }

  .multistepform-container {
    flex-direction: column;
  }
  .instaform {
    width: 100%;
    margin: 0 auto;
    padding: 2rem 0;
    overflow: hidden;
  }
  .instaRightform {
    padding: 7rem 0;
    padding-bottom: 2rem;
    width: 90%;
    margin: 0 auto;
  }
  .instaRightform h3 {
    font-size: 2rem;
    width: 100%;
    margin: 0 auto;
  }
  .instaRightform p {
    font-size: 1rem;
    color: #fffefa;
    max-width: 100%;
    margin: 0 auto;
  }
  .instaform {
    width: 100%;
    margin: 0 auto;
    padding: 0rem 0 1rem;
    overflow: hidden;
  }
}

#aoitextarea {
  height: 150px;
}

.readiofeild label {
  all: unset;
}

.readiofeild {
  display: flex;
  align-items: center;
  gap: 10px;
}

.stepform-tittle {
  max-width: 90%;
  margin: 0 auto;
}

.required-star {
  color: red;
  margin-left: 0px;
}

.instaform {
}

.stepform {
  border-top: 10px solid #807e7e;
  transition: 0.5s ease;
}
