@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul,
  ol {
    list-style: revert;
  }
}

body {
  margin: 0;
}

.container {
  max-width: 100% !important;
}

img {
  max-width: none;
}

.bodyy > div {
  padding-left: 10%;
  padding-right: 10%;
}

.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

* {
  box-sizing: border-box;
}

article.yt-lite {
  width: 100%;
  border-radius: 1rem;
  background-image: url("https://firebasestorage.googleapis.com/v0/b/hashpro-academy-e69e8.appspot.com/o/dmx-thumbnail.webp?alt=media&token=841768eb-b22e-448e-a779-1d79fb9978fc") !important;
}

:root {
  --blue: #1e90ff;
  --white: #ffffff;
  --hashpro: #f8d00d;
  --secondary: #30312f;
  --hashpro-hover: #ffdb00;
  --hashprolight: #f7c40a60;
  --testimonial-gradient: linear-gradient(
    linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%)
  );
  --swiper-theme-color: #f7c20a !important;
}

body {
  color: white;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
button,
img,
span,
p,
input,
ul,
li,
div,
select,
tr,
td,
th,
table {
  margin: 0;
  /* font-family: 'Sora', sans-serif; */
  font-family: "DM Sans", sans-serif;
}

a {
  text-decoration: none;
  color: white;
  font-weight: 600;
}

html {
  overflow-x: hidden;
}

body {
  margin: 0;
  background-color: #050505;
  color: white;
}

section {
  padding: 0 8%;
  background-color: #050505;
  z-index: 9995;
  position: relative;
}

@media only screen and (min-width: 1800px) {
  body > div {
    padding: 0 15%;
  }
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background: #2c2c2c;
}

::-webkit-scrollbar-thumb {
  height: 10px;
  background: var(--hashpro);
}

::-webkit-scrollbar-thumb:hover {
  background: var(--hashpro-hover);
}

::selection {
  background-color: var(--hashpro);
  color: black;
}

html {
  overflow: -moz-scrollbars-vertical;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

hr {
  color: #4c4c4c !important;
}

.center {
  text-align: center;
}

.subheading {
  margin-top: 10px;
  font-size: 1.05rem;
  line-height: 22px;
  color: #b7b7b7;
  font-weight: 400;
}

.heading {
  font-size: 2.5rem;
  text-align: center;
  color: white;
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .subheading {
    font-size: 0.96rem;
  }

  .heading {
    font-size: 1.9rem;
  }
}

.bold {
  font-weight: 500;
  font-family: Sora, Arial, Helvetica, sans-serif;
}

.bold-italic {
  font-weight: 600;
  font-style: italic;
}

.yellow-text {
  color: var(--hashpro) !important;
}

.black-font {
  color: black !important;
}

button {
  font-family: Sora, Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: black;
  font-size: 0.8rem;
  background-color: white;
  border-radius: 25px;
  display: block;
  width: fit-content;
  transition: 0.2s;
  border: 1px solid transparent;
  padding: 8px 25px;
}

@media only screen and (max-width: 600px) {
  button {
    font-size: 0.8rem;
  }
}

button:hover {
  cursor: pointer;
  background-color: black;
  color: white;
  /* scale: 1.03; */
  border: 1px solid white;
}

button.yellow {
  background-color: var(--hashpro);
  color: black;
}

button.yellow:hover {
  background-color: transparent;
  border: 1px solid var(--hashpro);
  color: white;
}

button.yellow-black {
  background-color: var(--hashpro);
  color: black;
}

button.yellow-black:hover {
  background-color: white;
  border: 1px solid var(--hashpro);
  color: black;
}

button.black {
  border: 1px solid white;
  background-color: black;
  color: white;
}

button.black:hover {
  border-color: black;
  background-color: white;
  color: black;
}

button.white {
  border: 1px solid black;
  background-color: white;
  color: black;
}

button.white:hover {
  border-color: white;
  background-color: black;
  color: white;
}

footer > * {
  z-index: 0;
  -webkit-transform: translateZ(0) translate3d(0px, 0px, 0px);
  transform: translateZ(0) translate3d(0px, 0px, 0px);
}

form {
  padding: 20px 20px;
}

form input {
  border: 1px solid white;
  border-radius: 2rem;
  padding: 8px 15px;
  color: black;
  font-size: 1rem;
}

form textarea {
  border: 1px solid white;
  padding: 8px 15px;
  border-radius: 1.2rem;
  color: white;
}

form.dark input {
  border: 1px solid black;
  color: black;
}

form.dark textarea {
  border: 1px solid black;
  color: black;
}

input.dark {
}

textarea,
select,
input {
  width: 100%;
}

.filter-black {
  filter: brightness(0) saturate(100%);
}

.filter-hashpro {
  filter: invert(84%) sepia(32%) saturate(1250%) hue-rotate(354deg)
    brightness(101%) contrast(94%);
}

.hashpro-gradient-bg-1 {
  background: radial-gradient(
    50% 50% at 50% 50%,
    var(--hashpro) 0%,
    #ffd60d12 100%
  );
  filter: blur(100px);
}

.gsap-marker-scroller-start,
.gsap-marker-scroller-end,
.gsap-marker-end,
.gsap-marker-start {
  z-index: 9999 !important;
}

.animated svg {
  position: absolute;
  top: 92%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: visible;
  width: 71%;
  height: 109px;
}

.animated path {
  stroke: var(--hashpro);
  stroke-width: 2px;
  fill: none;
  stroke-dasharray: 0 1500;
  transition: 0.8s;
}

.animated path.animate {
  stroke-dasharray: 1500 1500;
}

.bg-black-grain {
  background-image: url(/public/global/decoration/transparent-grain-white.png);
  background-size: 9rem;
}

.bg-white-grain {
  background-image: url(/public/global/decoration/transparent-grain.png);
  background-size: 12rem;
}

/*! CSS Used from: Embedded */
.policy-page-container {
  margin-top: 150px;
  margin-bottom: 50px;
  background: transparent;
}

.policy-heading h1 {
  font-size: 2.3rem;
  text-align: center;
  color: var(--hashpro);
}

#back {
  padding: 0;
}
.policy-body {
  margin-top: 30px;
  color: white;
}

.policy-body p,
li,
b {
  font-size: 1rem !important;
  line-height: 1.7;
  font-weight: 300;
  margin: 10px 0;
}

.policy-body b {
  font-weight: 500;
}

.policy-body h2 {
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 1.7rem;
  font-weight: 500;
}

.policy-body {
  font-size: 14px !important;
  line-height: 1.5;
  font-weight: 400;
}

.paynow-sec {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  background-color: #30312f;
}

.paynow-sec .container {
  padding-top: 2rem;
  height: 80vh;
  width: 400px;
  border: 0.5px solid white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  color: #050505;
  justify-content: start;
  overflow: hidden;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0e0c02;
}
.sign-in-img {
  background-image: url("../public/global/decoration/white-grain.svg");
  width: 40%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 5px 0px 0px 5px;
  border: 2px 0px 2px 2px solid black;
}

.sign-in-img img {
  height: 90%;
  width: 90%;
  object-fit: cover;
  border-radius: 5px;
  background-color: #f8d00d;
}
#logo-login {
  margin-top: 2rem;
  width: 50%;
}

.paynow-sec .container > p {
  margin-top: 0rem;
  font-size: 1rem;
  color: #ffffff;
  text-transform: capitalize;
  border-bottom: 2px solid white;
}
.paynow-sec .container {
  border: none;
  border-radius: 0px;
}
.paynow-sec .container button {
  margin-top: 1rem;
  border-radius: 5px 5px 5px 5px !important;
  text-transform: capitalize;
  background-color: #fff !important;
  color: #0e0c02 !important;
}

.paynow-sec {
  border-radius: 5px;
}

#userdash-default-payform {
  padding: 0;
  margin: 0;
}

#userdash-default-payform .fee-form-container {
  padding: 0;
}

#login-btn {
  display: flex;
  color: #f7c20a;
  width: 70%;
  background-color: #4c4c4c;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  border-radius: 20px;
  transition: 0.5s ease;
  font-size: 1rem;
  text-transform: none;
  border-radius: 20px;
}

#google {
  height: 30px;
  width: 30px;
  object-fit: cover;
  margin-left: 1rem;
}

#animate-login-text {
  max-width: 90%;
  text-align: center;
  margin-top: 4rem;
  color: white;
}
.def-from-text {
  text-align: center;
  margin-top: 150px;
}

#user-head-pay {
  display: none;
}
.def-from-text h1 {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  font-size: 2rem;
}
.def-from-text h1 span {
  color: #f7c20a;
  font-size: auto;
  display: block;
}
.fee-form-container {
  border: 2px solid white;
  width: 50%;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}

.fee-form-container {
  backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#userdash-default-payform {
  margin: 0;
  padding: 0;
}

.fee-form-container form {
  width: 90% !important;
  padding: 10px 5px !important;
}
.fee-form-container .form-group {
  width: 100% !important;
}
.fee-form-container .form-group input {
  border-radius: 5px;
  transition: ease 0.5s;
  padding: 6px 15px;
  border: 2px solid;
}

.fee-form-container form button {
  margin: 0;
  width: 100%;
  text-transform: capitalize;
  transition: 0.5s ease;
  border-radius: 5px;
  background-color: #fff;
}

.fee-form-container form button:hover {
  background-color: #050505;
  color: #fff;
}

#Refund-Policy ul > li {
  color: #f7c20a;
}

#Refund-Policy ul > li {
}

#Refund-Policy h3 {
  font-size: 20px;
}

#Refund-Policy ul ol > li {
  margin-left: 10px;
}

.fee-form-container .form-group input[type="checkbox"] {
  padding: 0px;
  height: 20px;
  width: 20px;
}
#termsacc {
  display: flex;
  align-items: center;
}
#termsacc span {
  margin-left: 10px;
}
#termsacc span a {
  all: unset;
  color: #f7c20a;
  cursor: pointer;
}
.fee-form-container .form-group select {
  border: none;
  border: 1px solid white;
  padding: 8px 15px;
  color: black;
  font-size: 1rem;
  border-radius: 5px;
}

.details-label {
  margin: 3px 0 !important;
  max-width: 90%;
}

.error-pay {
  color: #d23422;
  font-weight: 600;
}

.hilight {
}

.paynow-sec .container {
  border: none;
  border-radius: 1px 5px 5px 1px;
}

.paynow-sec {
  margin-top: 5rem;
}

.fee-form-container form button,
#refundpolicy {
  width: 0%;
  width: fit-content;
  background-color: #f9d00c;
  border-radius: 20px;
  margin: 0 auto;
  margin-top: 1.5rem;
}
.form-group {
  margin-bottom: 0.6rem;
}

#refundpolicy {
  margin: 1rem 0rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
#refundpolicy:hover {
  color: #050505;
}

#mobile-terms #termsaccpted {
  display: inline;
  color: #f7c20a;
}

@media screen and (max-width: 600px) {
  .fee-form-container input {
  }
  .fee-form-container .details-label {
    display: inline-block;
    max-width: 90%;
    text-align: left;
    margin-bottom: 0.5rem !important;
  }
  .fee-form-container form {
    width: 95% !important;
  }
  .paynow-sec {
    padding-top: 20px;
    margin-top: 0rem;
    border: 2px 0px solid;
  }
  .paynow-sec .container {
    height: 70vh !important;
    max-width: 100%;

    border-radius: 5px;
  }
  #animate-login-text {
    max-width: 90%;
    text-align: center;
    margin-top: 4rem;
    color: white;
  }

  .def-from-text {
    text-align: center;
    margin-top: 150px;
  }

  #user-head-pay {
    display: none;
  }
  .def-from-text h1 {
    margin: 0;
    padding: 0;
    padding-top: 10px;
  }
  .def-from-text h1 span {
    color: #f7c20a;
    font-size: auto;
    display: block;
  }
  .fee-form-container {
    border: 2px solid white;
    width: 90%;
    overflow: hidden;
    margin: 0 auto;
    display: flex;
    justify-content: center;
  }
  .fee-form-container {
    backdrop-filter: blur(20px);
    border-radius: 8px;
  }
  #back {
    background-image: linear-gradient(
      to right bottom,
      #a4902e,
      #796d26,
      #524b1d,
      #2f2b13,
      #0e0c02
    );
  }
  #login-btn {
    width: 90%;
  }

  #mobile-terms {
    margin-top: 1rem !important;
  }
  .fee-form-container form button {
    width: 0%;
    width: fit-content;
    background-color: #f7c20a;
    border-radius: 20px;
    margin: 0 auto;
    margin-top: 1rem;
  }
  .form-group {
    margin-bottom: 0.6rem;
  }
}

.fee-form-container {
  margin-bottom: 4rem;
}

#back {
  background-image: linear-gradient(
    to right bottom,
    #a4902e,
    #796d26,
    #524b1d,
    #2f2b13,
    #0e0c02
  );
  position: relative;
  top: -150px;
  align-items: stretch;
}

.def-from-text {
  background: none;
}
#userdash-default-payform {
  background: none;
  padding-bottom: 4rem;
}

@media screen and (min-width: 1440px) {
  .paynow-sec .container {
    max-height: 70vh;
  }
  .sign-in-img {
    background-color: #0e0c02;
    width: 50%;
    max-height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
  }
}

@media screen and (max-width: 767px) {
  .sign-in-img {
    display: none;
  }
}

.sign-in-img {
  border: 2px solid #0e0c02;
  border-right: 0px;
}

#login-btn {
  border-radius: 30px !important;
  transition: 0.5s ease;
  margin-top: 4rem;
}

#login-btn:hover {
  background-color: #30312f !important;
  color: white !important;
}

#terms-refund {
  color: #f8d00d !important;
}

.logout-container {
  position: relative;
}

.logout-dropdown {
  display: none;
  position: absolute;
  top: 90%;
  right: 0;
  left: 10%;
  color: white;
  z-index: 1000;
  padding: 0px;
  cursor: pointer;
}

.logout-dropdown p {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logout-container:hover .logout-dropdown {
  display: block;
}


