@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");

.main-header {
  column-gap: 50px;
  row-gap: 50px;
  color: black;
  background-color: #131313;
  background-position: top center;
  padding-top: 180px;
  padding-bottom: 100px;
  background-size: 75px;
}

@media only screen and (max-width: 600px) {
  .main-header {
    padding-top: 120px;
  }
}

@keyframes move-anim-grow-key {
  from {
    transform: translate(-13.3rem);
  }

  to {
    transform: translate(0rem);
  }
}

@media only screen and (max-width: 600px) {
  @keyframes move-anim-grow-key {
    from {
      transform: translate(-8.8rem);
    }

    to {
      transform: translate(0rem);
    }
  }
}

.main-header .move-anim-grow {
  animation: infinite move-anim-grow-key 3s linear;
}

.main-header h4 {
  text-align: center;
}

@media screen and (max-width: 900px) {
  .main-header {
    grid-template-columns: 1fr;
  }

  .m-bottom-left {
    display: none;
  }
}

.main-header .grid {
  /* display: grid; */
  grid-template-columns: 50% 50%;
}

.main-header .grid .points {
  display: none;
  /* display: flex; */

  height: fit-content;
  width: fit-content;
  margin: auto;
  flex-direction: column;
  gap: 10px;
}

.main-header .grid .points .point {
  display: flex;
  gap: 10px;
}

.main-header .grid .points .point img {
  width: 12px;
  height: 12px;
  display: block;
  margin: auto 0;
}

.main-header .grid .points .point p {
  font-size: 0.9rem;
  color: #dddddd;
}

.main-header .grid .points button {
  margin: 0 auto;
  margin-top: 20px;
  width: 100%;
}

.main-header .banner img {
  width: 100%;
  margin-top: 80px;
  border-radius: 2rem;
  box-shadow: 0px 0px 20px rgb(158, 158, 158);
}

@media only screen and (max-width: 600px) {
  .main-header .banner {
    /* width: 99vw; */
    margin: auto;
    /* margin-left: -10%; */
  }

  .main-header .banner img {
    width: 100vw;
    margin: 0 auto;
    /* margin-left: -10%; */
    display: block;
    margin-top: 60px;
    border-radius: 0rem;
    box-shadow: 0px 0px 20px rgb(74 74 74);
  }
}

.main-header .left {
  position: relative;
  margin: auto;
  text-align: left;
}

.main-header .heading {
  font-size: 6rem;
  text-align: center;
  line-height: 1.2;
  font-weight: 500 !important;
  /* line-height   : 90px; */
  /* text-align    : left; */
}

@keyframes move-right-arrows-anim {
  from {
    transform: translateX(-1rem);
  }

  to {
    transform: translateX(0rem);
  }
}

.move-right-arrows-course {
  animation: move-right-arrows-anim 1s linear infinite;
}

.main-header .subheading {
  text-align: center;
  margin-top: 52px;
  font-size: 20px;
  /* margin    : 15px; */
  color: rgb(202, 202, 202);
}

.main-header .right {
  /* background-color   : #f7c40a03; */
  border-radius: 15px;
  /* background-image: url('/public/global/home/design/main-header-r.png'); */
  background-size: cover;
  background-position: center;
  position: relative;
  width: 100%;
}

.main-header .right .hero-img {
  /* position: absolute; */

  /* transform: translateY(-50%); */
  /* display  : block; */
  display: flex;
  align-content: center;
  align-items: center;
  margin: auto;
  width: 100%;
  scale: 1.1;
  /* animation : move 3s ease-in-out infinite; */
}

@media screen and (max-width: 600px) {
  .main-header {
    /* padding-top: vh; */
  }

  .main-header .heading {
    font-size: 53px;
    line-height: 51px;
  }

  .main-header .subheading {
    margin-top: 63px;
    font-size: 16px;
  }
}

@keyframes move {
  0% {
    transform: translateY(-30px);
  }

  50% {
    transform: translateY(-20px);
  }

  100% {
    transform: translateY(-30px);
  }
}

.moving-img {
  position: absolute;
  width: 80px;
}

@keyframes tr {
  0% {
    transform: translateX(100px) rotateZ(60deg) translateY(-3px);
  }

  50% {
    transform: translateX(50px) rotateZ(30deg) translateY(0px);
  }

  100% {
    transform: translateX(0) rotateZ(5deg) translateY(-3px);
  }
}

@keyframes tl {
  from {
    transform: translateX(-100px) rotateZ(-120deg);
  }

  to {
    transform: translateX(0) rotateZ(-10deg);
  }
}

@keyframes br {
  from {
    transform: translateX(300px) rotateZ(60deg);
  }

  to {
    transform: translateX(0) rotateZ(0deg);
  }
}

@keyframes bl {
  from {
    transform: translateX(-300px) rotateZ(-60deg);
  }

  to {
    transform: translateX(0) rotateZ(0deg);
  }
}

.moving {
  animation: move infinite 1s;
  animation-delay: 0.5s;
}

.m-top-right {
  top: 0;
  right: 5%;
  animation: tr 1s forwards;
  animation-delay: 0.3s;
}

.m-top-left {
  display: none;
  top: -20%;
  left: 6%;
  animation: tl 1s forwards;
}

@media screen and (min-width: 1050px) {
  .m-top-left {
    top: -18%;
  }
}

.m-bottom-left {
  width: 60px;
  height: 60px;
  bottom: -100px;
  left: 10%;
  transform: translateX(-300px);
  animation: bl 1s forwards;
  animation-delay: 0.5s;
}

.m-bottom-right {
  bottom: -60px;
  right: 8%;
  transform: translateX(300px);
  animation: br 1s forwards;
  animation-delay: 0.3s;
}

@media screen and (max-width: 600px) {
  .moving-img {
    /* z-index: -1; */
    opacity: 0.5;
  }

  .m-bottom-right,
  .m-bottom-left {
    display: none;
  }
}

.home-page .credibility {
  text-align: center;
  padding-top: 60px;
  /* padding-bottom: 70px; */
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.credibility .first {
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: center;
  gap: 40px;
  flex-wrap: wrap;
}

.home-page .main-header .banner {
  width: 80%;
  margin: auto;
  margin-top: 30px;
}

@media only screen and (max-width: 600px) {
  .home-page .main-header .banner {
    width: 90vw;
    margin: auto;
    margin-top: 30px;
    margin-left: -3.5%;
  }

  .home-page .main-header .banner article.yt-lite {
    /* border-radius: 0 !important; */
  }
}

.home-page .main-header .banner video {
}

@media only screen and (max-width: 900px) {
  .credibility .first {
    grid-template-columns: 1fr 1fr;
  }
}

.credibility .first .card {
  /* filter: invert(1); */
  padding: 40px 20px;
  text-align: center;
  border: 2px solid white;
  border-radius: 1rem;
  width: 300px;
  backdrop-filter: blur(15px);
}

.credibility .first .card h3 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-top: 10px;
  transition: 0.2s;
  color: white;
}

.credibility .first .card img {
  transition: 0.2s;
  width: 100px;
  margin: 0 auto;
  filter: brightness(100%) saturate(0%);
}

.credibility .first .card p {
  font-size: 0.9rem;
  font-weight: 500;
  margin-top: 10px;
  transition: 0.2s;
  color: white;
}

@media only screen and (min-width: 600px) {
  .credibility .first .card:hover img {
    width: 50px;
  }

  .credibility .first .card:hover h3 {
    font-size: 3rem !important;
  }

  .credibility .first .card:hover p {
    font-size: 2rem;
    margin-top: 0;
  }
}

.credibility .second {
  /* margin-top: 40px; */
  width: 80%;
  margin: auto;
  display: flex;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: center;
}

@media only screen and (max-width: 600px) {
  .about-page .credibility .second {
    width: 100%;
  }
}

.credibility .second .card {
  /* background-color: #101010; */
  display: flex;
  padding: 20px 20px;
  /* text-align: center; */
  border: 1px solid #6b6b6b;
  border-radius: 1rem;
  gap: 20px;
  width: fit-content;
  margin: auto;
  backdrop-filter: blur(15px);
}

.credibility .second .card img {
  width: 80px;
}

.credibility .second .card p {
  font-size: 0.9rem;
  font-weight: 300;
  color: #dddddd;
  margin-top: 10px;
}

@media only screen and (min-width: 600px) {
  .credibility .second .card:hover h3 {
    font-size: 1.35rem;
    font-weight: 700;
    margin-top: 5px;
  }

  .credibility .second .card:hover img {
    width: 80px;
  }

  .credibility .second .card:hover p {
    font-size: 0.9rem;
    font-weight: 300;
    color: #dddddd;
    margin-top: 10px;
  }

  .credibility .second .card:hover h3 {
    font-size: 1.35rem;
    font-weight: 700;
    margin-top: 5px;
  }
}

.cta-2 {
  /* display                 : grid; */
  grid-template-columns: 1fr 1fr;
  border: var(--hashprolight) 1px solid;
  width: fit-content;
  border-radius: 8px;
  overflow: hidden;
  margin-top: 20px;
  /* margin               : 0 auto; */
  /* justify-content      : space-evenly; */
}

.cta-2 a {
  display: block;
  margin: 0 auto;
}

.cta-2:hover {
  cursor: pointer;
}

.cta-2 p {
  /* width          : fit-content; */
  padding: 15px 50px;
  transition: 0.3s;
  font-weight: 500;
  text-align: center;
  /* margin         : auto; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.cta-2 .left {
  background-color: #f8d00d;
  color: black;
  width: auto;
  margin: auto;
  font-weight: 600;
  /* overflow     : hidden; */
  /* border       : 2px solid black; */
}

.cta-2 .right {
  border-radius: 0px;
  background-image: none;
  background-color: #f8d00df0;
  color: black;
  width: auto;
}

@media screen and (max-width: 420px) {
  .cta-2 {
    display: block;
    width: 100%;
  }

  .cta-2 p {
    padding: 10px 20px;
  }
}

.cta-2 .left:hover {
  background-color: #e0e0e0;
  color: black;
}

.cta-2 .right:hover {
  background-color: #e0e0e0;
  color: black;
}

.industry-container {
  display: none;
  background-image: "url(/public/global/home/design/counter-bg.svg)";
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  margin-bottom: 30px;
}

.industry-ready {
  margin: 50px 0;
  border-radius: 15px;
  /* background-color: #0c0c0c; */
  background-color: white;
  color: white;
  padding: 0 5%;
  padding-top: 40px;
  text-align: center;
  padding-bottom: 50px;
}

@media only screen and (max-width: 600px) {
  .industry-container {
    padding: 0 2%;
  }

  .industry-ready {
    margin: 10px 2%;
  }
}

.industry-ready .heading {
  line-height: 42px;
  color: black;
}

.industry-ready .subheading {
  margin: 18px;
  margin-top: 16px;
  color: black;
  opacity: 1;
  font-weight: 600;
}

.stats {
  margin-top: 50px;
  display: flex;
  flex-wrap: wrap;
  row-gap: 20px;
}

@keyframes stroke {
  from {
    width: 0%;
  }

  to {
  }
}

.stats-card h1 {
  position: relative;
  margin: 0;
  margin-bottom: -3px;
  color: black;
  font-weight: 800;
}

.stats-card-container {
  margin: 10px 30px;
  display: flex;
  flex: 1;
  justify-content: space-evenly;
  width: fit-content;
}

.stats-card-container .underline-container {
  height: 2px;
  width: 100%;
  background-color: #f8d00d;
  margin: 10px 0;
  width: 80%;
  margin: 20px auto;
}

#counter-1,
#counter-2,
#counter-3,
#counter-4 {
  line-height: 1;
}

.stats-card p {
  margin: auto;
  white-space: nowrap;
}

.stats-card .symbol {
  display: inline-block;
  font-size: 30px;
  /* transform     : translateY(-3px); */
  /* line-height   : 150px; */
}

.stats-card .letter-symbol {
  display: inline-block;
  font-size: 30px;
  /* transform     : translateY(-2px); */
}

.stats-card .bottom {
  font-size: 16px;
  color: black;
  font-weight: 600;
}

@media screen and (max-width: 474px) {
  .stats-card .symbol,
  .stats-card .letter-symbol,
  .stats-card p {
    font-size: 25px;
    margin-bottom: 5px;
  }

  .stats-card .bottom {
    font-size: 16px;
    margin-top: 5px;
  }
}

.stats-card img {
  position: absolute;
  bottom: -3px;
  left: -15px;
  /* right   : auto; */
  animation: stroke 1s forwards;
}

.courses {
  position: relative;
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #0c0c0c;
  overflow: hidden;
  background-image: url(/public/global/texture/white.jpg);
}

@media only screen and (max-width: 800px) {
  .courses {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.courses .heading {
  color: black;
  text-align: left;
}

.home-page .courses .subheading {
  color: #656565;
}

.courses .slide-btn-container {
  display: none;
  /* display: flex; */
  column-gap: 15px;
  position: absolute;
  top: 50px;
  right: 50px;
}

.courses .slide-btn-container .btn-container {
  width: 60px;
  height: 60px;
  background-color: rgb(139, 139, 139);
  border-radius: 50%;
  position: relative;
  transition: 0.1s;
}

.courses .slide-btn-container .btn-container:hover {
  cursor: pointer;
  filter: invert(100%);
}

.courses .slide-btn-container img.left {
  transform: translate(-50%, -50%) rotate(180deg);
}

.courses .slide-btn-container img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50px;
  height: 50px;
  /* filter: invert(100%); */
}

.heading-img {
  width: 16px !important;
  scale: 2;
  position: absolute;
  top: 0;
  right: -2rem;
  filter: invert(90%) sepia(27%) saturate(3362%) hue-rotate(348deg)
    brightness(102%) contrast(102%);
  /* transform: translateY(12px) !important; */
  /* transform: translateY(12px) !important; */
}

.courses .heading img {
  position: absolute;
  filter: invert(1);
  width: 60px;
  top: -40%;
  right: -15%;
  /* transform: translateX(-50%); */
  height: 36px;
  filter: brightness(0) saturate(100%);
  /* filter: invert(90%) sepia(27%) saturate(3362%) hue-rotate(348deg) brightness(102%) contrast(102%); */
  rotate: 45deg;
}

@media only screen and (max-width: 600px) {
  .courses .heading img {
    top: -61%;
  }
}

.courses .subheading {
  margin-bottom: 50px;
  text-align: left;
}

@media only screen and (max-width: 600px) {
  .courses .subheading {
    margin-bottom: 0px;
  }
}

.courses .card-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-wrap: wrap;
  column-gap: 50px;
  row-gap: 30px;
  justify-content: space-evenly;
  align-items: left;
}

@media screen and (max-width: 1200px) {
  .courses .card-container {
    grid-template-columns: 1fr 1fr;
  }
}

@media screen and (max-width: 700px) {
  .courses .card-container {
    grid-template-columns: 1fr;
  }
}

.courses .card {
  /* height                   : max-content; */
  /* margin                   : 0 auto; */
  box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  /* overflow                 : hidden; */
  border-radius: 15px;
  position: relative;
  /* max-width                : 360px; */
  /* width                    : 240px; */
  background-color: #7e7e7e;
  height: 100%;

  max-height: 100%;
  width: 400px;
  max-width: 85vw;
}

/* @keyframes move-gradient {
    from {
        background-position: 0%;
    }

    to {
        background-position: 100%;

    }
} */
.courses .splide__slide {
  width: fit-content !important;
  margin: 0 20px !important;
}

.courses .splide__arrows {
  position: absolute;
  height: fit-content;
  width: fit-content;
  display: flex;
  column-gap: 20px;
  right: 10vw;
  top: 60px;
}

@media only screen and (max-width: 800px) {
  .courses .splide__arrows {
    /* padding-top: 10px; */
    top: 93%;
    right: 50%;
    transform: translateX(50%) translateY(-60%);
  }

  .courses .splide__arrow {
    width: 30px !important;
    height: 30px !important;
  }
}

.splide__arrow {
  background-color: white !important;
  opacity: 1 !important;
}

.courses .splide__arrow {
  position: static !important;
  background-color: white !important;
  opacity: 1 !important;
  width: 45px !important;
  height: 45px !important;
}

.courses .splide {
  position: static !important;
}

.home-page .courses .splide__pagination {
  display: none;
}

.courses .splide__track {
  overflow: visible !important;
  position: static !important;
}

.courses .card .message {
  z-index: 0;
  position: absolute;
  top: 15px;
  left: 10px;
  background-color: rgb(236, 236, 236);
  border-radius: 10px;
}

.courses .card .message p {
  font-weight: 500;
  font-size: 14px;
  color: black;
  padding: 0 15px;
}

.courses .card .text {
  color: black;
  padding: 20px;
  padding-bottom: 25px;
  padding-top: 8px;
}

.courses .card .course-img {
  transition: 0.3s;
  /* padding                : 30px; */
  width: 100%;
  background: rgb(40, 23, 0);
  background: -moz-linear-gradient(
    82deg,
    rgba(40, 23, 0, 0) 13%,
    rgba(255, 255, 255, 0.41780462184873945) 49%,
    rgba(79, 0, 0, 0) 85%
  );
  background: -webkit-linear-gradient(
    82deg,
    rgba(40, 23, 0, 0) 13%,
    rgba(255, 255, 255, 0.41780462184873945) 49%,
    rgba(79, 0, 0, 0) 85%
  );
  background: linear-gradient(
    82deg,
    rgba(40, 23, 0, 0) 13%,
    rgba(255, 255, 255, 0.41780462184873945) 49%,
    rgba(79, 0, 0, 0) 85%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#281700", endColorstr="#4f0000", GradientType=1);
  background-size: 700%;
  border: 1px solid rgb(48, 48, 48);
  border-bottom: none;
  border-radius: 8px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}

.courses .card .bullet-container {
  display: flex;
  margin-top: 10px;
  flex-wrap: wrap;
}

.courses .card .bullet-container .bullet {
  margin-bottom: 5px;
  margin-right: 10px;
  display: flex;
  height: fit-content;
  width: fit-content;
  border-radius: 20px;
  padding: 2px 10px;
  flex-shrink: 0;
}

.courses .card .bullet-container .bullet img {
  width: 30px;
  height: 30px;
  margin: auto;
}

.courses .card .bullet-container .bullet p {
  font-size: 12px;
  margin: auto;
  margin-left: 5px;
  font-weight: 600;
}

.courses .card .course-name {
  letter-spacing: 1px;
  margin-top: 4px;
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 3px;
}

.courses .card .description {
  color: black;
  font-size: 14px;
  margin: 5px 0;
}

.courses .card .description.light {
  font-weight: 400;
}

.courses .card .price {
  margin-top: 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid black;
  width: fit-content;
  color: black;
  font-size: 16px;
  font-weight: 700;
  border-radius: 2px;
}

.courses .card .price.white {
  color: white;
  border-bottom: 1px solid white;
}

.courses .card .price span {
  font-weight: 500;
}

.courses .card .open-course {
  display: inline-block;
  margin-bottom: 0;
  padding: 8px 20px;
  border-radius: 5px;
  border: none;
  font-weight: 500;
  transition: 0.3s;
  background-color: #363636;
  color: #cccccc;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 2px, rgba(0, 0, 0, 0.3) 0px 2px 4px,
    rgba(0, 0, 0, 0.3) 0px 4px 8px, rgba(0, 0, 0, 0.3) 0px 8px 16px,
    rgba(0, 0, 0, 0.3) 0px 16px 32px, rgba(0, 0, 0, 0.3) 0px 32px 64px;
  margin-top: 15px;
  font-size: 12px;
}

.courses .card .open-course:hover {
  cursor: pointer;
  scale: 1.02;
  background-color: #ffffff;
  color: black;
}

.courses-bg {
  width: 100%;
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  bottom: 0;
}

.courses-bg > div {
  width: 100%;
  background-color: black;
}

.courses .card.aoi {
  position: relative;
}

.courses .card.aoi .open-course {
  background-image: "linear-gradient(to bottom, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0.4) 100%), url(/public/global/home/design/aoi-btn-bg.png)";
  color: black;
  font-weight: 600;
}

.courses .card.aoi .design {
  position: absolute;
  width: 50px;
  height: 50px;
}

.courses .card.aoi .balloons {
  z-index: 1;
  top: -5px;
  left: -5px;
  rotate: -25deg;
}

.courses .card.aoi .popper {
  bottom: -10px;
  right: 0;
}

.courses .card.aoi .flags {
  top: -5px;
  right: -10px;
}

.swiper-wrapper {
  padding-bottom: 40px;
}

.white {
  color: white;
}

.faculties {
  overflow: hidden;
  position: relative;
  padding-top: 50px;
  color: black;
  background-color: #0c0c0c;
  padding-bottom: 50px;
}

@keyframes rotate-l {
  0% {
    rotate: 0deg;
  }

  50% {
    rotate: 180deg;
  }

  100% {
    rotate: 360deg;
  }
}

.faculties .ftl {
  display: none;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: -30px;
  background-image: "linear-gradient(to bottom, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.1) 100%), url(/public/global/home/design/yellow-dot.png)";
  height: 200px;
  width: 220px;
  background-size: 20px;
  opacity: 0.5;
  z-index: 0;
  animation: rotate-l infinite 10s linear;
}

.faculties .fbr {
  opacity: 0.4;
  position: absolute;
  bottom: -10px;
  right: -10px;
  background-image: "url(.design/yellow-dot.png)";
  height: 200px;
  width: 220px;
  background-size: 20px;
  animation: rotate-l infinite 10s linear reverse;
}

@media screen and (max-width: 759px) {
  .faculties .ftl {
    height: 150px;
    width: 180px;
    opacity: 0.2;
  }

  .faculties .fbr {
    height: 150px;
    width: 120px;
  }
}

.faculties .heading {
  color: white;
}

.faculties .faculty-container {
  overflow: hidden;
  margin-top: 30px;
  display: flex;
  justify-content: flex-start;
  padding-bottom: 40px;
}

.faculties .faculty-container .splide__arrow {
  width: 35px !important;
  height: 35px !important;
  top: 40% !important;
}

.faculties .faculty-container::-webkit-scrollbar {
  height: 10px;
  background-color: #0000002c;
  border-radius: 5px;
}

.faculties .faculty-container::-webkit-scrollbar-thumb {
  background-color: var(--hashpro);
  border-radius: 5px;
}

.faculties .faculty {
  margin: 0 15px;
  width: fit-content;
  margin: auto;
}

.home-page .faculty .faculty-img {
  border-radius: 6px;
  aspect-ratio: 1/1;
  width: 250px;
  height: 250px;
  box-shadow: 2px 2px white;
}

.faculty .text {
  margin-top: 5px;
  position: relative;
  margin-left: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
}

.faculty .text p {
  line-height: 28px;
}

.home-page .faculty .text .name {
  font-weight: 600;
  font-size: 0.95rem;
  color: black;
}

.home-page .faculty .text .desc {
  font-weight: 500;
  font-size: 0.85rem;
  line-height: 20px;
  color: rgba(45, 45, 45, 0.712);
}

.text .social-media {
  position: absolute;
  bottom: 0px;
  right: 0;
}

.home-page .faculty .text .social-media img {
  width: 20px;
  height: 20px;
  filter: invert(0.7);
}

.faculty .text .social-media img:hover {
  filter: invert(81%) sepia(69%) saturate(1755%) hue-rotate(352deg)
    brightness(100%) contrast(83%);
}

@keyframes move-right {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-1450px);
  }
}

.testimonial {
  background-size: cover;
  background-image: linear-gradient(to bottom, #0d0d0d, black);
  overflow: hidden;
  margin: 0;
  padding-top: 50px;
  padding-bottom: 40px;
}

.testimonial-cards {
  animation: move-right infinite linear 22s;
}

.reverse-animation {
  animation-direction: reverse;
}

.testimonial .subheading {
  text-align: center;
  margin-bottom: 30px;
}

.testimonial .heading {
  text-align: center;
}

.testimonial .subheading {
  margin-bottom: 30px;
}

.testimonial .testimonial-card {
  position: relative;
  width: 250px;
  border: 1px solid rgba(255, 255, 255, 0.034);
  padding: 25px 20px;
  background-color: #131313;
  box-shadow: rgb(0, 0, 0) 0px 20px 30px -10px;
  border-radius: 10px;
  background-size: cover;
  transition: 0.3s;
}

.testimonial .testimonial-card .para {
  font-size: 13px !important;
  color: white;
  line-height: 1.5;
}

.testimonial .testimonial-card .quote {
  opacity: 0.2;
  bottom: 20px;
  right: 20px;
  position: absolute;
  filter: invert(100%);
  width: 50px;
  height: 50px;
}

.testimonial-card:hover {
  scale: 1.1;
}

.testimonial-card-container {
  background-size: cover;
  margin: 20px;
  transition: 0.5s;
}

.testimonial-profile {
  margin-top: 20px;
  width: 100%;
  /* display   : flex; */
  display: none;
}

.testimonial-profile img {
  width: 30px;
  height: 30px;
}

.testimonial-profile-text {
  margin-left: 10px;
}

.testimonial-cards {
  display: flex;
}

.name {
  font-size: 0.9rem;
}

.designation {
  font-size: 0.8rem;
  color: rgba(227, 227, 227, 0.861);
}

/* 
.testimonial .testimonial-card:nth-child(1) {
    background-image: url(/public/global/home/images/testimonial-red.png);
} */

/* .tc-1 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%), url(/public/global/home/images/blob-scene-haikei.png);
}

.tc-2 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%), url(/public/global/home/images/testimonial-white.png);
}

.tc-3 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%), url(/public/global/home/images/testimonial-red.png);
}

.tc-4 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%), url(/public/global/home/images/testimonial-yellow.png);
}

.tc-5 {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.85) 100%), url(/public/global/home/images/blob-scene-haikei.png);
} */

/* 

.testimonial-card-container:nth-child(1) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/blob-scene-haikei.png);
}

.testimonial-card-container:nth-child(2) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/testimonial-white.png);
}

.testimonial-card-container :nth-child(3) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/testimonial-red.png);

}

.testimonial-card-container:nth-child(4) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/testimonial-yellow.png);

}

.testimonial-card-container:nth-child(5) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/blob-scene-haikei.png);

}

.testimonial-card-container:nth-child(6) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/testimonial-white.png);

}

.testimonial-card-container:nth-child(7) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/testimonial-red.png);

}

.testimonial-card-container:nth-child(8) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/testimonial-yellow.png);

}

.testimonial-card-container:nth-child(8) {
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 0%, rgba(0, 0, 0, 0.8) 100%), url(/public/global/home/images/blob-scene-haikei.png);

} */

@keyframes instagram-bg {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.instagram {
  padding: 0;
  position: relative;
  /* background            : linear-gradient(90deg, rgb(14 9 0) 0%, rgb(9 0 14) 49%, rgb(14 0 0) 100%); */
  background-size: 400% 400%;
  background-image: linear-gradient(to bottom, #000000, #080808);
  padding-top: 50px;
  /* animation: linear instagram-bg 20s infinite; */
  position: relative;
  padding-bottom: 50px;
}

@media screen and (max-width: 450px) {
  .instagram {
    animation: linear instagram-bg 5s infinite;
  }
}

.instagram-container {
  padding: 0 7%;
}

.instagram-container .splide__arrow {
  width: 30px !important;
  height: 30px !important;
}

.instagram .heading {
  text-align: left;
  /* background-color     : #f8d00d; */
  width: fit-content;
  /* color                : black; */
}

.instagram .heading img {
  width: 150px;
  height: 28px;
  margin: auto 0;
  padding-left: 6px;
  display: block;
}

@media only screen and (max-width: 600px) {
  .instagram .heading img {
    width: 130px;
    height: 24px;
    margin: auto 0;
    padding-left: 6px;
    display: block;
  }
}

.instagram a {
  width: fit-content;
  padding-bottom: 20px;
}

.instagram-follow-button {
  top: 45px;
  position: absolute;
  right: 5%;
  background: #833ab4;
  background: -moz-linear-gradient(
    270deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 99%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 99%
  );
  background: linear-gradient(
    270deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833ab4", endColorstr="#fcb045", GradientType=1);
  width: fit-content;
  border-radius: 15px;
  display: flex;
  align-items: center;
  padding: 6px 15px;
  transition: 0.5s;
}

@media screen and (max-width: 600px) {
  .instagram-follow-button {
    top: 53px;
  }
}

@media screen and (max-width: 450px) {
  .instagram-follow-button {
    opacity: 0.8;
    /* top    : 20px; */
  }
}

.instagram-follow-button:hover {
  scale: 1.1;
}

@media (min-width: 0px) and (max-width: 640px) {
  .instagram-follow-button p {
    display: none;
    padding: 2px;
  }

  .instagram-follow-button {
    padding: 4px;
  }
}

.instagram-follow-button p {
  margin-right: 10px;
}

.instagram-video-container {
  transform: translateY(10px);
  /* background-image: ; */
  /* border          : 1px solid #f8d00d69; */
  position: relative;
  width: fit-content;
  height: fit-content;
  overflow: hidden;
  margin: 0 auto;
  transition: 0.6s;
  border-radius: 10px;
}

.home-page .instagram-videos .splide__pagination {
  bottom: -28px !important;
}

.instagram-videos .splide__arrow {
  top: 60% !important;
}

.home-page .faculties .splide__pagination__page.is-active {
  background: white !important;
}

.home-page .faculties .splide__pagination__page {
  background: #333333 !important;
}

.instagram-video-anchor {
  display: block;
  width: fit-content;
  margin: 0 auto;
}

.instagram-video-container .reel-count {
  position: absolute;
  /* display : flex; */
  display: none;
  bottom: 15px;
  left: 15px;
}

.instagram-video-container .reel-count svg {
  color: rgb(241, 241, 241);
}

.instagram-video-container .reel-count span {
  display: inline-block;
  /* margin-bottom: 50px; */
  margin-left: 3px;
  color: rgb(241, 241, 241);
}

.instagram-video-container:hover .insta-play-btn-border {
  opacity: 1;
}

.insta-play-btn-border {
  background: rgb(131, 58, 180);
  background: -moz-linear-gradient(
    270deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 99%
  );
  background: -webkit-linear-gradient(
    270deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 99%
  );
  background: linear-gradient(
    270deg,
    rgba(131, 58, 180, 1) 0%,
    rgba(253, 29, 29, 1) 50%,
    rgba(252, 176, 69, 1) 99%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#833ab4", endColorstr="#fcb045", GradientType=1);

  filter: brightness(85%);
  transition: 0.4s;

  position: absolute;
  top: 50%;
  left: 50%;
  background-color: gray;
  border-radius: 15px;

  width: 45px;
  height: 45px;
  /* padding: 10px; */
  transform: translate(-50%, -50%);
  filter: brightness(80%);
}

.instagram-video-container img {
  width: 220px;
  aspect-ratio: 4/6;
  object-fit: cover;
}

.insta-play-btn {
  width: 40px !important;
  aspect-ratio: 1/1 !important;
  transform: translateX(2px) translateY(2px);

  /* position    : absolute;
    top            : 50%;
    left           : 50%;
    width          : 50px !important;
    opacity        : 0;
    z-index        : 500;
    transform      : translate(-50%, -50%); */
}

.thumbnail {
  transition: 0.3s;
  background-image: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgb(0, 0, 0) 22.27%
  );
}

.instagram-video-container:hover {
  cursor: pointer;
  scale: 1.02;
}

.instagram-video-container:hover .thumbnail {
  /* filter: blur(3px); */
}

.instagram-videos {
  user-select: none;
  padding-top: 50px !important;
}

.instagram-btn {
  position: absolute;
  top: 50%;
  z-index: 10;
  transform: translateY(-50%);
}

.instagram-btn:hover {
  cursor: pointer;
}

.instagram-pagination {
  margin: 0 auto;

  margin-top: -10px;
  width: fit-content !important;
}

.instagram-btn img {
  width: 30px;
  height: 30px;

  filter: invert(50%);
}

.insta-next {
  right: 0%;
}

.insta-prev {
  left: 0%;
}

.insta-prev img {
  rotate: 180deg;
}

#instagram-video-viewer {
  display: none;
  position: fixed;
  top: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 101;
  text-align: center;
  backdrop-filter: blur(10px);
}

#insta-video {
  position: absolute;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: max-content;
  height: 75vh;
}

#insta-close-btn {
  position: absolute;
  top: 2%;
  right: 2%;
  filter: invert(100%);
}

#insta-close-btn:hover {
  cursor: pointer;
  scale: 1.2;
}

.instagram-wave {
  display: none;
  background: linear-gradient(
    90deg,
    rgba(40, 23, 0, 1) 0%,
    rgba(22, 0, 37, 1) 49%,
    rgba(79, 0, 0, 1) 100%
  );
  background-size: 400% 400%;
  position: absolute;
  bottom: 0px;
  left: 0;
  background-image: "url(/public/global/home/design/insta-wave.png)";
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 100vw;
  transform: translateY(5px);
}

.learn-from-best {
  position: relative;
  z-index: 100;
  margin-bottom: -1px;
  /* background-image: linear-gradient(to bottom , rgba(0, 0, 0, 0.3) 0**************************************************%, rgba(0, 0, 0, 0.3) 100%) ,url('/public/global/home/design/grid.png'); */
  background-size: 30px;
  padding-top: 50px;
  text-align: center;
  padding-bottom: 50px;
  background-color: #090909;
}

.best {
  background-color: #151515;
  color: #f8d00d;
  padding: 0 5px;
}

.learn-from-best .heading {
  margin-bottom: 5px;
}

.learn-from-best .subheading {
  font-weight: 400;
}

.cta-container {
  position: relative;
  background-color: transparent;
  /* background-color: #ffffff; */
  padding-top: 69px;
  padding-bottom: 69px;
  border-bottom-left-radius: 6rem;
  border-bottom-right-radius: 6rem;
  margin-bottom: -1px;
  transition: 0.3s;
}

.home-page .cta {
  padding: 30px 35px;
  border-radius: 8px;
  margin: 0 auto;
  color: black;
  display: grid;
  grid-template-columns: 80% 20%;
  width: 80%;
  /* background-image           : url(/public/global/home/design/cta-bg-1.svg); */
  background-color: #f8d00d;
  background-size: 150%;
  transition: 0.3s;
  border: 1px solid #f8d00d42;
  /* animation                  : linear cta-bg 40s infinite; */
  box-shadow: 3px 3px white;
  row-gap: 20px;
}

@media screen and (max-width: 500px) {
  .home-page .cta {
    width: 90%;
    grid-template-columns: 1fr;
  }
}

.cta:hover {
  /* filter   : brightness(150%); */
  /* scale : 1.01; */
}

.home-page .cta .left {
  display: flex;
  align-items: center;
}

.home-page .cta .left .heading-cta {
  /* text-align: center;
    margin: auto; */
  text-transform: uppercase;
  font-size: 1.4rem;
  font-weight: 700;
  color: black;
  line-height: 1.5;
}

@media only screen and (max-width: 767px) {
  .home-page .cta .left .heading-cta {
    text-align: center;
  }
}

.home-page .cta .left .heading-cta span {
  font-size: 1.4rem;
  font-style: italic;
}

.cta:hover .left .heading {
  /* background             : linear-gradient(90deg, #fff,#FFD700  );
    background-size        : 400% 400%;
    -webkit-background-clip: text;
    background-clip        : text;
    -webkit-text-fill-color: transparent;
    animation              : cta-bg linear infinite 2s; */
}

.home-page .cta .right {
  margin: auto;
}

.home-page .cta .right button {
  width: 100%;
  margin: auto !important;
}

.cta .right .enroll-now p {
  font-weight: 600;
  padding: 10px;
}

@media screen and (max-width: 769px) {
  .cta {
    grid-template-columns: 1fr;
  }

  .cta .left .heading {
    margin-bottom: 20px;
    font-size: 25px;
  }

  .cta .right .enroll-now p {
    padding: 3px 10px;
  }
}

.learn-points-container {
  background-size: 200px;

  /* background-color: rgb(7,7,7); */

  margin-top: 20px;
  /* border                 : 1px solid rgba(255, 255, 0, 0.068); */
  /* backdrop-filter        : blur(50px)  ; */
  /* -webkit-backdrop-filter: blur(16px) saturate(180%); */
  border-radius: 12px;
}

.learn-points {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1350px) {
  .new-container .learn-points {
    flex-wrap: wrap;
  }
}

.new-container .grid-container {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  flex-wrap: wrap;
  transition: 0.2s;
}

@media only screen and (max-width: 1350px) {
  .new-container .grid-container {
    grid-template-columns: 1fr;
  }
}

.learn-point-card {
  width: 200px;
  margin: 20px;
}

.learn-points:hover {
  /* scale: 1.02; */
}

.learn-point-card img {
  width: 100px;
  /* filter: invert(100%);     */
}

.learn-point-card .para {
  margin-top: 15px;
  font-size: 17px;
  font-weight: 600;
}

@keyframes marquee-anim {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-1117px);
  }
}

.marquee {
  margin-top: 30px;
  display: flex;
  animation: marquee-anim linear infinite 15s;
  transition: 0.5s;
}

.marquee:hover {
  /* scale: 1.05; */
}

.marquee img {
  width: 120px;
  height: 20px;
  margin: auto;
  /* max-height   : 100%; */
  /* padding      : 9px 5px; */
  transform: translateY(3px);
}

.marquee pre {
  font-weight: 500;
  color: rgb(221, 221, 221);
  padding: 8px 0;
  border-top: 1px solid var(--hashpro);
  border-bottom: 1px solid var(--hashpro);
  font-size: 15px !important;

  line-height: 20px;
}

@media screen and (max-width: 600px) {
  .marquee img {
    width: 100px;
    height: 18px;
  }

  .marquee pre {
    font-size: 10px !important;
  }
}

/* USP-2  */
.usp-2-div {
  padding: 80px 7%;
  background-color: #0e0e0e !important;
}

@media screen and (max-width: 600px) {
  .usp-2-div {
    padding: 50px 0;
  }
}

.usp-2-div .usp-sub {
  margin: auto;
  text-align: center;
  margin-top: 40px;
  width: 90%;
  font-weight: 600;
  font-size: 20px;
  line-height: 35px;
  /* padding-bottom: 50px; */
}

.outline {
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

.usp-2 {
  margin: 0 5%;
  border-radius: 20px;
  background-color: #0d0d0d;
  overflow: hidden;
  position: relative;
  /* border-radius         : 30px; */
  /* margin                : 0 5%; */
  padding-top: 30px;
  padding-bottom: 40px;
  color: black;
  box-shadow: 0px 0px 5px white;
  /* background-image      : url(/public/global/home/design/usp-2-bg.svg); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

@media screen and (max-width: 600px) {
  .usp-2 {
    border-radius: 20px;
  }
}

.usp-2 .card-container {
  margin: 0 3%;
  margin-top: 20px;
  /* 
    display        : flex;
    justify-content: center;
    flex-wrap      : wrap; */

  display: grid;
  column-gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  /* background-color: black; */
  /* grid-gap: 1px; */

  row-gap: 20px;
}

@media screen and (max-width: 700px) {
  .usp-2 .card-container {
    grid-template-columns: 1fr 1fr;
    column-gap: 0px;
  }
}

@media screen and (max-width: 950px) {
  .usp-2 .card-container {
    /* grid-template-columns: 1fr 1fr; */
  }
}

.usp-2 .card {
  text-align: center;
  /* background-image   : linear-gradient(rgba(255, 255, 255, 0.8) ,rgba(255, 255, 255, 0.8)), url(/public/global/home/design/yellow-dot.png); */
  background-size: 10px;
  transition: 0.4s;
  /* background-color   : white; */
  /* padding            : 0 20px; */
  /* margin             : 0 10px; */
  max-width: 250px;
  /* box-shadow         : rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px; */
  margin: auto;
  border-radius: 8px;
}

.usp-2 .card:hover {
  scale: 1.06;
}

.usp-2 .card img {
  display: flex;
  width: 60px;
  height: 60px;
  margin: auto;
  transition: 0.2s;
}

.usp-2 .card:hover img {
  filter: contrast(150%);
}

@media screen and (max-width: 480px) {
  .usp-2 .card {
    width: 90%;
    max-width: none;
  }
}

.usp-2 .card .h {
  display: none;
  color: #e9e9e9;
  font-size: 16px;
  font-weight: 300;
  line-height: 20px;
  /* text-align : left; */
}

.usp-2 .card .para {
  color: white;
  font-weight: 600;
  margin-top: 10px;
  line-height: 16px;
  font-size: 14px;
}

.usp-2 .card .text {
  margin-top: 5px;
}

#faculties-a,
#whyhashpro-a {
  transform: translateY(-80px);
}

#usp-a {
  transform: translateY(-100px);
}

#contact-a {
  transform: translateY(-150px);
}

/* Test  */

:root {
  --image-size: 200px;
}

.test {
  /* overflow        : hidden; */
  /* margin-top      : 50px; */
  /* margin-bottom: 100vh; */
  padding-top: 230px;
  padding-bottom: 90px;

  position: relative;
  height: 200vh;

  display: block;
}

@media only screen and (max-width: 1000px) {
  .test {
  }
}

.test .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: linear-gradient(
    to bottom,
    #0d0d0d 0%,
    transparent 10%,
    transparent 90%,
    #090909 100%
  );
  z-index: 10;
}

.test .sticky-container {
  position: sticky;
  top: calc(50% - var(--image-size) - 100px);
  /* transform: translateY(-50%); */
  left: 0;
}

.test .testimonial-container {
  position: relative;
}

.test .testimonial-container .test-container {
  position: absolute;
  width: var(--image-size);
  height: var(--image-size);
}

.test .testimonial-container .img-container {
  overflow: hidden;
  width: var(--image-size);
  height: var(--image-size);
  position: relative;
}

.test .testimonial-container .img-container img.one {
  z-index: 5;
}

.test .testimonial-container .img-container img.two {
  z-index: 0;
}

.test .testimonial-container img {
  position: absolute;
  width: var(--image-size);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.test .testimonial-container .center {
  padding: 300px 0;

  position: relative;

  height: 100%;
}

.test .testimonial-container .center h2 {
  font-size: 30px;

  position: absolute;
  top: 50%;
  width: 100%;
  text-align: center;

  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.test .testimonial-container .tl {
  top: -70%;
  left: 0;
  z-index: 2;
}

.test .testimonial-container .tc {
  top: -70%;
  left: 50%;
  transform: translateX(-50%);
}

.test .testimonial-container .tr {
  top: -70%;
  right: 0;
}

.test .testimonial-container .bl {
  bottom: 150%;
  left: 0;
  transform: translateX(-50%);
}

.test .testimonial-container .bc {
  bottom: 150%;
  left: 50%;
  transform: translateX(-100%);
}

.test .testimonial-container .br {
  /* bottom   : calc(100% - 200px); */
  bottom: 150%;
  right: 0;
  transform: translateX(-50%);
}

.new-test-slider {
  overflow: hidden;
  padding-top: 60px;
  padding-bottom: 60px;
  /* margin        : 50px 0; */
}

.new-test-slider .container {
  margin-top: 20px;
}

.new-test-slider .splide__slide {
  width: fit-content !important;
}

.new-test-slider .splide__track {
  /* overflow: visible !important; */
}

.new-test-slider .splide__slide img {
  width: 300px;
  height: 300px;
}

/* Testimonial Controller  */
.new-test-slider {
  display: none;
}

@media only screen and (max-width: 1000px) {
  .new-test-slider {
    display: block;
  }

  .test {
    display: none;
  }
}

.courses .card {
  border: 2px solid;
  border-radius: 10px;
  position: relative;
  padding: 30px;
  border-image: repeating-linear-gradient(
      135deg,
      white 0 10px,
      white 0 20px,
      white 0 30px
    )
    8;
  -webkit-mask: conic-gradient(
        from 180deg at top 8px right 8px,
        #0000 90deg,
        #000 0
      )
      var(--_i, 200%) 0 /200% var(--_i, 8px) border-box no-repeat,
    conic-gradient(at bottom 8px left 8px, #0000 90deg, #000 0) 0
      var(--_i, 200%) / var(--_i, 8px) 200% border-box no-repeat,
    linear-gradient(#000 0 0) padding-box no-repeat;
  transition: 0.3s, -webkit-mask-position 0.3s 0.3s;
}

/* 
.courses .card:hover {
    --_i      : 100%;
    color     : #CC333F;
    transition: .3s, -webkit-mask-size .3s .3s;
} */

.courses .card .arrow {
  /* background-color: white; */
  border-radius: 50%;

  rotate: -45deg;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 15px;
  right: 15px;
  box-sizing: content-box;
}

.courses .card.one {
  background-color: var(--hashpro);
  color: black;
}

.courses .card.two {
  background-color: #121212;
  color: white;
  border: 1px solid white;
}

.courses .card.two .arrow {
  filter: invert(100%);
}

.courses .card.two .bullet {
  background-color: var(--hashpro);
  border: 1px solid var(--hashpro);
}

.courses .card.two .bullet p {
  color: black;
}

.courses .card.two .bullet img {
  filter: invert(0);
}

.courses a {
  height: 100%;
  display: inline-block;
  transition: 0.2s ease-in-out;
}

.courses a:hover {
  /* scale : 1.1; */
  cursor: pointer;
  transform: translateY(-8px);
  z-index: 999;
}

.courses .card .description {
  color: inherit;
}

.courses .card .bullet {
  background-color: #121212;
}

.courses .card .bullet p {
  color: white;
}

.courses .card .bullet img {
  filter: invert(100%);
}

.courses .card.three {
  background-color: var(--hashpro);
  color: black;
}

.courses .card.three .arrow {
  display: none;
}

.courses .card.three .description {
  font-weight: 600;
}

.courses .card.four {
  background-color: #121212;
  color: white;
}

.courses .card.four .arrow {
  display: none;
  filter: invert(100%);
}

.courses .card.four .bullet {
  background-color: white;
  border: 1px solid var(--hashpro);
}

.courses .card.four .bullet p {
  color: black;
}

.courses .card.four .bullet img {
  filter: invert(0);
}

.home-page .hero h1 {
  font-size: 60px;
}

.new-test-slider,
.test {
  display: none;
}

@keyframes move-left {
  from {
    transform: 0;
  }

  to {
    transform: translateX(-197px);
  }
}

.coming-soon {
  position: absolute;
  /* rotate          : -45deg; */
  bottom: 0;
  left: 0;
  background-color: white;
  /* display         : flex; */
  white-space: nowrap;
  animation: move-left linear 2s infinite;
}

.coming-soon span {
  display: block;
  white-space: nowrap;
  font-size: 12px !important;
  color: black;
  font-weight: 600;
}

@media screen and (max-device-width: 480px) {
  body {
    -webkit-text-size-adjust: 100%;
  }
}

.usp-scroller-container {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f8d00d;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.usp-scroller .flex {
  display: flex;
  column-gap: 20px;
}

.usp-scroller img {
  width: 28px;
  height: 28px;
  filter: invert(1);
  filter: drop-shadow(3px 3px 0px black);
}

.italic-gradient {
  /* font-family: "Satisfy", cursive; */
  font-size: 24px;
  background-image: linear-gradient(
    to right,
    #000000,
    rgb(54, 54, 54),
    #000000
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  width: fit-content;
  line-height: 1.1;
  margin: auto 0;
  font-weight: 600;
}

.usp-scroller p {
  white-space: nowrap;
}

.usp-scroller .bold {
  color: black;
  font-size: 22px;
  font-weight: 700;
  margin: auto 0;
}

@media only screen and (max-width: 600px) {
  .usp-scroller p {
    font-size: 0.9rem;
  }

  .usp-scroller .bold {
    font-size: 0.9rem;
  }

  .usp-scroller img {
    width: 15px;
    height: 15px;
  }

  .usp-scroller-container {
    padding-top: 17px;
    padding-bottom: 15px;
    gap: 12px;
  }
}

.usp-scroller.forward {
  transform: translateX(-100%);
}

@keyframes usp-glow-container-anim-box {
  0% {
    transform: rotateX(0deg);
  }

  49% {
    transform: rotateX(90deg);
  }

  50% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

@keyframes usp-glow-container-anim-text {
  from {
    transform: rotateX(0);
    /* opacity: 1; */
  }

  49% {
    /* opacity: 1; */
    transform: rotateX(90deg);
  }

  50% {
    /* opacity: 0; */
    transform: rotateX(90deg);
  }

  to {
    /* opacity: 0; */
    transform: rotateX(0deg);
  }
}

@keyframes usp-glow-container-anim-text-default {
  from {
    transform: rotateX(0deg);
    /* opacity: 0; */
  }

  49% {
    /* opacity: 0; */
    transform: rotateX(90deg);
  }

  50% {
    transform: rotateX(90deg);

    /* opacity: 1; */
  }

  to {
    /* opacity: 1; */
    transform: rotateX(0);
  }
}

@keyframes usp-glow-container-anim-box-default {
  0% {
    transform: rotateX(0deg);
  }

  49% {
    transform: rotateX(90deg);
  }

  50% {
    transform: rotateX(90deg);
  }

  100% {
    transform: rotateX(0deg);
  }
}

.usp-glow-container {
  background-color: #131313;

  box-shadow: 3px 3px white;
  display: grid;
  grid-template-columns: 40% 60%;
  padding-top: 50px;
  padding-bottom: 70px;
  column-gap: 30px;
  row-gap: 30px;
}

@media only screen and (max-width: 1000px) {
  .usp-glow-container {
    grid-template-columns: 100%;
  }
}

.usp-glow-container .heading-container {
  height: fit-content;
  margin: auto 0;
}

.usp-glow-container .heading-container h1 {
  /* font-size  : 3rem; */
  text-align: left;
  /* line-height: 1; */
  margin: auto;
}

.usp-glow-container .heading-container h3 {
  /* font-size : 1rem;     */
  text-align: left;
  margin-top: 10px;
}

@media only screen and (max-width: 600px) {
  .usp-glow-container .heading h1 {
    /* font-size: 2.5rem; */
  }
}

.empty {
  grid-area: empty;
  background-color: transparent;
}

.home-page .usp-container .text-box {
  border: 1px solid #ffffff29;
}

.home-page .usp-container .text-box:nth-child(1) {
  grid-area: usp-one;
}

.home-page .usp-container .text-box:nth-child(2) {
  grid-area: usp-two;
}

.home-page .usp-container .text-box:nth-child(3) {
  grid-area: usp-three;
}

.home-page .usp-container .text-box:nth-child(4) {
  grid-area: usp-four;
}

.home-page .usp-container .text-box:nth-child(5) {
  grid-area: usp-five;
}

.home-page .usp-container .text-box:nth-child(6) {
  grid-area: usp-six;
}

.home-page .usp-container .text-box:nth-child(7) {
  grid-area: usp-seven;
}

.home-page .usp-container .text-box:nth-child(8) {
  grid-area: usp-eight;
}

.home-page .usp-container .text-box:nth-child(9) {
  grid-area: usp-nine;
}

.home-page .usp-container .text-box:nth-child(10) {
  grid-area: usp-ten;
}

.home-page .usp-container {
  background-image: radial-gradient(
    #f8d00d36,
    #f8d00d21,
    transparent,
    transparent
  );
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas:
    "usp-one . usp-six . "
    ". usp-four . usp-nine"
    "usp-two . usp-seven ."
    ". usp-five . usp-ten"
    "usp-three . usp-eight .";
  gap: 10px;
}

@media only screen and (max-width: 600px) {
  .home-page .usp-container {
    grid-template-columns: 1fr 1fr 1fr;
    padding-top: 10px;
    grid-template-areas:
      "usp-one . usp-six"
      ". usp-four ."
      "usp-two . usp-seven"
      ". usp-five ."
      "usp-three . usp-eight"
      ". usp-ten ."
      "usp-nine . .";
  }

  /* .home-page .usp-container {
        grid-template-areas: 'usp-one usp-two'
            'usp-three usp-four'
            'usp-six usp-five'
            'usp-seven usp-nine'
            'usp-eight usp-ten';

    } */
}

.usp-glow-container .text-box {
  width: 100%;
  transition: 1s;
  background-color: #131313;
  color: white;
  padding: 5px;
  border-radius: 5px;
  box-shadow: 3px 3px #f8d00d17;
  height: -moz-fit-content;
  border: 1px solid #ffffff0f;
  height: fit-content;
  margin: auto 0;
}

.usp-glow-container .text-box.show {
  /* box-shadow      : 0; */

  animation: 1s ease-in-out forwards usp-glow-container-anim-box-default;
}

.usp-glow-container .text-box p {
  text-align: center;
  /* color: black; */
  font-weight: 600;
  font-size: 14px;
  animation-delay: 2s;
}

.usp-glow-container .text-box.show p {
  animation: 1s ease-in-out forwards usp-glow-container-anim-text-default;
}

.usp-glow-container .text-box {
  animation: 1s ease-in-out forwards usp-glow-container-anim-box;
}

.usp-glow-container .text-box p {
  animation: 1s ease-in-out forwards usp-glow-container-anim-text;
}

.usp-glow-container .heading img {
  position: absolute;
  top: -30%;
  right: -25%;
  filter: invert(1);
  width: 51px;
  filter: invert(90%) sepia(27%) saturate(3362%) hue-rotate(348deg)
    brightness(102%) contrast(102%);
}

.course-heading {
  /* background-image: linear-gradient(to bottom, #000000aa, #000000aa), url(/public/global/texture/black.jpg); */
}

@media only screen and (max-width: 600px) {
  .course-heading {
    /* background-image: linear-gradient(to bottom, #00000005, #00000005), url(/public/global/texture/black.jpg); */
  }
}

.courses-container-2 {
  padding-top: 20px;
  padding-bottom: 6rem;
  display: flex;
  flex-direction: column;
  /* gap: 200px; */
  /* background-image: linear-gradient(to bottom, #000000aa, #000000aa), url(/public/global/texture/black.jpg); */
}

@media only screen and (max-width: 600px) {
  .courses-container-2 {
    padding: 20px 1%;
    padding-bottom: 5rem;
    /* background-image: linear-gradient(to bottom, #00000011, #00000011), url(/public/global/texture/black.jpg); */
  }
}

.courses-container-2 .heading-container {
  padding: 10px 7%;
  margin-bottom: 20px;
}

.courses-container-2 .heading-container .subheading {
  text-align: center;
}

.courses-container-2 .course-card {
  background-color: black;
  position: sticky;
  top: 120px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  padding: 50px;
  /* margin               : 0 2%; */
  border-radius: 10px;
  row-gap: 20px;
  color: white;
}

@media only screen and (max-width: 600px) {
  .courses-container-2 .course-card {
    grid-template-columns: 1fr;
    padding: 30px;
    top: 95px;
    /* margin-bottom: 100px; */
  }
}

@keyframes spin-star {
  from {
    rotate: 0deg;
  }

  to {
    rotate: 360deg;
  }
}

.courses-container-2 .course-card .info .star-redirect-container {
  display: flex;
  justify-content: space-between;
}

.courses-container-2 .course-card .info .star {
  display: flex;
  column-gap: 10px;
  background-color: #f8d00d;
  width: fit-content;
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 20px;
  color: black;
}

.courses-container-2 .course-card .info .star p {
  margin: auto 0;
  line-height: 2;
  /* color      : black; */
  font-weight: 700;
  font-size: 12px;
  text-transform: uppercase;
}

.courses-container-2 .course-card .info .star img {
  width: 20px;
  animation: spin-star 5s linear infinite;
  margin: auto 0;
  filter: invert(1);
}

.courses-container-2 .course-card .info .redirect {
  display: flex;
  column-gap: 10px;
  transform: translateY(5px);
}

.courses-container-2 .course-card .info .redirect span {
  display: block;
  margin: auto 0;
}

.courses-container-2 .course-card .info .redirect a {
  font-size: 12px;
  /* display : block; */
  /* margin  : auto 0; */
  height: fit-content;
  line-height: 2.4;
}

.courses-container-2 .course-card .info .redirect .arrow-anim-container {
  width: 22px;

  overflow-x: hidden;
  height: fit-content;
}

.courses-container-2 .course-card .info .redirect .slider {
  display: flex;
  column-gap: 10px;
  transform: translateX(-150%);
  transition: 0.5s ease-out;
}

.courses-container-2 .course-card .info .redirect:hover .slider {
  transform: translateX(0%);
}

.courses-container-2 .course-card .info .redirect img {
  width: 22px;
  filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%)
    hue-rotate(348deg) brightness(98%) contrast(86%);
  height: 28px;
  display: block;
  margin-bottom: 2px;
  /* margin    : auto 0; */
}

.courses-container-2 .course-card .info h2 {
  font-size: 1.8rem;
}

.courses-container-2 .course-card .info .para {
  margin-top: 10px;
  font-size: 0.9rem;
  line-height: 1.3rem;
  font-weight: 300;
}

@media only screen and (max-width: 600px) {
  .courses-container-2 .course-card .info .para {
    font-size: 0.83rem;
  }

  .courses-container-2 .course-card .info .star {
    margin-bottom: 10px;
  }
}

.courses-container-2 .course-card .info .bullets {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}

.courses-container-2 .course-card .info .bullets .bullet {
  display: flex;
  column-gap: 10px;
}

.courses-container-2 .course-card .info .bullets .bullet p {
  font-size: 0.8rem;
  font-weight: 300;
}

.courses-container-2 .course-card .info .bullets .bullet img {
  transform: translateY(-5px);
  width: 18px;
  height: 21px;
  display: block;
  margin: auto 0;
  /* animation: spin-star 5s linear infinite reverse; */
  filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%)
    hue-rotate(348deg) brightness(98%) contrast(86%);
}

.courses-container-2 .course-card .media {
  height: fit-content;
  margin: auto;
  width: 100%;
}

.courses-container-2 .course-card:nth-child(2) {
  background-color: white;
  color: black;
}

.courses-container-2 .course-card:nth-child(2) .info .para {
  font-weight: 500;
}

.courses-container-2 .course-card:nth-child(2) .info .bullets .bullet p {
  font-weight: 500;
}

.courses-container-2 .course-card:nth-child(2) .star {
  /* border          : 2px solid #f8d00d; */
  background-color: black;
  color: white;
}

.courses-container-2 .course-card:nth-child(2) .star img {
  filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%)
    hue-rotate(348deg) brightness(98%) contrast(86%);
}

.courses-container-2 .course-card:nth-child(2) .redirect span {
  color: black;
}

.courses-container-2 .course-card:nth-child(2) .redirect img {
  /* filter: brightness(0) saturate(100%) */
}

/* .courses-container-2 .course-card:nth-child(3) .info .bullet img,
.courses-container-2 .course-card:nth-child(3) .info .star img {
    filter: brightness(0) saturate(100%) invert(88%) sepia(18%) saturate(2099%) hue-rotate(348deg) brightness(98%) contrast(86%);
}

.courses-container-2 .course-card:nth-child(3) {
    background-color: #f8d00d;
    color           : black;
}

.courses-container-2 .course-card:nth-child(3) .star {
    border          : 2px solid white;
    background-color: transparent;

}
.courses-container-2 .course-card:nth-child(3) .star {}

.courses-container-2 .course-card:nth-child(3) .info .bullet img,
.courses-container-2 .course-card:nth-child(3) .info .star img {
    filter: brightness(0) saturate(100%);
} */

.course-heading {
  padding-top: 60px;
  text-align: center;
  padding-bottom: 30px;
}

.why-hashpro {
  background-color: #505050;
  background-image: linear-gradient(#000000dd, #000000dd),
    url("/public/global/home/images/bg-grid.png");
  padding-top: 70px;
  padding-bottom: 70px;
  background-size: 54px;
}

.why-hashpro .subheading {
  text-align: center;
}

.why-hashpro .grid {
  margin-top: 40px;
  display: grid;
  grid-template-columns: 30% 23.3% 23.3% 23.3%;
  gap: 20px;
}

@media only screen and (max-width: 1100px) {
  .why-hashpro .grid {
    grid-template-columns: 50% 50%;
  }
}

@media only screen and (max-width: 600px) {
  .why-hashpro .grid {
    grid-template-columns: 100%;
  }
}

.why-hashpro .grid .banner {
  border-radius: 1rem;

  border: 1px solid #464646;
  overflow: hidden;
}

.why-hashpro .grid .banner img {
  width: 100%;
  object-fit: cover;
  object-position: center;
  height: 100%;
}

.why-hashpro .grid .card {
  background-color: #131313;
  padding: 30px;
  border-radius: 1rem;

  border: 1px solid #464646;
}

.why-hashpro .grid .card img {
  width: 50px;
  height: 50px;
}

.why-hashpro .grid .card h3 {
  font-weight: 700;
  margin-top: 20px;
  font-size: 1.2rem;
  color: var(--hashpro);
}

.why-hashpro .grid .card p {
  font-weight: 300;
  margin-top: 15px;
  line-height: 1.5;
  font-size: 0.9rem;
}

@keyframes bg-move {
  from {
    background-position: 0% 100%;
  }

  to {
    background-position: 100% 100%;
  }
}

@media only screen and (min-width: 1000px) {
  .home-page .new-bg-move {
    animation: alternate ease-in-out 3s bg-move infinite;
  }
}

