.jd-container .points {
  margin: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.jd-container .points .point {
  display: flex;
  gap: 8px;
}

.jd-container .points .point img {
  width: 10px;
  height: 13px;
  display: inline-block;
  filter: invert(1);
  margin: auto 0;
}

.jd-container .heading {
  text-align: left;
  margin-bottom: 50px;
}

.jd-container {
  padding-top: 10rem;
  padding-bottom: 3rem;
}

.resumeBox {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-btn-file {
  width: 50%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  border-style: none;
  padding: 1em 0em;
  border-radius: 1.5rem;
  overflow: hidden;
  z-index: 1;

  transition: all 250ms;
}
.container-btn-file input[type="file"] {
  position: absolute;
  width: 50%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.choosedfile {
  overflow: hidden;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1rem;
}

.container-btn-file::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0;
  border-radius: 1.5rem;
  z-index: -1;
  transition: all 350ms;
}

.container-btn-file:hover::before {
  width: 100%;
}

@media only screen and (max-width: 600px) {
  .jd-container {
    padding-top: 8rem;
  }
}

.jd-container .jd-heading {
  font-size: 1.4rem;
  margin-bottom: 8px;
  color: var(--hashpro);
}

.jd-container .jd {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.jd-container .grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
}

@media only screen and (max-width: 1100px) {
  .jd-container .grid {
    grid-template-columns: 1fr;
  }
}

@media screen and (min-width: 320px) and (max-width: 426px) {
  .container-btn-file {
    width: 70%;
    background-color: black;
    padding: 0.7rem 1rem;
    color: white;
    margin-top: 5px;
  }

  .resumeBox {
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .choosedfile {
    padding: 0 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 10px;
    margin-top: 2px;

    margin: 0;
    padding: 0;
  }
}

@media only screen and (max-width: 600px) {
  .jd-container form {
    padding: 0 !important;
  }
}
